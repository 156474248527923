import { Object } from "aws-sdk/clients/s3";
import { defaultTo } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import ArrowDownloadSVG from "../../../assets/arrow_download.svg";
import BookSVG from "../../../assets/book.svg";
import EmailSVG from "../../../assets/email.svg";
import * as Intercom from "../../services/intercom";
import TourSVG from "../../../assets/tour.svg";
import { ChameleonSettings, IntercomSettings } from "../../models/bespoken-user";
import { State } from "../../reducers";
import { push } from "react-router-redux";
import sleep from "../../utils/sleep";
import { User } from "../../reducers/user";
import { Organization, SelectedOrganization } from "../../reducers/organization";

const style = require("./needHelpStyles.scss");
const globalWindow: any = typeof (window) !== "undefined" ? window : {};

interface NeedHelpProps {
    user: User;
    organization: SelectedOrganization;
    chmlnSettings: ChameleonSettings;
    intercomSettings: IntercomSettings;
    goTo: (path: string) => (dispatch: any) => void;
}

const mapStateToProps = (state: State.All) => ({
    user: state.user?.currentUser,
    organization: state.organization?.selectedOrganization,
    chmlnSettings: defaultTo(state?.context.appSettings?.chameleon, {}),
    intercomSettings: defaultTo(state?.context.appSettings?.intercom, {})
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        goTo: (path: string) => {
            dispatch(push(path));
        },
    }
};

export class NeedHelp extends React.Component<NeedHelpProps, undefined> {
    getSections = (isTrial: boolean, areThereVirtualDevices: boolean) => [
        {
            sectionName: "Guided Tours",
            items: [
                {
                    icon: <TourSVG />,
                    primaryText: "Dashboard Overview",
                    secondaryText: "Explains the different Dashboard components",
                    onClick: () => {
                        this.props.goTo('/skills');
                        this.showTour(this.props.intercomSettings.autoWelcomeTour);
                    },
                },
                {
                    icon: <TourSVG />,
                    primaryText: "Create a Virtual Device",
                    secondaryText: "Your first step for e2e testing and monitoring",
                    onClick: () => {
                        this.props.goTo('/virtualdevice');
                        this.showTour(this.props.intercomSettings.autoVirtualDevicesTour);
                    },
                },
                {
                    icon: <TourSVG />,
                    primaryText: "Test and monitor your test suites",
                    secondaryText: "Make sure test suites are up and running 24/7",
                    onClick: () => {
                        this.props.goTo('/skills');
                        this.showTour(this.props.intercomSettings.manualTestPageTour);
                    },
                },
            ]
        },
        {
            sectionName: "Need more help?",
            items: [
                {
                    icon: <BookSVG />,
                    primaryText: "Read the Docs",
                    secondaryText: "Master how to use our tools",
                    onClick: () => window.open("https://read.bespoken.io/", "_blank"),
                },
                {
                    icon: <ArrowDownloadSVG />,
                    primaryText: "Download our CLI",
                    secondaryText: "Test your test suites from preferred console",
                    onClick: () => window.open("https://read.bespoken.io/api/cli/", "_blank"),
                },
                {
                    icon: <EmailSVG />,
                    primaryText: "Contact Support",
                    secondaryText: "Drop us and email and we'll get in touch",
                    onClick: () => window.location.href = "mailto:support@bespoken.io?subject=Dashboard support request",
                },
            ]
        }
    ]

    showTour = async (tourId: string) => {
        await sleep(1000);
        Intercom.startIntercomTour(globalWindow, tourId);
    }

    handleOnClickItem = (onClick: any) => {
        onClick && onClick();
    }

    renderItem = (item: any, index: string) => (
        <div key={index} className={style.item} onClick={this.handleOnClickItem.bind(this, item.onClick)}>
            {item.icon && <div data-id="icon"> {item.icon} </div>}
            <div data-id="description">
                <div data-id="primaryText">{item.primaryText}</div>
                <div data-id="secondaryText">{item.secondaryText}</div>
            </div>
        </div>
    )

    renderGuidedTours = () => {
        const { virtualDevices = {}, status } = this.props.organization || {};
        const areThereVirtualDevices = !!Object.keys(virtualDevices).length;
        const isTrialPlan = status === "trial"
        return this.getSections(isTrialPlan, areThereVirtualDevices).map((section, index, array) => {
            const items: any = section.items;
            const isLastItem = array.length === index + 1;
            return (
                <div className={style.section} key={index}>
                    <div data-id="innerSection">
                        {!!section.sectionName &&
                            <div data-id="sectionName">{section.sectionName}</div>
                        }
                        {items.map(this.renderItem)}
                    </div>
                    {!isLastItem && <hr />}
                </div>
            );
        });
    }

    render() {
        return (
            <div className={style.needHelp}>
                {this.renderGuidedTours()}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NeedHelp);
