import * as cn from "classnames";
import { capitalize, chain, filter, isEmpty, isEqual } from "lodash";
import * as React from "react";
import { CSSProperties } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Button } from "react-toolbox/lib/button";
import Drawer from "react-toolbox/lib/drawer";
import Snackbar from "react-toolbox/lib/snackbar";
import Tooltip from "react-toolbox/lib/tooltip";
import { setLoading } from "../actions/loading";
import { setCurrentPageProps } from "../actions/session";
import { fetchUser } from "../actions/user";
import Avatar from "../components/Avatar/Avatar";
import { LoaderIndicator } from "../components/ConsolidatedReports";
import { DateText, DeleteMessageIconButton, HorizontalLine, IconUserProfile, PrimaryButtonMedium, TextBlueLabelSmall, TextBlueStrongLabelMedium, TextButtonSmall, TextNormal, TextTableHeader, TrashCanIconButton } from "../components/lunacy";
import { ERROR } from "../components/lunacy/debug-panel/DebugPanel";
import { OrganizationUserRoleDropdown, OrganizationUserRoleDropdownWithLabel } from "../components/lunacy/dropdowns/OrganizationUserRoleDropdown";
import { InputWithLabel } from "../components/lunacy/inputs/InputWithLabel";
import { BespokenModal } from "../components/Modal/BespokenModal";
import { WarningModal } from "../components/Modal/WarningModal";
import { ReactLi } from "../components/ReactDiv";
import BespokenSideBar from "../components/SideBar/SideBar";
import { ValidateEmailStatus } from "../components/ValidationText/ValidationText";
import { State } from "../reducers";
import { MemberRole, SelectedOrganization } from "../reducers/organization";
import { CurrentPageProps } from "../reducers/session";
import { fetchInternalApi } from "../services/internal-api";
import { attemptInvoke, wrapCallbackAsAsync } from "../utils/ReactHelpers";

import BespokenBackground from "../../assets/bespoken_background.svg";
import DefaultPluginIcon from "../../assets/bespoken_default_plugin_icon.svg";
import BespokenLogo from "../../assets/bespoken_logo.svg";
import BespokenMountains from "../../assets/bespoken_mountains.svg";
import Billing from "../../assets/billing.svg";
import MonitoringHistory from "../../assets/monitoring.svg";
import QuestionMark from "../../assets/question_mark.svg";
import VirtualDevices from "../../assets/virtual_devices.svg";
import VoiceApps from "../../assets/voice_apps.svg";
import { fetchAppSettings } from "../actions/context";
import { fetchOrganization } from "../actions/organization";
import { setFilterParameters } from "../actions/reporting";
import { BespokenLoader } from "../components/Loader/LoaderV2";
import { PlanIdTypes } from "../constants/stripe";
import NeedHelp from "../containers/NeedHelp/NeedHelp";
import { AuthUser } from "../reducers/context";
import { User } from "../reducers/user";
import { chameleonIdentify } from "../services/chameleon";
import * as Intercom from "../services/intercom";
import sleep from "../utils/sleep";

const globalWindow: any = typeof (window) !== "undefined" ? window : {};
const bespokenButton = require("../themes/bespoken_button.scss");

const PluginIcon = ({ path, width = 25, height = 25 }: { path: string, width?: number, height?: number }) => (<svg width={width} height={height}><image {...{ "href": path }} width={width} height={height} /></svg>);
const absoluteLayout: CSSProperties = {
  paddingLeft: 80,
  paddingTop: 120,
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "#f0f3f8",
  zIndex: 0,
};
const absoluteImageLayout: CSSProperties = {
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: -1,
};

interface DispatchToProps {
  fetchAppSettings: () => Promise<any>;
  fetchUser: () => Promise<any>;
  fetchOrganization: () => Promise<any>;
  goTo?: (path: string) => any;
  setFilterParameters: any;
  setLoading: (value: boolean) => void;
}
function mapDispatchToProps(dispatch: any): DispatchToProps {
  return {
    fetchAppSettings: () => wrapCallbackAsAsync(handle => dispatch(fetchAppSettings(handle))),
    fetchUser: () => wrapCallbackAsAsync(handle => dispatch(fetchUser(handle))),
    fetchOrganization: () => wrapCallbackAsAsync(handle => dispatch(fetchOrganization("default", handle))),
    goTo: (path: string) => dispatch(push(path)),
    setFilterParameters: (value: any) => dispatch(setFilterParameters(value)),
    setLoading: (value: any) => dispatch(setLoading(value))
  };
}

interface StateToProps {
  authUser: AuthUser;
  user: User;
  organization: SelectedOrganization;
  loading: boolean;
}
function mapStateToProps(state: State.All): StateToProps {
  return {
    authUser: state.context.authUser,
    user: state.user.currentUser,
    organization: state.organization.selectedOrganization,
    loading: state.loading?.loading
  };
}

interface ExposedProps { }
function mergeProps(ownProps: any, stateProps: any, dispatchProps: ExposedProps) {
  return { ...ownProps, ...stateProps, ...dispatchProps };
}
interface PageProps extends DispatchToProps, StateToProps, ExposedProps { }

interface PageState {
  showCookiePolicySnackbar: boolean;
  showCookiePolicyModal: boolean;
  selectedItemId: string;
}

class DashboardComponent extends React.Component<PageProps, PageState> {
  firebaseListener: any;
  constructor(props: PageProps) {
    super(props);
    this.state = {
      showCookiePolicySnackbar: false,
      showCookiePolicyModal: false,
      selectedItemId: "",
    };
  }

  async componentDidMount() {
    this.launchChameleonAndUpdateIntercom();
  }

  async componentWillReceiveProps(nextProps: Readonly<PageProps>, nextContext: any): Promise<void> {
    // console.log("DashboardComponent:componentWillReceiveProps", nextProps, this.props);
    // JPK - wait for a user (auth or not) AND the organization before proceeding
    if (
      ((nextProps.authUser?.id &&
        this.props.authUser?.id !== nextProps.authUser?.id) ||
      (nextProps.user?.id &&
        this.props.user?.id !== nextProps.user?.id)) &&
      (nextProps.organization?.id &&
        this.props.organization?.id !== nextProps.organization?.id)) {
      this.launchChameleonAndUpdateIntercom();
    }
  }

  componentWillUnmount() {
  }

  onBeforeNavigate(it: any): void {
    if (it.id === "history") {
      this.props.setFilterParameters(undefined);
    }
  }

  launchChameleonAndUpdateIntercom = () => {
    const { organization, user } = this.props;
    // If the organization has not been loaded into the state, skip this routine (this happens on login)
    if (!organization) {
      return;
    }
    const { dailyUtterances, platforms, numberOfDays, utterances } = organization.subscription || {};
    const numberOfPlatforms = platforms.length;
    const subscribedPlatforms = platforms?.reduce((acc: string, item: string, index: number) => {
      if (numberOfPlatforms === 1) {
        acc = item;
      } else if (index === numberOfPlatforms - 1) {
        acc += ` and ${item}`;
      } else {
        acc += `, ${item}`;
      }
      return acc;
    }) || "";
    // console.log('launchChameleon', globalWindow, subscribedPlatforms)
    const status = organization.status === "trial" ? "onTrial" : organization.status;
    globalWindow && Intercom.updateIntercom(globalWindow, {
      company: {
        name: organization.name,
        id: organization.id,
        plan: organization.planId,
        size: organization.members?.length || 0,
        remote_created_at: new Date(organization.creationDate).getTime() / 1000,
        status,
        trialEndDate: organization?.subscription?.endDate,
        githubRepo: organization.githubRepo,
        ibmUser: organization.ibmUser,
        numTestSuites: (organization.sources || []).length,
      }
    }, user.email);
  }

  render({ children } = this.props) {
    const preItems: any = [
      {
        id: "skills",
        icon: <VoiceApps />,
        label: "Test Suites",
      },
      {
        id: "virtualdevice",
        icon: <VirtualDevices />,
        label: "Virtual Devices",
      },
      {
        id: "history",
        icon: <MonitoringHistory />,
        label: "History",
      },
      {
        id: "billing",
        icon: <Billing />,
        label: "Billing",
      },
      {
        id: "needHelp",
        icon: <QuestionMark />,
        label: "Need Help?",
        isSecondaryOption: true,
        sideModalComponent: <NeedHelp />,
      },
    ];

    const { authUser, user, organization } = this.props;

    // Adding plugins into menu items
    chain(organization)
      .pick("plugins").values().first().defaultTo([])
      .map((rest, index) => ({ ...rest, index }))
      .reverse()
      .forEach(({ index, pluginTitle: label, pluginIconURL }) => {
        // console.info("loading plugin: " + label);
        const positionToInsert = 3;
        const icon = isEmpty(pluginIconURL) ? <DefaultPluginIcon /> : <PluginIcon path={pluginIconURL} />;
        preItems.splice(
          positionToInsert, 0,
          { id: `plugins/${index}`, label, icon }
        );
      })
      .value();

    const enableMonitoringHistory = process.env.ENABLE_MONITORING_HISTORY_PAGE &&
      organization?.subscription?.planId !== PlanIdTypes.STARTUP;
    const { ibmUser = false, status } = organization || {};
    const hideBilling = ["trial", "trialEnded", "disabled"].indexOf(status) > -1;
    const items = chain(preItems)
      .thru(elements => enableMonitoringHistory ? elements : filter(elements, it => it.id !== "history"))
      .thru(elements => hideBilling ? filter(elements, it => it.id !== "billing") : elements)
      .value();

    const extraChildrenProps = {
      goTo: this.props.goTo,
    };
    // const notFoundPage = this.isNotFoundPage();

    const isMissingData = isEmpty(authUser) || isEmpty(user) || isEmpty(organization);
    return (
      <div style={absoluteLayout}>
        {
          <div style={absoluteLayout}>
            <BespokenSideBar
              className="bespokenSidebar"
              goTo={this.props.goTo}
              onBeforeNavigate={(it: any) => this.onBeforeNavigate(it)}
              user={this.props.user}
              items={items}
              onLogoClick={items[0]}
              currentOrganization={this.props.organization}
            />
            <Avatar
            />
            <BespokenLoader active={this.props.loading} />
            <BespokenBackground style={absoluteImageLayout} />
            {
              !this.props.organization?.subscription?.isTrialTimeExpired &&
              <BespokenMountains id={"bespokenMountains"} style={absoluteImageLayout} />
            }
            {!!children && React.cloneElement((children as any), { ...extraChildrenProps })}
            {/*
            <Snackbar
              key={"optOutSnackBar"}
              className="sm-snackbar optOutSnackBar"
              action="Dismiss"
              type="cancel"
              label={<p style={{ fontSize: 12, marginBottom: 0 }}>
                Our site uses cookies to give you the best possible testing experience and to improve our features.
                Continue browsing if you’re OK with this. And don’t worry! We don’t
                share the collected data with anyone.
                <a style={{ fontSize: 12, color: "rgb(244, 67, 54)" }}
                  onClick={() => this.setState({ showCookiePolicyModal: true })}>Opt out</a></p>
              }
              active={this.state.showCookiePolicySnackbar}
              onClick={() => this.setState({ showCookiePolicySnackbar: false })}
            />
            <CookiePolicyModal
              onAcceptDialog={() => this.setState({ showCookiePolicyModal: false })}
              onOptOutDialog={() => this.setState({ showCookiePolicyModal: false })}
              showModal={this.state.showCookiePolicyModal}
            />
 */}
          </div>}
      </div>
    );
  }
}

export const Dashboard = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(DashboardComponent);

type CookiePolicyModalProps = { showModal: boolean, onAcceptDialog: () => void, onOptOutDialog: () => void };
const CookiePolicyModal = (props: CookiePolicyModalProps) => {
  const { onAcceptDialog, onOptOutDialog, showModal } = props;
  return (
    <BespokenModal
      title="Remove user invitation"
      showModal={showModal}
      dialogToggle={() => attemptInvoke(onOptOutDialog)}
    >
      <p>Bespoken provides tools to test, tune, and monitor voice experiences. Our tools, services, and
        app use cookies to understand how people use our products.</p><br />
      <p><strong>What are cookies and how we use them</strong></p><br />
      <p>A cookie is a small text file that is stored in your device so our website can recognize it.
        Almost every site uses cookies. We use cookies to understand how people interact with the
        content and features on our website and use this information to improve our product. You’ll
        never see a third-party personalize ad, and we don’t share the collected data with anyone.</p>
      <br />
      <div className={bespokenButton.buttons_container}>
        <Button style={{ marginBottom: 5 }} theme={bespokenButton} accent={true}
          onClick={() => attemptInvoke(onAcceptDialog)}>I am OK with this</Button>
        <p style={{ textAlign: "right", cursor: "pointer" }} onClick={() => attemptInvoke(onOptOutDialog)}>I do not consent
          to the use of cookies</p>
      </div>
    </BespokenModal>
  );
};