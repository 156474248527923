import * as React from "react";

export interface ShadowDomProps {
    label?: string;
    children?: any;
    builder: (shadowRoot: ShadowRoot) => void;
}

export interface ShadowDomState {
    label?: string;
}

export class ShadowDom extends React.Component<ShadowDomProps, ShadowDomState> {

    state: ShadowDomState;
    props: ShadowDomProps;

    constructor(props: ShadowDomProps) {
        super(props);

        this.state = {
            label: "hello!!"
        };
    }

    attachShadow(host: Element) {
        if (!host || host.shadowRoot) return;

        const shadowRoot = host.attachShadow({ mode: "open" });

        if (this.props.builder) {
            this.props.builder(shadowRoot);
        }
    }

    render() {
        return (
            <div ref={host => this.attachShadow(host)} />
        );
    }

}
