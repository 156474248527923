
namespace errorHelper {
  const customMessagesByCode: any = {
      "custom/not-valid-email": "The email address is not a valid email.",
      "custom/empty-fields": "Please enter missing information.",
      "custom/user-have-email-account": "You have previously authenticated using an Email and Password account. Please, use the same method when logging in.",
      "custom/trying-login": "Error returned trying to log in.",
      "custom/password-not-match": "Passwords do not match.",
      "custom/password-length": "Password must be greater than five characters.",
      "custom/fake-error": " ",
      "auth/wrong-password": "The password is wrong. Please, try again.",
      "auth/too-many-requests": "Too many unsuccessful login attempts. Please try again in a few minutes.",
      "custom/cookies": "This browser is not supported or 3rd party cookies and data may be disabled.",
      "custom/disposable-email": `This domain is not allowed. Please, use another email account or <a href="mailto:support@bespoken.io">get in touch with us</a>`,
  };

  export const getErrorCode = (err?: any) => err && (err.code || err.message);

  export const isEqualToErrorCode = (err: any, code: string) => getErrorCode(err) === code;

  export const getMessage = (err: any) => {
    const code: string = getErrorCode(err);
    const message = customMessagesByCode[code];
    return message || err.message;
  };
}

export default errorHelper;
