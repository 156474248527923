
import * as React from "react";
import BespokenLogo from "../../../assets/bespoken_logoweb.svg";

const Styles = require("./ibm-logout.scss");

export const LogoutPage: () => JSX.Element = () => <div className={Styles.container}>
    <div className={Styles.llama_icon}>
        <BespokenLogo />
    </div>
    <div className={Styles.message}>
        <span>Thanks for using Bespoken.</span>
        <span>To login again, please do so through <a href="https://cloud.ibm.com/resources"> IBM Cloud</a>.</span>
    </div>
</div>
