import { Moment } from "moment";
import momentHelper from "../helpers/moment";

export enum userStatus {
    Unsubscribed = "unsubscribed",
    Disabled = "disabled",
    Enabled = "enabled",
    Trial = "trial",
    TrialEnded = "trialEnded",
}

export interface UserBenefits {
    platforms: string[];
}

export interface PluginDef {
    pluginURL: string; pluginIconURL: string; pluginTitle: string;
}

export class UserDetails {
    readonly uid?: string;
    readonly userId?: string;
    readonly email?: string;
    readonly silentEchoToken?: string;
    readonly smAPIAccessToken?: string;
    readonly vendorID?: string;
    readonly status?: string;
    readonly stripeSubscribedPlanId?: string;
    readonly stripeCustomerObjId?: string;
    readonly planId?: string;
    readonly name?: string;
    readonly company?: string;
    readonly jobTitle?: string;
    readonly virtualDevices?: object;
    readonly originalCreatedAt?: string;
    readonly createdAt?: Moment;
    readonly isTrialTimeExpired?: boolean;
    readonly diffTrialTime?: number;
    readonly trialEndDate: string;
    readonly subscriptionEndDate?: string;
    readonly surveyCompleted?: boolean;
    readonly surveyData?: any;
    readonly isTrialPlan?: boolean;
    readonly isSubscribed?: boolean;
    readonly isUnsubscribed?: boolean;
    readonly isDisabled?: boolean;
    readonly isSubscriptionExpired?: boolean;
    readonly expirationDate?: Moment;
    readonly expirationDateUnix?: number;
    readonly benefits?: any;
    readonly newsLetter?: boolean;
    readonly mailchimpId?: string;
    readonly isInDowngradeProcess?: boolean;
    readonly downgradeDate?: number;
    readonly isTrialTimeExpiredWithoutStatus?: boolean;
    readonly weeklyStats?: boolean;
    readonly yearlyPlan?: boolean;
    readonly musicVirtualDevices?: boolean;
    readonly userRequiresActivation?: boolean;
    readonly hardLimits?: boolean;
    readonly skipEmailVerification?: boolean;
    readonly monthlyUsage?: number;
    readonly genesysClientId?: string;
    readonly genesysClientSecret?: string;
    readonly genesysAccessToken?: string;
    readonly genesysRefreshToken?: string;
    readonly plugins?: PluginDef[];
    readonly ibmUser?: boolean; // should be used only for sending the survey

    constructor(user: any = {}) {
        this.uid = user.uid;
        this.userId = user.userId;
        this.email = user.email;
        this.silentEchoToken = user.silentEchoToken;
        this.smAPIAccessToken = user.smAPIAccessToken;
        this.vendorID = user.vendorID;
        this.status = user.status;
        this.stripeSubscribedPlanId = user.stripeSubscribedPlanId;
        this.stripeCustomerObjId = user.stripeCustomerObjId;
        this.planId = user.planId;
        this.name = user.name;
        this.company = user.company;
        this.jobTitle = user.jobTitle;
        this.virtualDevices = user.virtualDevices;
        this.originalCreatedAt = user.creationTime;
        this.createdAt = user.creationTime ? momentHelper.parseToUTCmoment(user.creationTime) : momentHelper.parseToUTCmoment(momentHelper.getNowUtcFormat());
        this.isTrialTimeExpired = user.isTrialTimeExpired;
        this.diffTrialTime = user.diffTrialTime && parseInt(user.diffTrialTime);
        this.trialEndDate = user.trialEndDate;
        this.subscriptionEndDate = user.subscriptionEndDate;
        this.surveyCompleted = user.surveyCompleted;
        this.surveyData = user.surveyData;
        this.isTrialPlan = user.status === userStatus.Trial && !user.stripeSubscribedPlanId;
        this.isSubscribed = user.status === userStatus.Enabled;
        this.isUnsubscribed = user.status === userStatus.Unsubscribed;
        this.isDisabled = user.status === userStatus.Disabled;
        this.isSubscriptionExpired = (user.status === userStatus.Disabled) ||
            (user.status === userStatus.Unsubscribed && user.subscriptionEndDate && momentHelper.getNowUtc().isAfter(user.subscriptionEndDate));
        this.expirationDate = user.trialEndDate && momentHelper.parseToUTCmoment(user.trialEndDate);
        this.expirationDateUnix = this.expirationDate?.unix();
        this.benefits = user.benefits;
        this.newsLetter = user.newsLetter;
        this.mailchimpId = user.mailchimpId;
        this.isInDowngradeProcess = user.isInDowngradeProcess;
        this.downgradeDate = user.downgradeDate;
        this.isTrialTimeExpiredWithoutStatus = user.isTrialTimeExpiredWithoutStatus;
        this.weeklyStats = user.weeklyStats;
        this.yearlyPlan = user.yearlyPlan;
        this.musicVirtualDevices = user.musicVirtualDevices;
        this.userRequiresActivation = user.userRequiresActivation;
        this.hardLimits = user.hardLimits;
        this.skipEmailVerification = user.skipEmailVerification;
        this.monthlyUsage = user.monthlyUsage;
        this.genesysClientId = user.genesysClientId;
        this.genesysClientSecret = user.genesysClientSecret;
        this.genesysAccessToken = user.genesysAccessToken;
        this.genesysRefreshToken = user.genesysRefreshToken;
        this.plugins = (user.plugins || []).map(({ pluginURL, pluginIconURL, pluginTitle }: PluginDef) => ({ pluginURL, pluginIconURL, pluginTitle }));
        this.ibmUser = user.ibmUser;
    }
}

export interface UserProperties {
    readonly email: string;
    readonly userId?: string;
    readonly displayName?: string;
    readonly photoUrl?: string;
    emailVerified?: boolean;
    surveyCompleted?: boolean;
    stripeSubscribedPlanId?: string;
    stripeCustomerObjId?: string;
    skipEmailVerification?: boolean;
}

export class User implements UserProperties {

    readonly userId: string;
    readonly email: string;
    readonly displayName?: string;
    readonly photoUrl?: string;
    emailVerified?: boolean;
    surveyCompleted?: boolean;
    stripeSubscribedPlanId?: string;
    stripeCustomerObjId?: string;
    skipEmailVerification?: boolean;

    constructor(props: UserProperties) {
        this.userId = props.userId;
        this.email = props.email;
        this.displayName = props.displayName;
        this.photoUrl = props.photoUrl;
        this.emailVerified = props.emailVerified;
        this.surveyCompleted = props.surveyCompleted;
        this.stripeSubscribedPlanId = props.stripeSubscribedPlanId;
        this.stripeCustomerObjId = props.stripeCustomerObjId;
        this.skipEmailVerification = props.skipEmailVerification;
    }
}

export default User;

import { remoteservice } from "../services/remote-service";

export class FirebaseUser extends User {

    constructor(user: remoteservice.user.User) {
        super({
            userId: user.uid, email: user.email, displayName: user.displayName,
            photoUrl: user.photoURL, emailVerified: user.emailVerified
        });
    }
}
