/*
 * These are the variables that determine what our central data store (reducer.js)
 * changes in our state. When you add a new action, you have to add a new constant here
 */

export type LOG_LEVELS = "DEBUG" | "INFO" | "WARN" | "ERROR";

export type AUTH_FORM_CHANGED = "bst/AUTH_FORM_CHANGED";
export const AUTH_FORM_CHANGED: AUTH_FORM_CHANGED = "bst/AUTH_FORM_CHANGED";

export type AUTH_FORM_ERROR = "bst/AUTH_FORM_ERROR";
export const AUTH_FORM_ERROR: AUTH_FORM_ERROR = "bst/AUTH_FORM_ERROR";

export type SET_USER = "bst/SET_USER";
export const SET_USER: SET_USER = "bst/SET_USER";

export type SET_USER_DETAILS = "bst/SET_USER_DETAILS";
export const SET_USER_DETAILS: SET_USER_DETAILS = "bst/SET_USER_DETAILS";

export type SET_BESPOKEN_USER = "SET_BESPOKEN_USER";
export const SET_BESPOKEN_USER: SET_BESPOKEN_USER = "SET_BESPOKEN_USER";

export type SET_BESPOKEN_USER_VIRTUAL_DEVICES = "SET_BESPOKEN_USER_VIRTUAL_DEVICES";
export const SET_BESPOKEN_USER_VIRTUAL_DEVICES: SET_BESPOKEN_USER_VIRTUAL_DEVICES = "SET_BESPOKEN_USER_VIRTUAL_DEVICES";

export type SET_AMAZON_FLOW = "bst/SET_AMAZON_FLOW";
export const SET_AMAZON_FLOW: SET_AMAZON_FLOW = "bst/SET_AMAZON_FLOW";

export type SET_EMAIL_SENT = "bst/SET_EMAIL_SENT";
export const SET_EMAIL_SENT: SET_EMAIL_SENT = "bst/SET_EMAIL_SENT";

export type SET_LOADING = "bst/SET_LOADING";
export const SET_LOADING: SET_LOADING = "bst/SET_LOADING";

export type SET_DEBOUNCING = "bst/SET_DEBOUNCING";
export const SET_DEBOUNCING: SET_DEBOUNCING = "bst/SET_DEBOUNCING";

export type SET_EDITOR = "bst/SET_EDITOR";
export const SET_EDITOR: SET_EDITOR = "bst/SET_EDITOR";

export type SET_CURRENT_PAGE_PROPS = "bst/SET_CURRENT_PAGE_PROPS";
export const SET_CURRENT_PAGE_PROPS: SET_CURRENT_PAGE_PROPS = "bst/SET_CURRENT_PAGE_PROPS";


export const SENDING_REQUEST = "SENDING_REQUEST";

export const LOGIN_USER_PENDING = "bst/LOGIN_USER_PENDING";
export const LOGIN_USER_SUCCESS = "bst/LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "bst/LOGIN_USER_ERROR";
export const LOGOUT_USER = "bst/LOGOUT_USER";

export type FETCH_LOGS_REQUEST = "bst/FETCH_LOGS_REQUEST";
export const FETCH_LOGS_REQUEST: FETCH_LOGS_REQUEST = "bst/FETCH_LOGS_REQUEST";

export type SET_LOGS = "bst/SET_LOGS";
export const SET_LOGS: SET_LOGS = "bst/SET_LOGS";

export type SET_CURRENT_SOURCE = "bst/SET_CURRENT_SOURCE";
export const SET_CURRENT_SOURCE: SET_CURRENT_SOURCE = "bst/SET_CURRENT_SOURCE";

export type SET_SOURCES = "bst/SET_SOURCES";
export const SET_SOURCES: SET_SOURCES = "bst/SET_SOURCES";

export type SET_SOURCE_SEARCH_TEXT = "bst/SET_SOURCE_SEARCH_TEXT";
export const SET_SOURCE_SEARCH_TEXT: SET_SOURCE_SEARCH_TEXT = "bst/SET_SOURCE_SEARCH_TEXT";

export type REMOVE_SOURCE = "bst/REMOVE_SOURCE";
export const REMOVE_SOURCE: REMOVE_SOURCE = "bst/REMOVE_SOURCE";

export type CREATE_SOURCE_SUCCESS = "bst/CREATE_SOURCE_SUCCESS";
export const CREATE_SOURCE_SUCCESS: CREATE_SOURCE_SUCCESS = "bst/CREATE_SOURCE_SUCCESS";

export type FETCH_SOURCES_REQUEST = "bst/FETCH_SOURCES_REQUEST";
export const FETCH_SOURCES_REQUEST: FETCH_SOURCES_REQUEST = "bst/FETCH_SOURCES_REQUEST";

export type FETCH_SOURCE_SUCCESS = "bst/FETCH_SOURCES_SUCCESS";
export const FETCH_SOURCES_SUCCESS: FETCH_SOURCE_SUCCESS = "bst/FETCH_SOURCES_SUCCESS";

export type SET_SNACKBAR_MESSAGE = "bst/SET_SNACKBAR_MESSAGE";
export const SET_SNACKBAR_MESSAGE: SET_SNACKBAR_MESSAGE = "bst/SET_SNACKBAR_MESSAGE";

export type ADD_TOAST = "bst/ADD_TOAST";
export const ADD_TOAST: ADD_TOAST = "bst/ADD_TOAST";
export type REMOVE_TOAST = "bst/REMOVE_TOAST";
export const REMOVE_TOAST: REMOVE_TOAST = "bst/REMOVE_TOAST";


export type SOURCE_SET_CURRENT = "SOURCE_SET_CURRENT";
export const SOURCE_SET_CURRENT: SOURCE_SET_CURRENT = "SOURCE_SET_CURRENT";

export type SOURCE_DELETE_SOURCE = "SOURCE_DELETE_SOURCE";
export const SOURCE_DELETE_SOURCE: SOURCE_DELETE_SOURCE = "SOURCE_DELETE_SOURCE";

export type SOURCE_SET_IS_VALID_SYNTAX = "SOURCE_SET_IS_VALID_SYNTAX";
export const SOURCE_SET_IS_VALID_SYNTAX: SOURCE_SET_IS_VALID_SYNTAX = "SOURCE_SET_IS_VALID_SYNTAX";

export type SOURCE_SET_LOADING = "SOURCE_SET_LOADING";
export const SOURCE_SET_LOADING: SOURCE_SET_LOADING = "SOURCE_SET_LOADING";

export type SOURCE_UPDATE_NAME = "SOURCE_UPDATE_NAME";
export const SOURCE_UPDATE_NAME: SOURCE_UPDATE_NAME = "SOURCE_UPDATE_NAME";

export type SOURCE_UPDATE_META = "SOURCE_UPDATE_META";
export const SOURCE_UPDATE_META: SOURCE_UPDATE_META = "SOURCE_UPDATE_META";

export type SOURCE_UPDATE_YAML = "SOURCE_UPDATE_YAML";
export const SOURCE_UPDATE_YAML: SOURCE_UPDATE_YAML = "SOURCE_UPDATE_YAML";

export type SOURCE_UPDATE_YAML_OBJECT = "SOURCE_UPDATE_YAML_OBJECT";
export const SOURCE_UPDATE_YAML_OBJECT: SOURCE_UPDATE_YAML_OBJECT = "SOURCE_UPDATE_YAML_OBJECT";

export type SOURCE_YAMLOBJECT_UPDATE_CONFIG = "SOURCE_YAMLOBJECT_UPDATE_CONFIG";
export const SOURCE_YAMLOBJECT_UPDATE_CONFIG: SOURCE_YAMLOBJECT_UPDATE_CONFIG = "SOURCE_YAMLOBJECT_UPDATE_CONFIG";

export type SOURCE_YAMLOBJECT_RESET_CONFIG = "SOURCE_YAMLOBJECT_RESET_CONFIG";
export const SOURCE_YAMLOBJECT_RESET_CONFIG: SOURCE_YAMLOBJECT_RESET_CONFIG = "SOURCE_YAMLOBJECT_RESET_CONFIG";

export type SOURCE_YAMLOBJECT_UPDATE_CONFIG_NESTED = "SOURCE_YAMLOBJECT_UPDATE_CONFIG_NESTED";
export const SOURCE_YAMLOBJECT_UPDATE_CONFIG_NESTED: SOURCE_YAMLOBJECT_UPDATE_CONFIG_NESTED = "SOURCE_YAMLOBJECT_UPDATE_CONFIG_NESTED";

export type SOURCE_YAMLOBJECT_ADD_TEST = "SOURCE_YAMLOBJECT_ADD_TEST";
export const SOURCE_YAMLOBJECT_ADD_TEST: SOURCE_YAMLOBJECT_ADD_TEST = "SOURCE_YAMLOBJECT_ADD_TEST";

export type SOURCE_YAMLOBJECT_DELETE_TEST = "SOURCE_YAMLOBJECT_DELETE_TEST";
export const SOURCE_YAMLOBJECT_DELETE_TEST: SOURCE_YAMLOBJECT_DELETE_TEST = "SOURCE_YAMLOBJECT_DELETE_TEST";

export type SOURCE_YAMLOBJECT_CLONE_TEST = "SOURCE_YAMLOBJECT_CLONE_TEST";
export const SOURCE_YAMLOBJECT_CLONE_TEST: SOURCE_YAMLOBJECT_CLONE_TEST = "SOURCE_YAMLOBJECT_CLONE_TEST";

export type SOURCE_YAMLOBJECT_UPDATE_TEST_NAME = "SOURCE_YAMLOBJECT_UPDATE_TEST_NAME";
export const SOURCE_YAMLOBJECT_UPDATE_TEST_NAME: SOURCE_YAMLOBJECT_UPDATE_TEST_NAME = "SOURCE_YAMLOBJECT_UPDATE_TEST_NAME";

export type SOURCE_YAMLOBJECT_UPDATE_ONLY_FLAG = "SOURCE_YAMLOBJECT_UPDATE_ONLY_FLAG";
export const SOURCE_YAMLOBJECT_UPDATE_ONLY_FLAG: SOURCE_YAMLOBJECT_UPDATE_ONLY_FLAG = "SOURCE_YAMLOBJECT_UPDATE_ONLY_FLAG";

export type SOURCE_YAMLOBJECT_UPDATE_SKIP_FLAG = "SOURCE_YAMLOBJECT_UPDATE_SKIP_FLAG";
export const SOURCE_YAMLOBJECT_UPDATE_SKIP_FLAG: SOURCE_YAMLOBJECT_UPDATE_SKIP_FLAG = "SOURCE_YAMLOBJECT_UPDATE_SKIP_FLAG";

export type SOURCE_YAMLOBJECT_ADD_INTERACTION = "SOURCE_YAMLOBJECT_ADD_INTERACTION";
export const SOURCE_YAMLOBJECT_ADD_INTERACTION: SOURCE_YAMLOBJECT_ADD_INTERACTION = "SOURCE_YAMLOBJECT_ADD_INTERACTION";

export type SOURCE_YAMLOBJECT_REMOVE_INTERACTION = "SOURCE_YAMLOBJECT_REMOVE_INTERACTION";
export const SOURCE_YAMLOBJECT_REMOVE_INTERACTION: SOURCE_YAMLOBJECT_REMOVE_INTERACTION = "SOURCE_YAMLOBJECT_REMOVE_INTERACTION";

export type SOURCE_YAMLOBJECT_UPDATE_INTERACTION_INPUT = "SOURCE_YAMLOBJECT_UPDATE_INTERACTION_INPUT";
export const SOURCE_YAMLOBJECT_UPDATE_INTERACTION_INPUT: SOURCE_YAMLOBJECT_UPDATE_INTERACTION_INPUT = "SOURCE_YAMLOBJECT_UPDATE_INTERACTION_INPUT";

export type SOURCE_YAMLOBJECT_ADD_ASSERTION = "SOURCE_YAMLOBJECT_ADD_ASSERTION";
export const SOURCE_YAMLOBJECT_ADD_ASSERTION: SOURCE_YAMLOBJECT_ADD_ASSERTION = "SOURCE_YAMLOBJECT_ADD_ASSERTION";

export type SOURCE_YAMLOBJECT_DELETE_ASSERTION = "SOURCE_YAMLOBJECT_DELETE_ASSERTION";
export const SOURCE_YAMLOBJECT_DELETE_ASSERTION: SOURCE_YAMLOBJECT_DELETE_ASSERTION = "SOURCE_YAMLOBJECT_DELETE_ASSERTION";

export type SOURCE_YAMLOBJECT_UPDATE_ASSERTION_ACTION = "SOURCE_YAMLOBJECT_UPDATE_ASSERTION_ACTION";
export const SOURCE_YAMLOBJECT_UPDATE_ASSERTION_ACTION: SOURCE_YAMLOBJECT_UPDATE_ASSERTION_ACTION = "SOURCE_YAMLOBJECT_UPDATE_ASSERTION_ACTION";

export type SOURCE_YAMLOBJECT_UPDATE_ASSERTION_OPERATOR = "SOURCE_YAMLOBJECT_UPDATE_ASSERTION_OPERATOR";
export const SOURCE_YAMLOBJECT_UPDATE_ASSERTION_OPERATOR: SOURCE_YAMLOBJECT_UPDATE_ASSERTION_OPERATOR = "SOURCE_YAMLOBJECT_UPDATE_ASSERTION_OPERATOR";

export type SOURCE_YAMLOBJECT_UPDATE_ASSERTION_VALUE = "SOURCE_YAMLOBJECT_UPDATE_ASSERTION_VALUE";
export const SOURCE_YAMLOBJECT_UPDATE_ASSERTION_VALUE: SOURCE_YAMLOBJECT_UPDATE_ASSERTION_VALUE = "SOURCE_YAMLOBJECT_UPDATE_ASSERTION_VALUE";

export type SOURCE_RESULTS_INIT = "SOURCE_RESULTS_INIT";
export const SOURCE_RESULTS_INIT: SOURCE_RESULTS_INIT = "SOURCE_RESULTS_INIT";

export type SOURCE_RESULTS_PENDING = "SOURCE_RESULTS_PENDING";
export const SOURCE_RESULTS_PENDING: SOURCE_RESULTS_PENDING = "SOURCE_RESULTS_PENDING";

export type SOURCE_RESULTS_UPDATE = "SOURCE_RESULTS_UPDATE";
export const SOURCE_RESULTS_UPDATE: SOURCE_RESULTS_UPDATE = "SOURCE_RESULTS_UPDATE";

export type SOURCE_RESULTS_UPDATE_INTERACTION_ALL_STATUS = "SOURCE_RESULTS_UPDATE_INTERACTION_ALL_STATUS";
export const SOURCE_RESULTS_UPDATE_INTERACTION_ALL_STATUS: SOURCE_RESULTS_UPDATE_INTERACTION_ALL_STATUS = "SOURCE_RESULTS_UPDATE_INTERACTION_ALL_STATUS";

export type SOURCE_RESULTS_UPDATE_ALL_PENDING_STATUS = "SOURCE_RESULTS_UPDATE_ALL_PENDING_STATUS";
export const SOURCE_RESULTS_UPDATE_ALL_PENDING_STATUS: SOURCE_RESULTS_UPDATE_ALL_PENDING_STATUS = "SOURCE_RESULTS_UPDATE_ALL_PENDING_STATUS";

export type SOURCE_INTERIM_RESULTS_UPDATE = "SOURCE_INTERIM_RESULTS_UPDATE";
export const SOURCE_INTERIM_RESULTS_UPDATE: SOURCE_INTERIM_RESULTS_UPDATE = "SOURCE_INTERIM_RESULTS_UPDATE";

export type SET_REPORTING_FILTERS = "bst/SET_REPORTING_FILTERS";
export const SET_REPORTING_FILTERS: SET_REPORTING_FILTERS = "bst/SET_REPORTING_FILTERS";

export type SOURCE_UPDATE_MONITORING_SCHEDULE_TYPE = "SOURCE_UPDATE_MONITORING_SCHEDULE_TYPE";
export const SOURCE_UPDATE_MONITORING_SCHEDULE_TYPE: SOURCE_UPDATE_MONITORING_SCHEDULE_TYPE = "SOURCE_UPDATE_MONITORING_SCHEDULE_TYPE";

export type SOURCE_UPDATE_TEST_ID = "SOURCE_UPDATE_TEST_ID";
export const SOURCE_UPDATE_TEST_ID: SOURCE_UPDATE_TEST_ID = "SOURCE_UPDATE_TEST_ID";

export type SOURCE_UPDATE_TEST_DESCRIPTION = "SOURCE_UPDATE_TEST_DESCRIPTION";
export const SOURCE_UPDATE_TEST_DESCRIPTION: SOURCE_UPDATE_TEST_DESCRIPTION = "SOURCE_UPDATE_TEST_DESCRIPTION";

export type SOURCE_UPDATE_TEST_SUITE_DESCRIPTION = "SOURCE_UPDATE_TEST_SUITE_DESCRIPTION";
export const SOURCE_UPDATE_TEST_SUITE_DESCRIPTION: SOURCE_UPDATE_TEST_SUITE_DESCRIPTION = "SOURCE_UPDATE_TEST_SUITE_DESCRIPTION";

export type SET_LATEST_ORGANIZATION_ID = "SET_LATEST_ORGANIZATION_ID";
export const SET_LATEST_ORGANIZATION_ID: SET_LATEST_ORGANIZATION_ID = "SET_LATEST_ORGANIZATION_ID";

export const TestResultStatus = {
    COMPLETED: "COMPLETED",
    PENDING: "PENDING",
    INITIAL: "INITIAL",
};
export const MAX_LENGTH_INPUT = 25;

export namespace COLORS {
    export const RED = "#F44336";
    export const GREEN = "#4CAF50";
    export namespace ICONS {
        export const PRIMARY = RED;
    }
}

export enum sourceType {
    alexa = "alexa",
    google = "google",
    twilio = "twilio",
    sms = "sms",
    whatsapp = "whatsapp",
    phone = "phone",
    webchat = "webchat",
    voiceflow = "voiceflow",
    lex = "lex",
    watson = "watson",
    email = "email",
}

export const sourceTypes: any[] = [
    { value: "alexa", label: "Alexa Skill" },
    { value: "email", label: "Email" },
    { value: "google", label: "Google Action" },
    { value: "twilio", label: "Twilio" },
    { value: "sms", label: "SMS" },
    { value: "whatsapp", label: "WhatsApp" },
    { value: "phone", label: "Phone" },
    { value: "webchat", label: "Webchat" },
    { value: "watson", label: "Watson" },
    { value: "voiceflow", label: "Voiceflow" },
    { value: "lex", label: "AWS Lex" },
];

export namespace CLASSES {
    export namespace COLOR {
        export const BLUE_GREY_800 = "mdl-color--blue-grey-800";
        export const BLUE_GREY_900 = "mdl-color--blue-grey-900";
        export const GREY_100 = "mdl-color--grey-100";
        export const GREY_900 = "mdl-color--grey-900";
        export const GREEN_BESPOKEN = "mdl-color-green-bespoken";
        export const CYAN_BESPOKEN = "mdl-color-cyan-bespoken";
    }

    export namespace TEXT {
        export const BLUE_GREY_50 = "mdl-color-text--blue-grey-50";
        export const BLUE_GREY_400 = "mdl-color-text--blue-grey-400";
        export const GREY_600 = "mdl-color-text--grey-600";
    }
}
