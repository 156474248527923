import * as React from "react";
import AceEditor from "react-ace";

require("ace-builds/src-noconflict/mode-yaml");

const styles = require("../lunacy/texts/styles.scss");
const debugPanelStyles = require("./DebugPanel.scss");

interface DebugPanelProps {
    content: any;
    style?: React.CSSProperties;
}

interface DebugPanelState {

}

class DebugPanel extends React.Component<DebugPanelProps, DebugPanelState> {
    constructor(props: DebugPanelProps) {
        super(props);
        this.state = {}
    }

    render(): JSX.Element {
        return (
            <div style={this.props.style}>
                <AceEditor
                    // @ts-ignore
                    ref={(el) => this.aceEditorRef = el}
                    mode="yaml"
                    theme="dracula"
                    name={`debugEditor`}
                    fontSize={6}
                    setOptions={{
                        hScrollBarAlwaysVisible: true
                    }}
                    showPrintMargin={false}
                    highlightActiveLine={true}
                    style={{ width: '100%' }}
                    value={JSON.stringify(this.props.content, null, 2)}
                />
            </div>
        )

    }

}

export { DebugPanel }