import * as moment from "moment";

namespace momentHelper {

  export const UTC_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";

  export const getNowUtc = (): moment.Moment => {
    return moment.utc();
  };

  export const getNowUtcFormat = (): string => {
    return getNowUtc().format();
  };

  export const parseToMoment = (date: string, format: string): moment.Moment => {
    const momentDate =  moment(date, format);
    if (date && !momentDate.isValid()) {
      throw new Error("The date is not a valid format");
    }
    return momentDate;
  };

  export const parseToUTCmoment = (date: string): moment.Moment => {
    return  parseToMoment(date, UTC_FORMAT).utc();
  };

}

export default momentHelper;
