import * as React from "react";
import * as cn from "classnames";
import { attemptInvoke, cnToggle } from "../../../utils/ReactHelpers";
import { BespokenColors, defaultIconColor } from "../constants";
import { IconCheckmark, IconDebug, IconDeleteEmail, IconPlusSign, IconPlusSignOnly, IconRemove, IconTrashCan, IconCopy, IconX } from "../icons";

const Styles = require("./styles.scss");
const COMPONENT = "component"

type ExposedProps = {
    onClick?: () => void;
    title?: string;
    color?: BespokenColors;
    hoverColor?: BespokenColors;
    disabled?: boolean;
}
export const TrashCanIconButton = (props: ExposedProps) => {
    const { onClick, title = "" } = props
    return (
        <a className={cn(COMPONENT, Styles.component)}
            onClick={() => attemptInvoke(onClick)}
            title={title}
        >
            <IconTrashCan />
        </a>
    )
}


export const DeleteMessageIconButton = (props: ExposedProps) => {
    const { onClick, title = "" } = props
    return (
        <a className={cn(COMPONENT, Styles.component)}
            onClick={() => attemptInvoke(onClick)}
            title={title}
        >
            <IconDeleteEmail />
        </a>
    )
}


export const PlusSignIconButton = (props: ExposedProps) => {
    const { onClick, title = "" } = props
    return (
        <a className={cn(COMPONENT, Styles.component)}
            onClick={() => attemptInvoke(onClick)}
            title={title}
        >
            <IconPlusSign />
        </a>
    )
}

const IconButton = (props: ExposedProps & { children: JSX.Element, controlName: string }) => (
    <a className={
        cn(
            COMPONENT,
            'IconButton',
            props?.controlName,
            Styles.component,
            cnToggle(!props?.disabled, Styles[`normal_${props?.color || defaultIconColor}`]),
            cnToggle(!props?.disabled, Styles[`hover_${props?.hoverColor || 'color_lavender_gray'}`]),
            cnToggle(props?.disabled, Styles['disabled'])
        )}
        onClick={() => props?.disabled !== true && attemptInvoke(props?.onClick)}
        title={props?.title}
    >
        {props?.children}
    </a>
)


export const RemoveIconButton = (props: ExposedProps) => {
    return (<IconButton controlName="RemoveIconButton" {...props}><IconRemove /></IconButton>)
}

export const XIconButton = (props: ExposedProps) => {
    return (<IconButton controlName="RemoveIconButton" {...props}><IconX /></IconButton>)
}

export const CkeckIconButton = (props: ExposedProps) => {
    const { onClick, title = "" } = props
    return (
        <a className={cn(COMPONENT, Styles.component)}
            onClick={() => attemptInvoke(onClick)}
            title={title}
        >
            <IconCheckmark />
        </a>
    )
}


export const PlusSignOnlyIconButton = (props: ExposedProps) => {
    const { onClick, title = "", color = defaultIconColor, hoverColor } = props
    return (
        <a className={cn(COMPONENT, Styles.component, Styles[`normal_${color}`], Styles[`hover_${hoverColor}`])}
            onClick={() => attemptInvoke(onClick)}
            title={title}
        >
            <IconPlusSignOnly />
        </a>
    )
}

export const CopyIconButton = (props: ExposedProps) => {
    const { onClick, title = "", color = defaultIconColor, hoverColor = 'color_lavender_gray' } = props
    return (
        <a className={cn(COMPONENT, Styles.component, Styles[`normal_${color}`], Styles[`hover_${hoverColor}`])}
            onClick={() => attemptInvoke(onClick)}
            title={title}
        >
            <IconCopy />
        </a>
    )
}


export const DebugIconButton = (props: ExposedProps) => {
    const { onClick, title = "", color = defaultIconColor, hoverColor = 'color_lavender_gray' } = props
    return (
        <a className={cn(COMPONENT, Styles.component, Styles[`normal_${color}`], Styles[`hover_${hoverColor}`])}
            onClick={() => attemptInvoke(onClick)}
            title={title}
        >
            <IconDebug/>
        </a>
    )
}