import { Chart, registerables, TooltipItem, TooltipModel } from "chart.js";
import { isEqual, get } from "lodash";
import * as React from "react";
import { ShadowDom } from ".";
import { DoughnutChartData } from "../../services/bespoken-reporting-api";

Chart.register(...registerables);

const DoughnutChartStyle = require("./DoughnutChartStyle.scss");

export interface DoughnutChartProps {
    data: DoughnutChartData;
}

export interface DoughnutChartState {
}

export class DoughnutChart extends React.Component<DoughnutChartProps, DoughnutChartState> {

    static defaultProps: DoughnutChartProps = {
        data: {
            labels: ['Red'],
            datasets: [{
                data: [12],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.5)'
                ]
            }]
        },
    }

    state: DoughnutChartState;
    props: DoughnutChartProps;

    chart: Chart;

    constructor(props: DoughnutChartProps) {
        super(props);

        this.state = {};
    }

    setupChart(shadowRoot: ShadowRoot): void {
        const canvas = document.createElement('canvas');
        const aspectRatio = 16 / 9;

        shadowRoot.appendChild(canvas);


        const label: (this: TooltipModel<"doughnut">, tooltipItem: TooltipItem<"doughnut">) => string | string[] = (tooltipItem) => {
            const value = tooltipItem.parsed;
            const total = get(tooltipItem, "dataset.data", []).reduce((prev: number, curr: number) => prev + curr, 0)
            const percentage = Math.round((value / total) * 100 * 10) / 10

            return [`${tooltipItem.label} - ${value} (${percentage}%)`];
        }

        const data = this.props.data
        const ctx = canvas.getContext('2d');
        this.chart = new Chart(ctx, {
            type: 'doughnut',
            data,
            plugins: [{
                id: "noDataLabel",
                afterDraw: function (chart) {
                    if (get(chart, 'data.datasets[0].data.length') === 0) {
                        // No data is present
                        var ctx = chart.ctx;
                        var width = chart.width;
                        var height = chart.height
                        chart.clear();

                        ctx.save();
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';
                        ctx.fillStyle = "#19496B"
                        ctx.font = "14px 'Open Sans', sans-serif";
                        ctx.fillText("No data available.", width / 2, height / 2 - 16);
                        ctx.fillText("Run some end-to-end tests", width / 2, height / 2);
                        ctx.fillText("to see your historical results here.", width / 2, height / 2 + 16);
                        ctx.restore();
                    }
                }
            }],
            options: {
                cutout: "80%",
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio,
                plugins: {
                    legend: {
                        position: 'bottom',
                        labels: {
                            color: "#19496B",
                            font: {
                                family: "Open Sans"
                            }
                        }
                    },
                    tooltip: {
                        callbacks: {
                            label
                        }
                    }
                }
            }
        });
    }

    componentDidUpdate(prevProps: Readonly<DoughnutChartProps>, prevState: Readonly<DoughnutChartState>, prevContext: any): void {
        if (!isEqual(this.props.data, prevProps.data)) {
            this.chart.data = this.props.data;
            this.chart.update();
        }
    }

    render() {
        return (
            <div>
                <ShadowDom builder={shadowRoot => this.setupChart(shadowRoot)} />
            </div>
        );
    }

}
