
import * as React from "react";
import * as cn from "classnames";
import { isEmpty, toLower } from "lodash";
import { attemptInvoke } from "../../utils/ReactHelpers";

const Styles = require("./styles.scss");
type ValidationProps = { valueToValidate: string; onChange: (val: any) => void; }
type ValidationState = { value: string, valid: boolean, empty: boolean, message: string }
abstract class StatusValidation extends React.Component<ValidationProps, ValidationState> {

    timeoutId: any = undefined;

    constructor(props: ValidationProps) {
        super(props)

        this.state = {
            value: props.valueToValidate,
            valid: undefined,
            empty: true,
            message: ""
        }
    }

    componentWillReceiveProps(nextProps: Readonly<ValidationProps>, nextContext: any): void {
        clearTimeout(this.timeoutId)

        if (nextProps.valueToValidate !== this.state.value) {
            const { valueToValidate: email } = nextProps
            this.setState({ value: email }, () => {
                this.timeoutId = setTimeout(() => this.validate(), 1000)
            })
        }
    }

    abstract validate(): Promise<any>;

    render() {
        const { message } = this.state

        return (
            <div className={Styles.control}>
                <label className={Styles.title}>&nbsp;</label>
                <span className={Styles.status}>{message}</span>
            </div>
        )
    }
}

export class ValidateEmailStatus extends StatusValidation implements React.Component<ValidationProps, ValidationState> {
    async validate(): Promise<any> {
        const { value } = this.state
        const { onChange } = this.props
        const completeCallback = () => attemptInvoke(onChange, this.state.valid)

        if (isEmpty(value)) {
            return this.setState({ valid: false, message: "" }, completeCallback)
        }

        const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(toLower(value))) {
            return this.setState({ valid: false, message: "Please enter a valid email address." }, completeCallback)
        }

        /*
                const { exists, hasError, message = "This email is already registered. Please use a different one." }: any = await source.userExists(email)
                    .then(exists => ({ exists }))
                    .catch(() => ({ hasError: true, message: "There was a server error" }))

                if (exists) { return this.setState({ valid: false, message }, completeCallback) }
                if (hasError) { return this.setState({ valid: false, message }, completeCallback) }
        */

        return this.setState({ valid: true, message: '' }, completeCallback)
    }
}