import { SetFilterParameters } from "../actions/reporting";
import { SET_REPORTING_FILTERS } from "../constants";
import { FilterParameters } from "../services/bespoken-reporting-api";

export type ReportingState = {
    filters?: FilterParameters
};

const INITIAL_STATE: ReportingState = {
};

export type SetReportingFilters = {
    type: SET_REPORTING_FILTERS,
    filters: FilterParameters
};

type FilterParametersAction = SetFilterParameters;

export function reporting(state: ReportingState = INITIAL_STATE, action: FilterParametersAction): ReportingState {
    switch (action.type) {
        case SET_REPORTING_FILTERS: {
            return { ...state, filters: action.filters };
        }
        default:
          return state;
      }
}
