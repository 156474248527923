import * as cn from "classnames";
import * as React from "react";
import { IconButton } from "react-toolbox/lib/button";
import Dialog from "react-toolbox/lib/dialog";

const bespokenModalTheme = require("./BespokenModal.scss");

export interface BespokenModalProps {
    title: string;
    titleIcon?: any;
    redTitleBorder?: boolean;
    showModal: boolean;
    dialogToggle: any;
    className?: string;
}

interface BespokenModalState {
    activeModal: boolean;
}

export class BespokenModal extends React.Component<BespokenModalProps, BespokenModalState> {
    render({children, showModal, dialogToggle, redTitleBorder, titleIcon, title} = this.props) {
        const className = this.props.className ? this.props.className : 'BespokenModal'
        //console.info('classname: ' + className)
        return (
            <Dialog
                className={cn(className, {[bespokenModalTheme.red_border]: redTitleBorder, [bespokenModalTheme.space_for_icon]: titleIcon})}
                theme={bespokenModalTheme}
                active={!!showModal}
                onEscKeyDown={dialogToggle}
                onOverlayClick={dialogToggle}
                title={title}>
                <IconButton
                    className={bespokenModalTheme.close_button}
                    icon={"close"}
                    onClick={dialogToggle}/>
                {
                    this.props.titleIcon &&
                    <div className={bespokenModalTheme.title_icon}>{this.props.titleIcon}</div>
                }
                <div className={bespokenModalTheme.modal_body} >
                    {children}
                </div>
            </Dialog>
        );
    }
}
