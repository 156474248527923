import { capitalize, chain, includes, upperCase } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { State } from "../reducers";
import { attemptInvoke } from "../utils/ReactHelpers";
import { HorizontalCategoryFilter } from "./HorizontalCategoryFilter/HorizontalCategoryFilter";

type PlatformsSelectorExposedProps = { onChangeCategory: (val: any) => void };
type PlatformsSelectorProps = { categories: any[], selectedValue: string };
export const BespokenPlatformsSelector = connect(
    function mapStateToProps(state: State.All) {
        const formatPlatformName = (val: string) => includes(["sms"], val) ? upperCase(val) : capitalize(val);

        const categories = chain(state?.organization?.selectedOrganization?.subscription?.platforms)
            .sort()
            .map((value: string) => ({ value, label: formatPlatformName(value) }))
            .value();
        const selectedValue = "";

        categories.unshift({ value: "", label: "View All" });

        return {
            categories,
            selectedValue
        };
    },
    undefined,
    function mergeProps(ownProps: any, stateProps: any, dispatchProps: PlatformsSelectorExposedProps) {
        return { ...ownProps, ...stateProps, ...dispatchProps };
    }
)((props: PlatformsSelectorExposedProps & PlatformsSelectorProps) => {
    const { onChangeCategory, categories, selectedValue } = props;
    return (
        <HorizontalCategoryFilter categories={categories} selectedValue={selectedValue} onChangeCategory={val => attemptInvoke(onChangeCategory, val)} />
    );
});

