import * as cn from "classnames";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { default as InputToolbox } from "react-toolbox/lib/input";

const inputStyles = require("./inputStyles.scss");
const theme = require("./inputTheme.scss");

export interface InputProps {
  value?: string;
  label?: string;
  type?: string;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  autoFocus?: boolean;
  className?: string;
  onKeyPress?: (event: any) => void;
  hidden?: boolean;
  maxLength?: number;
  disabled?: boolean;
  onBlur?: () => void;
}

export default class Input extends React.Component<InputProps, any> {

  handleOnChange = (value: string) => {
    const { onChange } = this.props;
    onChange && onChange(value);
  }

  handleOnKeyPress = (event: any) => {
    const { onKeyPress } = this.props;
    onKeyPress && onKeyPress(event);
  }

  handleOnFocus = () => {
      const { onFocus } = this.props;
      onFocus && onFocus();
  }

  handleOnBlur = () => {
    const { onBlur } = this.props;
    onBlur && onBlur();
}

  focus = () => {
      const inputNode = ReactDOM.findDOMNode(this);
      const inputElement = inputNode.getElementsByTagName("input");
      setTimeout(() => {
          inputElement && inputElement.length && inputElement[0].focus();
      }, 50);
  }

  render({ value, type = "text", label, autoFocus, className, hidden, maxLength = 255, disabled, ...rest } = this.props) {
    let _className = className;
    if (typeof hidden !== "undefined") {
      _className = cn(_className, inputStyles.inOutInput, { [inputStyles.active]: !hidden });
    }

    const inputProps = { autoFocus, placeholder: label, ...rest };
    return (
      <InputToolbox
        disabled={disabled}
        floating={false}
        theme={theme}
        type={type}
        value={value}
        className={_className}
        maxLength={maxLength}
        onChange={this.handleOnChange}
        onFocus={this.handleOnFocus}
        onKeyPress={this.handleOnKeyPress}
        onBlur={this.handleOnBlur}
        {...inputProps}
      />
    );
  }

}
