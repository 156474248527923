import * as React from "react";
import Source from "../../../models/source";
import AceYamlEditor from "../../AceYamlEditor/AceYamlEditor";

const validationStyle = require("./yaml-editor.scss");

interface YamlEditorProps {
  source: Source;
  handleYamlChange: (value: string) => void;
}

interface YamlEditorState {
}


export default class YamlEditor extends React.Component<YamlEditorProps, YamlEditorState> {
  constructor(props: YamlEditorProps) {
    super(props);

    this.state = {
      additionalSettings: '',
      additionalSettingsError: '',
      homophones: [],
    }
  }

  render() {
    return (
      <div className={validationStyle.yaml_editor_container}>
        <AceYamlEditor
          className={validationStyle.ace_editor}
          value={this.props.source.yaml}
          onChange={this.props.handleYamlChange}
        />
        {this.props.children}
      </div>
    )
  }
}