import React = require("react");
import CustomMode from "./custom-mode";
import AceEditor from "react-ace";

require("ace-builds/src-noconflict/mode-yaml");
require("ace-builds/src-noconflict/theme-dracula");

interface AceYamlEditorProps {
  className: string;
  onChange: any;
  value: any;
}

interface AceYamlEditorState {
}


export default class AceYamlEditor extends React.Component<AceYamlEditorProps, AceYamlEditorState> {
  constructor(props: AceYamlEditorProps) {
    super(props);
    // @ts-ignore
    this.aceEditorRef = undefined;
    this.state = {
    }
  }

  componentDidMount() {
    const customMode = new CustomMode();
    // @ts-ignore
    this.aceEditorRef.editor.getSession().setMode(customMode);
  }

  render() {
    return (
      <AceEditor
        // @ts-ignore
        ref={(el) => this.aceEditorRef = el}
        className={this.props.className}
        mode="yaml"
        theme="dracula"
        name="yamlEditor"
        onChange={this.props.onChange}
        fontSize={14}
        showPrintMargin={false}
        highlightActiveLine={true}
        value={this.props.value}
      />
    )
  }
}
