import * as React from "react";
import Dropdown from "react-toolbox/lib/dropdown";
import * as uuid from "uuid";
import * as cn from "classnames";
import BespokenCheckIcon from "../../../../assets/bespoken_check_icon.svg";
import { getLocaleDefaultVoice, getVoicesByLocaleAndType } from "../../../utils/locales";
import { TextNormal } from "../texts/Text";
import { snakeCase } from "lodash";

// TODO: Requires to refactor to be aligned with other components. (date: 2019-10-10)

// Must be the same as the name of the component in the file
const COMPONENT_NAME = "VoiceDropdown"
const COMPONENT = "component"

const voiceDropdownStyle = require("./VoiceDropdown.module.scss");

interface VoiceDropdownState {
    voice?: string;
    voices: any[];
}

interface VoiceDropdownProps {
    handleVoiceChange?: (value: string) => void;
    locale?: string;
    voice?: string;
    className?: string;
    theme?: any;
    disabled?: boolean;
    sourceType?: string;
    "data-id"?: string;
    appSettings?: any;
}

export class VoiceDropdown extends React.Component<VoiceDropdownProps, VoiceDropdownState> {
    constructor(props: any) {
        super(props);

        this.state = {
            voices: [{ label: "Joey", value: "Joey" }],
        };
    }

    componentDidMount() {
        this.setVoices(this.props);
    }

    async componentWillReceiveProps(nextProps: VoiceDropdownProps) {
        if (this.props.sourceType !== nextProps.sourceType || this.props.locale !== nextProps.locale) {
            this.setVoices(nextProps);
        }
        if (!nextProps.voice) {
            const voice = await getLocaleDefaultVoice(nextProps.appSettings, nextProps.locale);
            voice !== 0 ? this.props.handleVoiceChange(voice) : (this.state.voices[0] && this.props.handleVoiceChange(this.state.voices[0].value));
        }
    }

    setVoices = async (props?: VoiceDropdownProps) => {
        const locale = (props && props.locale) || this.props.locale;
        const appSettings = (props && props.appSettings) || this.props.appSettings;
        const alexaVoicesObj = getVoicesByLocaleAndType(appSettings, "alexa", locale);
        const googleVoicesObj = getVoicesByLocaleAndType(appSettings, "google", locale);
        const watsonVoicesObj = getVoicesByLocaleAndType(appSettings, "watson", locale);
        let voices: any = [];
        voices = voices.concat(alexaVoicesObj.map((voice: any, index: number) =>
            ({ value: voice.id, label: voice.label, platform: "Amazon Polly", index })));
        voices = voices.concat(googleVoicesObj.map((voice: any, index: number) =>
            ({ value: voice.id, label: voice.label, platform: "Google Cloud TTS", index })));
        voices = voices.concat(watsonVoicesObj.map((voice: any, index: number) =>
            ({ value: voice.id, label: voice.label, platform: "Watson TTS", index })));
        this.setState(prevState => ({
            ...prevState,
            voices,
        }));
    }

    getValue = (voice: string, voices: any[]) => {
        const isOnTheLocaleList = voices.some(item => item.value === voice);
        return isOnTheLocaleList ? voice : (voices[0] && voices[0].value);
    }

    handleTypeChange = (value: string) => {
        const oldValue = this.props.voice;
        if (value !== oldValue) {
            this.props.handleVoiceChange(value);
        }
        this.setState(prevState => ({
            ...prevState,
            voice: value,
        }));
    }

    dropdownTemplate = (item: any) => {
        return item.index === 0 ?
            <div className={voiceDropdownStyle.title_item}><span className={voiceDropdownStyle.platform}>{item.platform}</span>
                <div>
                    <span className={voiceDropdownStyle.check}>
                        <BespokenCheckIcon />
                    </span>
                    {item.value}
                </div>
            </div> :
            <div>
                <span className={voiceDropdownStyle.check}>
                    <BespokenCheckIcon />
                </span>
                {item.value}
            </div>;
    }

    render({ disabled, theme, className, locale, voice, handleVoiceChange, sourceType, appSettings, ...rest } = this.props) {
        const voices = this.state.voices;
        const value = this.getValue(voice, voices);
        return (
            <Dropdown
                template={this.dropdownTemplate}
                key={`${voice}-${uuid.v4()}`}
                disabled={disabled}
                theme={theme}
                className={cn(COMPONENT, snakeCase(COMPONENT_NAME), voiceDropdownStyle.container, className)}
                onChange={this.handleTypeChange}
                source={voices}
                value={value}
                {...rest} />
        );
    }
}
