import * as React from "react";
import * as trialExpired from "../../assets/images/trial_expired.png";
import SuperLlama from "../../assets/super_llama.svg";
import { Button } from "../components";

const Trial = require("../components/TrialExpired/trialExpiredStyle.scss");

export default class NotFoundPage extends React.Component<any, any> {

    handleSubscribe = () => {
        const { goTo } = this.props;
        goTo && goTo("/skills");
    }

    get mailTo() {
        return "support@bespoken.io";
    }

    get subject() {
        return "Broken link";
    }

    render() {
        return (
            <div className={Trial.trialContainer}>
                <h1>Hi There!</h1>
                <h3>We couldn’t find the page you were looking for</h3>
                <h6>Click below to go back home</h6>
                <Button label="Go back" accent onClick={this.handleSubscribe} />
                <h6 data-id="help">If you think this is a broken link
                    <a href={`mailto:${this.mailTo}?subject=${this.subject}`}>
                        please tell us
                    </a>
                </h6>
                <SuperLlama />
                <img src={trialExpired} alt="NotFoundPage" />
            </div>
        );
    }
}
