import * as React from "react";
import { IconButton } from "react-toolbox/lib/button";
import * as cs from "classnames"

const Styles = require("./IconLabelButtonStyle.scss");

const ICON_DIRECTIONS = {
  "left": "row",
  "right": "row-reverse"
}
type IconPosition = "left" | "right"
type ButtonSize = "small" | "medium" | "large"
interface IconLabelButtonProps {
  icon: React.ReactNode;
  onClick?: () => void
  disable?: boolean;
  label: string;
  gap?: string;
  iconPosition?: IconPosition;
  size?: ButtonSize;
}

interface IconLabelButtonState {

}
export class IconLabelButton extends React.Component<IconLabelButtonProps, IconLabelButtonState> {

  static defaultProps: IconLabelButtonProps = {
    gap: undefined,
    label: "Link Button",
    icon: undefined,
    iconPosition: "left",
    size: "small"
  }

  constructor(props: IconLabelButtonProps) {
    super(props);
  }

  render(): false | JSX.Element {
    const {
      disable,
      onClick,
      label,
      gap,
      icon,
      iconPosition,
      size
    } = this.props

    const flexDirection = ICON_DIRECTIONS[iconPosition] as "row" | "row-reverse"

    return <div className={Styles.container} style={{ gap, flexDirection }}>
      <IconButton
        ripple={false}
        className={cs(Styles.icon_button, Styles[`icon_${size}`], disable ? Styles.icon_disabled : undefined)}
        onClick={() => { if (!disable) { onClick && onClick() } }}
        icon={icon}
      />
      <a
        className={cs(Styles[`label_${size}`], disable ? Styles.button_disabled : undefined)}
        onClick={() => { if (!disable) { onClick && onClick() } }}
      >
        {label}
      </a>
    </div>
  }
}