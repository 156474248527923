exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".iconMenuTheme__menu--2TjpN {\n  background-color: transparent;\n  border-radius: 5px;\n  margin-top: 30px; }\n\n.iconMenuTheme__menuInner--3gjDE {\n  padding: 0px;\n  background-color: transparent;\n  border-radius: 5px; }\n\n.iconMenuTheme__outline--2Ah0N {\n  background-color: transparent;\n  border-radius: 5px; }\n\n.iconMenuTheme__iconMenu--BHDnQ svg {\n  cursor: pointer;\n  height: 24px; }\n\n.iconMenuTheme__icon--3Bo2v {\n  display: grid !important; }\n", "",{"version":3,"sources":["/Users/diegomartin/Documents/GitHub/dashboard/src/components/UserProfileMenu/iconMenuTheme.scss"],"names":[],"mappings":"AAAA;EACI,6BAA6B;EAC7B,kBAAkB;EAClB,gBAAgB,EAAA;;AAGpB;EACI,YAAY;EACZ,6BAA6B;EAC7B,kBAAkB,EAAA;;AAGtB;EACI,6BAA6B;EAC7B,kBAAkB,EAAA;;AAGtB;EAEQ,eAAe;EACf,YAAY,EAAA;;AAIpB;EACI,wBAAwB,EAAA","file":"iconMenuTheme.scss","sourcesContent":[".menu {\n    background-color: transparent;\n    border-radius: 5px;\n    margin-top: 30px;\n}\n\n.menuInner {\n    padding: 0px;\n    background-color: transparent;\n    border-radius: 5px;\n}\n\n.outline {\n    background-color: transparent;\n    border-radius: 5px;\n}\n\n.iconMenu {\n    svg {\n        cursor: pointer;\n        height: 24px;\n    }\n}\n\n.icon {\n    display: grid !important;\n}"],"sourceRoot":""}]);

// Exports
exports.locals = {
	"menu": "iconMenuTheme__menu--2TjpN",
	"menuInner": "iconMenuTheme__menuInner--3gjDE",
	"outline": "iconMenuTheme__outline--2Ah0N",
	"iconMenu": "iconMenuTheme__iconMenu--BHDnQ",
	"icon": "iconMenuTheme__icon--3Bo2v"
};