import { isEmpty, partial } from "lodash";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { connect } from "react-redux";
import Input from "react-toolbox/lib/input";
import BespokenAvatar from "../../../assets/bespoken_avatar.svg";
import NotificationBell from "../../../assets/notification-bell.svg";
import { UserDetails, userStatus } from "../../models/user";
import { State } from "../../reducers";
import { SourceUtil } from "../../utils/Source";
import { TextTitleLight, TextTitleStrong } from "../lunacy";
import { ERROR } from "../lunacy/debug-panel/DebugPanel";
import { ProfileAvatar } from "../lunacy/profile-avatar/ProfileAvatar";
import { UserProfileMenu } from "../UserProfileMenu/UserProfileMenu";
import { User } from "../../reducers/user";
import { SelectedOrganization } from "../../reducers/organization";
import { CurrentPageProps } from "../../reducers/context";
import { setSourceSearchText } from "../../actions/source";

const Styles = require("./AvatarStyle.scss");
const bespokenInput = require("../../themes/bespoken_input.scss");
const globalWindow: any = typeof (window) !== "undefined" ? window : {};
let timeout: any = undefined;
const timeToSleep = 1000;

interface DispatchToProps {
    setSourceSearchText: (value: string) => any
}
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        setSourceSearchText: (value: string) => dispatch(setSourceSearchText(value)),
    }
}

interface StateToProps {
    user?: User;
    organization?: SelectedOrganization;
    currentPageProps?: CurrentPageProps;
    searchText: string;
}
function mapStateToProps(state: State.All): StateToProps {
    return {
        user: state.user?.currentUser,
        organization: state.organization?.selectedOrganization,
        currentPageProps: state?.context?.currentPageProps,
        searchText: state.source.searchText
    };
}

interface ExposedProps {
}
function mergeProps(ownProps: any, stateProps: any, dispatchProps: ExposedProps) {
    return { ...ownProps, ...stateProps, ...dispatchProps }
}

interface PageProps extends DispatchToProps, StateToProps, ExposedProps { }

interface PageState {
    welcomeMessage: string;
    displayPlanName: string;
    valueSourceSearchText?: string;
}

class AvatarComponent extends React.Component<PageProps, PageState> {
    constructor(props: any) {
        super(props);

        this.state = {
            welcomeMessage: "Welcome",
            displayPlanName: "User Plan Name",
            valueSourceSearchText: props.searchText || '',
        };
    }

    componentDidMount() {
        this.loadUserPlan();
        globalWindow.Headway.init({
            selector: '[data-id="notification-release-logs"]',
            account: process.env.HEADWAY_ACCOUNT_ID,
            trigger: '[data-id="notification-release-logs"]',
        });
    }

    componentWillReceiveProps(nextProps: PageProps) {
        this.loadUserPlan(nextProps);
    }

    loadUserPlan = async (props?: PageProps) => {
        const { user, organization } = props || this.props;
        const displayName = user?.name
        const displayPlanName = organization?.subscription?.planName
        const email = user && user.email;
        const welcomeMessage = displayName ? `Welcome ${displayName}` : `Welcome ${email}`;
        this.setState(prevState => ({
            ...prevState,
            welcomeMessage,
            displayPlanName,
        }));
    }

    handleSearchTextChange = (value: string) => {
        this.setState(prevState => ({
            ...prevState,
            valueSourceSearchText: value,
        }));
        this.props.setSourceSearchText(value)
    }

    render() {
        const isExpiredOrRenew = this.props.organization?.subscription?.isSubscriptionExpired
        const locationPathString = globalWindow && globalWindow.location && globalWindow.location.pathname && globalWindow.location.pathname.toLocaleLowerCase();
        const showTopNavBar = /\/skills?$/.test(locationPathString);
        const { currentPageProps } = this.props
        return (
            <div className={Styles.main_container}>
                {/* TODO is this used? */}
                {/* {!isExpiredOrRenew && this.props.pageSubtitle &&
                    <div className={Styles.page_subtitle}>
                        <div className={Styles.border} />
                        <span dangerouslySetInnerHTML={{ __html: this.props.pageSubtitle }} />
                    </div>
                } */}
                {currentPageProps?.title &&
                    <div className={Styles.page_subtitle_v2} >
                        <div className={Styles.border} />
                        <TextTitleStrong>{currentPageProps?.title}</TextTitleStrong>
                        <TextTitleLight>&nbsp;{currentPageProps?.subTitle}</TextTitleLight>
                    </div>
                }

                <div className={Styles.search_container}>
                    {
                        showTopNavBar && <Input theme={bespokenInput} icon={"search"}
                            hint={"Search"} value={this.state.valueSourceSearchText} onChange={this.handleSearchTextChange} />}
                </div>
                <div className={Styles.bell_container} data-id="notification-release-logs">
                    <NotificationBell />
                </div>
                <div className={Styles.organization_switcher} onClick={() => this.clickProfileMenu()}>
                    <ProfileAvatar />
                </div>
                <div className={Styles.user_profile_button_container}>
                    <UserProfileMenu ref={ref => { this.clickProfileMenu = partial(this.clickProfileMenu, ReactDOM.findDOMNode(ref)) }} />
                </div>
            </div>
        );
    }

    clickProfileMenu(element: HTMLElement = undefined) {
        try {
            element?.querySelector("button")?.click()
        } catch (err) {
            ERROR('Cannot find UserProfileMenu node', err)
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(AvatarComponent);
