import { SET_DEBOUNCING } from "../constants";

export type SetDebouncing = {
  type: SET_DEBOUNCING,
  debouncing: boolean,
};

export function setDebouncing(value: boolean): SetDebouncing {
  return {
    type: SET_DEBOUNCING,
    debouncing: value,
  };
}
