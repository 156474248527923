import * as React from "react";
import Dialog from "react-toolbox/lib/dialog";
const LoaderStyle = require("../../themes/loader-component.scss");

interface LoaderProps {
    active: boolean;
};

export const BespokenLoader: React.SFC<LoaderProps> = (props) => {
    return (
        <Dialog
            theme={LoaderStyle}
            active={!!props.active} >
            <div className={LoaderStyle.loader_parent} >
                <div className={LoaderStyle.loader} /></ div>
        </Dialog>
    );
};
