
import { defaultTo, size, xor } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { PlanIdTypes } from "../../../constants/stripe";
import { State } from "../../../reducers";
import { attemptInvoke } from "../../../utils/ReactHelpers";

type PrivilegeName = 'can-use-monitoring' | 'can-manage-members' | 'can-create-organization' | 'can-edit-schedule'

interface DispatchToProps {
}
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {};
}

interface StateToProps {
    privileges: PrivilegeName[]
}
function mapStateToProps(state: State.All): StateToProps {
    const privileges: PrivilegeName[] = []

    const { selectedOrganization } = defaultTo(state?.organization, {})

    if (selectedOrganization?.planId !== PlanIdTypes.STARTUP) {
        privileges.push("can-use-monitoring")
    }

    if (selectedOrganization?.status !== 'trial') {
        privileges.push("can-edit-schedule")
    }

    if (selectedOrganization?.isAdmin || selectedOrganization?.isOwner) {
        privileges.push("can-manage-members")
    }

    return {
        privileges
    };
}

type OnPrivilegesLoad = (privileges: PrivilegeName[]) => void
interface ExposedProps {
    onPrivilegesLoaded: OnPrivilegesLoad
}
function mergeProps(ownProps: any, stateProps: any, dispatchProps: ExposedProps) {
    return { ...ownProps, ...stateProps, ...dispatchProps }
}

interface ComponentProps extends DispatchToProps, StateToProps, ExposedProps { }

interface ComponentState {
    privileges: PrivilegeName[];
}


class HasPrivilegeComponent extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);
        const { privileges } = props
        this.state = {
            privileges
        };
    }

    componentWillMount(): void {
        attemptInvoke(this.props.onPrivilegesLoaded, this.state.privileges)
    }

    componentWillReceiveProps(nextProps: Readonly<ComponentProps>, nextContext: any): void {
        if (xor(nextProps.privileges, this.state?.privileges).length > 0) {
            const { privileges } = nextProps
            this.setState({ privileges }, () => attemptInvoke(this.props.onPrivilegesLoaded, privileges))
        }
    }

    render(): false | JSX.Element {
        return false;
    }
}

export const HasPrivilege = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(HasPrivilegeComponent);
