import * as React from "react";
import AceEditor from "react-ace";
import { Button } from "react-toolbox/lib/button";
import Dropdown from "react-toolbox/lib/dropdown";
import Input from "react-toolbox/lib/input";
import { Table, TableCell, TableHead, TableRow } from "react-toolbox/lib/table";
import * as uuid from "uuid";
import BespokenButtonIcon from "../../assets/bespoken_button_icon.svg";
import Billing from "../../assets/billing.svg";
import Monitoring from "../../assets/monitoring.svg";
import VirtualDevices from "../../assets/virtual_devices.svg";
import VoiceApps from "../../assets/voice_apps.svg";
import Avatar from "../components/Avatar/Avatar";
import BarsChart from "../components/Graphs/Bar/BarsChart";
import RadialBarChart from "../components/Graphs/Radial/ValidationRadialChart";
import BespokenSideBar from "../components/SideBar/SideBar";

const centerStyle = {
    margin: "auto",
    width: "50%",
    padding: "10px"
};

const buttonStyle = require("../themes/button_theme.scss");
const bespokenButton = require("../themes/bespoken_button.scss");
const bespokenDropdown = require("../themes/bespoken_dropdown.scss");
const bespokenInput = require("../themes/bespoken_input.scss");


export default class ComponentsPage extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            bannerHtml: "",
            script: "",
            value: "EN-uk",
            name: "",
            phone: "",
            email: "",
            hint: "",
        };
    }

    async componentDidMount() {
        // const banner = await SourceService.getBanner("communication");
        // this.setState((prevState: any) => ({
        //     ...prevState,
        //     bannerHtml: banner.htmlstring,
        //     script: banner.script
        // }));
        // if (banner.script) {
        //     this.runScript(banner.script);
        // }
    }

    runScript = (script: string) => {
        const scriptTag = document.createElement("script");
        scriptTag.async = true;
        scriptTag.innerHTML = script;
        document.body.appendChild(scriptTag);
    }

    handleBannerHtmlChange = (value: string) => {
        this.setState(() => ({
            bannerHtml: value,
        }));
        if (this.state.script) {
            this.runScript(this.state.script);
        }
    }

    handleDropdownChange = (value: string) => {
        this.setState((prevState: any) => ({
            ...prevState,
            value,
        }));
    }

    handleChange = (value: string, ev: any) => {
        this.setState({ [ev.target.name]: value });
    }

    getSource = () => [
        { tag: <h1 id={"h1"}>This is a h1 tag</h1>, style: "h1", usage: "page title" },
        { tag: <h2 id={"h2"}>This is a h2 tag</h2>, style: "h2", usage: "page sub titles" },
        { tag: <h3 id={"h3"}>This is a h3 tag</h3>, style: "h3", usage: "section titles" },
        { tag: <h4 id={"h4"}>This is a h4 tag</h4>, style: "h4", usage: "section titles" },
        { tag: <h5 id={"h5"}>This is a h5 tag</h5>, style: "h5", usage: "item titles" },
        { tag: <h6 id={"h6"}>This is a h6 tag</h6>, style: "h6", usage: "item titles" },
        { tag: <p id={"p"}>this is a regular p tag</p>, style: "p", usage: "regular paragraph texts" },
        { tag: <a id={"a"} target={"_blank"} href={"https://www.google.com"}>this is a regular link</a>, style: "a", usage: "links" },
        { tag: <small id={"small"}>This is small tag</small>, style: "small", usage: "subtexts or explanation texts" },
        { tag: <label id={"label"}>This is a label tag</label>, style: "label", usage: "button creations, general items etc" },
        { tag: <span id={"span"}>This is a span tag</span>, style: "span", usage: "general text where we'll need custom styles on texts, the styles defined on this tag are not mandatory" },
    ]

    renderBodyTable = (rows: any) => {
        const element = document.getElementById(rows[0].style);
        return rows.map((row: any, index: number) => {
            const element = document.getElementById(row.style);
            const computedStyle = element && getComputedStyle(element);
            const style = computedStyle && `${computedStyle.fontSize}, ${computedStyle.fontFamily}, font-weight: ${computedStyle.fontWeight}`;
            return (
                <TableRow key={index}>
                    <TableCell>{row.tag}</TableCell>
                    <TableCell>{style}</TableCell>
                    <TableCell>{row.usage}</TableCell>
                </TableRow>
            );
        });
    }

    render() {
        const items = [
            {
                id: 1, icon: <VoiceApps />, label: "test suites", onClick: () => {
                }
            },
            {
                id: 2, icon: <VirtualDevices />, label: "Virtual Devices", onClick: () => {
                }
            },
            {
                id: 3, icon: <Monitoring />, label: "Monitoring", onClick: () => {
                }
            },
            {
                id: 4, icon: <Billing />, label: "Billing", onClick: () => {
                }
            },
        ];

        const data = [
            { name: "Page A", uv: 4000 },
            { name: "Page B", uv: 3000 },
            { name: "Page C", uv: 2000 },
            { name: "Page D", uv: 2780 },
            { name: "Page E", uv: 1890 },
            { name: "Page F", uv: 2390 },
            { name: "Page G", uv: 3490 },
            { name: "Page A", uv: 4000 },
            { name: "Page B", uv: 3000 },
            { name: "Page C", uv: 2000 },
            { name: "Page D", uv: 2780 },
            { name: "Page E", uv: 1890 },
            { name: "Page F", uv: 2390 },
            { name: "Page G", uv: 3490 },
            { name: "Page A", uv: 4000 },
            { name: "Page B", uv: 3000 },
            { name: "Page C", uv: 2000 },
            { name: "Page D", uv: 2780 },
            { name: "Page E", uv: 1890 },
            { name: "Page F", uv: 2390 },
            { name: "Page G", uv: 3490 },
            { name: "Page A", uv: 4000 },
            { name: "Page B", uv: 3000 },
            { name: "Page C", uv: 2000 },
            { name: "Page D", uv: 2780 },
            { name: "Page E", uv: 1890 },
            { name: "Page F", uv: 2390 },
            { name: "Page G", uv: 3490 },
            { name: "Page A", uv: 4000 },
            { name: "Page B", uv: 3000 },
            { name: "Page C", uv: 2000 },
            { name: "Page D", uv: 2780 },
            { name: "Page E", uv: 1890 },
            { name: "Page F", uv: 2390 },
            { name: "Page G", uv: 3490 },
        ];

        const languages: any[] = [
            { value: "EN-us", label: "English (US)" },
            { value: "EN-uk", label: "English (UK)" },
            { value: "EN-au", label: "English (Australia)" },
            { value: "EN-ca", label: "English (Canada)" },
            { value: "EN-in", label: "English (India)" },
            { value: "FR", label: "French" },
            { value: "GE", label: "German" },
            { value: "IT", label: "Italian" },
        ];

        return (
            <div style={{ marginLeft: 80 }}>
                <BespokenSideBar goTo={undefined} user={this.props.userDetails} items={items} currentOrganization={undefined}/>
                <Avatar />
                <h1 style={{ ...centerStyle, ...{ textAlign: "center" } }}>Components Page</h1>
                <div style={{ padding: "1rem" }}>
                    <Table selectable={false} style={{ marginTop: 10 }}>
                        <TableHead>
                            <TableCell>Tag</TableCell>
                            <TableCell>Style</TableCell>
                            <TableCell>Usage</TableCell>
                        </TableHead>
                        {this.renderBodyTable(this.getSource())};
                    </Table>
                </div>
                <div style={{ padding: "1rem" }}>
                    <h2>Buttons</h2>
                    <h4>Old buttons</h4>
                    <div className={"button_container_components_page"} >
                        <div>
                            <div>classname bespoken primary</div>
                            <button className={`${buttonStyle.bespoken} ${buttonStyle.primary}`}>Primary</button>
                        </div>
                        <div>
                            <div>classname bespoken primary</div>
                            <button className={`${buttonStyle.bespoken} ${buttonStyle.primary}`} disabled={true}>Disabled</button>
                        </div>
                        <div>
                            <div>classname bespoken secondary</div>
                            <button className={`${buttonStyle.bespoken} ${buttonStyle.secondary}`}>Secondary</button>
                        </div>
                        <div>
                            <div>classname bespoken tertiary</div>
                            <button className={`${buttonStyle.bespoken} ${buttonStyle.tertiary}`}>Tertiary</button>
                        </div>
                    </div>
                    <h4>New buttons</h4>
                    <div className={"button_container_components_page"} >
                        <div>
                            <div>bespoken primary</div>
                            <Button theme={bespokenButton} primary={true} icon={<BespokenButtonIcon />} >Create New Account</Button>
                        </div>
                        <div>
                            <div>bespoken primary disabled</div>
                            <Button theme={bespokenButton} primary={true} icon={<BespokenButtonIcon />} disabled={true} >Create New Account</Button>
                        </div>
                        <div>
                            <div>bespoken accent</div>
                            <Button className={bespokenButton.large} accent={true} theme={bespokenButton} icon={<BespokenButtonIcon />} >New Account</Button>
                        </div>
                        <div>
                            <div>bespoken secondary</div>
                            <Button theme={bespokenButton} >Secondary Button</Button>
                        </div>
                        <div>
                            <div>bespoken secondary disabled</div>
                            <Button theme={bespokenButton} disabled={true} >Secondary Button Disabled</Button>
                        </div>
                        <div>
                            <div>bespoken secondary accent</div>
                            <Button theme={bespokenButton} accent={true} >Secondary Button Accent</Button>
                        </div>
                    </div>
                    <h2>DropDowns</h2>
                    <div className={"button_container_components_page"} >
                        <div>
                            <div>bespoken primary</div>
                            <Dropdown
                                key={uuid.v4()}
                                theme={bespokenDropdown}
                                onChange={this.handleDropdownChange}
                                source={languages}
                                value={this.state.value} />
                        </div>
                        <div>
                            <div>bespoken small</div>
                            <Dropdown
                                key={uuid.v4()}
                                theme={bespokenDropdown}
                                className={bespokenDropdown.small}
                                onChange={this.handleDropdownChange}
                                source={languages}
                                value={this.state.value} />
                        </div>
                    </div>
                    <h2>Inputs</h2>
                    <div className={"button_container_components_page"} >
                        <div>
                            <div>bespoken primary</div>
                            <Input theme={bespokenInput} floating={false} type="text" label="Company" name="name" value={this.state.name} onChange={this.handleChange} maxLength={16} />
                        </div>
                        <div>
                            <div>bespoken small</div>
                            <Input theme={bespokenInput} className={bespokenInput.small} floating={false} type="text" label="Company" name="name" value={this.state.name} onChange={this.handleChange} maxLength={16} />
                        </div>
                    </div>
                </div>
                <div style={{ minHeight: 830 }}>
                    <h2 style={{ paddingLeft: 20 }}>Login right panel</h2>
                    <div className={"global_login_container"}>
                        <div style={{ paddingTop: 0, textAlign: "center" }}>
                            <AceEditor
                                style={{ width: "90%", height: "90%", margin: "auto" }}
                                mode="html"
                                theme="monokai"
                                name="yamlEditor"
                                onChange={this.handleBannerHtmlChange}
                                fontSize={14}
                                showPrintMargin={false}
                                showGutter={true}
                                highlightActiveLine={true}
                                value={this.state.bannerHtml}
                                wrapEnabled={true} />
                        </div>
                        <div className={"banner_test"} dangerouslySetInnerHTML={{ __html: this.state.bannerHtml }} />
                    </div>
                </div>
                <div>
                    <div className="components_container">
                        <h2>Radial chart</h2>
                        <div><RadialBarChart successRatio={100} /></div>
                        <div><RadialBarChart successRatio={80} color={"#FF0000"} /></div>
                        <div><RadialBarChart successRatio={20} color={"#000"} /></div>

                    </div>

                    <div className="components_container" style={{ height: 250, marginBottom: 50 }}>
                        <h2 style={{ margin: 0 }}>Bars chart</h2>
                        <BarsChart data={data} bars={[{ dataKey: "uv", title: "Daily Events", average: 879 }]} />
                    </div>
                </div>
            </div>
        );
    }
}
