import * as React from "react";
import { connect } from "react-redux";
import BespokenLogo from "../../../assets/bespoken_logoweb.svg";
import BespokenMountains from "../../../assets/bespoken_mountains.svg";
import BinocularsIcon from "../../../assets/binoculars.svg";
import * as backgroundLeft from "../../../assets/images/bespoken_auth_no_text.jpg";
import { LeftPanel, Loader, RightPanel } from "../../components";
import { LoaderIndicator } from "../../components/ConsolidatedReports";
import User from "../../models/user";
import { State } from "../../reducers";
import { loginWithIbm } from "../../actions/context";
import { wrapCallbackAsAsync } from "../../utils/ReactHelpers";


const style = require("./loginStyles.scss");

interface IbmLoginProps {
  loginWithIbm: (instanceId: string, code: string) => Promise<any>;
}

interface IbmLoginState {
  error?: any;
  loading?: boolean;
  showLoader?: boolean;
}

const mapStateToProps = (state: State.All) => ({});

const mapDispatchToProps = (dispatch: any) =>
({
  loginWithIbm: (instanceId: string, code: string) =>
    wrapCallbackAsAsync(handle => dispatch(loginWithIbm(instanceId, code, handle))),
});

export class IbmRedirect extends React.Component<IbmLoginProps, IbmLoginState> {
  constructor(props: IbmLoginProps) {
    super(props);
    this.state = { error: "", showLoader: false };
  }

  async componentDidMount() {
    const urlParsed = new URL(location.href);
    const code = urlParsed.searchParams.get('code');
    const state = urlParsed.searchParams.get('state');
    if (code) {
      await this.props.loginWithIbm(state, code);
    } else {
      console.log('missing code')
      // redirect to login
    }
  }


  render() {
    const allProps = this.props as any;
    const location = allProps.location;
    return (
      <div>
        <LeftPanel className={style.leftPanel} fullHeight={true} image={backgroundLeft}>
          <div data-id="details">
            <BinocularsIcon data-id="icon" />
            <div data-id="message1">Welcome to your Dashboard</div>
            <div data-id="message2">from here, you can</div>
            <div data-id="message3">see everything</div>
          </div>
          <div data-id="companyName">Bespoken</div>
        </LeftPanel>
        <RightPanel className={style.rightPanel} fullHeight>
          <section>
            <BespokenLogo data-id="icon" />
            <LoaderIndicator />
          </section>
          <BespokenMountains data-id="mountains" />
        </RightPanel>
        {this.state.showLoader && <Loader />}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IbmRedirect);
