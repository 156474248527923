import React = require("react");
import BespokenUser from "../../models/bespoken-user";
import { UserDetailsSection } from "./sections/user-details-section";
import { DangerSection } from "./sections/danger-section";
import { SecuritySection } from "./sections/security-section";
import { CommunicationSection } from "./sections/communication-section";
import { CookieSection } from "./sections/cookie-section";
import { ApiSection } from "./sections/api-section";
import { SelectedOrganization } from "../../reducers/organization";
import { User } from "../../reducers/user";

const AccountStyle = require("./account-style.scss");

export interface AccountProps {
    user: User;
    updateUser: (user: User) => Promise<any>;
    setLoading: (value: boolean) => any;
    changeSnackMessage: (snackbarMessage: string) => any;
    handleDeleteAccountDialogToggle: () => void;
    currentOrganization: SelectedOrganization;
}

export interface AccountState {
}

export class Account extends React.Component<AccountProps, AccountState> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        const user = this.props.user;
        const { id } = user || {};
        const { ibmUser = false } = this.props.currentOrganization;
        return (
            <div className={AccountStyle.container}>
                <UserDetailsSection
                    user={user}
                    organization={this.props.currentOrganization}
                    updateUser={this.props.updateUser}
                    setLoading={this.props.setLoading}
                    changeSnackMessage={this.props.changeSnackMessage}
                />
                <SecuritySection
                    setLoading={this.props.setLoading}
                    changeSnackMessage={this.props.changeSnackMessage}
                />
                <CommunicationSection
                    user={user}
                    updateUser={this.props.updateUser}
                    setLoading={this.props.setLoading}
                    changeSnackMessage={this.props.changeSnackMessage}
                />
                <CookieSection
                    user={user}
                    setLoading={this.props.setLoading}
                    changeSnackMessage={this.props.changeSnackMessage}
                />
                <ApiSection
                    id={id}
                    changeSnackMessage={this.props.changeSnackMessage}
                />
                {
                    !ibmUser &&
                    <DangerSection
                        handleDeleteAccount={this.props.handleDeleteAccountDialogToggle}
                    />
                }
            </div>
        )
    }

}