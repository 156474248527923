import React = require("react");
import { Button } from "react-toolbox/lib/button";
import Input from "react-toolbox/lib/input";

const AccountStyle = require("../account-style.scss");
const InputTheme = require("../../../themes/input.scss");
const buttonStyle = require("../../../themes/amazon_pane.scss");
const bespokenButton = require("../../../themes/bespoken_button.scss");

interface DangerSectionProps {
    handleDeleteAccount: any;
}

interface DangerSectionState {
    deleteConfirmation: string;
    deleteButtonDisabled: boolean;
}

export class DangerSection extends React.Component<DangerSectionProps, DangerSectionState> {
    constructor(props: any) {
        super(props);
        this.state = {
            deleteConfirmation: '',
            deleteButtonDisabled: true,
        };
    }

    handleDelete = async () => {
        this.props.handleDeleteAccount();
    }

    handleChangeInput = (v: string, event: any) => {
        if (event && event.target) {
            const nameInput = event.target.name;
            const value = event.target.value;
            switch (nameInput) {
                case "deleteConfirmation":
                    if (value === "delete my account") {
                        this.setState((prevState) => ({ ...prevState, deleteButtonDisabled: false }));
                    } else {
                        this.setState((prevState) => ({ ...prevState, deleteButtonDisabled: true }));
                    }
                    this.setState((prevState) => ({ ...prevState, deleteConfirmation: value }));
                    break;
                default:
                    break;
            }
        }
    }

    render() {
        return (
            <div className={`${AccountStyle.accountSection}`}>
                <div className={AccountStyle.description}>
                    <h4>
                        Danger Zone
                    </h4>
                    <span>Type "delete my account" and click on the button to delete your account. Once you delete your account, there is no going back.</span>
                </div>
                <div className={`${AccountStyle.content} ${AccountStyle.work_around}`}>
                    <div className={AccountStyle.fields_container}>
                        <div className={AccountStyle.property}>
                            <Input
                                maxLength={100}
                                label="delete my account"
                                name="deleteConfirmation"
                                theme={InputTheme}
                                className={InputTheme.user_input}
                                value={this.state.deleteConfirmation}
                                onChange={this.handleChangeInput} />
                            <Button
                                theme={bespokenButton}
                                className={`${bespokenButton.medium} ${bespokenButton.warning} ${AccountStyle.right}`}
                                disabled={this.state.deleteButtonDisabled}
                                accent={true}
                                label={"Delete"}
                                onClick={this.handleDelete} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}