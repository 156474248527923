import { chain, isEmpty } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { RouterState } from "react-router";
import { replace } from "react-router-redux";
import { switchOrganization } from "../../actions/organization";
import { LoaderIndicator } from "../../components/ConsolidatedReports";
import { TextBlueLabelSmall } from "../../components/lunacy";
import { ERROR } from "../../components/lunacy/debug-panel/DebugPanel";
import { State } from "../../reducers";
import { Organization } from "../../reducers/organization";
import { wrapCallbackAsAsync } from "../../utils/ReactHelpers";
import { CurrentPageProps } from "../../reducers/session";
import { setCurrentPageProps } from "../../actions/session";

const Styles = require("./styles.scss");

interface DispatchToProps {
    goTo?: (path: string) => void;
    switchOrganization?: (organizationId: string) => Promise<any>;
    setCurrentPageProps?: (value: CurrentPageProps) => any;
}
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        goTo: (path: string) => dispatch(replace(path)),
        switchOrganization: async (organizationId: string) => wrapCallbackAsAsync(handle => dispatch(switchOrganization(organizationId, handle))),
        setCurrentPageProps: (value: CurrentPageProps) => dispatch(setCurrentPageProps(value))
    };
}

interface StateToProps {
    organizations?: Organization[]
}
function mapStateToProps(state: State.All): StateToProps {
    return {
        organizations: state?.organization?.organizations
    };
}

interface ExposedProps { }
function mergeProps(ownProps: any, stateProps: any, dispatchProps: ExposedProps) {
    return { ...ownProps, ...stateProps, ...dispatchProps }
}

interface PageProps extends DispatchToProps, StateToProps, ExposedProps { }

interface PageState {
    redirectUrl: string;
    organizationId: string;
    organizationName: string;
    loadingMessage: string;
}

class Page extends React.Component<PageProps, PageState> {

    static defaultProps: PageProps = {}

    constructor(props: PageProps) {
        super(props);

        this.state = {
            loadingMessage: '...',
            organizationId: undefined,
            redirectUrl: undefined,
            organizationName: undefined
        }

    }

    async componentDidMount(): Promise<void> {
        this.props.setCurrentPageProps({ subTitle: '', title: 'Not found' })
        this.processLanding()
    }

    async processLanding(): Promise<void> {
        const routerState: RouterState = this.props as any
        const organizationId = routerState?.params?.organizationId

        if (isEmpty(organizationId)) {
            ERROR('Organization id not provided')
            return this.props?.goTo('/notFound')
        }

        const organizationName = chain(this.props?.organizations).find(it => it.id === organizationId).value()?.name

        if (isEmpty(organizationName)) {
            ERROR('Organization id not found')
            return this.props?.goTo('/notFound')
        }

        this.setState({ loadingMessage: `Loading organization: ${organizationName}`, organizationId, organizationName })

        const redirectUrl = chain(routerState?.location?.pathname)
            .split(organizationId)
            .last()
            .value()

        await this.props.switchOrganization(organizationId)

        this.setState({ loadingMessage: `Redirecting: ${redirectUrl}`, redirectUrl })

        setTimeout(() => this.props.goTo(redirectUrl), 500);
    }

    render(): false | JSX.Element {
        return (
            <div className={Styles.container}>
                <div className={Styles.loading_icon}>
                    <LoaderIndicator />
                    <TextBlueLabelSmall>{this.state.loadingMessage}</TextBlueLabelSmall>
                </div>
            </div>
        )
    }
}

export const LangingPage = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(Page);

