import { first, isEmpty, isFunction, isUndefined, size, values } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { State } from "../../reducers";
import { attemptInvoke } from "../../utils/ReactHelpers";

const Styles = require("./style.scss");

interface DispatchToProps { }
function mapDispatchToProps(dispatch: any) { return {}; }

interface StateToProps { }
function mapStateToProps(state: State.All) { return {}; }

interface ExposedProps {
    onValueChanged: (value: string) => void;
    children?: React.ReactNode[]
}
function mergeProps(ownProps: any, stateProps: any, dispatchProps: ExposedProps) {
    return { ...ownProps, ...stateProps, ...dispatchProps }
}

interface ToggleLinkProps extends DispatchToProps, StateToProps, ExposedProps { }

interface ToggleLinkState {
    selectedIndex: number;
    items: any[];
}

class ToggleLinkComponent extends React.Component<ToggleLinkProps, ToggleLinkState> {

    constructor(props: ToggleLinkProps) {
        super(props);
        this.state = {
            items: props.children,
            selectedIndex: 0
        }
    }

    render(): false | JSX.Element {
        const { selectedIndex, items } = this.state
        const currentItem: ToggleLinkItemProps = items[selectedIndex].props

        return (<a className={Styles.container} onClick={() => this.handleClick()}>{currentItem.children}</a>)
    }

    handleClick(): void {
        const { onValueChanged } = this.props
        const { selectedIndex, items } = this.state
        let nextIndex = selectedIndex + 1;

        if (isEmpty(items)) { return }

        if (nextIndex === size(items)) {
            nextIndex = 0
        }

        const value = (items[nextIndex]?.props as ToggleLinkItemProps)?.value
        if (isUndefined(value)) { return }

        attemptInvoke(onValueChanged, value)

        this.setState({ selectedIndex: nextIndex })
    }
}

export const ToggleLink = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ToggleLinkComponent);

type ToggleLinkItemProps = { value: string, children?: React.ReactNode }
export const ToggleLinkItem = (props: ToggleLinkItemProps) => {
    return <div />
}