import * as React from "react";
const Styles = require("./styles.scss");

export const alexa = <img className={Styles.type_icon} src={"https://bespoken.io/wp-content/uploads/2018/03/amazon-alexa.png"} alt={"alexa icon"} />
export const google = <img className={Styles.type_icon} src={"https://bespoken.io/wp-content/uploads/2018/03/google-actions.png"} alt={"google icon"} />
export const twilio = <img className={Styles.type_icon} src={"https://firebasestorage.googleapis.com/v0/b/bespoken-tools.appspot.com/o/images%2Fphone.png?alt=media&token=ad09a2ce-d6ed-404b-9aba-d26b84c6978d"} alt={"phone icon"} />
export const sms = <img className={Styles.type_icon} src={"https://firebasestorage.googleapis.com/v0/b/bespoken-tools.appspot.com/o/images%2Fsms.png?alt=media&token=1cfb2acf-204a-417e-8fb6-f30b63e5b0ca"} alt={"sms icon"} />
export const whatsapp = <img className={Styles.type_icon} src={"https://firebasestorage.googleapis.com/v0/b/bespoken-tools.appspot.com/o/images%2Fwhatsapp.png?alt=media&token=7f3b554e-4255-4287-876e-b74483f63914"} alt={"whatsapp icon"} />
export const webchat = <img className={Styles.type_icon} src={"https://firebasestorage.googleapis.com/v0/b/bespoken-tools.appspot.com/o/images%2Fwebchat.png?alt=media&token=25ee025c-524a-424e-bfad-4a99ae49f6bf"} alt={"whatsapp icon"} />
export const watson = <img className={Styles.type_icon} src={"https://firebasestorage.googleapis.com/v0/b/bespoken-tools.appspot.com/o/images%2Fwatson.png?alt=media&token=fb4faaca-c71a-4348-a7a7-1b6838e3fbee"} alt={"watson icon"} />
export const voiceflow = <img className={Styles.type_icon} src={"https://firebasestorage.googleapis.com/v0/b/bespoken-tools.appspot.com/o/images%2Fvoiceflow.png?alt=media&token=fb4faaca-c71a-4348-a7a7-1b6838e3fbee"} alt={"voiceflow icon"} />
export const lex = <img className={Styles.type_icon} src={"https://firebasestorage.googleapis.com/v0/b/bespoken-tools.appspot.com/o/images%2Famazon-lex.png?alt=media&token=ea4e668d-b55e-4185-ab76-8d33364622ee"} alt={"lex icon"} />
export const phone = <img className={Styles.type_icon} src={"https://firebasestorage.googleapis.com/v0/b/bespoken-tools.appspot.com/o/images%2Fphone.png?alt=media&token=ad09a2ce-d6ed-404b-9aba-d26b84c6978d"} alt={"phone icon"} />
export const email = <img className={Styles.type_icon} src={"https://firebasestorage.googleapis.com/v0/b/bespoken-tools.appspot.com/o/images%2Femail1.png?alt=media&token=56636a28-4b93-421c-844f-718c55d4e2ee"} alt={"email icon"} />
export const hybrid = <div>
    <img className={Styles.type_icon} src={"https://bespoken.io/wp-content/uploads/2018/03/amazon-alexa.png"} alt={"alexa icon"} />
    <img className={Styles.type_icon} src={"https://bespoken.io/wp-content/uploads/2018/03/google-actions.png"} alt={"google icon"} />
</div>

export type PlatformNames = "alexa" | "google" | "twilio" | "sms" | "whatsapp" | "webchat" | "voiceflow" | "lex" | "watson" | "phone" | "hybrid" | "email"
export const PLATFORM_ICONS = {
    alexa,
    google,
    email,
    twilio,
    sms,
    lex,
    voiceflow,
    whatsapp,
    webchat,
    watson,
    phone,
    hybrid
}
