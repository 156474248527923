/* eslint-env browser */

// @ts-ignore
const audioContext = new (window.AudioContext || window.webkitAudioContext)()

const CHUNK_SIZE = 16000
/**
 *
 */
class StreamPlayer {
    private static singleton: StreamPlayer = new StreamPlayer(8000)
    public static instance(): StreamPlayer {
        return this.singleton
    }
    private source: AudioBufferSourceNode;
    private bufferArray: Uint16Array;
    private bufferLength: number;
    private _playing = true;
    private onPlayingCallback: () => void;

    constructor(private sampleRate: (8000 | 16000)) {
        this.sampleRate = sampleRate

        // Our buffer stores audio coming from the WebSocket before playback
        this.bufferArray = new Uint16Array(CHUNK_SIZE)
        this.bufferLength = 0
    }

    get playing () {
        return this._playing
    }

    play(): StreamPlayer {
        this._playing = true
        this.onPlayingCallback()
        //this.source.start()
        return this
    }

    /**
     * Data from http calls is best handled as an ASCII string so it is in unsigned 8-bit form for processing
     * @param {string} audioData
     * @returns {void}
     */
    pushAsciiData(audioData: string) {
        const frameCount = audioData.length / 2

        // const frameCount = 16000

        const audioBuffer = audioContext.createBuffer(1, frameCount, this.sampleRate)

        // console.info('get data: ' + audioData.length)
        const newBuffer = audioBuffer.getChannelData(0)
        for (let i = 0; i < frameCount; i++) {
            // audio needs to be in [-1.0; 1.0]
            // for this reason I also tried to divide it by 32767
            // as my pcm sample is in 16-Bit. It plays still the
            // same creepy sound less noisy.
            let word = 0
            if (audioData) {
                word = (audioData.charCodeAt(i * 2) & 0xff) + ((audioData.charCodeAt(i * 2 + 1) & 0xff) << 8)
            }

            // nowBuffering[i] = ((word + 32768) % 65536 - 32768) / 32768.0
            newBuffer[i] = ((word + 32768) % 65536 - 32768) / 32768.0
        }
        // for demo only

        if (this.source) {
            this.source.stop()
        }
        this.source = audioContext.createBufferSource()
        // set the buffer in the AudioBufferSourceNode
        this.source.buffer = audioBuffer
        // connect the AudioBufferSourceNode to the
        // destination so we can hear the sound
        this.source.connect(audioContext.destination)
        // start the source playing
        this.source.start()
    }

    onPlaying(callback: () => void) {
        this.onPlayingCallback = callback
    }

    async pushBlob(audioData: Blob): Promise<void> {
        if (!this._playing) {
            return
        }

        // We accumulate incoming audio into an 16-bit array buffer to smooth it
        // const newArray = this._base64ToUInt16Array(audioData)
        const intArray = await this._blobToUInt16Array(audioData)
        for (let i = 0; i < intArray.length; i++) {
            const index = this.bufferLength + i
            if (index - 1 >= CHUNK_SIZE) {
                break
            }

            this.bufferArray[index] = intArray[i]
        }
        this.bufferLength += intArray.length

        // If our internal buffer is less than the chunk size, wait until more accumulates
        if (this.bufferLength < CHUNK_SIZE) {
            // console.warn('accumulating buffer: ' + this.bufferLength)
            return
        } else {
            //console.warn('playing buffer: ' + this.bufferLength)
        }

        // console.info('audio length: ' + audioData.size)
        const frameCount = CHUNK_SIZE / 2
        // const frameCount = 16000

        const audioBuffer = audioContext.createBuffer(2, frameCount, this.sampleRate)

        // console.info('get data: ' + audioData.length)
        const buffer1 = audioBuffer.getChannelData(0)
        const buffer2 = audioBuffer.getChannelData(1)

        // Convert our unsigned 16-bit PCM data into Float32 for playback as required by browser
        for (let i = 0; i < frameCount; i++) {
            buffer1[i] = ((this.bufferArray[i * 2] + 32768) % 65536 - 32768) / 32768.0
            buffer2[i] = ((this.bufferArray[(i * 2) + 1] + 32768) % 65536 - 32768) / 32768.0
        }

        // Stop any data that was playing before - we give priority to the latest audio to arrive
        if (this.source) {
            this.source.stop()
        }
        this.source = audioContext.createBufferSource()
        // set the buffer in the AudioBufferSourceNode
        this.source.buffer = audioBuffer
        // connect the AudioBufferSourceNode to the
        // destination so we can hear the sound
        this.source.connect(audioContext.destination)
        // start the source playing
        this.source.start()

        // Reset out internal buffer
        this.bufferArray = new Uint16Array(CHUNK_SIZE)
        this.bufferLength = 0
    }

    reset () {
        this.bufferLength = 0
    }

    async _blobToUInt16Array(blob: Blob): Promise<Uint16Array> {
        // @ts-ignore
        const buffer = await blob.arrayBuffer()
        const uint16Array = new Uint16Array(buffer)
        return uint16Array
    }

    _base64ToUInt16Array(base64: string): Uint16Array {
        const binaryString = window.atob(base64)
        const length = binaryString.length
        const bytes = new Uint8Array(length)
        for (let i = 0; i < length; i++) {
            bytes[i] = binaryString.charCodeAt(i)
        }
        // return bytes.buffer
        // const frameCount = blob.size / 2
        // const buffer = await blob.arrayBuffer()
        const uint16Array = new Uint16Array(bytes)
        return uint16Array
    }

    pause () {
        //this.source.stop()
        return this._playing = false
    }
}

export default StreamPlayer
