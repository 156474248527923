export type FETCH_ORGANIZATION = "bst/FETCH_ORGANIZATION";
export const FETCH_ORGANIZATION: FETCH_ORGANIZATION = "bst/FETCH_ORGANIZATION";

export type CREATE_ORGANIZATION = "bst/CREATE_ORGANIZATION";
export const CREATE_ORGANIZATION: CREATE_ORGANIZATION = "bst/CREATE_ORGANIZATION";

export type UPDATE_ORGANIZATION = "bst/UPDATE_ORGANIZATION";
export const UPDATE_ORGANIZATION: UPDATE_ORGANIZATION = "bst/UPDATE_ORGANIZATION";

export type SWITCH_ORGANIZATION = "bst/SWITCH_ORGANIZATION";
export const SWITCH_ORGANIZATION: SWITCH_ORGANIZATION = "bst/SWITCH_ORGANIZATION";

export type FETCH_SOURCES = "bst/FETCH_SOURCES";
export const FETCH_SOURCES: FETCH_SOURCES = "bst/FETCH_SOURCES";

export type UPDATE_SOURCE = "bst/UPDATE_SOURCE";
export const UPDATE_SOURCE: UPDATE_SOURCE = "bst/UPDATE_SOURCE";

export type CREATE_VIRTUAL_DEVICE = "bst/CREATE_VIRTUAL_DEVICE";
export const CREATE_VIRTUAL_DEVICE: CREATE_VIRTUAL_DEVICE = "bst/CREATE_VIRTUAL_DEVICE";

export type CREATE_VIRTUAL_DEVICE_WITH_OAUTH_LINK = "bst/CREATE_VIRTUAL_DEVICE_WITH_OAUTH_LINK";
export const CREATE_VIRTUAL_DEVICE_WITH_OAUTH_LINK: CREATE_VIRTUAL_DEVICE_WITH_OAUTH_LINK = "bst/CREATE_VIRTUAL_DEVICE_WITH_OAUTH_LINK";

export type REFRESH_VIRTUAL_DEVICE_WITH_OAUTH_LINK = "bst/REFRESH_VIRTUAL_DEVICE_WITH_OAUTH_LINK";
export const REFRESH_VIRTUAL_DEVICE_WITH_OAUTH_LINK: REFRESH_VIRTUAL_DEVICE_WITH_OAUTH_LINK = "bst/REFRESH_VIRTUAL_DEVICE_WITH_OAUTH_LINK";

export type DELETE_VIRTUAL_DEVICE = "bst/DELETE_VIRTUAL_DEVICE";
export const DELETE_VIRTUAL_DEVICE: DELETE_VIRTUAL_DEVICE = "bst/DELETE_VIRTUAL_DEVICE";

export type UPDATE_VIRTUAL_DEVICE = "bst/UPDATE_VIRTUAL_DEVICE";
export const UPDATE_VIRTUAL_DEVICE: UPDATE_VIRTUAL_DEVICE = "bst/UPDATE_VIRTUAL_DEVICE";

export type FETCH_VIRTUAL_DEVICE = "bst/FETCH_VIRTUAL_DEVICE";
export const FETCH_VIRTUAL_DEVICE: FETCH_VIRTUAL_DEVICE = "bst/FETCH_VIRTUAL_DEVICE";

export type REFRESH_VIRTUAL_DEVICES_STATE = "bst/REFRESH_VIRTUAL_DEVICES_STATE";
export const REFRESH_VIRTUAL_DEVICES_STATE: REFRESH_VIRTUAL_DEVICES_STATE = "bst/REFRESH_VIRTUAL_DEVICES_STATE";

export type RESET_ORGANIZATION_STATE = "bst/RESET_ORGANIZATION_STATE";
export const RESET_ORGANIZATION_STATE: RESET_ORGANIZATION_STATE = "bst/RESET_ORGANIZATION_STATE";