import * as cn from "classnames";
import * as React from "react";
import { Button } from "react-toolbox/lib/button";
import Drawer from "react-toolbox/lib/drawer";
import Tooltip from "react-toolbox/lib/tooltip";
import BespokenLogo from "../../../assets/bespoken_logo.svg";
import Source from "../../models/source-legacy";
import { UserDetails, userStatus } from "../../models/user";
import { DebugPanel } from "../lunacy/debug-panel/DebugPanel";
import { ReactLi } from "../ReactDiv";
import { SelectedOrganization } from "../../reducers/organization";
import { User } from "../../reducers/user";

const bespokenTooltip = require("../../themes/bespoken_tooltip.scss");
const bespokenTooltipGray = require("../../themes/bespoken_tooltip_gray.scss");
const bespokenDrawer = require("../../themes/bespoken_drawer.scss");
const sideBarStyle = require("./SideBarStyle.scss");

const TooltipLi = Tooltip(ReactLi);

interface SideBarProps {
    items: any[];
    goTo: any;
    onLogoClick?: any;
    isTrialExpire?: boolean;
    notFoundPage?: boolean;
    className?: any;
    onBeforeNavigate?: (it: any) => void;
    currentOrganization: SelectedOrganization;
    user: User;
}

export default class SideBar extends React.Component<SideBarProps, any> {
    constructor(props: SideBarProps) {
        super(props);

        this.state = {
            drawerActive: true,
            selectedId: props.items[0] && props.items[0].id,
            showClass: "",
            sideModalComponent: undefined,
        };
    }

    componentDidMount(): void {
        const locationPathString = location && location.pathname && location.pathname.toLocaleLowerCase();
        const current = this.props.items.filter(item => locationPathString.indexOf(item.id) >= 0)[0];
        if (current) {
            this.setState((prevState: any) => ({
                ...prevState,
                selectedId: current.id,
            }));
        }
    }

    componentDidUpdate(prevProps: SideBarProps, prevState: any) {
        const locationPathString = location && location.pathname && location.pathname.toLocaleLowerCase();
        const current = this.props.items.filter(item => locationPathString.indexOf(item.id) >= 0)[0];
        if (current && prevState.selectedId !== current.id) {
            this.setState((prevState: any) => ({
                ...prevState,
                selectedId: current.id,
            }));
        }
    }

    handleToggle = () => {
        this.setState((prevState: any) => ({
            ...prevState,
            drawerActive: !prevState.drawerActive,
        }));
    }

    handleSelectLi = (item: any = {}) => {
        if (!item.isSecondaryOption) {
            this.setState((prevState: any) => ({
                ...prevState,
                selectedId: item.id,
            }));
            this.props.onBeforeNavigate && this.props.onBeforeNavigate(item)
            this.props.goTo && this.props.goTo(`/${item.id}`);
        } else {
            this.addClass();
            item.sideModalComponent && this.setState({ sideModalComponent: item.sideModalComponent });
        }
    }

    handleLogoClick = async () => {
        const subscription = this.props.currentOrganization?.subscription;
        if (!(subscription && (subscription.isTrialTimeExpired || subscription.isSubscriptionExpired))) {
            this.handleSelectLi(this.props.onLogoClick);
        }
    }

    renderOptions = (items: any[]) => {
        const isTrialOrSubscriptionExpired = this.props.currentOrganization?.subscription &&
            (this.props.currentOrganization?.subscription?.isTrialTimeExpired || this.props.currentOrganization?.subscription?.isSubscriptionExpired);
        return <ul data-id="options">
            {
                items.map((item: any, index: any) => {
                    const removeOnClick = item.disabled || (item.id !== "billing" && isTrialOrSubscriptionExpired);
                    return <TooltipLi
                        data-id={`option-${item.id}`}
                        theme={removeOnClick ? bespokenTooltipGray : bespokenTooltip}
                        key={index}
                        tooltipPosition={"right"}
                        tooltip={item.label}
                        onClick={removeOnClick ? undefined : this.handleSelectLi.bind(this, item)}
                        className={(!this.props.notFoundPage && !this.props.isTrialExpire) ? cn({
                            [sideBarStyle.selected]: item.id === this.state.selectedId,
                            [sideBarStyle.disabled]: item.disabled,
                        }) : ""}>
                        <div>{item.icon}</div>
                    </TooltipLi>;
                }
                )
            }
        </ul>;
    }

    addClass = () => {
        this.setState((prevState: any) => ({
            ...prevState,
            showClass: sideBarStyle.show,
        }));
        setTimeout(() => {
            this.setState((prevState: any) => ({
                ...prevState,
                showClass: "",
            }));
        }, 2000);
    }

    getSecondaryOptions = () => this.props.items.filter(item => !!item.isSecondaryOption);

    handleBuyNow = () => {
        const { ibmUser } = this.props.currentOrganization || {};
        if (ibmUser) {
            const url = process.env.IBM_CATALOG_PRODUCT_URL;
            window.open(url, "_blank");
        } else {
            this.props.goTo('/billing')
        }
    }

    render({ className } = this.props) {
        const enableBuyButton = this.props.currentOrganization?.status === userStatus.Trial;
        const { ibmUser } = this.props.currentOrganization || {};
        const secondaryOptions = this.getSecondaryOptions();
        const displayDaysLeft = enableBuyButton && !this.props.currentOrganization?.subscription?.isTrialTimeExpired;
        const daysLeft = this.props.currentOrganization?.subscription?.numberOfDaysLeft;
        const buyButtonProps = ibmUser
            ? { onClick: this.handleBuyNow }
            : { href: 'mailto:sales@bespoken.io?subject=Bespoken%20subscription&body=Please%20contact%20me%20-%20I%20want%20to%20convert%20to%20a%20paid%20plan.' };
        return (
            <Drawer
                className={cn(sideBarStyle.main_container, className)}
                withOverlay={false}
                theme={bespokenDrawer}
                active={this.state.drawerActive}
                onOverlayClick={this.handleToggle} >
                <div className={sideBarStyle.logo_container}>
                    <BespokenLogo onClick={this.handleLogoClick} />
                    <span onClick={this.handleLogoClick}>Bespoken</span>
                </div>
                <div className={sideBarStyle.tab_container} data-id="options-primary">
                    {
                        this.renderOptions(this.props.items.filter(item => !item.isSecondaryOption))
                    }
                </div>
                <div className={sideBarStyle.bottom_container}>
                    <div className={sideBarStyle.payment_container} data-id="trialMessage">
                        {
                            displayDaysLeft ?
                                <div>
                                    <span>{daysLeft}</span>
                                    <span>{daysLeft === 1 ? "Day" : "Days"} left</span>
                                </div> : ""
                        }
                        {enableBuyButton &&
                            <Button data-id="sidebar-buy-now" label={"Buy Now"} {...buyButtonProps} />
                        }
                    </div>
                    {secondaryOptions &&
                        <div className={sideBarStyle.tab_container} data-id="options-secondary">
                            {
                                this.renderOptions(secondaryOptions)
                            }
                            <div className={`${sideBarStyle.sideModal} ${this.state.showClass}`}>{this.state.sideModalComponent}</div>
                        </div>
                    }
                </div>
                {/* {<DebugPanel />} */}
            </Drawer>
        );
    }
}
