import { ContextReduxActions } from "../actions/context";
import {
  LOGIN,
  ONBOARDING_VIA_INVITE,
  REMOVE_AUTH_USER,
  SET_APP_SETTINGS,
  SET_CURRENT_PAGE_PROPS,
  SET_LATEST_ORGANIZATION_ID,
  UPDATE_AUTH_USER,
} from "../constants/context";

export type AuthUser = {
  id: string;
  debounceValid?: boolean;
  email?: string;
  emailVerified?: boolean;
};

export type ChameleonSettings = {
  annWelcomeTour: string;
  annVirtualDeviceTour: string;
  annEndToEndTestingTour: string;
  wtWelcomeTour: string;
  wtVirtualDeviceTour: string;
  wtEndToEndTestingTour: string;
};

export type IntercomSettings = {
  autoWelcomeTour: string;
  autoVirtualDevicesTour: string;
  autoTestPageTour: string;
  manualTestPageTour: string;
};

export type AppSettings = {
  chameleon: ChameleonSettings;
  intercom: IntercomSettings;
};

export type CurrentPageProps = {
  title?: string;
  subTitle?: string;
};

export type ContextState = {
  authUser?: AuthUser;
  latestOrganizationId?: string;
  onboardingViaInvite?: boolean;
  appSettings?: AppSettings;
  currentPageProps?: CurrentPageProps;
};

const INITIAL_STATE: ContextState = {
  latestOrganizationId: "default",
};

export function context(state: ContextState = INITIAL_STATE, action: ContextReduxActions): ContextState {
  // TODO storing context on local storage for now
  //console.log("reducer context", action, state);
  // localStorage.setItem("context", JSON.stringify(state));
  switch (action.type) {

    case LOGIN:
      return { ...state };
    case UPDATE_AUTH_USER:
      return { ...state, authUser: action.contextState.authUser };
    case ONBOARDING_VIA_INVITE:
      return { ...state, onboardingViaInvite: action.onboardingViaInvite };
    case REMOVE_AUTH_USER:
      return {
        ...state,
        authUser: undefined,
        latestOrganizationId: undefined,
      };
    case SET_LATEST_ORGANIZATION_ID:
      return { ...state, ...{ latestOrganizationId: action.latestOrganizationId } };
    case SET_APP_SETTINGS:
      return { ...state, ...{ appSettings: action.appSettings } };
    case SET_CURRENT_PAGE_PROPS:
      return { ...state, ...{ currentPageProps: action.currentPageProps } };
    default:
      return state;
  }
}
