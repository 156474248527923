import { snakeCase, sortBy } from "lodash";
import * as cn from "classnames";
import * as React from "react";
import * as ReactDOM from "react-dom";
import Dropdown from "react-toolbox/lib/dropdown";
import * as uuid from "uuid";
import BespokenCheckIcon from "../../../../assets/bespoken_check_icon.svg";
import { getLocaleDefaultVoice, getLocalesByType, getVoicesByLocaleAndType, localeKeys } from "../../../utils/locales";

// TODO: Requires to refactor to be aligned with other components. (date: 2019-10-10)

// Must be the same as the name of the component in the file
const COMPONENT_NAME = "LocaleDropdown"
const COMPONENT = "component"

const Styles = require("./LocaleDropdown.module.scss");

interface LocaleDropdownState {
    locale: string;
    locales: any[];
}

interface LocaleDropdownProps {
    handleLocaleChange?: (value: string) => void;
    handleVoiceChange?: (value: string) => void;
    locale?: string;
    voice?: string;
    className?: string;
    theme?: any;
    disabled?: boolean;
    sourceType?: string;
    "data-id"?: string;
    appSettings?: any;
}

export class LocaleDropdown extends React.Component<LocaleDropdownProps, LocaleDropdownState> {
    constructor(props: any) {
        super(props);

        this.state = {
            locale: localeKeys.EN_US,
            locales: [{ label: "English (US)", value: "en-US" }],
        };
    }

    componentDidMount() {
        this.setLocales(this.props);
        this.setDataIdAttrs();
    }

    componentDidUpdate() {
        this.setDataIdAttrs();
    }

    async componentWillReceiveProps(nextProps: LocaleDropdownProps) {
        if (this.props.sourceType !== nextProps.sourceType) {
            this.setLocales(nextProps);
        }
    }

    setDataIdAttrs = () => {
        const { "data-id": dataId } = this.props;
        const dropdown = ReactDOM.findDOMNode(this);
        const items = dropdown.querySelectorAll("ul li");
        const locales = this.state.locales;
        items.forEach((item: any) => {
            const label = item.innerHTML;
            const locale = locales && locales.find((_locale: any) => _locale.label === label);
            const value: any = locale && locale.value;
            if (value) {
                item.setAttribute("data-id", `${dataId}-${value}`);
            }

        });
    }

    setLocales = async (props?: LocaleDropdownProps) => {
        const sourceType = (props && props.sourceType) || this.props.sourceType;
        const localesObj = getLocalesByType(props.appSettings, sourceType);
        const locales = sortBy(localesObj.map(locale => ({ value: locale.id, label: locale.label })), ({ label }) => label);

        if (props && locales.findIndex((item: any) => item.value === props.locale) < 0) {
            this.props.handleLocaleChange(localeKeys.EN_US);
            this.setState(prevState => ({
                ...prevState,
                locale: localeKeys.EN_US,
            }));
        }
        this.setState(prevState => ({
            ...prevState,
            locales,
        }));
    }

    getValue = (locale: string, locales: any[]) => {
        const isOnTheLocaleList = locales.some(locales => locales.value === locale);
        return isOnTheLocaleList ? locale : localeKeys.EN_US;
    }

    handleTypeChange = async (value: string) => {
        const locales = this.state.locales;
        const oldValue = this.getValue(this.props.locale, locales);
        if (value !== oldValue) {
            this.props.handleLocaleChange(value);
            const voicesObj = await getVoicesByLocaleAndType(this.props.appSettings, this.props.sourceType, value);
            const voices = voicesObj.map(voice => voice.id);
            if (voices.indexOf(this.props.voice) < 0) {
                const voice = await getLocaleDefaultVoice(this.props.appSettings, value);
                voice !== 0 ? this.props.handleVoiceChange(voice) : (voices && this.props.handleVoiceChange(voices[0]));
            }
        }
        this.setState(prevState => ({
            ...prevState,
            locale: value,
        }));

    }

    dropdownTemplate = (item: any) => (<div>
        <span className={Styles.check}>
            <BespokenCheckIcon />
        </span>
        {item.label}
    </div>)

    render({ disabled, theme, className, locale, voice, handleLocaleChange, handleVoiceChange, sourceType, appSettings, ...rest } = this.props) {
        const locales = this.state.locales;
        const value = this.getValue(locale, locales);

        return (
            <Dropdown
                template={this.dropdownTemplate}
                key={`${value}-${uuid.v4()}`}
                disabled={disabled}
                theme={theme}
                className={cn(COMPONENT, snakeCase(COMPONENT_NAME), Styles.container, className)}
                onChange={this.handleTypeChange}
                source={locales}
                value={value}
                {...rest} />
        );
    }
}
