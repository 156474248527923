import * as React from "react";
import {Button, IconButton} from "react-toolbox/lib/button";
import Input from "react-toolbox/lib/input";
import BespokenAddIcon from "../../../assets/bespoken_add_icon.svg";
import BespokenRemoveIcon from "../../../assets/bespoken_remove_icon.svg";

const homophonesStyle = require("./HomophonesStyle.scss");
const bespokenButton = require("../../themes/bespoken_button.scss");
const bespokenInput = require("../../themes/bespoken_input.scss");

interface HomophonesProps {
    homophones: any[];
    removeHomophone: any;
    addHomophone: any;
    onKeyChange: any;
    onValueChange: any;
    toolTip?: any;
    disabled: boolean;
}

interface HomophonesState {
}

export default class HomophonesComponent extends React.Component<HomophonesProps, HomophonesState> {
    constructor(props: any) {
        super(props);

        this.state = {
            homophones: [{key: "", value: ""}],
        };
    }

    handleTabKey = async (event: any) => {
        if (event.key === "Tab") {
            event.preventDefault();
            event.stopPropagation();
            await this.props.addHomophone();
            const index = this.props.homophones.length - 1;
            const keyInput = document.getElementsByName(`key-${index}`)[0];
            keyInput.focus();
        }
    }

    renderHomophone = (homophone: any, index: any) => {
        return <div key={`parent-${index}`}>
            <Input
                disabled={this.props.disabled}
                name={`key-${index}`}
                className={homophonesStyle.homophone}
                theme={bespokenInput}
                floating={false}
                key={`key-${index}`}
                value={homophone.key}
                onChange={this.props.onKeyChange.bind(this, index)}
                label={"add a key"}
                />
            <Input
                disabled={this.props.disabled}
                name={`value-${index}`}
                className={homophonesStyle.homophone}
                theme={bespokenInput}
                floating={false}
                key={`value-${index}`}
                value={homophone.value}
                onChange={this.props.onValueChange.bind(this, index)}
                label={"add a value"}
                onKeyDown={this.props.homophones.length - 1 === index ? this.handleTabKey : undefined}
                />
            {(this.props.homophones.length > 1)
                ? <IconButton disabled={this.props.disabled} key={`delete-${index}`} icon={<BespokenRemoveIcon />} onClick={this.props.removeHomophone.bind(this, index)} />
                : <IconButton className={homophonesStyle.hide}/>}
        </div>;
    }

    render() {
        return (
            <div className={homophonesStyle.container}>
                <div>
                    <span>Key</span>
                    <span>Values</span>
                    <IconButton style={{width: 48}} className={homophonesStyle.hide}/>
                </div>
                {
                    this.props.homophones.map((homophone: any, index: any) =>
                        this.renderHomophone(homophone, index))
                }
                <a onClick={this.props.disabled ? undefined : this.props.addHomophone}>Add homophone</a>
            </div>
        );
    }
}
