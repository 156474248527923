import * as React from "react";
import * as ReactDOM from "react-dom";

export interface ExecutionSummaryCardProps {
}

export interface ExecutionSummaryCardState {
}

export class ExecutionSummaryCard extends React.Component<ExecutionSummaryCardProps, ExecutionSummaryCardState> {

    state: ExecutionSummaryCardState;
    props: ExecutionSummaryCardProps;

    constructor(props: ExecutionSummaryCardProps) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div>{"ExecutionSummaryCard"}</div>
        );
    }

}
