// import source from "./source";

export const reportSkillAlertToSales = async (userEmail: string, planName: string,
    totalSkillsCounted: number, allowedSkills: number) => {
    // try {
    //     const result = await source.sendSkillNotificationEmailToSales(userEmail, planName, totalSkillsCounted, allowedSkills);
    //     return result;

    // } catch (error) {
    //     return error;
    // }
};

export const sendNotificationToSalesForEnterprisePlan = async (userEmail: string, userMessage: string) => {
    // try {
    //     const result = await source.sendNotificationToSalesForEnterprisePlan(userEmail, userMessage);
    //     return result;
    // } catch (error) {
    //     console.log("error sending log alert email to sales", error);
    //     return error;
    // }
};
