import * as React from "react";
import { connect } from "react-redux";
import BespokenLogo from "../../../assets/bespoken_logoweb.svg";
import BespokenMountains from "../../../assets/bespoken_mountains.svg";
import BinocularsIcon from "../../../assets/binoculars.svg";
import * as backgroundLeft from "../../../assets/images/bespoken_auth_no_text.jpg";
import {
    AmazonFlowFlag, login, loginWithIbm, SuccessCallback
} from "../../actions/session";
import { LeftPanel, Loader, RightPanel } from "../../components";
import AuthForm from "../../components/AuthForm/AuthForm";
import User from "../../models/user";
import { State } from "../../reducers";
import auth from "../../services/auth";
import { BespokenModal } from "../../components/Modal/BespokenModal";
import { Button, Input } from "../../components";
import BespokenLockIcon from "../../../assets/bespoken_lock_icon.svg";

const style = require("./loginStyles.scss");

interface IbmLoginProps {
    login: (email: string, password: string, redirectStrat?: SuccessCallback) => Promise<User>;
    loginWithIbm: (accessToken: string, redirectStrat?: SuccessCallback) => Promise<User>;
}

interface IbmLoginState {
    error?: any;
    loading?: boolean;
    showLoader?: boolean;
}

const mapStateToProps = (state: State.All) => ({});

const mapDispatchToProps = (dispatch: any) =>
    ({
        login: function (email: string, password: string, redirectStrat?: SuccessCallback): Promise<User> {
            return dispatch(login(email, password, redirectStrat));
        }
    });

export class IbmLogin extends React.Component<IbmLoginProps, IbmLoginState> {
    constructor(props: IbmLoginProps) {
        super(props);
        this.state = { error: "", showLoader: false};
    }

    async componentDidMount () {}

    handleFormLogin= async () => {
      const instanceId = new URL(location.href).searchParams.get('instance_id')
      const authorizationEndpoint = "https://identity-2.us-south.iam.cloud.ibm.com/identity/authorize";
      const clientId = "automated-testing-and-monitoring-for-voice-and-chat";
      const dashboarUrl = "https://dashboard.bespoken.io/ibm-redirect";
      const redirectUrl = `${authorizationEndpoint}?client_id=${encodeURIComponent(clientId)}&redirect_uri=${dashboarUrl}&response-type=code&state=${encodeURIComponent(instanceId)}`;
      window.open(redirectUrl, '_blank', 'noreferrer');
    }

    render() {
        const allProps = this.props as any;
        const location = allProps.location;
        console.log('Ibm Login');
        return (
          <div>
            <LeftPanel className={style.leftPanel} fullHeight={true} image={backgroundLeft}>
                <div data-id="details">
                    <BinocularsIcon data-id="icon" />
                    <div data-id="message1">Welcome to your Dashboard</div>
                    <div data-id="message2">from here, you can</div>
                    <div data-id="message3">see everything</div>
                </div>
                <div data-id="companyName">Bespoken</div>
            </LeftPanel>
            <RightPanel className={style.rightPanel} fullHeight>
              <section>
                <BespokenLogo data-id="icon" />
                <div className={style.main_form}>
                  <div data-id="actions">
                    <Button className={`${style.regular_button} login_email_id`}
                        label="Log in"
                        accent
                        icon={<BespokenLockIcon />}
                        onClick={this.handleFormLogin} />
                </div>
                </div>
              </section>
              <BespokenMountains data-id="mountains" />
            </RightPanel>
            {this.state.showLoader && <Loader />}
          </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IbmLogin);
