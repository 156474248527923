import { castArray, chain, clamp, defaultTo, get, includes, isBoolean, isEmpty, isFunction, isNumber, isObject, isString, join, keys, merge, negate, omit, reduce, toNumber, toString, trim } from "lodash";
import * as React from "react";
import { Button, IconButton } from "react-toolbox/lib/button";
import Checkbox from "react-toolbox/lib/checkbox";
import Drawer from "react-toolbox/lib/drawer";
import Input from "react-toolbox/lib/input";
import Tooltip from "react-toolbox/lib/tooltip";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { Source } from "../../../models/source";
import ReactDiv from "../../ReactDiv";
import HomophonesComponent from "../../validation/HomophonesComponent";
import RepeatOnPhraseComponent from "../../validation/RepeatOnPhraseListComponent";

import * as cn from "classnames";
import { MultipleTextInput } from "../../lunacy";
import { he, vi } from "date-fns/locale";

const TooltipDiv = Tooltip(ReactDiv);

const Styles = require("./source-settings.scss");
const validationStyle = require("../../validation/ValidationParentComponentStyle.scss");
const tooltipTheme = require("../../../themes/tooltip.scss");
const bespokenInput = require("../../../themes/bespoken_input.scss");
const bespokenButton = require("../../../themes/bespoken_button.scss");
const circleCheckBox = require("../../../components/Checkbox/checkboxTheme.scss");
const tooltipThemeWrap = require("../../../themes/tooltip_wrap.scss");
const buttonStyle = require("../../../themes/amazon_pane.scss");

type SettingControlTypes = 'text' | 'boolean' | 'number' | 'multiple-text' | 'homophones' | 'speech-to-text-model' | 'password' | 'masked' | 'inputSelector' | 'replySelector' | 'listOfScripts' | 'viewportSize';

interface SourceSettingsProps {
    source: Source;
    showSettings: boolean;
    handleShowSettings: any;
    updateConfig: (property: string, value: any) => any;
    updateConfigNested: (property: string, value: string) => void;
    getConfig: () => any
    saveSource: () => any;
    hasUnsavedChanges: boolean;
}

interface SourceSettingsState {
    additionalSettings: any;
    additionalSettingsPrevious: any;
    additionalSettingsError: string;
    homophones: any[];
    maxAsyncE2EResponseWaitTime: any;
    stopTestOnFailure: any;
    lenientMode: any;
    recordCall: any;
    repeatOnPhrase: string[];
    platform: string;
    speechModel: string;

    initialize: string;
    openSelectors: string;

    inputSelector: string;
    inputAfterEach: string;
    inputIFrameSelector: string;

    replySelector: string;
    replyTimeout: string;
    replyWaitTime: string;
    replyIFrameSelector: string;

    timeoutOnLoad: number;
    timeoutOnInitialize: number;
    pauseBeforeUtterance: number;
    sttThreshold: number;
    defaultNavigationTimeout: number;

    serviceUrl: string;
    apiKey: string;
    assistantId: string;

    versionId: string;

    accessKeyId: string;
    secretAccessKey: string;
    botId: string;
    botAliasId: string;
    botName: string;
    region: string;

    endSpeechTimeout: number;
    isObfuscate: boolean;

    extraParametersHeadless: boolean;
    extraParametersBypassCsp: boolean;

    extraParametersScripts: string[];

    extraParametersViewPort: { width: number, height: number };

    virtualDeviceConfigFuzzyMatching: number;
    targetEmail: string;
    sourceEmail: string;
    subjectEmail: string;
    contentOnly: boolean;
}

const SPEECH_MODELS = [
    { label: 'Command and search', value: 'command_and_search' },
    { label: 'Default', value: 'default' },
    { label: 'Phone call', value: 'phone_call' },
    { label: 'Medical dictation', value: 'medical_dictation' },
    { label: 'Medical conversation', value: 'medical_conversation' },
    { label: 'Video', value: 'video' },
];

const toBoolean = (val: any) => {
    if (val === undefined) {
        return undefined
    }
    return val === 'true' || isBoolean(val) && val
}

// List of read-only properties, and nested paths are supported by using a dot
// These components can't be overwritten using the advanced setting json editor
const CONFIG_READ_ONLY_PATHS = [
    "locale",
    "voiceId",
    "platform",
    "type",
    "virtualDeviceToken",
    "maxAsyncE2EResponseWaitTime",
    "homophones",
    "stopTestOnFailure",
    "projectId",
    "lenientMode",
    "client",
    "phoneNumber",
    "trace",
    "asyncMode",

    "virtualDeviceConfig.speechModel",
    "virtualDeviceConfig.recordCall",
    "virtualDeviceConfig.repeatOnPhrase",
    "virtualDeviceConfig.pauseBeforeUtterance",
    "virtualDeviceConfig.sttThreshold",
    "virtualDeviceConfig.apiKey",
    "virtualDeviceConfig.assistantId",
    "virtualDeviceConfig.endSpeechTimeout",
    "virtualDeviceConfig.serviceUrl",
    "virtualDeviceConfig.fuzzyMatching",
    "virtualDeviceConfig.targetEmail",
    "virtualDeviceConfig.sourceEmail",
    "virtualDeviceConfig.subjectEmail",
    "virtualDeviceConfig.contentOnly",
    "virtualDeviceConfig.versionId",
    "virtualDeviceConfig.accessKeyId",
    "virtualDeviceConfig.secretAccessKey",
    "virtualDeviceConfig.botId",
    "virtualDeviceConfig.botAliasId",
    "virtualDeviceConfig.botName",
    "virtualDeviceConfig.region",
    "virtualDeviceConfig.headless",
    "virtualDeviceConfig.replyTimeout",
    "virtualDeviceConfig.replyWaitTime",
    "virtualDeviceConfig.timeoutOnInitialize",
    "virtualDeviceConfig.timeoutOnLoad",
    "virtualDeviceConfig.initialize",
    "virtualDeviceConfig.inputSelector",
    "virtualDeviceConfig.replySelector",
    "virtualDeviceConfig.openSelectors",
    "virtualDeviceConfig.bypassCSP",
    "virtualDeviceConfig.scripts",
    "virtualDeviceConfig.viewPort",
    "virtualDeviceConfig.url",
    "extraParameters.headless",
    "extraParameters.initialize",
    "extraParameters.inputSelector",
    "extraParameters.replySelector",
    "extraParameters.timeoutOnLoad",
    "extraParameters.timeoutOnInitialize",
    "extraParameters.openSelectors",
    "extraParameters.bypassCSP",
    "extraParameters.scripts",
    "extraParameters.viewPort",
    "extraParameters.url",
    "extraParameters.headless",
];

export default class SourceSettings extends React.Component<SourceSettingsProps, SourceSettingsState> {
    constructor(props: SourceSettingsProps) {
        super(props);
        this.state = {
            additionalSettings: '',
            additionalSettingsPrevious: '',
            additionalSettingsError: '',
            homophones: [],
            maxAsyncE2EResponseWaitTime: 0,
            stopTestOnFailure: false,
            lenientMode: false,
            repeatOnPhrase: [],
            platform: undefined,
            speechModel: '',
            recordCall: false,

            initialize: undefined,
            openSelectors: undefined,

            inputSelector: undefined,
            inputAfterEach: undefined,
            inputIFrameSelector: undefined,

            replySelector: undefined,
            replyTimeout: undefined,
            replyWaitTime: undefined,
            replyIFrameSelector: undefined,

            timeoutOnLoad: undefined,
            timeoutOnInitialize: undefined,
            pauseBeforeUtterance: undefined,
            sttThreshold: undefined,
            defaultNavigationTimeout: undefined,

            serviceUrl: undefined,
            apiKey: undefined,
            assistantId: undefined,

            versionId: undefined,

            accessKeyId: undefined,
            secretAccessKey: undefined,
            botId: undefined,
            botAliasId: undefined,
            botName: undefined,
            region: 'us-east-1',

            endSpeechTimeout: undefined,
            isObfuscate: true,

            extraParametersHeadless: undefined,
            extraParametersBypassCsp: undefined,

            extraParametersScripts: [],

            extraParametersViewPort: { width: undefined, height: undefined },

            virtualDeviceConfigFuzzyMatching: undefined,
            targetEmail: undefined,
            sourceEmail: undefined,
            subjectEmail: undefined,
            contentOnly: false,
        }
    }

    componentDidMount() {
        this.initializeState();
    }

    componentDidUpdate(prevProps: SourceSettingsProps) {
        if (prevProps?.source?.name !== this.props?.source?.name) {
            this.initializeState();
        }
    }

    initializeState() {
        const {
            locale,
            voiceId,
            platform,
            type,
            virtualDeviceToken,
            maxAsyncE2EResponseWaitTime: currentMaxAsyncE2EResponseWaitTime,
            homophones: currentHomophones,
            stopTestOnFailure: currentStopTestOnFailure,
            lenientMode: currentLenientMode,
            repeatOnPhrase: currentRepeatOnPhrase,
            virtualDeviceConfig,
            extraParameters,
            ...rest
        } = this.props?.source?.config as any || {};

        const {
            speechModel,
            recordCall,
            pauseBeforeUtterance: currentPauseBeforeUtterance,
            sttThreshold: currentSttThreshold,
            serviceUrl,
            apiKey,
            assistantId,
            versionId,
            endSpeechTimeout,
            replyTimeout: currentReplyTimeout,
            replyWaitTime: currentReplyWaitTime,
            targetEmail,
            sourceEmail,
            subjectEmail,
            contentOnly,
            fuzzyMatching: virtualDeviceConfigFuzzyMatching,
            accessKeyId,
            secretAccessKey,
            botId,
            botAliasId,
            botName,
            region
        } = virtualDeviceConfig || {};

        // If platform is webchat, make sure to get the right values
        // virtualDeviceConfig parameters take precedence over extraParameters

        const initialize = virtualDeviceConfig?.initialize || extraParameters?.initialize;
        const extraParametersHeadless = virtualDeviceConfig?.headless || extraParameters?.headless;
        const currentInputSelector = virtualDeviceConfig?.inputSelector || extraParameters?.inputSelector;
        const currentReplySelector = virtualDeviceConfig?.replySelector || extraParameters?.replySelector;
        const currentOpenSelectors = virtualDeviceConfig?.openSelectors || extraParameters?.openSelectors;
        const timeoutOnLoad = virtualDeviceConfig?.timeoutOnLoad || extraParameters?.timeoutOnLoad;
        const timeoutOnInitialize = virtualDeviceConfig?.timeoutOnInitialize || extraParameters?.timeoutOnInitialize;
        const currentDefaultNavigationTimeout = virtualDeviceConfig?.defaultNavigationTimeout || extraParameters?.defaultNavigationTimeout;
        const extraParametersBypassCsp = virtualDeviceConfig?.bypassCSP || extraParameters?.bypassCSP;
        const extraParametersScripts = virtualDeviceConfig?.scripts || extraParameters?.scripts;
        const viewPortWidth = virtualDeviceConfig?.viewPort?.width || extraParameters?.viewPort?.width;
        const viewPortHeight = virtualDeviceConfig?.viewPort?.height || extraParameters?.viewPort?.height;

        const homophones = chain(currentHomophones)
            .mapValues((value, key) => ({ key, value: join(castArray(value)) }))
            .values()
            .defaults([{ key: '', value: '' }])
            .value()
        const maxAsyncE2EResponseWaitTime = defaultTo(currentMaxAsyncE2EResponseWaitTime,
            ["phone", "twilio"].indexOf(platform) > -1 ? 60000
                : ["webchat"].indexOf(platform) > -1 ? 200000
                    : 15000)
        const additionalSettings = JSON.stringify(
            chain({ ...rest, virtualDeviceConfig, extraParameters })
                .omit(CONFIG_READ_ONLY_PATHS)
                .omitBy((v, k) => (isObject(v) && isEmpty(v) && k === 'virtualDeviceConfig'))
                .value()
            , undefined, 2)
        const stopTestOnFailure = toBoolean(currentStopTestOnFailure)
        const additionalSettingsError = ''
        const lenientMode = toBoolean(currentLenientMode)
        const repeatOnPhrase = currentRepeatOnPhrase || []
        const openSelectors = chain(currentOpenSelectors).castArray().first().value()
        const pauseBeforeUtterance = currentPauseBeforeUtterance || 0
        const sttThreshold = currentSttThreshold || 0.8
        const defaultNavigationTimeout = currentDefaultNavigationTimeout

        const inputSelector = isString(currentInputSelector) ? currentInputSelector : currentInputSelector?.selector
        const inputAfterEach = currentInputSelector?.afterEach
        const inputIFrameSelector = currentInputSelector?.iframeSelector

        const replySelector = isString(currentReplySelector) ? currentReplySelector : currentReplySelector?.selector
        // extraParameters?.timeoutOnReply is used as a fallback for replyTimeout while all attribute is moved to nested replySelector
        const replyTimeout = platform === "webchat" ? isNumber(currentReplySelector?.timeout) ? currentReplySelector?.timeout : extraParameters?.timeoutOnReply : currentReplyTimeout || 30
        const replyWaitTime = currentReplyWaitTime || 30
        const replyIFrameSelector = currentReplySelector?.iframeSelector

        const extraParametersViewPort = isNumber(viewPortWidth) || isNumber(viewPortHeight) ? { width: viewPortWidth, height: viewPortHeight } : undefined

        this.setState({
            additionalSettings,
            additionalSettingsPrevious: additionalSettings,
            homophones,
            maxAsyncE2EResponseWaitTime,
            stopTestOnFailure,
            additionalSettingsError,
            lenientMode,
            repeatOnPhrase,
            platform,
            speechModel,
            recordCall,

            initialize,

            inputSelector,
            inputAfterEach,
            inputIFrameSelector,

            replySelector,
            replyTimeout,
            replyWaitTime,
            replyIFrameSelector,

            openSelectors,
            timeoutOnLoad,
            timeoutOnInitialize,

            pauseBeforeUtterance,
            sttThreshold,
            defaultNavigationTimeout,

            serviceUrl,
            apiKey,
            assistantId,

            versionId,
            accessKeyId,
            secretAccessKey,
            botId,
            botAliasId,
            botName,
            region,

            endSpeechTimeout,
            extraParametersHeadless,
            extraParametersBypassCsp,
            extraParametersScripts,

            extraParametersViewPort,
            targetEmail,
            sourceEmail,
            subjectEmail,
            contentOnly,
            virtualDeviceConfigFuzzyMatching
        });
    }

    removeHomophone = (index: any) => {
        let homophones = this.state.homophones;
        homophones.splice(index, 1);
        this.setState(prevState => ({
            ...prevState,
            homophones,
        }));
    }

    addHomophone = () => {
        let homophones = this.state.homophones;
        homophones.push({ key: "", value: "" });
        this.setState(prevState => ({
            ...prevState,
            homophones,
        }));
    }

    setRepeatOnPhrase = (repeatOnPhrase: string[]) => {
        this.setState(prevState => ({
            ...prevState,
            repeatOnPhrase,
        }));
    }

    onKeyChange = (index: any, value: string) => {
        let homophones = this.state.homophones;
        homophones[index].key = value;
        this.setState(prevState => ({
            ...prevState,
            homophones,
        }));
    }

    onValueChange = (index: any, value: string) => {
        let homophones = this.state.homophones;
        homophones[index].value = value;
        this.setState(prevState => ({
            ...prevState,
            homophones,
        }));
    }

    handleChangeAdditionalSettings = (value: string) => {
        this.setState(prevState => ({
            ...prevState,
            additionalSettings: value,
        }));
    }

    onCloseHandle = () => {
        this.props.handleShowSettings();
        this.initializeState();
    }

    onSaveHandle = () => {
        const flattenKeysTraverse: (obj: any, path: any) => any = (obj, path = []) => (!isObject(obj) || keys(obj).length === 0)
            ? { [path.join('.')]: obj }
            : reduce(obj, (cum: any, next: any, key: any) => merge(cum, flattenKeysTraverse(next, [...path, key])), {});

        const { platform } = this.state

        /** **********
         * Validate and update JSON from additional settings
         ** **********/

        let updatedAdditionalSettings: any;
        let additionalSettingsPrevious: any;
        try {
            updatedAdditionalSettings = JSON.parse(this.state.additionalSettings)
            additionalSettingsPrevious = JSON.parse(this.state.additionalSettingsPrevious)
        } catch (error) {
            this.setState(prevState => ({
                ...prevState,
                additionalSettingsError: `Invalid (${error.message})`,
            }));
            return;
        }
        this.setState(prevState => ({
            ...prevState,
            additionalSettingsError: '',
        }));

        // Get object omitting all read only properties
        const writeableAdditionalSettings = omit(updatedAdditionalSettings, CONFIG_READ_ONLY_PATHS)

        // Flatten keys and create an array with all properties to be updated omitting read-only ones
        const entriesToBeUpdated = chain(flattenKeysTraverse(writeableAdditionalSettings, []))
            .keys()
            .map(key => ({ key, value: get(updatedAdditionalSettings, key) }))
            .value()

        // remove old values before update
        const resetEntriesObject = chain(flattenKeysTraverse(additionalSettingsPrevious, []))
            .keys()
            .difference(chain(flattenKeysTraverse(writeableAdditionalSettings, [])).keys().value())
            .uniq()
            .difference(CONFIG_READ_ONLY_PATHS)
            .map(key => ({ key, value: undefined }))
            .value()

        // remove properties from additionalSettingsPrevious - updatedAdditionalSettings
        resetEntriesObject.forEach(entry => this.props.updateConfig(entry.key, entry.value))

        // Update non-readonly properties from additionalSettings
        entriesToBeUpdated.forEach(entry => this.props.updateConfig(entry.key, entry.value))


        this.setState({
            additionalSettingsPrevious: JSON.stringify(JSON.parse(this.state.additionalSettings), undefined, 2),
            additionalSettings: JSON.stringify(JSON.parse(this.state.additionalSettings), undefined, 2)
        });

        // Get homophones object
        const updatedHomophones = this.state.homophones.reduce((acc: any, item: any) => {
            if (item && item.value && item.key) {
                acc[item.key] = item.value.split(',');
            }
            return acc;
        }, {});

        /** **********
         * Update from additional settings UI
         ** **********/

        // Update direct children(force set to undefined to reset before updating arrays/objects, it prevents merge with null values)
        this.props.updateConfig('maxAsyncE2EResponseWaitTime', this.state.maxAsyncE2EResponseWaitTime);
        this.props.updateConfig('virtualDeviceConfig.fuzzyMatching', undefined);
        if (toNumber(this.state?.virtualDeviceConfigFuzzyMatching) > 0) {
            this.props.updateConfig('virtualDeviceConfig.fuzzyMatching', this.state.virtualDeviceConfigFuzzyMatching);
        }
        this.props.updateConfig('lenientMode', toBoolean(this.state.lenientMode));
        this.props.updateConfig('stopTestOnFailure', toBoolean(this.state.stopTestOnFailure));

        this.props.updateConfig('virtualDeviceConfig.recordCall', toBoolean(this.state.recordCall));

        this.props.updateConfig('virtualDeviceConfig.repeatOnPhrase', undefined);
        if (!isEmpty(this.state.repeatOnPhrase)) {
            this.props.updateConfig('virtualDeviceConfig.repeatOnPhrase', this.state.repeatOnPhrase);
        }

        this.props.updateConfig('virtualDeviceConfig.speechModel', this.state.speechModel);

        this.props.updateConfig('homophones', undefined);
        this.props.updateConfig('homophones', updatedHomophones);

        if (platform === 'webchat') {
            this.props.updateConfig('virtualDeviceConfig.defaultNavigationTimeout', isEmpty(toString(this.state.defaultNavigationTimeout)) ? undefined : toNumber(this.state.defaultNavigationTimeout));
            this.props.updateConfig('virtualDeviceConfig.initialize', this.state.initialize);
            this.props.updateConfig('virtualDeviceConfig.headless', toBoolean(this.state.extraParametersHeadless));
            this.props.updateConfig('virtualDeviceConfig.bypassCSP', toBoolean(this.state.extraParametersBypassCsp));

            this.props.updateConfig('virtualDeviceConfig.inputSelector.selector', this.state.inputSelector);
            this.props.updateConfig('virtualDeviceConfig.inputSelector.afterEach', this.state.inputAfterEach);
            this.props.updateConfig('virtualDeviceConfig.inputSelector.iframeSelector', this.state.inputIFrameSelector);

            this.props.updateConfig('virtualDeviceConfig.replySelector.selector', this.state.replySelector);

            this.props.updateConfig('virtualDeviceConfig.replySelector.timeout', isEmpty(toString(this.state.replyTimeout)) ? undefined : toNumber(this.state.replyTimeout));

            // remove an used setting property, it will be removed in the future
            this.props.updateConfig('virtualDeviceConfig.timeoutOnReply', undefined);

            this.props.updateConfig('virtualDeviceConfig.replySelector.iframeSelector', this.state.replyIFrameSelector);

            this.props.updateConfig('virtualDeviceConfig.openSelectors', undefined);
            this.props.updateConfig('virtualDeviceConfig.openSelectors', chain(this.state.openSelectors).castArray().map(trim).filter(negate(isEmpty)).value());
            this.props.updateConfig('virtualDeviceConfig.timeoutOnLoad', toNumber(this.state.timeoutOnLoad));
            this.props.updateConfig('virtualDeviceConfig.timeoutOnInitialize', toNumber(this.state.timeoutOnInitialize));

            this.props.updateConfig('virtualDeviceConfig.scripts', undefined);
            const scripts = chain(this.state.extraParametersScripts).filter(e => !isEmpty(trim(e))).value()
            if (!isEmpty(scripts)) {
                this.props.updateConfig('virtualDeviceConfig.scripts', scripts);
            }

            this.props.updateConfig('virtualDeviceConfig.viewPort', undefined);
            if (!isEmpty(this.state.extraParametersViewPort)) {
                this.props.updateConfig('virtualDeviceConfig.viewPort', this.state.extraParametersViewPort);
            }

        } else if (platform === 'watson') {
            this.props.updateConfig('virtualDeviceConfig.serviceUrl', this.state.serviceUrl);
            this.props.updateConfig('virtualDeviceConfig.apiKey', this.state.apiKey);
            this.props.updateConfig('virtualDeviceConfig.assistantId', this.state.assistantId);
        } else if (platform === 'voiceflow') {
            this.props.updateConfig('virtualDeviceConfig.apiKey', this.state.apiKey);
            this.props.updateConfig('virtualDeviceConfig.versionId', this.state.versionId);
        } else if (platform === 'lex') {
            this.props.updateConfig('virtualDeviceConfig.accessKeyId', this.state.accessKeyId);
            this.props.updateConfig('virtualDeviceConfig.secretAccessKey', this.state.secretAccessKey);
            this.props.updateConfig('virtualDeviceConfig.botId', this.state.botId);
            this.props.updateConfig('virtualDeviceConfig.botAliasId', this.state.botAliasId);
            this.props.updateConfig('virtualDeviceConfig.botName', this.state.botName);
            this.props.updateConfig('virtualDeviceConfig.region', this.state.region);
        } else if (platform === 'phone') {
            this.props.updateConfig('virtualDeviceConfig.pauseBeforeUtterance', isEmpty(toString(this.state.pauseBeforeUtterance)) ? undefined : toNumber(this.state.pauseBeforeUtterance));
            this.props.updateConfig('virtualDeviceConfig.sttThreshold', isEmpty(toString(this.state.sttThreshold)) ? undefined : toNumber(this.state.sttThreshold));
            this.props.updateConfig('virtualDeviceConfig.endSpeechTimeout', undefined);
            if (toNumber(this.state?.endSpeechTimeout) > 0) {
                this.props.updateConfig('virtualDeviceConfig.endSpeechTimeout', toNumber(this.state.endSpeechTimeout));
            }
        }
        else if (platform === 'email') {
            this.props.updateConfig('virtualDeviceConfig.sourceEmail', this.state.sourceEmail);
            this.props.updateConfig('virtualDeviceConfig.subjectEmail', this.state.subjectEmail);
            this.props.updateConfig('virtualDeviceConfig.contentOnly', toBoolean(this.state.contentOnly));
        }
        else if (platform === 'sms' || platform === 'whatsapp') {
            this.props.updateConfig('virtualDeviceConfig.replyWaitTime', this.state.replyWaitTime);
        }
        this.props.updateConfig('maxAsyncE2EResponseWaitTime', toNumber(this.state.maxAsyncE2EResponseWaitTime))
            .then(() => {
                if (this.props.hasUnsavedChanges) {
                    this.props.saveSource()
                }
            });

        this.props.handleShowSettings();
    }

    renderSettingProperty(params: {
        propertyName: keyof SourceSettingsState,
        title: string,
        tooltip: string,
        controlType: SettingControlTypes,
        additionalProps?: { maxLength?: number, max?: number, min?: number, step?: number }
    }) {
        const buildKey = (control: string, propertyName: string) => `control-${propertyName}-${control}`
        const {
            propertyName,
            tooltip,
            title,
            controlType,
            additionalProps: { maxLength, min, max, step }
        } = merge({ additionalProps: {} }, params)

        const renderControl = (controlType: SettingControlTypes) => {
            switch (controlType) {
                case "text":
                    return <Input
                        key={buildKey('input', propertyName)}
                        type={controlType}
                        className={Styles.input_text}
                        floating={false}
                        theme={bespokenInput}

                        value={this.state[propertyName]}
                        onChange={(val: any) => this.setState({ [propertyName]: val } as any)}

                        {...{ 'autoComplete': 'new-password' }}
                    />
                case "password":
                    return <Input
                        key={buildKey('input', propertyName)}
                        type={controlType}
                        className={Styles.input_text}
                        floating={false}
                        theme={bespokenInput}

                        value={this.state[propertyName]}
                        onChange={(val: any) => this.setState({ [propertyName]: val } as any)}

                        {...{ 'autoComplete': 'new-password' }}
                    />
                case "boolean":
                    return <Checkbox
                        key={buildKey('checkbox', propertyName)}
                        theme={circleCheckBox}
                        className={circleCheckBox.circle}

                        checked={toBoolean(this.state[propertyName])}
                        onChange={(val: any) => this.setState({ [propertyName]: toString(val) } as any)}
                    />
                case "number":
                    return <Input
                        key={buildKey('input', propertyName)}
                        maxLength={maxLength || 6}
                        type={controlType}
                        className={Styles.input_number}
                        floating={false}
                        theme={bespokenInput}

                        value={this.state[propertyName]}
                        onChange={(val: any) => {
                            if (val === '') {
                                this.setState({ [propertyName]: undefined } as any)
                                return
                            }

                            const newValue = clamp(toNumber(val), min || -Infinity, max || Infinity)
                            this.setState({ [propertyName]: newValue } as any)
                        }}
                        {...{ min, max, step }}
                    />
                case "speech-to-text-model":
                    return <Dropdown
                        key={buildKey('dropdown', propertyName)}
                        source={SPEECH_MODELS}
                        className={Styles.dropdown}

                        value={this.state[propertyName] || 'default'}
                        onChange={(val: any) => this.setState({ [propertyName]: val } as any)}
                    />
                case "multiple-text":
                    return <div className={cn(Styles.expand, Styles.multiple_text)}>
                        <span key={buildKey('title', propertyName)} className={Styles.property_title}>
                            "Repeat on" phrases
                        </span>
                        {renderTooltip(tooltip, propertyName)}
                        <RepeatOnPhraseComponent
                            key={buildKey('multiple-text', propertyName)}
                            onChange={items => this.setRepeatOnPhrase(items)}
                            values={this.state.repeatOnPhrase}
                        />
                    </div>
                case "homophones":
                    return <div className={cn(Styles.expand, Styles.homophones)}>
                        <span key={buildKey('title', propertyName)} className={Styles.property_title}>
                            Homophones
                        </span>
                        {renderTooltip(tooltip, propertyName)}
                        <HomophonesComponent
                            key={buildKey('homophones', propertyName)}
                            disabled={false}

                            homophones={this.state.homophones}
                            addHomophone={this.addHomophone}
                            removeHomophone={this.removeHomophone}
                            onKeyChange={this.onKeyChange}
                            onValueChange={this.onValueChange}
                        />
                    </div>
                case "masked":
                    return <Input
                        key={buildKey('input', propertyName)}
                        type={controlType}
                        className={`${Styles.input_text} ${this.state.isObfuscate ? Styles.masked : ''}`}
                        floating={false}
                        theme={bespokenInput}
                        onMouseEnter={() => this.setState({ isObfuscate: false })}
                        onMouseLeave={() => this.setState({ isObfuscate: true })}
                        value={this.state[propertyName]}
                        onChange={(val: any) => this.setState({ [propertyName]: val } as any)}
                        {...{ 'autoComplete': 'new-password' }}
                    />
                case "inputSelector":
                    return <div className={cn(Styles.expand, Styles.input_selector)}>
                        <span key={buildKey('title', propertyName)} className={Styles.property_title}>
                            Text input settings
                        </span>
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>

                        <span className={Styles.sub_property_title}>Selector</span>
                        <Input
                            key={buildKey('input', `${propertyName}.selector`)}
                            type={"text"}
                            className={Styles.input_text}
                            floating={false}
                            theme={bespokenInput}

                            value={this.state.inputSelector}
                            onChange={(inputSelector: string) => this.setState({ inputSelector } as any)}

                            {...{ 'autoComplete': 'new-password' }}
                        />
                        {renderTooltip('The CSS selector for the text input where messages should be entered.', `${propertyName}.selector`)}

                        <span className={Styles.sub_property_title}>Script after each command</span>
                        <Input
                            key={buildKey('input', `${propertyName}.afterEach`)}
                            type={"text"}
                            className={Styles.input_text}
                            floating={false}
                            theme={bespokenInput}

                            value={this.state.inputAfterEach}
                            onChange={(inputAfterEach: string) => this.setState({ inputAfterEach } as any)}

                            {...{ 'autoComplete': 'new-password' }}
                        />
                        {renderTooltip('(optional) Script to run after each input has been entered.', `${propertyName}.afterEach`)}

                        <span className={Styles.sub_property_title}>IFrame selector</span>
                        <Input
                            key={buildKey('input', `${propertyName}.iframeSelector`)}
                            type={"text"}
                            className={Styles.input_text}
                            floating={false}
                            theme={bespokenInput}

                            value={this.state.inputIFrameSelector}
                            onChange={(inputIFrameSelector: string) => this.setState({ inputIFrameSelector } as any)}

                            {...{ 'autoComplete': 'new-password' }}
                        />
                        {renderTooltip('(optional) IFrame selector, if the chatbot lives within one.', `${propertyName}.iframeSelector`)}
                    </div>
                case "replySelector":
                    return <div className={cn(Styles.expand, Styles.reply_selector)}>
                        <span key={buildKey('title', propertyName)} className={Styles.property_title}>
                            Reply settings
                        </span>
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>

                        <span className={Styles.sub_property_title}>Selector</span>
                        <Input
                            key={buildKey('input', `${propertyName}.selector`)}
                            type={"text"}
                            className={Styles.input_text}
                            floating={false}
                            theme={bespokenInput}

                            value={this.state.replySelector}
                            onChange={(replySelector: string) => this.setState({ replySelector } as any)}

                            {...{ 'autoComplete': 'new-password' }}
                        />
                        {renderTooltip('The CSS selector used to look for the reply within the webchat HTML.', `${propertyName}.selector`)}
                        <span className={Styles.sub_property_title}>Timeout</span>
                        <Input
                            key={buildKey('input', `${propertyName}.timeout`)}
                            maxLength={maxLength || 6}
                            type={"number"}
                            className={Styles.input_number}
                            floating={false}
                            theme={bespokenInput}

                            value={this.state.replyTimeout}
                            onChange={(replyTimeout: string) => this.setState({ replyTimeout } as any)}
                        />
                        {renderTooltip('Miliseconds to wait for a reply.', `${propertyName}.timeout`)}

                        <span className={Styles.sub_property_title}>IFrame selector</span>
                        <Input
                            key={buildKey('input', `${propertyName}.iframeSelector`)}
                            type={"text"}
                            className={Styles.input_text}
                            floating={false}
                            theme={bespokenInput}

                            value={this.state.replyIFrameSelector}
                            onChange={(replyIFrameSelector: string) => this.setState({ replyIFrameSelector } as any)}

                            {...{ 'autoComplete': 'new-password' }}
                        />
                        {renderTooltip('(optional) IFrame selector, if the chatbot lives within one.', `${propertyName}.iframeSelector`)}

                    </div>
                case "viewportSize":
                    return <div className={cn(Styles.expand, Styles.reply_selector)}>
                        <span key={buildKey('title', propertyName)} className={Styles.property_title}>
                            Viewport
                        </span>
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>

                        <span className={Styles.sub_property_title}>Width</span>
                        <Input
                            key={buildKey('input', `${propertyName}.width`)}
                            maxLength={8}
                            type={'number'}
                            className={Styles.input_number}
                            floating={false}
                            theme={bespokenInput}

                            value={this.state?.extraParametersViewPort?.width}
                            onChange={(width: any) => { width = parseInt(width); this.setState({ extraParametersViewPort: { ...this.state.extraParametersViewPort, width } }) }}
                        />
                        {renderTooltip('Desired width in which to test the page.', `${propertyName}.selector`)}

                        <span className={Styles.sub_property_title}>Height</span>
                        <Input
                            key={buildKey('input', `${propertyName}.height`)}
                            maxLength={8}
                            type={'number'}
                            className={Styles.input_number}
                            floating={false}
                            theme={bespokenInput}

                            value={this.state?.extraParametersViewPort?.height}
                            onChange={(height: any) => { height = parseInt(height); this.setState({ extraParametersViewPort: { ...this.state.extraParametersViewPort, height } }) }}
                        />
                        {renderTooltip('Desired height in which to test the page.', `${propertyName}.timeout`)}

                    </div>
                case "listOfScripts":
                    return <div className={cn(Styles.expand, Styles.additional_scripts)}>
                        <span key={buildKey('title', propertyName)} className={Styles.property_title}>
                            {title}
                        </span>
                        {renderTooltip(tooltip, propertyName)}
                        <MultipleTextInput
                            addButtonText="Add script"
                            key={buildKey('scripts', `${propertyName}.iframeSelector`)}
                            values={this.state?.extraParametersScripts}
                            onChange={(extraParametersScripts: string[]) => this.setState({ extraParametersScripts } as any)}
                        />
                    </div>
                default:
                    return <div />
            }
        }

        const renderTooltip = (tooltip: string, propertyName: string) => <TooltipDiv
            key={buildKey('tooltip', propertyName)}
            className={Styles.drawerInfoTooltip}
            tooltipPosition={"left"}
            theme={tooltipTheme}
            tooltip={tooltip}>
            <IconButton className={cn(buttonStyle.info_button, Styles.info_button)} disabled={true} icon={"info"} />
        </TooltipDiv>

        return <div key={buildKey('control', propertyName)} className={Styles.property_container}>
            {
                controlType === 'homophones' && renderControl(controlType)
            }
            {
                controlType === 'multiple-text' && renderControl(controlType)
            }
            {
                controlType === 'inputSelector' && renderControl(controlType)
            }
            {
                controlType === 'replySelector' && renderControl(controlType)
            }
            {
                controlType === 'listOfScripts' && renderControl(controlType)
            }
            {
                controlType === 'viewportSize' && renderControl(controlType)
            }
            {!includes(['homophones', 'multiple-text', 'inputSelector', 'replySelector', 'listOfScripts', 'viewportSize'], controlType) && [<span key={buildKey('title', propertyName)} className={Styles.property_title}>{title}</span>, renderControl(controlType), renderTooltip(tooltip, propertyName)]}
        </div>
    }

    renderPropertyControl = {
        renderMaxResponseWaitTime: () => this.renderSettingProperty({
            propertyName: 'maxAsyncE2EResponseWaitTime',
            controlType: 'number',
            title: 'Max. response wait time',
            tooltip: 'Interval in milliseconds to wait for a response.'
        }),
        renderLenientMode: () => this.renderSettingProperty({
            propertyName: 'lenientMode',
            controlType: 'boolean',
            title: 'Lenient mode',
            tooltip: 'Removes common punctuation signs and extra white spaces from the transcript.'
        }),
        renderStopTestOnFailure: () => this.renderSettingProperty({
            propertyName: 'stopTestOnFailure',
            controlType: 'boolean',
            title: 'Stop tests on first failure',
            tooltip: 'Stop the test as soon as the first error is detected, saving time between runs.'
        }),
        renderRecordCall: () => this.renderSettingProperty({
            propertyName: 'recordCall',
            controlType: 'boolean',
            title: 'Record call',
            tooltip: 'When checked, a player to listen to the test call recording is shown after the test finishes.'
        }),
        renderRepeatOnPhrase: () => this.renderSettingProperty({
            propertyName: 'repeatOnPhrase',
            controlType: 'multiple-text',
            title: '"Repeat on" phrases',
            tooltip: 'Repeats the current utterance when one of these values is found. Useful when the system we are calling does not understand what was said.'
        }),
        renderSpeechModel: () => this.renderSettingProperty({
            propertyName: 'speechModel',
            controlType: 'speech-to-text-model',
            title: 'Speech-to-Text model',
            tooltip: 'Select a machine-learning model to transcribe your audio. This can improve the transcript results depending on the audio source. Use with caution: not all models support all languages.'
        }),
        renderHomophones: () => this.renderSettingProperty({
            propertyName: 'homophones',
            controlType: 'homophones',
            title: '',
            tooltip: 'List of values that will be replaced by their key when found. Example: There vs. Their vs. They\'re. Values should be separated by commas.'
        }),
        renderInitialize: () => this.renderSettingProperty({
            propertyName: 'initialize',
            controlType: 'text',
            title: 'Initialize script',
            tooltip: 'Script to run before starting the test commands and after opening the chatbot widget.'
        }),
        renderOpenSelectors: () => this.renderSettingProperty({
            propertyName: 'openSelectors',
            controlType: 'text',
            title: 'Open selector',
            tooltip: 'The CSS selector to open the chatbox widget.'
        }),
        renderInputSelector: () => this.renderSettingProperty({
            propertyName: 'inputSelector',
            controlType: 'inputSelector',
            title: 'Input selector',
            tooltip: 'The CSS selector for the text input where messages should be entered.'
        }),
        renderReplySelector: () => this.renderSettingProperty({
            propertyName: 'replySelector',
            controlType: 'replySelector',
            title: 'Reply selector',
            tooltip: 'The CSS selector used to look for the reply within the webchat HTML.'
        }),
        renderTimeoutOnLoad: () => this.renderSettingProperty({
            propertyName: 'timeoutOnLoad',
            controlType: 'number',
            title: 'Load timeout',
            tooltip: 'Miliseconds to wait for the webpage to finish loading.'
        }),
        renderTimeoutOnInitialize: () => this.renderSettingProperty({
            propertyName: 'timeoutOnInitialize',
            controlType: 'number',
            title: 'Initialize timeout',
            tooltip: 'Miliseconds to wait for the initialize script to finish.'
        }),
        renderPauseBeforeUtterance: () => this.renderSettingProperty({
            propertyName: 'pauseBeforeUtterance',
            controlType: 'number',
            title: 'Pause before utterance',
            tooltip: 'Delay in seconds that is added before playing the current utterance. This delay comes after detecting a finishOnPhrase or reaching a listeningTimeout value.'
        }),

        renderSttThreshold: () => this.renderSettingProperty({
            propertyName: 'sttThreshold',
            controlType: 'number',
            additionalProps: { min: 0, max: 1 },
            title: 'Finish on phrase fuzzy threshold',
            tooltip: 'A decimal number from 0 to 1 that represents the threshold applied when using fuzzy matching to identify a finishOnPhrase value. Setting this property to 1 means the finishOnPhrase has to match exactly.'
        }),

        renderDefaultNavigationTimeout: () => this.renderSettingProperty({
            propertyName: 'defaultNavigationTimeout',
            controlType: 'number',
            title: 'Navigation timeout',
            tooltip: 'This setting will change the default maximum navigation time.'
        }),
        serviceUrl: () => this.renderSettingProperty({
            propertyName: 'serviceUrl',
            controlType: 'text',
            title: 'Watson Assistant Service URL',
            tooltip: 'Watson Assistant Service URL.'
        }),
        apiKey: () => this.renderSettingProperty({
            propertyName: 'apiKey',
            controlType: 'masked',
            title: 'Watson Assistant API Key',
            tooltip: 'Watson Assistant API Key.'
        }),
        assistantId: () => this.renderSettingProperty({
            propertyName: 'assistantId',
            controlType: 'text',
            title: 'Watson Assistant ID',
            tooltip: 'Watson Assistant ID.'
        }),
        voiceflowApiKey: () => this.renderSettingProperty({
            propertyName: 'apiKey',
            controlType: 'masked',
            title: 'Voiceflow API Key',
            tooltip: 'Voiceflow API Key.'
        }),
        voiceflowVersionId: () => this.renderSettingProperty({
            propertyName: 'versionId',
            controlType: 'masked',
            title: 'Voiceflow version Id',
            tooltip: 'Voiceflow Id.'
        }),
        lexApiAccessKeyId: () => this.renderSettingProperty({
            propertyName: 'accessKeyId',
            controlType: 'masked',
            title: 'AWS Lex Access Key Id',
            tooltip: 'AWS Lex Access Key Id.'
        }),
        lexSecretAccessKey: () => this.renderSettingProperty({
            propertyName: 'secretAccessKey',
            controlType: 'masked',
            title: 'AWS Lex Secret Access Key',
            tooltip: 'Lex Id.'
        }),
        lexBotAliasId: () => this.renderSettingProperty({
            propertyName: 'botAliasId',
            controlType: 'masked',
            title: 'AWS Lex Bot Alias Id',
            tooltip: 'AWS Lex Bot Alias Id.'
        }),
        lexBotId: () => this.renderSettingProperty({
            propertyName: 'botId',
            controlType: 'masked',
            title: 'AWS Lex Bot Id',
            tooltip: 'AWS Lex Bot Id.'
        }),
        lexBotName: () => this.renderSettingProperty({
            propertyName: 'botName',
            controlType: 'text',
            title: 'AWS Lex Bot Name',
            tooltip: 'AWS Lex Bot Name.',
        }),
        lexRegion: () => this.renderSettingProperty({
            propertyName: 'region',
            controlType: 'text',
            title: 'AWS Lex Region',
            tooltip: 'AWS Lex Region.',
        }),

        renderEndSpeechTimeout: () => this.renderSettingProperty({
            propertyName: 'endSpeechTimeout',
            controlType: 'number',
            title: 'End of speech timeout',
            tooltip: 'Time in seconds of silence that we\'ll wait for before moving to the next interaction.'
        }),

        renderExtraParameterHeadless: () => this.renderSettingProperty({
            propertyName: 'extraParametersHeadless',
            controlType: 'boolean',
            title: 'Headless testing',
            tooltip: 'Use a headless browser for testing, avoiding having to load the application\'s user interface. This enhances test speed and reliability, but it may not be supported on all pages. Disable it if necessary.'
        }),

        renderExtraParameterCsp: () => this.renderSettingProperty({
            propertyName: 'extraParametersBypassCsp',
            controlType: 'boolean',
            title: 'Bypass CSP',
            tooltip: 'Bypass the page Content Security Policy. For sites that won\'t allow automated testing on their pages.'
        }),

        renderExtraParameterScripts: () => this.renderSettingProperty({
            propertyName: 'extraParametersScripts',
            controlType: 'listOfScripts',
            title: 'Additional scripts',
            tooltip: 'URLs pointing to addition JS scripts to be used during testing. Make sure to include JQuery if your page doesn\'t have it already (https://ajax.googleapis.com/ajax/libs/jquery/3.6.4/jquery.min.js)'
        }),

        renderExtraViewport: () => this.renderSettingProperty({
            propertyName: 'extraParametersViewPort',
            controlType: 'viewportSize',
            title: 'Viewport',
            tooltip: ''
        }),

        SESSourceEmail: () => this.renderSettingProperty({
            propertyName: 'sourceEmail',
            controlType: 'text',
            title: 'Source Email',
            tooltip: 'The email address that the message will be sent from.'
        }),

        SESSubjectEmail: () => this.renderSettingProperty({
            propertyName: 'subjectEmail',
            controlType: 'text',
            title: 'Subject Email',
            tooltip: 'The email subject.'
        }),

        SESContentOnly: () => this.renderSettingProperty({
            propertyName: 'contentOnly',
            controlType: 'boolean',
            title: 'Skip HTML',
            tooltip: 'Ignore HTML tags and get text content only.'
        }),

        renderFuzzyMatching: () => this.renderSettingProperty({
            propertyName: 'virtualDeviceConfigFuzzyMatching',
            controlType: 'number',
            additionalProps: { min: 0, max: 1 },
            title: 'Assertion fuzzy threshold',
            tooltip: 'A decimal number from 0 to 1 that represents the threshold applied when using fuzzy matching to verify a prompt assertion. Setting this property to 1 means the values have to match exactly.'
        }),

        renderReplyTimeout: () => this.renderSettingProperty({
            propertyName: 'replyTimeout',
            controlType: 'number',
            title: 'Reply timeout',
            tooltip: 'The amount of seconds to wait for a response message before timing out.'
        }),

        renderReplyWaitTime: () => this.renderSettingProperty({
            propertyName: 'replyWaitTime',
            controlType: 'number',
            title: 'Reply wait time',
            tooltip: 'The amount of additional seconds to wait for extra incoming messages after the first response arrives. Extra replies are concatenated together as they arrive.'
        }),

    }

    settingsConfiguration: { [key: string]: Function[] } = {
        common: [
            this.renderPropertyControl.renderFuzzyMatching,
            this.renderPropertyControl.renderMaxResponseWaitTime,
            this.renderPropertyControl.renderStopTestOnFailure,
        ],
        none: [
            // this.renderPropertyControl.renderHomophones,
        ],
        twilio: [
            this.renderPropertyControl.renderLenientMode,
            this.renderPropertyControl.renderSpeechModel,
            this.renderPropertyControl.renderHomophones
        ],
        google: [
            this.renderPropertyControl.renderLenientMode,
            this.renderPropertyControl.renderSpeechModel,
            this.renderPropertyControl.renderHomophones
        ],
        alexa: [
            this.renderPropertyControl.renderLenientMode,
            this.renderPropertyControl.renderSpeechModel,
            this.renderPropertyControl.renderHomophones
        ],
        whatsapp: [
            this.renderPropertyControl.renderReplyWaitTime
        ],
        sms: [
            this.renderPropertyControl.renderReplyWaitTime
        ],
        phone: [
            this.renderPropertyControl.renderLenientMode,
            this.renderPropertyControl.renderRecordCall,
            this.renderPropertyControl.renderRepeatOnPhrase,
            this.renderPropertyControl.renderSpeechModel,
            this.renderPropertyControl.renderHomophones,
            this.renderPropertyControl.renderPauseBeforeUtterance,
            this.renderPropertyControl.renderSttThreshold,
            this.renderPropertyControl.renderEndSpeechTimeout,
        ],
        webchat: [
            this.renderPropertyControl.renderExtraParameterHeadless,
            this.renderPropertyControl.renderExtraParameterCsp,
            this.renderPropertyControl.renderDefaultNavigationTimeout,
            this.renderPropertyControl.renderInitialize,
            this.renderPropertyControl.renderOpenSelectors,
            this.renderPropertyControl.renderInputSelector,
            this.renderPropertyControl.renderReplySelector,
            this.renderPropertyControl.renderTimeoutOnLoad,
            this.renderPropertyControl.renderTimeoutOnInitialize,
            this.renderPropertyControl.renderExtraViewport,
            this.renderPropertyControl.renderExtraParameterScripts,
        ],
        voiceflow: [
            this.renderPropertyControl.voiceflowApiKey,
            this.renderPropertyControl.voiceflowVersionId,
        ],
        lex: [
            this.renderPropertyControl.lexApiAccessKeyId,
            this.renderPropertyControl.lexSecretAccessKey,
            this.renderPropertyControl.lexBotName,
            this.renderPropertyControl.lexBotAliasId,
            this.renderPropertyControl.lexBotId,
            this.renderPropertyControl.lexRegion,
        ],
        watson: [
            this.renderPropertyControl.serviceUrl,
            this.renderPropertyControl.apiKey,
            this.renderPropertyControl.assistantId,
        ],
        email: [
            this.renderPropertyControl.SESSourceEmail,
            this.renderPropertyControl.SESSubjectEmail,
            this.renderPropertyControl.SESContentOnly,
        ],
    }

    renderPropertyControls(platform: string) {
        const { settingsConfiguration } = this
        return chain(settingsConfiguration.common)
            .concat(settingsConfiguration[platform] || settingsConfiguration.none)
            .filter(isFunction)
            .map(render => render())
            .value()
    }

    render() {
        const { platform } = this.state
        return (
            <Drawer
                theme={validationStyle}
                type={"right"}
                withOverlay={true}
                active={this.props.showSettings}
                onOverlayClick={this.onCloseHandle}>

                <div className={Styles.settings_container}>
                    <h4 className={Styles.header}>Advanced Settings</h4>

                    {this.renderPropertyControls(platform)}

                    <div className={Styles.advanced_json_text}>
                        <div className={Styles.json_textarea_title}>Additional settings (use with caution)</div>
                        <TooltipDiv
                            className={Styles.drawerInfoTooltip}
                            tooltipPosition={"left"}
                            theme={tooltipTheme}
                            tooltip={"Head to https://read.bespoken.io/end-to-end/guide/#configuration if you are looking for additional configuration values. Then put them here as JSON."}>
                            <IconButton
                                className={buttonStyle.info_button}
                                disabled={true}
                                icon={"info"}
                            />
                        </TooltipDiv>
                        <Input
                            className={Styles.json_textarea}
                            floating={false}
                            theme={bespokenInput}
                            type={"text"}
                            multiline={true}
                            rows={15}
                            value={this.state.additionalSettings}
                            onChange={this.handleChangeAdditionalSettings}
                        />
                        {this.state.additionalSettingsError && <span title={this.state.additionalSettingsError} className={Styles.json_errors}>Invalid Json</span>}
                    </div>
                    <div className={Styles.buttons_container}>
                        <Button
                            theme={bespokenButton}
                            label={"Cancel"}
                            onClick={this.onCloseHandle}
                        />
                        <Button
                            theme={bespokenButton}
                            label={"OK"}
                            accent={true}
                            onClick={this.onSaveHandle}
                        />
                    </div>
                </div>
            </Drawer>
        )
    }
}
