import * as cn from "classnames";
import { attempt, chain, defaultTo, snakeCase, trim, truncate } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { State } from "../../../reducers";
import { attemptInvoke } from "../../../utils/ReactHelpers";
import { DEBUG } from "../debug-panel/DebugPanel";
import * as ReactDOM from "react-dom";

const COMPONENT_NAME = "InplaceEditor"
const COMPONENT = "component"
const Styles = require("./styles.scss");

interface DispatchToProps { }
function mapDispatchToProps(dispatch: any) { return {} }

interface StateToProps { }
function mapStateToProps(state: State.All) { return {} }

interface ExposedProps {
    value: string;
    onChanged: (value: string) => void
    editing?: boolean;
    saveOnBlur?: boolean;
    placeHolder?: string;
    tooltip?: string;
    maxLength?: number;
    lines?: number;
    columns?: number;
    truncateLength?: number;
}
function mergeProps(ownProps: any, stateProps: any, dispatchProps: ExposedProps) {
    return { ...ownProps, ...stateProps, ...dispatchProps }
}

interface ComponentProps extends DispatchToProps, StateToProps, ExposedProps { }

interface ComponentState {
    value: string;
    editingValue: string;
    editing: boolean;
    previewValue: string;
}

class Component extends React.Component<ComponentProps, ComponentState> {

    static defaultProps: ComponentProps = {
        value: '',
        onChanged: () => { },
        editing: false,
        saveOnBlur: false,
        placeHolder: '',
        maxLength: 100,
        tooltip: '',
        lines: undefined,
        columns: undefined,
        truncateLength: undefined
    }

    turOffOnBlur: boolean = false;

    constructor(props: ComponentProps) {
        super(props);
        this.state = {
            editing: defaultTo(props?.editing, false),
            value: props.value,
            editingValue: props.value,
            previewValue: this.truncateStringIfRequried(props.value, props.truncateLength)
        }
    }

    truncateStringIfRequried(value: string, truncateLength: number) {
        return truncateLength > 0 ? truncate(value, { length: truncateLength }) : value
    }

    componentWillReceiveProps(nextProps: Readonly<ComponentProps>, nextContext: any): void {
        if (this.props?.value !== nextProps.value) {
            this.setState({ value: nextProps.value, editingValue: nextProps.value, previewValue: this.truncateStringIfRequried(nextProps.value, nextProps.truncateLength) })
        }
    }

    render(): false | JSX.Element {

        return (
            <div
                className={cn(COMPONENT, snakeCase(COMPONENT_NAME), Styles.component)}

                title={this.props?.tooltip}
            >
                <textarea
                    rows={this.props?.lines}
                    cols={this.props?.columns}
                    className={cn(Styles.input)}

                    placeholder={this.props?.placeHolder}
                    maxLength={this.props?.maxLength}

                    value={this.state?.previewValue || this.state?.editingValue}
                    onFocus={() => { this.setState({ previewValue: undefined }) }}
                    onKeyDown={(evt) => {
                        if (evt.key === "Escape") {

                            evt.preventDefault()

                            const currentTarget = evt.currentTarget

                            this.setState({ editingValue: this.state?.value }, () => {
                                this.turOffOnBlur = true

                                currentTarget.blur()

                                this.turOffOnBlur = false
                            })

                            return
                        }

                        if (evt.key === "Enter") {
                            evt.preventDefault()

                            const currentTarget = evt.currentTarget

                            this.setState({ value: this.state?.editingValue }, () => currentTarget.blur())

                            return
                        }
                    }}
                    onBlur={() => {
                        if (this.turOffOnBlur) { return }

                        const value = this.state?.editingValue
                        const editingValue = this.state?.editingValue
                        const previewValue = this.truncateStringIfRequried(editingValue, this.props.truncateLength)

                        if (this.props?.saveOnBlur) {
                            this.setState({ value, editingValue, previewValue }, () => attemptInvoke(this.props?.onChanged, this.state?.value))

                            return
                        }

                        this.setState({ editingValue, previewValue }, () => attemptInvoke(this.props?.onChanged, this.state?.value))
                    }}

                    onInput={evt => {
                        this.setState({ editingValue: chain(evt.currentTarget.value).split(/[\n\t ]/).map(w => trim(w)).join(' ').value() })
                    }}
                />
            </div>
        )
    }

}

export const InplaceEditor = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(Component);
