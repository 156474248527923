
import * as cn from "classnames";
import * as React from "react";
import ReactToolkitInput from "react-toolbox/lib/input";
import { attemptInvoke, cnToggle } from "../../../utils/ReactHelpers";
import { get, isEmpty, isFunction } from "lodash";


const Styles = require("./Input.module.scss");
const InputTheme = require("./InputTheme.scss");

const COMPONENT_NAME = "Input"
const COMPONENT = "component"

type Props = {
    autoFocus?: boolean;
    disabled?: boolean;
    onBlur?: (value: string) => void;
    onChange?: (value: string) => void;
    onTabKeyDown?: (value: string) => void;
    defaultValue?: string;
    placeholder?: string;
    hasError?: boolean;
    maxLenght?: number;
    value?: string;
    multiline?: boolean;
    rows?: number;
}
type State = {
    value: string;
}

export class Input extends React.Component<Props, State>  {

    static defaultProps: Props = {
        autoFocus: undefined,
        disabled: undefined,
        onBlur: undefined,
        onChange: undefined,
        defaultValue: undefined,
        placeholder: undefined,
        hasError: undefined,
        maxLenght: undefined,
        value: undefined,
        multiline: undefined,
        rows: 1,
        onTabKeyDown: undefined
    }

    constructor(props: Props) {
        super(props);

        this.state = {
            value: props.defaultValue || props.value || "",
        };
    }

    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        this.setState({ ...this.state, value: nextProps.value })
    }

    render(): false | JSX.Element {
        const { autoFocus, hasError, onBlur, onChange, placeholder, maxLenght, value } = this.props
        return (
            <div className={cn(COMPONENT, COMPONENT_NAME, Styles.component)}>
                <ReactToolkitInput
                    disabled={this.props?.disabled}
                    className={cn(Styles.field, cnToggle(hasError, Styles.error))}
                    multiline={this.props?.multiline}
                    rows={this.props?.rows}
                    theme={InputTheme}
                    value={this.state.value}
                    onBlur={() => attemptInvoke(onBlur, undefined)}
                    onChange={(value: string) => this.setState({ value }, () => attemptInvoke(onChange, this.state.value))}
                    maxLength={maxLenght || 300}
                    onKeyDown={(evt: any) => {
                        if (!isFunction(this.props?.onTabKeyDown) || !isEmpty(evt) && (get(evt, 'key') !== "Tab")) {
                            return
                        }

                        if (!isEmpty(evt)) {
                            evt.preventDefault();
                            evt.stopPropagation();
                        }

                        attemptInvoke(this.props?.onTabKeyDown)
                    }}
                    {...{ autoFocus, placeholder }} />
            </div>
        )
    }
}