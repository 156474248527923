import * as cs from "classnames";
import { format } from "date-fns";
import { capitalize, get, toLower, toUpper } from "lodash";
import * as React from "react";
import { Chip } from "react-toolbox/lib/chip";
import { Table, TableCell, TableHead, TableRow } from "react-toolbox/lib/table";
import GreaterThanIcon from "../../../assets/bespoken_greater_than.svg";
import GreaterThanIconDisabled from "../../../assets/bespoken_greater_than_disabled.svg";
import LessThanIcon from "../../../assets/bespoken_less_than.svg";
import LessThanIconDisabled from "../../../assets/bespoken_less_than_disabled.svg";
import { TestRunResultsRows } from "../../services/bespoken-reporting-api";

const Styles = require("./TestResultsTableStyle.scss");

type PageItem = { value: number, label: string, disable?: boolean };

export interface TestResultsTableProps {
    data: { resultSet: Array<TestRunResultsRows>, config: { total_rows: number, page_size: number } };
    onSelect: (testRunId: string) => void;
    onPageChanged: (page: number) => void;
    currentPage: number;
}

export interface TestResultsTableState {
    data: { resultSet: Array<TestRunResultsRows>, config: { total_rows: number, page_size: number } };
    pages: { value: number, label: string }[];
    visiblePages: { value: number, label: string }[];
    currentPage: PageItem;
    previousPage: PageItem;
    nextPage: PageItem;
    goToPageNumber: number;
}

const SUITE_STATUS_COLOR: { [key: string]: any } = {
    "GREEN": Styles.status_green,
    "YELLOW": Styles.status_yellow,
    "RED": Styles.status_red,
};

interface EmptyResultsProps {
    colSpan: number;
    noDataLabel: string;
    tagName?: string;
    column?: number;
}

/**
 * Class created to remove warning cause using colSpan in tag TD
 */
class EmptyResults extends React.Component<EmptyResultsProps, {}> {
    constructor(props: EmptyResultsProps) {
        super(props);
    }
    render(): JSX.Element {
        return (<td style={{ textAlign: "center" }} colSpan={this.props.colSpan}>{this.props.noDataLabel}</td>);
    }
}

export class TestResultsTable extends React.Component<TestResultsTableProps, TestResultsTableState> {

    state: TestResultsTableState;
    props: TestResultsTableProps;

    constructor(props: TestResultsTableProps) {
        super(props);

        this.state = {
            data: { resultSet: [], config: { total_rows: 0, page_size: 0 } },
            pages: [],
            visiblePages: [],
            currentPage: { label: "1", value: 1 },
            nextPage: { label: "1", value: 1 },
            previousPage: { label: "1", value: 1 },
            goToPageNumber: 1,
        };
    }

    componentWillReceiveProps(nextProps: Readonly<TestResultsTableProps>, nextContext: any): void {
        const currentPage: PageItem = { label: `${nextProps.currentPage}`, value: nextProps.currentPage };
        const totalPages = get(nextProps.data, "resultSet[0].pagination__total_pages", 0)
        // Contains all the pages found, it will be used for later find the pasge to show
        const pages: PageItem[] = Array(totalPages).fill(1).map((n, i) => ({ value: i + 1, label: `${i + 1}` }));
        const goToPageNumber = this.props.currentPage;
        // calculate previous and next page links
        const { previousPage, nextPage } = pages.reduce((prev, curr, i, all) => {
            if (curr.value !== this.state.currentPage.value) {
                return prev;
            }
            if (all[i - 1]) {
                prev.previousPage = all[i - 1]
            } else {
                prev.previousPage = curr
            }
            if (all[i + 1]) {
                prev.nextPage = all[i + 1]
            } else {
                prev.nextPage = curr
            }
            return prev;
        }, { nextPage: this.state.nextPage, previousPage: this.state.previousPage })

        // disabling previous and next in case the selected page is the first or the last
        previousPage.disable = this.state.currentPage.value === 1
        nextPage.disable = this.state.currentPage.value === pages.slice(-1)[0]?.value

        // the number of pages that will be shown before and after
        const offsetNumber = 3;
        // compute pages to show, it is uses offsetNumber for getting how many pages will be shown before and after.
        // if there is more pages than offsetNumber it will add a '...' label
        // first and last will be always shown
        const visiblePages: PageItem[] = this.computeVisiblePageNumber(pages, currentPage, offsetNumber);

        this.setState({ data: nextProps.data, pages, visiblePages, previousPage, nextPage, currentPage, goToPageNumber })
    }

    computeVisiblePageNumber(pages: PageItem[], currentPage: PageItem, offsetNumber: number): PageItem[] {
        if (pages.length === 0) {
            return []
        }

        if (pages.length <= 3) {
            return pages.slice()
        }

        const foundIndex = pages.findIndex((it) => it.value === currentPage.value)

        if (foundIndex === -1) {
            return pages.slice()
        }

        const clonedPages = pages.slice()
        const leftBranch = clonedPages.slice(0, foundIndex).reduce((p, c, i, all) => {
            if (i === 0) {
                return p.concat(c)
            }
            if (i > all.length - offsetNumber - 1) {
                return p.concat(c)
            }
            if (i === all.length - offsetNumber - 1) {
                return p.concat({ label: '...' })
            }

            return p
        }, [])
        const rightBranch = clonedPages.slice(foundIndex + 1).reduce((p, c, i, all) => {
            if (i === all.length - 1) {
                return p.concat(c)
            }
            if (i < offsetNumber) {
                return p.concat(c)
            }
            if (i === offsetNumber) {
                return p.concat({ label: '...' })
            }

            return p
        }, [])
        return [].concat(leftBranch).concat(currentPage).concat(rightBranch)
    }

    render() {
        const noDataLabel = "No data available. Run some end-to-end tests to see your historical results here.";
        return (
            <div>
                <Table key={"table__"} /*theme={tableTheme}*/ className={Styles.table} selectable={false} multiSelectable={false}>
                    <TableHead>
                        <TableCell className={Styles.column_header_left}>Date</TableCell>
                        <TableCell className={Styles.column_header_left}>Client</TableCell>
                        <TableCell className={Styles.column_header_left}>Project Name</TableCell>
                        <TableCell className={Styles.column_header_left}>Platform</TableCell>
                        <TableCell className={Styles.column_header_center}>Test Suites</TableCell>
                        <TableCell className={Styles.column_header_center}>Tests</TableCell>
                        <TableCell className={Styles.column_header_center}>Success %</TableCell>
                    </TableHead>
                    {
                        get(this.state, 'data.resultSet', [])
                            .map((row: TestRunResultsRows) => (
                                <TableRow className={Styles.test_run_row} key={row.test_run_id || `anonymous_${row.test_run_date}`} onClick={(a: any, b: any, c: any) => this.props.onSelect && this.props.onSelect(row.test_run_id)}>
                                    <TableCell className={Styles.column_value_left}>{format(row.test_run_date, "MM/dd/yyyy hh:mmaaa") || ""}</TableCell>
                                    <TableCell className={Styles.column_value_left}>{this.preProcessClient(row.client) || ""}</TableCell>
                                    <TableCell className={Styles.column_value_left}>{row.project_name || ""}</TableCell>
                                    <TableCell className={Styles.column_value_left}>{this.preProcessPlatform(row.platform) || ""}</TableCell>
                                    <TableCell className={Styles.column_value_center}>{row.test_suites || 0}</TableCell>
                                    <TableCell className={Styles.column_value_center}>{row.tests || 0}</TableCell>
                                    <TableCell className={Styles.column_value_center}>
                                        <Chip className={[Styles.status_chip, (SUITE_STATUS_COLOR[row.test_suite_success_rate_label] || SUITE_STATUS_COLOR.RED)].join(" ")}><span>{row.test_suite_success_rate * 100 || 0}%</span></Chip>
                                    </TableCell>
                                </TableRow>
                            ))
                            .reduce((prev: any[], curr: any, i: number, all: any[]) => {
                                if (all.length === 0) {
                                    prev.push(prev)
                                } else {
                                    return all
                                }
                                return prev
                            }, (<TableRow><EmptyResults colSpan={6} noDataLabel={noDataLabel} /></TableRow>))
                    }
                </Table>
                <hr />
                <div className={Styles.pagination_control}>
                    <span>&nbsp;</span>
                    <a
                        className={cs(Styles.page_prev_next, this.state.currentPage.value === this.state.previousPage.value ? Styles.disable : undefined)}
                        title={`Page: ${this.state.previousPage.label}`}
                        key={'go-previous'}
                        onClick={() => this.changePage(this.state.previousPage)}>
                        {this.state.currentPage.value === this.state.previousPage.value ? <LessThanIconDisabled /> : <LessThanIcon />}
                    </a>
                    {
                        (this.state.visiblePages || [])
                            .map((it, i) => (
                                <a
                                    title={`Page: ${it.label}`}
                                    className={cs(Styles.page_number, this.state.currentPage.value === it.value ? Styles.selected : undefined, it.value ? undefined : Styles.no_link)}
                                    key={`go-page-${it.value || '3dots'}-${i}`}
                                    onClick={() => it.value && this.changePage(it)}>
                                    <span>{it.label}</span>
                                </a>))
                    }
                    <a
                        className={cs(Styles.page_prev_next, this.state.currentPage.value === this.state.nextPage.value ? Styles.disable : undefined)}
                        title={`Page: ${this.state.nextPage.label}`}
                        key={'go-next'}
                        onClick={() => this.changePage(this.state.nextPage)}>
                        {this.state.currentPage.value === this.state.nextPage.value ? <GreaterThanIconDisabled /> : <GreaterThanIcon />}
                    </a>
                    <span className={Styles.space} />
                    <label>Go to page</label>
                    <input
                        type="text"
                        size={3}
                        onBlur={(event) => {
                            const value = parseInt((event.target as any).value)
                            const pageItem = this.state.pages.find(it => it.value === value)
                            if (pageItem) {
                                this.changePage(pageItem)
                            } else {
                                this.changePage(this.state.currentPage)
                            }
                            return;
                        }}
                        onKeyPress={(event) => {
                            if (event.key === "Enter") {
                                const value = parseInt((event.target as any).value)
                                const pageItem = this.state.pages.find(it => it.value === value)
                                if (pageItem) {
                                    this.changePage(pageItem)
                                } else {
                                    this.changePage(this.state.currentPage)
                                }
                                return;
                            }
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                                return;
                            }
                        }}
                        value={this.state.goToPageNumber}
                        onChange={(event) => {
                            const goToPageNumber = parseInt((event.target as any).value)
                            if (isNaN(goToPageNumber)) {
                                this.setState({ goToPageNumber: undefined })
                            } else {
                                this.setState({ goToPageNumber })
                            }
                        }}
                    />
                    <span>&nbsp;</span>
                </div>
            </div>
        );
    }

    preProcessPlatform(platform: string): string {
        if (["sms"].indexOf(toLower(platform)) !== -1) {
            return toUpper(platform);
        }
        return capitalize(platform)
    }

    preProcessClient(client: string): string {
        if (["cli", "http"].indexOf(toLower(client)) !== -1) {
            return toUpper(client);
        }
        return capitalize(client)
    }

    changePage(currentPage: PageItem): void {
        this.setState({ currentPage, goToPageNumber: currentPage.value })
        this.props.onPageChanged && this.props.onPageChanged(currentPage.value)
    }
}
