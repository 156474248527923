import { SetDebouncing } from "../actions/debounce";
import { SET_DEBOUNCING } from "../constants";

export type DebouncingState = {
    debouncing: boolean;
};

const INITIAL_STATE: DebouncingState = {
    debouncing: false,
};

type SessionStateAction = SetDebouncing | { type: "" };

export function debouncing(state: DebouncingState = INITIAL_STATE, action: SessionStateAction): DebouncingState {

  switch (action.type) {
    case SET_DEBOUNCING: {
        return { ...state, debouncing: action.debouncing };
    }
    default:
      return state;
  }
}
