import "isomorphic-fetch";
import { Query } from "../models/query";
import { User } from "../models/user";

export interface Plan {
    id: string;
    active: string;
    currency: string;
    amount: number;
    billing_scheme: string;
    interval: string;
    usage_type: string;
}

export interface Customer {
    id: string;
    created: string;
    currency: string;
    account_balance: string;
    delinquent: string;
    description: string;
    email: string;
}
export const STRIPE_TOKEN: string = (process.env.NODE_ENV === "production" || process.env.STAGING_ENV) ?
    process.env.STRIPE_PK_LIVE_TOKEN :
    process.env.STRIPE_PK_TEST_TOKEN;

const STRIPE_URL: string = process.env.NODE_ENV === "production"
    ? process.env.STRIPE_API_URL
    : "https://stripe-api-dev.bespoken.tools/";

const API_TOKEN = process.env.NODE_ENV === "production"
    ? process.env.STRIPE_API_TOKEN
    : process.env.STRIPE_API_TOKEN_DEV;

export async function registerSubscription(user: User, tokenId: any, planToSubscribe: string, upgradeCase?: boolean, prorationDate?: number): Promise<any> {
    const query: Query = new Query();
    query.add({ parameter: "userId", value: user.userId });
    query.add({ parameter: "token", value: tokenId });
    query.add({ parameter: "planToSubscribe", value: planToSubscribe });
    query.add({ parameter: "stripeCustomerObjId", value: user.stripeCustomerObjId ? user.stripeCustomerObjId : undefined });
    query.add({ parameter: "email", value: user.email });
    query.add({ parameter: "updateSubscribedPlan", value: user.stripeSubscribedPlanId ? true : false });
    query.add({
        parameter: "stripeSubscribedPlanId", value: user.stripeSubscribedPlanId ?
            user.stripeSubscribedPlanId : undefined
    });
    query.add({ parameter: "upgradeCase", value: upgradeCase });
    query.add({ parameter: "prorationDate", value: prorationDate });

    try {
        const result: any = await fetch(STRIPE_URL + "registerSubscription", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": API_TOKEN,
            },
            body: query.json()
        });
        if (result.status === 200) {
            return "operation success";
        }
        const data = await result.json();
        if (data.status === 401) {
            return "The request has not been applied because it lacks valid authentication";
        }
        if (data.status === 500) {
            return data.statusText;
        }
        console.error(result.text());

    } catch (error) {
        console.error(error);
    }
    return "Sorry there is an internal issue, Please try in another time or contact us.";

}

export async function getPreviewingProration(subscriptionId: string, customerId: string, stripePlanId: string): Promise<any> {
    const query: Query = new Query();
    query.add({ parameter: "stripeSubscriptionId", value: subscriptionId });
    query.add({ parameter: "customerId", value: customerId });
    query.add({ parameter: "stripePlanId", value: stripePlanId });

    try {
        const result: any = await fetch(STRIPE_URL + "getPreviewingProration/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": API_TOKEN,
            },
            body: query.json()
        });
        if (result.status === 200) {
            return result.json();
        } else {
            const data = await result.json();
            return Promise.reject({ message: data.statusText });
        }
    } catch (error) {
        console.error(error);
    }
    return undefined;
}
export async function updateUserCreditCard(customerId: string, tokenId: string): Promise<any> {
    const query: Query = new Query();

    query.add({ parameter: "customerId", value: customerId });
    query.add({ parameter: "token", value: tokenId });
    try {
        const result: any = await fetch(STRIPE_URL + "updateCustomer/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": API_TOKEN,
            },
            body: query.json()
        });
        if (result.status === 200) {
            return result.json();
        } else {
            const data = await result.json();
            return { message: data.statusText };
        }

    } catch (error) {
        console.error(error);
    }
    return undefined;
}

export async function getSubscriptionDetail(subscriptionId: string): Promise<any> {
    const query: Query = new Query();
    query.add({ parameter: "stripeSubscriptionId", value: subscriptionId });

    try {
        const result: any = await fetch(STRIPE_URL + "subscription/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": API_TOKEN,
            },
            body: query.json()
        });
        if (result.status === 200) {
            return result.json();
        } else {
            const data = await result.json();
            return Promise.reject({ message: data.statusText });
        }
    } catch (error) {
        console.error(error);
    }
    return undefined;
}

export async function cancelSubscription(subscriptionId: string): Promise<any> {
    const query: Query = new Query();
    query.add({ parameter: "stripeSubscriptionId", value: subscriptionId });

    try {
        const result: any = await fetch(STRIPE_URL + "cancelSubscription/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": API_TOKEN,
            },
            body: query.json()
        });
        if (result.status === 200) {
            return result.json();
        } else {
            const data = await result.json();
            return Promise.reject({ message: data.statusText });
        }
    } catch (error) {
        console.error(error);
    }
    return undefined;
}

export async function getCreditCardDetail(customerId: string): Promise<any> {
    const query: Query = new Query();

    query.add({ parameter: "customerId", value: customerId });
    try {
        const result: any = await fetch(STRIPE_URL + "creditCard/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": API_TOKEN,
            },
            body: query.json()
        });
        if (result.status === 200) {
            return result.json();
        } else {
            const data = await result.json();
            return Promise.reject({ message: data.statusText });
        }

    } catch (error) {
        console.error(error);
    }
    return undefined;

}

export async function getDefaultSource(customerId: string): Promise<any> {
    const query: Query = new Query();

    query.add({ parameter: "customerId", value: customerId });
    query.add({ parameter: "onlyDefaultSource", value: true });

    try {
        const result: any = await fetch(STRIPE_URL + "sources/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": API_TOKEN,
            },
            body: query.json()
        });
        if (result.status === 200) {
            return result.json();
        } else {
            const data = await result.json();
            return Promise.reject({ message: data.statusText });
        }

    } catch (error) {
        console.error(error);
    }
    return undefined;

}

export async function getPlanDetail(plan: string): Promise<Plan> {
    const query: Query = new Query();

    query.add({ parameter: "plan", value: plan });

    try {
        const result: any = await fetch(STRIPE_URL + "plan/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": API_TOKEN,
            },
            body: query.json()
        });

        if (result.status === 200) {
            return result.json();
        } else {
            const data = await result.json();
            return Promise.reject({ message: data.statusText });
        }
    } catch (error) {
        console.error(error);
    }
    return undefined;
}

export async function deleteCustomer(customerId: string): Promise<any> {
    const query: Query = new Query();

    query.add({ parameter: "customerId", value: customerId });

    try {
        const result: any = await fetch(STRIPE_URL + "deleteCustomer/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": API_TOKEN,
            },
            body: query.json()
        });

        if (result.status === 200) {
            return result.json();
        } else {
            const data = await result.json();
            return Promise.reject({ message: data.statusText });
        }
    } catch (error) {
        console.error(error);
    }
    return undefined;

}
