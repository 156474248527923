import * as React from "react";
import Source from "../../../models/source";
import { Button, IconButton } from "react-toolbox/lib/button";
import Tooltip from "react-toolbox/lib/tooltip";
import BespokenCheckIcon from "../../../../assets/bespoken_check_icon.svg";
import BespokenRemoveIcon from "../../../../assets/bespoken_remove_icon.svg";
import BespokenRefreshIcon from "../../../../assets/bespoken_refresh_icon.svg";
import BespokenTestIcon from "../../../../assets/bespoken_tests_icon.svg";

import ReactDiv from "../../ReactDiv";
import { TestResultStatus } from "../../../constants";

const bespokenButton = require("../../../themes/bespoken_button.scss");
const validationStyle = require("../../validation/ValidationResultYamlComponentStyle.scss");
const iconButtonTheme = require("../../../themes/icon-button-validation.scss");
const tooltipTheme = require("../../../themes/tooltip.scss");
const testResultsStyle = require("./test-results.scss");

const TooltipDiv = Tooltip(ReactDiv);

interface TestResultsProps {
    source: Source;
    testResults?: any;
    validateYamlSyntax: (yaml: string) => void;
    syntaxError?: string;
}

interface TestResultsState {
}


export default class TestResults extends React.Component<TestResultsProps, TestResultsState> {
    constructor(props: TestResultsProps) {
        super(props);
        this.state = {
        }
    }

    componentWillReceiveProps(nextProps: TestResultsProps) {
    }

    renderError = (error: any) => {
        const rowIconImg = <BespokenRemoveIcon />;
        return (
            <div className={validationStyle.result_text_row}>
                <span className={`${validationStyle.row_icon_status} ${validationStyle["error"]}`}>
                    <div>{rowIconImg}</div>
                </span>
                <span className={validationStyle.yaml_result}>{''}</span>
                {
                    <div>
                        <span
                            className={validationStyle.error_yaml_result}>{error?.message || error}</span>
                    </div>
                }
            </div>
        );
    }

    renderItemResult = (item: any, index: any) => {
        const rowIconImg = item.status === TestResultStatus.PENDING
            ? <BespokenRefreshIcon />
            : item.passed ? <BespokenCheckIcon /> : <BespokenRemoveIcon />;
        const tooltip = item.status === TestResultStatus.PENDING
            ? "Running..."
            : item && item.actual;
        const spanClassSuffix = item.status === TestResultStatus.PENDING
            ? "pending"
            : item.passed ? "success" : "error"
        return (
            <div key={`parent_assertion${index}`} className={validationStyle.result_text_row}>
                {item.status !== TestResultStatus.INITIAL &&
                    <span className={`${validationStyle.row_icon_status} ${validationStyle[spanClassSuffix]}`}>
                        <TooltipDiv
                            theme={tooltipTheme}
                            tooltipPosition={"left"}
                            key={`assertion_div${index}`}
                            tooltip={tooltip}>
                            {rowIconImg}
                        </TooltipDiv>
                    </span>
                }

                <span className={validationStyle.yaml_result} title={item.path}>{item.path}</span>
                {
                    !item.passed &&
                    (
                        <div>
                            <span
                                className={validationStyle.error_yaml_result}>{item.result}</span>
                        </div>
                    )
                }
            </div>
        );
    }

    renderInteractionResultRow = (row: any, index: any) => {
        return (
            <div className={`${validationStyle.validation_result_row}`}
                key={`parent_row_div${index}`}>
                <div>
                    <span>{row.input}</span>
                    {
                        row.error ? (
                            this.renderError(row.error)
                        ) : (
                            row?.items?.map((assertion: any, index: any) => this.renderItemResult(assertion, index))
                        )
                    }
                </div>
            </div>
        );
    }

    renderTestResultRow = (testResult: any, index: number) => {
        return (
            <div className={validationStyle.sequence_container} key={`parent_sequence_div${index}`}>
                <span>{testResult.name}</span>
                {
                    testResult?.interactions.map(this.renderInteractionResultRow)
                }
            </div>
        )
    }

    render() {
        const syntaxResultClass = this.props.syntaxError ? validationStyle.error : validationStyle.success;
        const syntaxResultIcon = this.props.syntaxError ? <BespokenRemoveIcon /> : <BespokenCheckIcon />;

        return (
            <div className={testResultsStyle.testResultsContainer}>
                <span className={testResultsStyle.title}><strong>Results: </strong></span>
                <div className={validationStyle.container}>
                    <div className={validationStyle.scrollable}>
                        <div
                            className={`${validationStyle.sequence_container} ${validationStyle.syntax} ${syntaxResultClass}`}>
                            <span><strong>Syntax: </strong>{this.props.syntaxError}</span>
                            <IconButton
                                className={`${syntaxResultClass} ${validationStyle.yaml_button} ${iconButtonTheme.v2}`}
                                primary={true}
                                theme={iconButtonTheme}
                                icon={syntaxResultIcon} />
                        </div>
                        {this.props?.testResults?.length > 0
                            ? this.props?.testResults.map(this.renderTestResultRow)
                            : <div className={validationStyle.empty_sequence_container}>
                                <span className={validationStyle.sequence_title} />
                            </div>

                        }
                    </div>
                </div>
                <div className={testResultsStyle.check_syntax}>
                    <Button
                        icon={<BespokenTestIcon />}
                        theme={bespokenButton}
                        // disabled={!!this.props.loadingValidationResults}
                        primary={true}
                        onClick={this.props.validateYamlSyntax}
                    >
                        <span>Check syntax</span>
                    </Button>
                </div>

            </div>

        );
    }
}