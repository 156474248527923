import * as React from "react";
import { connect } from "react-redux";
import Snackbar from "react-toolbox/lib/snackbar";

import { replace } from "react-router-redux";
import BespokenLogo from "../../../assets/bespoken_logoweb.svg";
import BespokenMountains from "../../../assets/bespoken_mountains.svg";
import * as backgroundLeft from "../../../assets/images/bespoken_email_verify.jpg";
import ThunderboltIcon from "../../../assets/thunderbolt.svg";
import { logout } from "../../actions/context";
import { LeftPanel, RightPanel } from "../../components";
import { State } from "../../reducers";
import { remoteservice } from "../../services/remote-service";
import { User } from "../../reducers/user";

const style = require("./emailVerifyStyles.scss");

interface EmailVerifyProps {
  user: User;
  goTo: (path: string) => (dispatch: any) => void;
  setUser: (user: User) => (dispatch: any) => void;
  logout: () => (dispatch: any) => void;
}

interface EmailVerifyStates {
  emailVerificationStatus?: boolean;
}

const mapStateToProps = (state: State.All) => ({
  user: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: any) => ({
    goTo: (path: string) => {
      dispatch(replace(path));
    },
    logout: () => {
        return dispatch(logout());
    },
  });

// TODO is still used
export class EmailVerify extends React.Component<EmailVerifyProps, EmailVerifyStates> {
    private intervalEmailVerified: any;

    constructor(props: EmailVerifyProps) {
      super(props);
      this.state = {
        emailVerificationStatus: false
      };
    }

    componentDidMount () {
      this.intervalEmailVerified = setInterval( () => {
        const currentUser = remoteservice.defaultService().auth().currentUser;
        currentUser.reload();
        if (currentUser.emailVerified) {
          // this.props.setUser({ ...this.props.user, emailVerified: true });
          this.props.goTo("/survey");

        }
      }, 1000);
    }

    componentWillUnmount() {
      clearInterval(this.intervalEmailVerified);
    }

    sendEmailVerification = () => {
      remoteservice.defaultService().auth().currentUser.sendEmailVerification();
      this.setState({ emailVerificationStatus: true });
    }

    renderClickHere = () => (
      <a onClick={this.sendEmailVerification}>click here</a>
    )

    hideSnackBar = () => this.setState({ emailVerificationStatus: false });

    renderBackToLogin = () => (
      <div>
        <a data-id="logout" onClick={this.props.logout}>Back to Login</a>
      </div>
    )

    render() {
        return (
            <div className={"emailVerify"}>
                <LeftPanel className={style.leftPanel} fullHeight image={backgroundLeft}>
                    <div data-id="details">
                        <ThunderboltIcon data-id="icon" />
                        <div data-id="message1">Welcome to your Dashboard</div>
                        <div data-id="message2">unleash your</div>
                        <div data-id="message3">superpowers</div>
                    </div>
                    <div data-id="companyName">Bespoken</div>
                </LeftPanel>
                <RightPanel className={style.rightPanel} fullHeight>
                    <BespokenLogo data-id="icon"/>
                    <section>
                        <div data-id="message1">Please verify your email</div>
                        Your account has been created. Please check your email to verify<br/>
                        your registration.<br/><br/>
                        If you haven't received a verification email, check your junk folder or<br/>
                        {this.renderClickHere()} to have another one sent to your inbox.
                        <br/><br/><br/>
                        {this.renderBackToLogin()}
                    </section>
                    <BespokenMountains data-id="mountains"/>
                </RightPanel>
                <Snackbar className="sm-snackbar" action="Dismiss" type="cancel"
                          active={this.state.emailVerificationStatus}
                          label="The email was sent, please check your mailbox"
                          timeout={10000}
                          onClick={this.hideSnackBar}
                          onTimeout={this.hideSnackBar}
                />
            </div>
        );
    }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailVerify);
