import { isEmpty, isEqual, noop, pick } from "lodash";
import * as React from "react";

interface DownloadFileProps {
    url: string;

    onError?: (err: Error) => void;
    onSucccess?: (res: { filename: string }) => void;
}

interface DownloadFileState {
    url: string;
}

export class DownloadFile extends React.Component<DownloadFileProps, DownloadFileState> {

    static defaultProps: DownloadFileProps = {
        url: undefined,

        onError: noop,
        onSucccess: noop
    }

    constructor(props: DownloadFileProps) {
        super(props)

        this.state = {
            url: props.url,
        }
    }

    async download() {
        const { url } = this.state
        const { onError, onSucccess } = this.props

        if (isEmpty(url)) { console.error("URL is undefined") }

        const filename = new URL(url).pathname.split("/").pop()
        await fetch(url, {
            mode: 'cors',
            headers: {
                'Access-Control-Request-Method': 'GET'
            }
        })
            .then(resp => resp.blob())
            .then(blob => this.downloadWithFilename(blob, filename))
            .then(filename => onSucccess && onSucccess(filename))
            .catch(err => onError && onError(err));
    }


    downloadWithFilename(blob: Blob, filename: string): any {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove()

        return filename
    }

    async componentWillReceiveProps(nextProps: Readonly<DownloadFileProps>, nextContext: any): Promise<void> {
        const properties = ["url"]
        if (isEqual(pick(this.props, properties), pick(nextProps, properties))) {
            return
        }

        const urlHasChanged = !isEqual(pick(this.props, "url"), pick(nextProps, "url"))
        if (urlHasChanged) {
            this.setState({ url: nextProps.url })
        }
    }

    render(): false {
        return false;
    }
}