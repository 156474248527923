import { isFunction, startsWith, toLower, trim } from "lodash";
import YamlHelper from "../helpers/yaml-helper";
import { BespokenSessionArgs } from "../index-utils";
import BespokenUser from "../models/bespoken-user";
import Source, { SourceProperties } from "../models/source";
import { hasExperimetalFlag } from "../utils/experimental-flags";
import { fetchInternalApi } from "./internal-api";


// export const BESPOKEN_API_URL = 'http://localhost:5000'
export const BESPOKEN_API_URL = process.env.BESPOKEN_API_REPORTING
export const INTERNAL_API_URL = process.env.INTERNAL_API_URL;

const isHttps = startsWith(toLower(BESPOKEN_API_URL), "https://");
export const BESPOKEN_API_WS_URL = process.env.BESPOKEN_API_WS_URL;
export const BESPOKEN_API_MEDIA_URL = process.env.BESPOKEN_API_MEDIA_URL;


export default class BespokenApi {
    static authorizationToken: string;

    static getHeaders() {
        return [
            ["Authorization", "Bearer " + this.authorizationToken],
            ["Content-Type", "application/json"],
        ];
    }

    static async user(userId: string) {
        const url = `${BESPOKEN_API_URL}/users/${userId}`;
        const response = await fetch(url, { method: "GET" });
        const responseBody = await response.json();

        const appSettings = await fetchInternalApi(`/apps/dashboard/settings`)
            .catch(err => {
                console.log("Error fetching app settings from internal-api", err);
                return {};
            });

        this.authorizationToken = responseBody.authorizationToken;
        const user = new BespokenUser({ ...responseBody, appSettings });
        return user;
    }

    static async sources(userId: string) {
        const url = `${BESPOKEN_API_URL}/sources`;
        const response = await fetch(url, { method: "GET", headers: this.getHeaders() });
        const responseBody = await response.json();
        return responseBody.map((item: SourceProperties) => new Source(item));

    }

    static async source(userId: string, sourceId: string) {
        const responseBody = await fetchBespokenApi(({ userId, organizationId }) => `/users/${userId}/organizations/${organizationId}/sources/${sourceId}`, "GET");
        const { monitoringConfig } = responseBody;
        const source = new Source(responseBody);

        const yaml = source.yaml;
        const config = source.config;
        const meta = source.meta;
        const yamlObject = YamlHelper.toYamlDashboardObject(source.yamlObject);
        return {
            name: source.name,
            yaml,
            config,
            yamlObject,
            meta,
            monitoringConfig,
        };
    }

    static async createSource(userId: string, config: any, meta: any) {
        const url = `${BESPOKEN_API_URL}/sources`;

        const response = await fetch(url, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify({
                userId,
                config,
                meta,
            }),
        });
        const responseBody = await response.json();
        const source = new Source(responseBody);

        const yamlObject = YamlHelper.toYamlDashboardObject(source.yamlObject);
        return {
            name: source.name,
            yaml: source.yaml,
            config: source.config,
            meta: source.meta,
            yamlObject: yamlObject
        };
    }

    static async saveSource(userId: string, sourceId: string, yaml: any, config: any, meta: any, monitoringConfig: any) {
        const responseBody = await fetchBespokenApi(({ userId, organizationId }) => `/users/${userId}/organizations/${organizationId}/sources/${sourceId}`, "PUT", {
            userId,
            yaml,
            config,
            meta,
            monitoringConfig,
        });

        const source = new Source(responseBody);

        const yamlObject = YamlHelper.toYamlDashboardObject(source.yamlObject);
        return {
            name: source.name,
            yaml: source.yaml,
            config: source.config,
            meta: source.meta,
            yamlObject: yamlObject
        };
    }

    static async deleteSource(userId: string, sourceId: string) {
        const url = `${BESPOKEN_API_URL}/sources/${sourceId}`;
        const response = await fetch(url, {
            method: "DELETE",
            headers: this.getHeaders(),
            body: JSON.stringify({
                userId,
            }),
        });
        const responseBody = await response.json();
    }

    static async download(sourceId: string) {
        const response = await fetch(`${BESPOKEN_API_URL}/sources/${sourceId}/download`, {
            method: "GET",
            headers: [
                ["Authorization", "Bearer " + this.authorizationToken],
            ],
        });

        return await response.blob();
    }

    static async validateYaml(yaml: any) {
        const url = `${BESPOKEN_API_URL}/sources/validateYaml`;
        const response = await fetch(url, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify({
                yaml,

            }),
        });
        const responseBody = await response.json();
        return responseBody;
    }

    static async toYamlObject(yaml: any) {
        const url = `${BESPOKEN_API_URL}/sources/toYamlObject`;
        const response = await fetch(url, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify({
                yaml,

            }),
        });
        const responseBody = await response.json();
        const yamlObject = YamlHelper.toYamlDashboardObject(responseBody);
        return yamlObject;
    }

    static async updateUser(userId: string, props: any) {
        const url = `${BESPOKEN_API_URL}/users/${userId}`;
        const response = await fetch(url, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(props),
        });
        const responseBody = await response.json();
        const user = new BespokenUser(responseBody);
        return user;
    }

    static async updateUserPassword(userId: string, props: any) {
        const url = `${BESPOKEN_API_URL}/users/${userId}/updatePassword`;
        const response = await fetch(url, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(props),
        });
        const responseBody = await response.json();
        const user = new BespokenUser(responseBody);
        return user;
    }

    static async linkAccountUrl(userId: string, platform: string, returnUrl: string, token: string = ""): Promise<{ linkAccountUrl: string, mode: "concent_screen" | "post" }> {
        const url = `${BESPOKEN_API_URL}/users/${userId}/linkAccountUrl?platform=${platform}&returnUrl=${returnUrl}&token=${token}`;
        const response = await fetch(url, {
            method: "GET",
            headers: this.getHeaders(),
        });
        const responseBody = await response.json();
        return responseBody;
    }

    static async updateVirtualDevice(userId: string, token: string, props: any) {
        const url = `${BESPOKEN_API_URL}/users/${userId}/virtualDevices/${token}`;
        const response = await fetch(url, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(props),
        });
        const responseBody = await response.json();
        return responseBody.virtualDevices;
    }

    static async deleteVirtualDevice(userId: string, token: string) {
        const url = `${BESPOKEN_API_URL}/users/${userId}/virtualDevices/${token}`;
        const response = await fetch(url, {
            method: "DELETE",
            headers: this.getHeaders(),
        });
        const responseBody = await response.json();
        return responseBody.virtualDevices;
    }

    static async deleteUser(userId: string) {
        const url = `${BESPOKEN_API_URL}/users/${userId}`;
        const response = await fetch(url, {
            method: "DELETE",
            headers: this.getHeaders(),
        });
        const responseBody = await response.json();
        return responseBody;
    }

    static async updateNewUserAndOrganization(email: string): Promise<{ userAccount: any, defaultOrganization: any }> {
        const url = `${BESPOKEN_API_URL}/users/new-user/`;
        const headers = { "Content-Type": "application/json" };
        const method = "POST";
        const body = JSON.stringify({ email });

        return fetch(url, { headers, method, body }).then(res => res.json());
    }
}


type HTTPMethod = "GET" | "POST" | "PUT" | "DELETE" | "HEAD";
type SessionAttributes = { userId?: string, organizationId: string };
type UrlBuilder = (args: SessionAttributes) => string;
export async function fetchBespokenApi(path: string | UrlBuilder, method: HTTPMethod = "GET", bodyObject: any = undefined, headers = { "Content-Type": "application/json" }): Promise<any> {
    if (isFunction(path)) {
        path = path({ userId: BespokenSessionArgs.UserId, organizationId: BespokenSessionArgs.OrganizationId });
    }

    const url = `${BESPOKEN_API_URL}${path}`;
    const body = JSON.stringify(bodyObject);
    return fetch(url, { method, body, headers })
        .then((response) => {
            // When method is HEAD this returns true if status is 200 otherwise returns false
            if (method === "HEAD") {
                return response.status === 200;
            }

            const contentType = trim(response.headers.get("content-type"));
            if (startsWith(contentType, "application/json")) {
                return response.json();
            } else if (startsWith(contentType, "application/zip")) {
                return response.blob();
            } else {
                return response.text();
            }
        });

}