import * as React from "react";
import SuperLlamaOclock from "../../../assets/super_llama.svg";
import { Button } from "../../components";

const RenewStyle = require("./renewSubscriptionStyle.scss");

interface RenewSubscriptionProps {
  goTo: any;
}

export default class RenewSubscription extends React.Component<RenewSubscriptionProps, any> {

  handleSubscribe = () => {
    const { goTo } = this.props;
      goTo && goTo("/billing");
  }

  get mailTo() {
    return "contact@bespoken.io";
  }

  get subject() {
    return "Renew Subscription";
  }

  render() {
    return (
      <div className={RenewStyle.renewContainer}>
        <h1>Hello There!</h1>
        <h3>We are glad to see you again</h3>
        <SuperLlamaOclock data-id="superLlama"/>
        <h6>Subscribe to one of our paid plans to continue testing your test suites</h6>
        <Button label="Subscribe" accent={true} href={`mailto:sales@bespoken.io?subject=Pricing%20inquiry%20-%20convert%20to%20a%20paid%20plan&body=Please%20contact%20me%20-%20I%20want%20to%20convert%20to%20a%20paid%20plan.`} />
        <h6 data-id="help">Do you need more time to evaluate our tools?
          <a href={`mailto:${this.mailTo}?subject=${this.subject}`}>
            Get in touch!
          </a>
        </h6>
      </div>
    );
  }
}
