import * as React from "react";
import { Component } from "react";

import { MenuItem, MenuItemProps } from "react-toolbox/lib/menu";

const MenuTheme = require("../../themes/bespoken_menu.scss");

export interface StyledMenuItemProps extends MenuItemProps {
    to?: string;
}

interface StyledMenuItemState {

}

export class BespokenMenuItem extends Component<StyledMenuItemProps, StyledMenuItemState> {

    render() {
        const { theme, ...others } = this.props;

        const useTheme = { ...MenuTheme, ...theme };

        return (
            <MenuItem
                theme={useTheme}
                {...others}>
                {this.props.children}
            </MenuItem>
        );
    }
}
