import * as React from 'react';
import { BespokenModal } from '../../components/Modal/BespokenModal';
import { Button } from "react-toolbox/lib/button";
const Styles = require("./import-modal-styles.scss");
const bespokenButton = require("../../themes/bespoken_button.scss");

interface ImportModalProps {
    showModal: boolean;
    onDismiss: () => void;
    handleImport: (file: File) => Promise<void>;
}

interface ImportModalState {
    selectedFile: File | null;
    isImporting: boolean;
}

class ImportModal extends React.Component<ImportModalProps, ImportModalState> {
    private fileInputRef: { current: HTMLInputElement | null };

    constructor(props: ImportModalProps) {
        super(props);
        this.state = {
            selectedFile: null,
            isImporting: false
        };
        this.fileInputRef = { current: null };
    }

    handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            this.setState({ selectedFile: files[0] });
        }
    };

    handleImportClick = async () => {
        if (this.state.selectedFile) {
            this.setState({ isImporting: true });
            try {

                await this.props.handleImport(this.state.selectedFile);

                this.setState({ selectedFile: null });
                if (this.fileInputRef.current) {
                    this.fileInputRef.current.value = "";
                }

            } catch (error) {
                console.error('Error during import:', error);
            } finally {
                this.setState({ isImporting: false });
            }
        }
    };

    handleCancel = () => {
        this.setState({ selectedFile: null });
        if (this.fileInputRef.current) {
            this.fileInputRef.current.value = "";
        }
        this.props.onDismiss();
    };


    render() {
        const { showModal, onDismiss } = this.props;
        const { selectedFile, isImporting } = this.state;

        return (
            <BespokenModal
                title="Import Test Suites from Excel"
                showModal={showModal}
                dialogToggle={onDismiss}
            >
                <div className={Styles.modal_body}>
                    <div>
                        <p>
                            Select the Excel file that contains your test suites and click the "Import" button to save them in your Dashboard.
                        </p>
                    </div>
                    <div>
                        <p>
                            <strong>Note:</strong> Ensure that your Excel file follows the correct format. You can <a href="../public/ImportTemplate.xlsx" target="_blank" rel="noopener noreferrer">download a template here</a>.
                        </p>
                    </div>
                    <div>
                        <form>
                            <div className={Styles.file_input_wrapper}>
                                <input
                                    type="file"
                                    id="fileInput"
                                    className={Styles.file_input}
                                    onChange={this.handleFileChange}
                                    ref={(el) => (this.fileInputRef.current = el)}
                                />
                                <label htmlFor="fileInput" className={Styles.file_input_label}>
                                    Choose File
                                </label>
                                <span className={Styles.file_name}>
                                    {selectedFile ? selectedFile.name : "No file chosen"}
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={Styles.modal_buttons}>
                    <Button
                        // TODO: Use a bespoken button instead of a regular button?
                        // TODO: see if we can use the NormalModalComponent instead?
                        // TODO: add a yellow / red stripe to warnings and error modals
                        theme={bespokenButton}
                        onClick={this.handleCancel} >
                        Cancel
                    </Button>
                    <Button
                        theme={bespokenButton}
                        accent={true}
                        onClick={this.handleImportClick}
                        disabled={isImporting} // Disable the button when importing
                        >
                            {isImporting ? "Importing..." : "Import"} {/* Update button text based on loading state */}
                        </Button>
                </div>
            </BespokenModal>
        );
    }
}

export default ImportModal;
