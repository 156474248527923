import { Chart, DecimationOptions, LegendItem, registerables } from "chart.js";
import { isEqual } from "lodash";
import * as React from "react";
import { ShadowDom } from ".";
import { LineChartData } from "../../services/bespoken-reporting-api";
const LineChartStyle = require("./LineChartStyle.scss");

Chart.register(...registerables);

export interface LineChartProps {
    data: LineChartData;
}

export interface LineChartState {
}

export class LineChart extends React.Component<LineChartProps, LineChartState> {

    static defaultProps: LineChartProps = {
        data: {
            labels: ["empty"],
            datasets: [{
                data: [10],
                borderColor: "rgba(255, 0, 0, 0.5)"
            }]
        },
    }

    state: LineChartState;
    props: LineChartProps;

    chart: Chart;

    constructor(props: LineChartProps) {
        super(props);

        this.state = {};
    }

    setupChart(shadowRoot: ShadowRoot): void {
        const canvas = document.createElement('canvas');

        shadowRoot.appendChild(canvas);

        const data = this.props.data
        const ctx = canvas.getContext('2d');
        const decimation: DecimationOptions = {
            enabled: true,
            samples: 10,
            algorithm: 'lttb',
        };

        // reference:https://github.com/chartjs/Chart.js/blob/master/src/controllers/controller.polarArea.js
        this.chart = new Chart(ctx, {
            type: "line",
            data,
            plugins: [{
                id: "noDataLabel",
                afterDraw: function (chart) {
                    if (chart.data.datasets.length === 0) {
                        // No data is present
                        var ctx = chart.ctx;
                        var width = chart.width;
                        var height = chart.height
                        chart.clear();

                        ctx.save();
                        ctx.textAlign = "center";
                        ctx.textBaseline = "middle";
                        ctx.fillStyle = "#19496B"
                        ctx.font = "14px 'Open Sans', sans-serif";
                        ctx.fillText("No data available. Run some end-to-end tests to see your historical results here.", width / 2, height / 2);
                        ctx.restore();
                    }
                }
            }],
            options: {
                plugins: {
                    decimation,
                    legend: {
                        onClick(e, legendItem, legend) {
                            Chart.defaults.plugins.legend.onClick.apply(undefined, [e, legendItem, legend])
                            legend.chart.toggleDataVisibility(legendItem.datasetIndex);
                            legend.chart.update();
                        },
                        position: "right",
                        labels: {
                            padding: 15,
                            usePointStyle: true,
                            color: "#19496B",
                            font: {
                                family: "Open Sans"
                            },
                            boxWidth: 8,
                            generateLabels: (chart) => {
                                return chart.data.datasets
                                    .map(({ label: legendText, borderColor: baseColor }, index) => ({
                                        datasetIndex: index,
                                        text: legendText,
                                        pointStyle: "circle",
                                        strokeStyle: baseColor,
                                        fillStyle: baseColor,
                                        hidden: !chart.getDataVisibility(index),
                                    })) as LegendItem[];
                            }
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                        min: 0
                    },
                    xAxis: {
                        ticks: {
                            maxTicksLimit: 10
                        }
                    }
                }
            }
        });
    }

    componentDidUpdate(prevProps: Readonly<LineChartProps>, prevState: Readonly<LineChartState>, prevContext: any): void {
        if (!isEqual(this.props.data, prevProps.data)) {
            this.chart.data = this.props.data;
            this.chart.update();
        }
    }

    render() {
        return (
            <div>
                <ShadowDom builder={shadowRoot => this.setupChart(shadowRoot)} />
            </div>
        );
    }

}
