import * as React from "react";
import { connect } from "react-redux";
import { setCurrentPageProps } from "../../actions/context";
import Source from "../../components/source/source";
import { State } from "../../reducers";
import { CurrentPageProps } from "../../reducers/context";
import { InjectedRouter, Router } from "react-router";

const Styles = require("./styles.scss");

type PathParams = {
    sourceId?: string;
}
interface SourcePageProps {
    setCurrentPageProps: (value: CurrentPageProps) => any;
    router: InjectedRouter;
    params: PathParams;
    hasUnsavedChanges: boolean;
}

interface SourcePageState {
}

function mapStateToProps(state: State.All) {
    return {
        hasUnsavedChanges: state.sourceGit.hasUnsavedChanges,
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        setCurrentPageProps: (value: CurrentPageProps) => dispatch(setCurrentPageProps(value)),
    };
}

export class SourcePage extends React.Component<SourcePageProps, SourcePageState> {

    constructor(props: SourcePageProps) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.props.setCurrentPageProps(undefined);
        // @ts-ignore
        this.props.router.setRouteLeaveHook(this.props.route, () => {
            if (this.props.hasUnsavedChanges) return 'You have unsaved changes one or more of your tests. Are you sure you want to leave?'
        })
    }

    render() {
        return (
            <div className={Styles.container}>
                <Source sourceId={this.props?.params?.sourceId} />
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SourcePage);
