import * as Bluebird from "bluebird";
import {
    CREATE_SOURCE_SUCCESS,
    REMOVE_SOURCE,
    SET_CURRENT_SOURCE,
    SET_SOURCE_SEARCH_TEXT,
    SET_SOURCES,
} from "../constants";
import Source from "../models/source-legacy";

export type SetCurrentSource = {
    type: SET_CURRENT_SOURCE,
    source: Source
};
export type SetSourceSearchText = {
    type: SET_SOURCE_SEARCH_TEXT,
    searchText: string
};


export type RemoveSource = {
    type: REMOVE_SOURCE,
    source: Source
};

export function setCurrentSource(source: Source): SetCurrentSource {
    return {
        type: SET_CURRENT_SOURCE,
        source: source
    };
}

export function setSourceSearchText(searchText: string): SetSourceSearchText {
    return {
        type: SET_SOURCE_SEARCH_TEXT,
        searchText,
    };
}

export type SetSources = {
    type: SET_SOURCES,
    sources: Source[],
    finishLoading: boolean,
};

export function setSources(sources: Source[], finishLoading: boolean): SetSources {
    return {
        type: SET_SOURCES,
        sources: sources,
        finishLoading: finishLoading,
    };
}

export function removeSource(source: Source): RemoveSource {
    return {
        type: REMOVE_SOURCE,
        source: source
    };
}

export type CreateSourceSuccess = {
    type: CREATE_SOURCE_SUCCESS;
    source: Source;
};

export function createSourceSuccess(source: Source): CreateSourceSuccess {
    return {
        type: CREATE_SOURCE_SUCCESS,
        source: source
    };
}

