import * as React from "react";
import * as trialExpired from "../../../assets/images/trial_expired.png";
import SuperLlama from "../../../assets/super_llama.svg";
import { Button } from "../../components";

const Trial = require("./trialExpiredStyle.scss");

interface TrialExpiredProps {
  handleOpenPaymentDialog: any;
  goTo: any;
}

export default class TrialExpired extends React.Component<TrialExpiredProps, any> {

    handleSubscribe = () => {
        const {goTo} = this.props;
        goTo && goTo("/billing");
    }

    get mailTo() {
        return "contact@bespoken.io";
    }

    get subject() {
        return "Trial Period Extension";
    }

    get mailToSales() {
        return "sales@bespoken.io";
    }

    get subjectSales() {
        return "Bespoken subscription";
    }

    render() {
        return (
            <div className={Trial.trialContainer}>
                <h1>Hi There!</h1>
                <h3>Your trial period has ended</h3>
                <h6>Subscribe to one of our paid plans to continue <br/> testing your test suites</h6>
                <Button label="Subscribe" accent
                    href={`mailto:${this.mailToSales}?subject=${this.subjectSales}`}
                />
                <h6 data-id="help">Do you need more time to evaluate our tools?
                    <a href={`mailto:${this.mailTo}?subject=${this.subject}`}>
                        Get in touch!
                    </a>
                </h6>
                <SuperLlama/>
                <img src={trialExpired} alt="trial expired"/>
            </div>
        );
    }
}
