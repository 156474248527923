
import * as React from "react";
import * as cn from "classnames";
import { State } from "../../../reducers";
import ReactToolboxSnackbar from "react-toolbox/lib/snackbar";
import { isEqual, isNumber } from "lodash";
import { attemptInvoke } from "../../../utils/ReactHelpers";


const Styles = require("./styles.scss");

interface DispatchToProps { }
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {};
}

interface StateToProps { }
function mapStateToProps(state: State.All): StateToProps {
    return {};
}

interface ExposedProps {
    timeout?: number;
    show: boolean;
    message: string;
    onDissmised: () => void
}
function mergeProps(ownProps: any, stateProps: any, dispatchProps: ExposedProps) {
    return { ...ownProps, ...stateProps, ...dispatchProps }
}

interface ComponentProps extends DispatchToProps, StateToProps, ExposedProps { }

interface ComponentState {
    message: string;
    show: boolean;
    timeout: number;
}

export class Snackbar extends React.Component<ComponentProps, ComponentState> {
    currentTimeoutId: any;

    constructor(props: ComponentProps) {
        super(props);
        this.state = {
            show: false,
            message: "",
            timeout: 10000
        }
    }

    componentWillReceiveProps(nextProps: Readonly<ComponentProps>, nextContext: any): void {
        const newState: ComponentState = { ...this.state }

        if (isNumber(nextProps.timeout) && !isEqual(this.state.timeout, nextProps.timeout)) {
            newState.timeout = nextProps.timeout
        }

        if (!isEqual(this.state.show, nextProps.show)) {
            newState.show = nextProps.show
        }

        if (!isEqual(this.state.message, nextProps.message)) {
            newState.message = nextProps.message
        }

        if (isEqual(newState, this.state)) { return }

        this.setState(newState, () => { if (newState.show) { this.scheduleTimeout() } })
    }

    scheduleTimeout() {
        if (this.currentTimeoutId) clearTimeout(this.currentTimeoutId);

        this.currentTimeoutId = setTimeout(() => {
            attemptInvoke(this.props.onDissmised)
            this.currentTimeoutId = undefined;
        }, this.state.timeout);
    }

    render(): false | JSX.Element {
        return <ReactToolboxSnackbar
            className={Styles.sm_snackbar}
            action="Dismiss"
            type="cancel"
            active={this.state.show}
            label={this.state.message}
            onClick={() => this.setState({ show: false }, () => { clearTimeout(this.currentTimeoutId); attemptInvoke(this.props.onDissmised) })}
            onTimeout={() => this.setState({ show: false }, () => attemptInvoke(this.props.onDissmised))}
            timeout={this.state.timeout}
        />
    }
}
