import * as React from "react";
import Button from "react-toolbox/lib/button";
import { attemptInvoke } from "../../utils/ReactHelpers";
import { BespokenModal } from "./BespokenModal";

const Styles = require("./warning-modal.scss");
type Item = any
type Props = {
    showModal: boolean;
    item: Item;
    onOkDialog: (item: Item) => void;
    onDismissDialog: () => void;
    onFinally?: () => void;
    children: React.ReactNode;
    title?: string;
    disableOkButton?: boolean;
    disableCancelButton?: boolean;
}

const WarningModalComponent = (props: Props) => {
    const { children, onOkDialog, item, showModal, onDismissDialog, title = "Warning" } = props

    return <BespokenModal
        title={title}
        showModal={showModal}
        dialogToggle={() => attemptInvoke(onDismissDialog)}
    >
        <div className={Styles.container}>
            <div>
                {children}
            </div>
            <div>
                <Button
                    theme={require("../../themes/bespoken_button.scss")}
                    onClick={() => attemptInvoke(onDismissDialog)}
                >
                    Cancel
                </Button>
                <Button
                    theme={require("../../themes/bespoken_button.scss")}
                    accent={true}
                    onClick={() => attemptInvoke(onOkDialog, item)}
                >
                    OK
                </Button>
            </div>
        </div>
    </BespokenModal >
}


const NormalModalComponent = (props: Props) => {
    const { children, onFinally, onOkDialog, item, showModal, onDismissDialog, title = "Warning", disableOkButton, disableCancelButton } = props

    return <BespokenModal
        title={title}
        showModal={showModal}
        dialogToggle={() => {
            attemptInvoke(onDismissDialog)
            attemptInvoke(onFinally)
        }}
    >
        <div className={Styles.container}>
            <div>
                {children}
            </div>
            <div>
                <Button
                    disabled={disableCancelButton}
                    theme={require("../../themes/bespoken_button.scss")}
                    onClick={() => {
                        attemptInvoke(onDismissDialog)
                        attemptInvoke(onFinally)
                    }}
                >
                    Cancel
                </Button>
                <Button
                    disabled={disableOkButton}
                    theme={require("../../themes/bespoken_button.scss")}
                    accent={true}
                    onClick={() => {
                        attemptInvoke(onOkDialog, item)
                        attemptInvoke(onFinally)
                    }}
                >
                    OK
                </Button>
            </div>
        </div>
    </BespokenModal >
}

export const WarningModal = WarningModalComponent
export const NormalModal = NormalModalComponent