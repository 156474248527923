import { chain, intersection, isFunction, size, uniqueId } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import DropdownToolbox from "react-toolbox/lib/dropdown";
import { setLoading } from "../../actions/loading";
import { switchOrganization } from "../../actions/organization";
import { State } from "../../reducers";
import { wrapCallbackAsAsync } from "../../utils/ReactHelpers";
import sleep from "../../utils/sleep";

const baseTheme = require("./baseTheme.scss");

type Item = { label: string; value: string; additional?: any; }

interface DispatchToProps {
    setLoading: (value: boolean) => any;
    onChangeOrganization: (item: Item) => Promise<void>
}

function mapDispatchToProps(dispatch: any) {
    return {
        setLoading: (value: boolean) => dispatch(setLoading(value)),
        onChangeOrganization: async (selected: Item) => wrapCallbackAsAsync(handle => dispatch(switchOrganization(selected.value, handle)))
    };
}

interface StateToProps {
    organizations: Item[];
    selectedValue: string;
}

function mapStateToProps(state: State.All) {
    const organizations = chain(state?.organization?.organizations).map(({ name: label, id: value }) => ({ value, label })).value()
    const selectedValue = state?.organization?.selectedOrganization?.id

    return {
        organizations,
        selectedValue
    };
}

interface OrganizationSwitcherProps extends DispatchToProps, StateToProps {
    disabled?: boolean;
}

interface OrganizationSwitcherState {
    items: Item[];
    selectedValue: string;
    disabled: boolean;
}

class OrganizationSwitcherComponent extends React.Component<OrganizationSwitcherProps, OrganizationSwitcherState> {

    static defaultProps: OrganizationSwitcherProps = {
        organizations: [],
        selectedValue: undefined,
        disabled: false,
        onChangeOrganization: async () => { },
        setLoading: () => { }
    }

    constructor(props: OrganizationSwitcherProps) {
        super(props);
        this.state = {
            items: [],
            selectedValue: undefined,
            disabled: false
        }
    }

    componentWillReceiveProps(nextProps: Readonly<OrganizationSwitcherProps>, nextContext: any): void {
        const organizations = chain(this.props.organizations).map(({ value }) => value).value()
        const nextOrganizations = chain(nextProps.organizations).map(({ value }) => value).value()

        if (intersection(organizations, nextOrganizations).length !== size(nextOrganizations)) {
            const { organizations: items, selectedValue } = nextProps
            this.setState({ items, selectedValue })
        }
    }

    componentDidMount(): void {
        const { organizations: items, selectedValue } = this.props
        this.setState({ items, selectedValue })
    }

    render(): false | JSX.Element {
        const { disabled } = this.props
        const { items, selectedValue } = this.state

        // Using random id to force close popup when value changed
        const uniqId = uniqueId()
        return (
            <DropdownToolbox
                key={`organization-switcher-${uniqId}`}
                theme={baseTheme}
                disabled={disabled}
                // className={className}
                onChange={(val: any) => this.handleOnChange(val)}
                source={items}
                value={selectedValue} />
        )
    }

    handleOnChange(selectedValue: any) {
        this.props.setLoading(true)
        this.setState({ selectedValue }, () => this.emitOnchangeOrganization()
            .then(() => sleep(500))
            .then(() => this.props.setLoading(false))
            .catch(() => this.props.setLoading(false))
        )
    }

    async emitOnchangeOrganization(): Promise<void> {
        if (!isFunction(this.props?.onChangeOrganization)) {
            console.warn('onChangeOrganization is not a function')
            return
        }

        const selectedItem: Item = chain(this.state.items).find(({ value }) => value === this.state.selectedValue).clone().value()
        return this.props.onChangeOrganization(selectedItem)
    }
}

export const OrganizationSwitcher = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganizationSwitcherComponent);
