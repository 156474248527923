import * as React from "react";
import * as cn from "classnames";

const LoaderIndicatorStyle = require("./LoaderIndicatorStyle.scss");

export interface LoaderIndicatorProps {
  className?: string;
}

export interface LoaderIndicatorState {
}

export class LoaderIndicator extends React.Component<LoaderIndicatorProps, LoaderIndicatorState> {

  static defaultProps: LoaderIndicatorProps = {}

  state: LoaderIndicatorState;
  props: LoaderIndicatorProps;

  constructor(props: LoaderIndicatorProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className={cn('LoaderIndicator', this.props.className)}>
        <div className={LoaderIndicatorStyle.lds_ring}>
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }

}
