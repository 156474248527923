import * as React from "react";
import * as cn from "classnames";
import { join, assign } from "lodash";
import { PlatformDropdown, PrimaryButtonMedium } from "../../components/lunacy";
import { PropertyDropdown } from "../../components/lunacy/dropdowns/PropertyDropdown";



const Styles = require("./ComponentsShowcasePage.module.scss");

type ComponentSection = {
    name?: string;
    tags?: string[];
    description?: string;
    usage?: string | string[];
    children?: JSX.Element;
    instanceId: string;
}
const ComponentSection = (props: ComponentSection) => {
    return <div
        className={cn('component', 'ControlSection', Styles.ControlSection)}
    >
        <h4>{props?.name}</h4>
        <span>{join(props?.tags, ' ')}</span>
        <div>{props?.children}</div>
    </div>
}

type ControllerSection = {
    children?: JSX.Element;
}
const ControllerSection = (props: any) => {
    return <div className={cn('component', 'ControllerSection', Styles.ControllerSection)}>
        {props?.children}
    </div>
}

interface PageProps { }

interface PageState {
    [key: string]: any
}

export class ComponentsShowcasePage extends React.Component<PageProps, PageState> {
    constructor(props: PageProps) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className={cn(Styles.container)}>
                <h1 className={Styles.title}>All Components</h1>

                <ControllerSection />
                <ComponentSection
                    name="Platform Dropdown"
                    tags={["dropdown"]}
                    instanceId="">
                    <PlatformDropdown selectedValue={undefined} onValueChanged={console.log} />
                </ComponentSection>

                <ControllerSection >
                    <label>Platform</label>
                    <PlatformDropdown
                        selectedValue={undefined}
                        onValueChanged={selected => this.setState({ ComponentSection: { ...this.state.ComponentSection, platform: selected?.value } })}
                    />
                    <PrimaryButtonMedium
                        onClick={() => {
                            this.setState({ ComponentSection: { ...this.state.ComponentSection, customValue: "UnKnown", value: "UnKnown" } })
                        }}>
                        Set UnKnown property
                    </PrimaryButtonMedium>
                    <PrimaryButtonMedium
                        onClick={() => {
                            this.setState({ ComponentSection: { ...this.state.ComponentSection, customValue: "prompt", value: "prompt" } })
                        }}>
                        Set prompt property
                    </PrimaryButtonMedium>
                    <span>{JSON.stringify(this.state.ComponentSection)}</span>
                </ControllerSection>
                <ComponentSection
                    name="PropertyDropdown"
                    tags={["dropdown", "property"]}
                    description="JSONPath Property/Shortand property used to test assertion."
                    instanceId="PropetyDropdown"
                >
                    <PropertyDropdown
                        customValue={this.state?.ComponentSection?.customValue}
                        value={this.state?.ComponentSection?.value}
                        {...this.state?.ComponentSection}
                        onChange={o => this.setState({ ComponentSection: { ...this.state.ComponentSection, value: o?.value } })}
                    />
                </ComponentSection>

            </div>
        )
    }
}
