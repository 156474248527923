import * as cn from "classnames";
import { capitalize, chain, get, includes, isEmpty, uniqueId, upperCase } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import DropdownToolbox from "react-toolbox/lib/dropdown";
import { State } from "../../../reducers";
import { attemptInvoke, isEqualByPropertiesLabelValue } from "../../../utils/ReactHelpers";

const baseTheme = require("./baseTheme.scss");

const CSS_CLASSES: { [key: string]: any[] } = {
    'small': [baseTheme.template, baseTheme.small],
    'normal': []
}

type Item = {
    label: string;
    value: string;
}

interface DispatchToProps { }
function mapDispatchToProps(dispatch: any) { return {} }

interface StateToProps {
    items: Item[];
}
function mapStateToProps(state: State.All) {
    const formatPlatformName = (val: string) => includes(['sms'], val) ? upperCase(val) : capitalize(val)
    const items = chain(state?.organization?.selectedOrganization?.subscription?.platforms)
        .uniq()
        .sort()
        .map((value: string) => ({ value, label: formatPlatformName(value) }))
        .value()

    return {
        items
    };
}

interface ExposedProps {
    onValueChanged: (token: Item) => void;
    selectedValue: string;
    size?: 'small' | 'normal';
}
function mergeProps(ownProps: any, stateProps: any, dispatchProps: ExposedProps) {
    return { ...ownProps, ...stateProps, ...dispatchProps }
}

interface ComponentProps extends DispatchToProps, StateToProps, ExposedProps {
    disabled?: boolean;
}

interface ComponentState {
    items: Item[];
    selectedValue: string;
    disabled: boolean;
}

class PlatformDropdownComponent extends React.Component<ComponentProps, ComponentState> {

    static defaultProps: ComponentProps = {
        items: [],
        disabled: false,
        selectedValue: undefined,
        onValueChanged: () => { },
        size: 'normal'
    }

    constructor(props: ComponentProps) {
        super(props);
        this.state = {
            items: [],
            selectedValue: undefined,
            disabled: false,
        }
    }

    componentDidMount(): void {
        const { items, selectedValue: initialValue } = this.props

        const selectedValue = isEmpty(initialValue) ? items[0]?.value : initialValue

        this.setState({ items, selectedValue })
    }

    componentWillReceiveProps(nextProps: Readonly<ComponentProps>, nextContext: any): void {
        if (nextProps.selectedValue !== this.props.selectedValue) {
            this.setState({ selectedValue: nextProps.selectedValue })
        }

        if (!isEqualByPropertiesLabelValue(nextProps.items, this.props.items)) {
            const selectedValue = chain(nextProps.items)
                .filter(({ value }) => value === this.props.selectedValue)
                .thru(items => get(items, '0.value') && get(nextProps, 'items.0.value'))
                .value()

            const items = chain(nextProps.items)
                .value()

            this.setState({ items, selectedValue })
        }
    }

    render(): false | JSX.Element {
        const { disabled, size } = this.props
        const { items, selectedValue } = this.state

        // Using random id to force close popup when value changed
        const uniqId = uniqueId()
        return (
            <DropdownToolbox
                key={`virtual-device-dropdown-${uniqId}`}
                theme={baseTheme}
                disabled={disabled}
                className={cn(CSS_CLASSES[size])}
                onChange={(value: any) => {
                    const selectedItem: Item = chain(this.state.items).find(it => it.value === value).clone().value()
                    this.setState({ selectedValue: value }, () => attemptInvoke(this.props.onValueChanged, selectedItem))
                }}
                source={items}
                value={selectedValue} />
        )
    }
}

export const PlatformDropdown = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(PlatformDropdownComponent);
