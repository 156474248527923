import * as React from "react";
import * as cs from "classnames"
import { isUndefined, isNull } from "lodash"
const Styles = require("./KPICardStyle.scss");

export interface KPICardProps {
    label: string;
    icon?: JSX.Element;
    data?: number;
    suffix?: string;
    className?: string;
}

export interface KPICardState {
}

export class KPICard extends React.Component<KPICardProps, KPICardState> {

    static defaultProps: KPICardProps = {
        label: "empty-label",
        icon: undefined,
        data: undefined,
    }

    state: KPICardState;
    props: KPICardProps;

    constructor(props: KPICardProps) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div className={cs(Styles.layout, this.props.className)}>
                <div className={Styles.icon}>
                    {this.props.icon}
                </div>
                <div className={[Styles.value].join(" ")}>
                    <span>{(isNull(this.props.data) || isUndefined(this.props.data)) ? <span>&mdash;</span> : `${this.props.data}${this.props.suffix || ""}`}</span>
                </div>
                <div className={[Styles.title].join(" ")}>
                    <span>{this.props.label}</span>
                </div>
            </div>
        );
    }

}
