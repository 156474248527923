import * as cn from "classnames";
import * as React from "react";
import { Button as ButtonToolbox } from "react-toolbox/lib/button";

const buttonStyles = require("./buttonStyles.scss");
const theme = require("./buttonTheme.scss");

export interface ButtonProps {
  label?: string;
  disabled?: boolean;
  onClick?: any;
  className?: string;
  style?: any;
  accent?: boolean;
  secondary?: boolean;
  icon?: any;
  iconToRight?: boolean;
  href?: string;
}

export default class Button extends React.Component<ButtonProps, any> {

  handleOnClick = () => {
    const { onClick } = this.props;
    onClick && onClick();
  }

  render({ label, className, style, disabled, accent, secondary, icon, iconToRight, href, ...rest } = this.props) {
      const _className = cn(className, { [buttonStyles.secondary]: secondary, [buttonStyles.iconRight]: iconToRight });

    return (
      <ButtonToolbox
        data-id="bespoken-button"
        style={style}
        label={label}
        theme={theme}
        accent={accent}
        disabled={disabled}
        className={_className}
        icon={icon}
        onClick={this.handleOnClick}
        href={href}
        {...rest}
      />
    );
  }

}
