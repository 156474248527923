import * as cn from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { Button } from "react-toolbox/lib/button";
import { BespokenModal } from "../Modal/BespokenModal";
import { fetchInternalApi } from "../../services/internal-api";
import { TextErrorMedium, TextErrorSmall, TextNormal, TextBlueLabelSmall } from "../lunacy";
import { InputWithLabel } from "../lunacy/inputs/InputWithLabel";
import TrialExpired from "../TrialExpired/TrialExpired";
import cronValidator from 'cron-validate'

const Styles = require("./monitoring-modal.scss");
const bespokenButton = require("../../themes/bespoken_button.scss");

export const SCHEDULE_TYPES = [
    { label: 'Currently executing every 30 minutes', key: '30m', cron: '*/30 * * * *', trialEnabled: false },
    { label: 'Currently executing every 1 hour', key: '1h', cron: '0 * * * *', trialEnabled: false },
    { label: 'Currently executing every 6 hours', key: '6h', cron: '0 */6 * * *', trialEnabled: true },
    { label: 'Currently executing every 12 hours', key: '12h', cron: '0 */12 * * *', trialEnabled: true },
    { label: 'Currently executing every 1 day', key: '1d', cron: '0 0 * * *', trialEnabled: true },
    { label: 'Currently executing every 7 days', key: '1w', cron: '0 0 * * 0', trialEnabled: true }
]

export interface TestDetailsTableProps {
    canEditSchedule: boolean;
    selectedScheduleType: string;
    showModal: boolean;
    onCancel?: () => Promise<void>;
    onSuccess?: (cron: string, emailsToNotify: string) => Promise<void>;
}

export interface TestDetailsTableState {
    createDescription: string;
    showModal: boolean;
    readonlyMode: boolean;
    selectedScheduleType: string;
    scheduleOptions: { value: string, label: string, cron: string, trialEnabled: boolean }[];
    cron: string;
    cronExplained: string;
    emailsToNotify: string;
    error?: string;
}

export class MonitoringModal extends React.Component<TestDetailsTableProps, TestDetailsTableState> {

    state: TestDetailsTableState;
    props: TestDetailsTableProps;

    constructor(props: TestDetailsTableProps) {
        super(props);

        this.state = {
            showModal: false,
            createDescription: '.....',
            readonlyMode: false,
            selectedScheduleType: SCHEDULE_TYPES[1].key,
            scheduleOptions: SCHEDULE_TYPES.map(({ key, cron, trialEnabled }) => ({ value: key, label: key, cron, trialEnabled })),
            cron: '',
            cronExplained: '',
            emailsToNotify: '',
            error: undefined
        };
    }

    componentWillReceiveProps(nextProps: Readonly<TestDetailsTableProps>, nextContext: any): void {
        if (!isEqual(nextProps, this.props)) {
            const { showModal } = nextProps
            let { selectedScheduleType } = nextProps

            if (!selectedScheduleType) {
                selectedScheduleType = '1d'
            }

            this.setState(prevState => ({ ...prevState, showModal, selectedScheduleType }))
        }
    }

    render() {
        const { showModal, readonlyMode, scheduleOptions, selectedScheduleType } = this.state
        return (<BespokenModal
            title={"Monitoring"}
            showModal={showModal}
            dialogToggle={async () => !readonlyMode && this.handleCancelModal()} >
            <div className={Styles.modal_body}>
                <div style={{ display: this.state.error ? 'block' : 'none', textAlign: 'center' }}>
                    <TextErrorSmall>{this.state.error}</TextErrorSmall>
                </div>
                <div>
                    <TextNormal>By enabling monitoring, your tests will run automatically at the selected interval below. Results will be visible at the History tab.</TextNormal>
                </div>
                <div className={Styles.grouping}>
                    <div >
                        <TextBlueLabelSmall>Select an interval:</TextBlueLabelSmall>
                    </div>
                    <div className={Styles.schedule_control}>
                        {
                            scheduleOptions
                                // .map((r, i) => ({ ...r, selectedClass: r.value === selectedScheduleType ? Styles.selected_option : undefined }))
                                .map(({ value, label, cron, trialEnabled }) => <div key={`schedule-type-${value}`}
                                    className={cn(Styles.schedule_option, this.props.canEditSchedule || trialEnabled ? 'hello' : Styles.schedule_option_disabled)}
                                    onClick={evt => this.props.canEditSchedule || trialEnabled ? this.onSelectedSchedule(cron) : undefined}>{label}</div>)
                        }
                    </div>
                </div>
                <div>
                    <InputWithLabel value={this.state.cron}
                        label={<TextBlueLabelSmall>CRON Schedule (expressed in UTC) - <a href="https://docs.gitlab.com/ee/topics/cron/">Learn More</a>:</TextBlueLabelSmall>}
                        onChange={(cron: string) => this.setState({ cron })}
                        onBlur={() => this.onSelectedSchedule(this.state.cron)}
                        disabled={!this.props.canEditSchedule}
                    />
                </div>
                <div>
                    <InputWithLabel
                        label={<TextBlueLabelSmall>CRON Schedule (In Plain English):</TextBlueLabelSmall>}
                        value={this.state.cronExplained}
                        disabled={true}/>
                </div>
                <div>
                    <InputWithLabel value={this.state.emailsToNotify}
                        label={<TextBlueLabelSmall>Emails to notify (comma separated):</TextBlueLabelSmall>}
                        onChange={(value) => this.setState(prevState => ({ ...prevState, emailsToNotify: value }))}
                    />
                </div>
            </div>
            <div className={Styles.modal_buttons}>
                <Button
                    disabled={readonlyMode}
                    data-id="virtual-device-add-modal-cancel-action"
                    theme={bespokenButton}
                    onClick={async () => this.handleCancelModal()}
                >
                    Cancel
                </Button>
                <Button
                    disabled={readonlyMode}
                    data-id="virtual-device-add-modal-ok-action"
                    theme={bespokenButton}
                    accent={true}
                    onClick={() => this.handleSaveModal()}
                >
                    OK
                </Button>
            </div>

        </BespokenModal >
        );
    }

    async onSelectedSchedule(cron: string) {
        if (!cron) {
            throw new Error(`Error cron cannot be null`)
        }

        const cronResult = cronValidator(cron)
        if (!cronResult.isValid()) {
            this.setState(prevState => ({ ...prevState, error: `CRON expression error: \n${cronResult.getError()[0]}` }))
            return
        }

        const cronExplained = await this.explainCron(cron)
        if (cronExplained.error) {
            this.setState(prevState => ({ ...prevState, error: cronExplained.error }))
        } else {
            this.setState(prevState => ({ ...prevState, cron, cronExplained: cronExplained.expression }))
        }
    }

    async handleSaveModal() {
        const { showModal: currentShowModal } = this.state
        if (this.props.onSuccess) {

            if (this.state.cron.trim().length === 0) {
                this.setState(prevState => ({ ...prevState, error: 'Please, enter a CRON expression first' }))
                return
            }

            const cronResult = cronValidator(this.state.cron)
            if (!cronResult.isValid()) {
                this.setState(prevState => ({ ...prevState, error: `CRON expression error:\n${cronResult.getError()[0]}` }))
                return
            }

            const cronExplained = await this.explainCron(this.state.cron)
            if (cronExplained.hasError()) {
                this.setState(prevState => ({ ...prevState, error: cronExplained.error }))
                return
            }

            if (this.state.emailsToNotify.trim().length === 0) {
                this.setState(prevState => ({ ...prevState, error: 'At least one email address must be provided' }))
                return
            }

            const invalidEmails = this.validateEmails(this.state.emailsToNotify)
            if(invalidEmails.length){
                this.setState(prevState => ({ ...prevState, error: `The following email(s) are not valid: ${invalidEmails.join(",")}`  }))
                return
            }

            await new Promise(resolve => this.setState(prevState => ({ ...prevState, readonlyMode: true }), () => resolve(undefined)))

            const { cron, emailsToNotify } = this.state
            this.setState(prevState => ({ ...prevState, error: undefined }))
            await this.props.onSuccess(cron, emailsToNotify).catch(err => console.error(err))
        }
        const showModal = !currentShowModal
        await new Promise(resolve => this.setState(prevState => ({ ...prevState, showModal }), () => resolve(undefined)))
        await new Promise(resolve => setTimeout(resolve, 500))
        this.setState(prevState => ({ ...prevState, readonlyMode: false }))
        this.setState(prevState => ({ ...prevState, error: undefined }))
    }

    async handleCancelModal() {
        const { showModal: currentShowModal } = this.state
        if (this.props.onCancel) {
            await new Promise(resolve => this.setState(prevState => ({ ...prevState, readonlyMode: true }), () => resolve(undefined)))
            await this.props.onCancel()
            await new Promise(resolve => this.setState(prevState => ({ ...prevState, readonlyMode: false }), () => resolve(undefined)))
        }
        const showModal = !currentShowModal
        this.setState(prevState => ({ ...prevState, showModal }))
        this.setState(prevState => ({ ...prevState, error: undefined }))
    }

    async explainCron(cron: string): Promise<CRONResult> {
        let cronExplained
        try {
            cronExplained = await fetchInternalApi(
                `/helpers/cronToPlainEnglish/${encodeURIComponent(cron)}`,
                "GET",
                undefined,
                { "Content-Type": "application/json" },
                false);
            return new CRONResult(cronExplained)
        } catch (e) {
            if (e.toString().startsWith('Invalid')) {
                return new CRONResult(undefined, 'Invalid CRON expression. Requires changes to enable monitoring.')
            } else {
                return new CRONResult(undefined, e.toString())
            }
        }
    }


    validateEmails(emails: string): string[] {
        // Regular expression to validate email addresses
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

        // Split the email list into an array of individual emails
        const emailList = emails.split(',')

        // Array to store invalid emails
        const invalidEmails: string[] = []

        // Function to validate each email
        emailList.forEach((email: string) => {
            const trimmedEmail: string = email.trim() // Remove any leading/trailing whitespace

            // Using regex for basic validation
            if (!emailRegex.test(trimmedEmail))
              invalidEmails.push(trimmedEmail)
          })
        return invalidEmails
    }
}

class CRONResult {
    constructor(public expression: string, public error?: string) { }

    hasError(): boolean {
        return this.error !== undefined
    }
}
