import { defaultTo } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import Snackbar from "react-toolbox/lib/snackbar";
import { logout, setCurrentPageProps } from "../actions/context";
import { setLoading } from "../actions/loading";
import { updateUser } from "../actions/user";
// import { setCurrentPageProps, updateUser } from "../actions/session";
import { BespokenModal } from "../components/Modal/BespokenModal";
import { Account } from "../components/my-account/account";
import { DeleteAccount } from "../components/MyAccount/DeleteAccountV2";
import { State } from "../reducers";
import { SelectedOrganization } from "../reducers/organization";
import { CurrentPageProps } from "../reducers/session";
import { User } from "../reducers/user";
import { shutdownIntercom, updateIntercom } from "../services/intercom";
import { wrapCallbackAsAsync } from "../utils/ReactHelpers";

const AccountStyle = require("../components/my-account/account-style.scss");

const globalWindow: any = typeof (window) !== "undefined" ? window : {};

interface MyAccountPageProps {
    user: User;
    currentOrganization: SelectedOrganization;

    setLoading: (value: boolean) => any;
    goTo: (path: string) => (dispatch: any) => void;
    doTheUserHaveAPassword?: () => boolean;
    logout: (email: string) => Promise<void>;
    updateUser: (user: User) => Promise<any>;
    setCurrentPageProps: (value: CurrentPageProps) => any;
}

interface MyAccountPageState {
    changeDeleteAccountDialogActive: boolean;
    showSnackbar: boolean;
    snackbarMessage: string;
}

function mapStateToProps(state: State.All) {
    return {
        user: state.user.currentUser,
        currentOrganization: defaultTo(state?.organization?.selectedOrganization, {})
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        setLoading: function (value: boolean) {
            return dispatch(setLoading(value));
        },
        goTo: (path: string) => {
            dispatch(replace(path));
            globalWindow && updateIntercom(globalWindow);
        },
        logout: (email: string) => {
            globalWindow && shutdownIntercom(globalWindow, email);
            return dispatch(logout());
        },
        updateUser: (user: User) => wrapCallbackAsAsync(handle => dispatch(updateUser(user, handle))),
        setCurrentPageProps: (value: CurrentPageProps) => dispatch(setCurrentPageProps(value))
    };
}


export class MyAccountPage extends React.Component<MyAccountPageProps, MyAccountPageState> {
    constructor(props: MyAccountPageProps) {
        super(props);

        this.state = {
            changeDeleteAccountDialogActive: false,
            showSnackbar: false,
            snackbarMessage: "",
        };
    }

    componentDidMount() {
        this.props.setCurrentPageProps({ title: "My Account", subTitle: "" });
    }

    disablingDialog = async () => {
        this.setState((prevState) => ({
            ...prevState,
            changePasswordDialogActive: false,
            changeDeleteAccountDialogActive: false,
        }));
    }

    handleDeleteAccountDialogToggle = async () => {
        this.setState((prevState) => ({
            ...prevState,
            changeDeleteAccountDialogActive: !this.state.changeDeleteAccountDialogActive,
        }));
    }

    activePasswordDialog = async () => {
        this.setState((prevState) => ({
            ...prevState,
            changePasswordDialogActive: true,
        }));
    }

    changeSnackMessage = (snackbarMessage: string) => {
        this.setState((prevState) => ({
            ...prevState,
            showSnackbar: true,
            snackbarMessage
        }));
    }

    handleSnackbarClick = async () => {
        this.setState({ ...this.state, showSnackbar: false });
    }

    render() {
        const { user } = this.props;
        const userId = user?.id;
        return (
            <div className={`${AccountStyle.mainContainer} ${AccountStyle.v2}`}>
                <Account
                    user={user}
                    updateUser={this.props.updateUser}
                    setLoading={this.props.setLoading}
                    changeSnackMessage={this.changeSnackMessage}
                    handleDeleteAccountDialogToggle={this.handleDeleteAccountDialogToggle}
                    currentOrganization={this.props.currentOrganization}
                />
                <BespokenModal
                    title={"Warning"}
                    redTitleBorder={true}
                    showModal={this.state.changeDeleteAccountDialogActive}
                    dialogToggle={this.disablingDialog} >
                    <DeleteAccount
                        setLoading={this.props.setLoading}
                        logout={() => this.props.logout(this.props.user?.email)}
                        handleCancelClick={this.handleDeleteAccountDialogToggle}
                        userId={userId}
                        globalWindow={globalWindow} />
                </BespokenModal>
                <Snackbar
                    className="sm-snackbar"
                    action="Dismiss"
                    type="cancel"
                    timeout={10000}
                    active={this.state.showSnackbar}
                    label={this.state.snackbarMessage}
                    onClick={this.handleSnackbarClick}
                    onTimeout={this.handleSnackbarClick}
                />
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyAccountPage);
