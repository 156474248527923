// Keys are taken from ../bespoken-colors.scss
export type BespokenColors =
    "color_cadet_grey" |
    "color_pastel_blue" |
    "color_dark_cerulean" |
    "color_lavender_gray" |
    "color_congo_pink" |
    "color_yale_blue" |
    "color_white" |
    "color_spanish_gray" |
    "color_medium_aquamarine" |
    "color_sea_serpent"


export const defaultIconColor: BespokenColors = "color_yale_blue";