import { format, formatDuration, intervalToDuration } from "date-fns";
import { chain, isDate, isEmpty, snakeCase } from "lodash";
import * as React from "react";
import * as cn from "classnames";

const Styles = require("./styles.scss");

type ExposedComponentProps = { children: React.ReactNode; }
type ComponentProps = { className: any; }

const Text = (props: ComponentProps & ExposedComponentProps) => {
    const { children, className } = props
    return (<span className={className}>{children}</span>);
}


export const TextTitleLight = (props: ExposedComponentProps) => (<Text {...props} className={cn("TextTitleLight", Styles.text_title_light)} />)
export const TextTitleStrong = (props: ExposedComponentProps) => (<Text {...props} className={cn("TextTitleStrong", Styles.text_title_strong)} />)

export const TextBlueLabelSmall = (props: ExposedComponentProps) => (<Text {...props} className={cn("TextBlueLabelSmall", Styles.text_blue_label_small)} />)
export const TextBlueLabel = (props: ExposedComponentProps) => (<Text {...props} className={cn("TextBlueLabel", Styles.text_blue_label)} />)
export const TextBlueLabelMedium = (props: ExposedComponentProps) => (<Text {...props} className={cn("TextBlueLabelMedium", Styles.text_blue_label_medium)} />)
export const TextBlueStrongLabelSmall = (props: ExposedComponentProps) => (<Text {...props} className={cn("TextBlueStrongLabelSmall", Styles.text_blue_strong_label_small)} />)
export const TextBlueStrongLabelMedium = (props: ExposedComponentProps) => (<Text {...props} className={cn("TextBlueStrongLabelMedium", Styles.text_blue_strong_label_medium)} />)

export const TextNormal = (props: ExposedComponentProps) => (<Text {...props} className={cn("TextNormal", Styles.text_normal)} />)
export const LabelNormal = (props: ExposedComponentProps) => (<Text {...props} className={cn("LabelNormal", Styles.label_normal)} />)
export const TextTableHeader = (props: ExposedComponentProps) => (<Text {...props} className={cn("TextTableHeader", Styles.text_table_header)} />)

export const TextNormalDark = (props: ExposedComponentProps) => (<Text {...props} className={cn("TextNormalDark", Styles.text_normal_dark)} />)

export const TextWarning = (props: ExposedComponentProps) => (<Text {...props} className={cn("TextWarning", Styles.text_warning)} />)
export const TextSuccess = (props: ExposedComponentProps) => (<Text {...props} className={cn("TextSuccess", Styles.text_success)} />)

export const TextErrorSmall = (props: ExposedComponentProps) => (<Text {...props} className={cn("TextErrorSmall", Styles.text_error_small)} />)
export const TextErrorMedium = (props: ExposedComponentProps) => (<Text {...props} className={cn("TextErrorMedium", Styles.text_error_medium)} />)
export const TextErrorLarge = (props: ExposedComponentProps) => (<Text {...props} className={cn("TextErrorLarge", Styles.text_error_large)} />)


type DateTextProps = {
    value: Date;
    formatType?: "MM/DD/YY" | "Elapsed";
    defaultValue?: string;
}
export const DateText = (props: DateTextProps) => {
    const { value, formatType = "MM/DD/YY", defaultValue = "" } = props

    if (formatType === "Elapsed") {
        return <span>{
            chain([value])
                .filter(it => isDate(it))
                .map(start => {
                    try {
                        return formatDuration(intervalToDuration({ start, end: new Date() }), { delimiter: "|" }).split("|")[0];
                    } catch (error) {
                        console.error(`Error parsing duration in <DateText/> value="${value}"`, error)
                        return undefined;
                    }
                })
                .map(it => `${it} ago`)
                .first()
                .defaultTo(defaultValue)
                .value()
        }</span>
    }

    if (formatType === "MM/DD/YY") {
        return <span>{
            chain([value])
                .filter(it => isDate(it))
                .map(it => {
                    try {
                        return format(it, 'MM/dd/yy')
                    } catch (error) {
                        console.error(`Error parsing date into <DateText/> value="${it}"`, error)
                        return undefined;
                    }
                })
                .first()
                .defaultTo(defaultValue)
                .value()
        }</span>
    }

    return <span>{value?.toISOString()}</span>
}

export const HorizontalLine = () => (<div className={Styles.horizontal_line} />)
