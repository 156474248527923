import { authFormError } from "../actions/auth-form";
import { displaySnackbar } from "../actions/notification";
import {
  SENDING_REQUEST,
  SET_AMAZON_FLOW,
  SET_BESPOKEN_USER,
  SET_BESPOKEN_USER_VIRTUAL_DEVICES,
  SET_CURRENT_PAGE_PROPS,
  SET_EMAIL_SENT,
  SET_LATEST_ORGANIZATION_ID,
  SET_USER,
  SET_USER_DETAILS
} from "../constants";
import BespokenUser from "../models/bespoken-user";
import User, { UserDetails } from "../models/user";
import { CurrentPageProps } from "../reducers/session";

import auth from "../services/auth";
import BespokenApi from "../services/bespoken-api";

export function sendingRequest(sending: boolean) {
  return {
    type: SENDING_REQUEST,
    sending: sending
  };
}

export type SetUser = {
  type: SET_USER,
  user: User | undefined
};

export type SetUserDetails = {
  type: SET_USER_DETAILS,
  userDetails: UserDetails | undefined
};

export type SetBespokenUser = {
  type: SET_BESPOKEN_USER,
  bespokenUser: BespokenUser
};

export type SetBespokenUserVirtualDevices = {
  type: SET_BESPOKEN_USER_VIRTUAL_DEVICES,
  virtualDevices: any
};

export type AmazonFlowFlag = {
  type: SET_AMAZON_FLOW,
  amazonFlow: boolean
};

export type SendEmailFlag = {
  type: SET_EMAIL_SENT,
  sendEmail: boolean
};

export type SetCurrentPageProps = {
  type: SET_CURRENT_PAGE_PROPS,
  currentPageProps: CurrentPageProps
};

export function setUser(user: User | undefined): any {
  return {
    type: SET_USER,
    user: user
  };
}

export function setUserDetails(userDetails: UserDetails | undefined): SetUserDetails {
  return {
    type: SET_USER_DETAILS,
    userDetails: userDetails
  };
}

export function setBespokenUser(bespokenUser: BespokenUser) {
  return {
    type: SET_BESPOKEN_USER,
    bespokenUser
  };
}

export function setBespokenUserVirtualDevices(virtualDevices: any) {
  return {
    type: SET_BESPOKEN_USER_VIRTUAL_DEVICES,
    virtualDevices
  };
}

export function setAmazonFlow(amazonFlow: boolean): AmazonFlowFlag {
  return {
    type: SET_AMAZON_FLOW,
    amazonFlow: amazonFlow
  };
}

export function setLogEmailSentFlag(sendEmail: boolean): SendEmailFlag {
  return {
    type: SET_EMAIL_SENT,
    sendEmail: sendEmail
  };
}

export interface SuccessCallback {
  loginSuccess(dispatch: any, user: User): void;
}

export function setCurrentPageProps(currentPageProps: CurrentPageProps | undefined): SetCurrentPageProps {
  return {
    type: SET_CURRENT_PAGE_PROPS,
    currentPageProps
  };
}

function loginMethod(dispatch: any, loginStrat: () => Promise<User>, redirectStrat?: SuccessCallback): Promise<User> {
  dispatch(sendingRequest(true));
  return loginStrat()
    .then(async function (user: User) {
      console.log("loginMethod", user);
      dispatch(setUser(user));
      dispatch(sendingRequest(false));
      // on user creation we alter the userDetails so we need to refresh the store by using setUserDetails
      const userDetails = await auth.currentUserDetails();
      console.log("loginMethod", userDetails);

      dispatch(setUserDetails(userDetails));
      console.log("loginMethod", redirectStrat);

      if (redirectStrat) {
        redirectStrat.loginSuccess(dispatch, auth.user());
      }

      return user;
    }).catch(function (err: Error) {
      dispatch(sendingRequest(false));
      dispatch(authFormError(err.message));
      throw err;
    });
}

export function login(email: string, password: string, redirectStrat?: SuccessCallback): (dispatch: any) => Promise<User> {
  return function (dispatch: any): Promise<User> {
    return loginMethod(dispatch, function () {
      return auth.login(email, password);
    }, redirectStrat);
  };
}

export function loginWithGoogle(redirectStrat?: SuccessCallback): (dispatch: any) => Promise<User> {
  return function (dispatch: any) {
    return loginMethod(dispatch, function () {
      return auth.loginWithGoogle();
    }, redirectStrat);
  };
}

export function loginWithIbm(instanceId: string, code: string, redirectStrat?: SuccessCallback): (dispatch: any) => Promise<User> {
  return function (dispatch: any) {
    return loginMethod(dispatch, function () {
      return auth.loginWithIbm(instanceId, code);
    }, redirectStrat);
  };
}

export function loginWithCustomToken(token: string, redirectStrat?: SuccessCallback): (dispatch: any) => Promise<User> {
  return function (dispatch: any) {
    return loginMethod(dispatch, function () {
      return auth.loginWithCustomToken(token);
    }, redirectStrat);
  };
}

export function signUpWithEmail(email: string, password: string, confirmPassword: string, redirectStrat?: SuccessCallback): (dispatch: any) => Promise<User> {
  return function (dispatch: any) {
    return loginMethod(dispatch, function () {
      return auth.signUpWithEmail(email, password, confirmPassword);
    }, redirectStrat);
  };
}

// export function logout(callback?: (success: boolean) => void): (dispatch: any) => Promise<void> {
//   return function (dispatch: any) {
//     return auth.logout().then(function () {
//       if (callback) {
//         callback(true);
//       }
//     }).catch(function (err: Error) {
//       if (callback) {
//         callback(false);
//       }
//     });
//   };
// }

export function resetPassword(email: string, callback?: (success: boolean) => void): (dispatch: any) => Promise<void> {
  return function (dispatch: any) {
    return auth.sendResetPasswordEmail(email).then(function () {
      dispatch(displaySnackbar("Check your inbox!"));
      if (callback) {
        callback(true);
      }
    }).catch(function (error: Error) {
      if (callback) {
        callback(false);
      }
      return Promise.reject(error);
    });
  };
}

// export function updateUser(id: string, props: any) {
//   return function (dispatch: any) {
//     return BespokenApi.updateUser(id, props).then((result: any) => {
//       dispatch({
//         type: SET_BESPOKEN_USER,
//         bespokenUser: result,
//       });
//       return result;
//     });
//   };
// }

export type SetLatestOrganizationId = {
  type: SET_LATEST_ORGANIZATION_ID,
  latestOrganizationId: string
};

export function setLatestOrganizationId(latestOrganizationId: string): SetLatestOrganizationId {
  return {
    type: SET_LATEST_ORGANIZATION_ID,
    latestOrganizationId
  };
}
