
import { chain, isEmpty } from "lodash";
import * as React from "react";
import { Button, Checkbox, Dropdown, Input } from "../../components";

const Styles = require("./member-singup.scss");
const JOB_TITLES = [
    { label: "Product Owner/Manager", value: "Product Owner/Manager" },
    { label: "Software Developer", value: "Software Developer" },
    { label: "QA Analyst/Manager", value: "QA Analyst/Manager" },
    { label: "Dev Ops", value: "Dev Ops" },
    { label: "Director/Management", value: "Director/Management" },
    { label: "Other", value: "Other" }
]
export interface MemberSingupFormProps {
    organizationName: string;
    onJoinOrganization: (payload: any) => void;
}

interface MemberSingupFormState {
    fullName: string;
    jobTitle: string;
    password: string;
    termOfService: boolean;
    newsletter: boolean;
    fullNameError: string;
    passwordError: string;
}

export class MemberSingupForm extends React.Component<MemberSingupFormProps, MemberSingupFormState> {

    emailInputField: any;

    constructor(props: MemberSingupFormProps) {
        super(props);
        this.state = {
            fullName: '',
            jobTitle: '',
            password: '',
            termOfService: false,
            newsletter: true,
            fullNameError: '',
            passwordError: '',
        }
        this.onPasswordKeyPress = this.onPasswordKeyPress.bind(this);
        this.onConfirmPasswordKeyPress = this.onPasswordKeyPress.bind(this);
    }

    onConfirmPasswordKeyPress(event: any) {
        this.onKeyPress("confirmPassword", event);
    }

    onPasswordKeyPress(event: any) {
        this.onKeyPress("password", event);
    }

    onKeyPress(name: string, event: any) {
        // if (event.charCode === 13) {
        //     if (name === "password") {
        //         this.props.onPasswordSubmit();
        //     } else if (name === "confirmPassword") {
        //         this.props.onConfirmPasswordSubmit();
        //     }
        // }
    }

    handleEmailInputRef = (email: any) => {
        this.emailInputField = email;
    }

    componentWillReceiveProps = (nextProps: any) => {
    }

    showError = () => {
        // const errorMessage = this.props.error && errorHelper.getMessage(this.props.error);
        // return <section data-id="error" dangerouslySetInnerHTML={{ __html: errorMessage}} />;
    }
    validateFullName = (fullName: string) => {
        let fullNameError = '';
        let hasError = false;
        if (!fullName) {
            hasError = true;
        } else if (!fullName.trim()) {
            hasError = true;
        }

        if (hasError) {
            fullNameError = 'Please, enter your full name.';
        }
        this.setState({ fullNameError });
    }

    validatePassword = (password: string) => {
        let passwordError = '';
        let hasError = false;
        if (!password) {
            hasError = true;
        } else if (/\s/.test(password)) {
            hasError = true;
        } else if (password.length < 8 ) {
            hasError = true;
        }
        if (hasError) {
            passwordError = 'Your password must be at least eight characters long and include digits, lower and uppercase letters.';
        }
        this.setState({ passwordError });
    }

    get isFormCompleted() {
        const { fullName, jobTitle,  password, termOfService, fullNameError, passwordError } = this.state

        if (!termOfService) { return false }
        if (passwordError) { return false }
        if (fullNameError) { return false }

        return !chain({ fullName, jobTitle, password })
            .values()
            .some(isEmpty)
            .value()
    }

    handleJoin = () => {
        const payload = {
            fullName: this.state.fullName.trim(),
            jobTitle: this.state.jobTitle,
            password: this.state.password,
            newsletter: this.state.newsletter,
        }
        this.props.onJoinOrganization(payload);
    }

    render() {
        const emailProps = { autoFocus: true };
        const items = chain(JOB_TITLES).sort().value();

        return (
            <div className={Styles.form}>
                <div className={Styles.field}>
                    <label className={Styles.title}>{'Full Name'}</label>
                    <Input
                        className={Styles.control}
                        ref={this.handleEmailInputRef}
                        type="text"
                        value={this.state.fullName}
                        // onBlur={this.props.onEmailBlur}
                        onChange={fullName => this.setState({ fullName }, () => this.validateFullName(fullName))}
                        {...emailProps}
                    />
                    {this.state.fullNameError &&
                        <span className={Styles.error_message}>{this.state.fullNameError}</span>
                    }
                </div>
                <div className={Styles.field}>
                    <label className={Styles.title}>{'Job Title'}</label>
                    <Dropdown
                        className={`${Styles.control} ${Styles.dropdown_custom}`}
                        source={items}
                        value={this.state.jobTitle}
                        onChange={jobTitle => this.setState({ jobTitle })}
                    />

                </div>
                <div className={Styles.field}>
                    <label className={Styles.title}>{'Password'}</label>
                    <Input
                        type="password"
                        className={Styles.control}
                        value={this.state.password}
                        onChange={password => this.setState({ password }, () => this.validatePassword(password))}
                        onKeyPress={this.onPasswordKeyPress}
                    />
                    {this.state.passwordError &&
                        <span className={Styles.error_message}>{this.state.passwordError}</span>
                    }
                </div>
                <div className={Styles.field} style={{ marginTop: '30px' }}>
                    <Checkbox
                        className={`${Styles.control} ${Styles.custom_check}`}
                        checked={this.state.termOfService}
                        onChange={termOfService => this.setState({ termOfService })}
                    />
                    <label className={`${Styles.custom_check_label}`}>I have read and agree to the <br/><a href="https://bespoken.io/privacy-policy/" target="_blank" className={Styles.bold}>Terms of Service</a> and <a href="https://bespoken.io/privacy-policy/" target="_blank" className={Styles.bold}>Privacy Policy</a>.</label>
                </div>
                <div className={Styles.field}>
                    <Checkbox
                        className={Styles.control}
                        label={'I would like to receive news about Bespoken.'}
                        checked={this.state.newsletter}
                        onChange={newsletter => this.setState({ newsletter })}
                    />
                </div>
                {this.showError()}
                <div className={Styles.field}>
                    <Button
                        className={`${Styles.control} ${Styles.button}`}
                        iconToRight
                        label={`Join ${this.props.organizationName}`}
                        accent
                        onClick={this.handleJoin}
                        disabled={!this.isFormCompleted}
                    />
                </div>
            </div>
        );
    }
}

export default MemberSingupForm;
