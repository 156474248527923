
import * as React from "react";
import * as cn from "classnames";
import Input from "react-toolbox/lib/input";
import { attemptInvoke, cnToggle } from "../../../utils/ReactHelpers";
import { LabelNormal } from "../texts/Text";
import { isEqual } from "lodash";


const Styles = require("./styles.scss");
const InputTheme = require("./InputTheme.scss");

type InputProps = {
    autoFocus?: boolean;
    onBlur?: (value: string) => void;
    onChange?: (value: string) => void;
    defaultValue?: string;
    label: string | JSX.Element;
    hasError?: boolean;
    maxLenght?: number;
    value?: string | Symbol;
    multiline?: boolean;
    rows?: number;
    disabled?: boolean;
}
type InputState = {
    value: string;
}

export class InputWithLabel extends React.Component<InputProps, InputState>  {

    static defaultProps: InputProps = {
        autoFocus: undefined,
        onBlur: undefined,
        onChange: undefined,
        defaultValue: undefined,
        label: undefined,
        hasError: undefined,
        maxLenght: undefined,
        value: undefined,
        multiline: undefined,
        rows: 1,
        disabled: undefined
    }

    constructor(props: InputProps) {
        super({ ...props, value: undefined });

        this.state = {
            value: props.defaultValue || "",
        };
    }

    componentWillReceiveProps(nextProps: Readonly<InputProps>, nextContext: any): void {
        const newState: InputState = { ...this.state }

        if (!isEqual(this.state.value, nextProps.value)) {
            newState.value = nextProps.value as string
        }

        if (isEqual(newState, this.state)) { return }

        this.setState(newState)
    }

    render(): false | JSX.Element {
        const { autoFocus, hasError, onBlur, onChange, label, maxLenght, value } = this.props
        return (
            <div className={Styles.control}>
                <LabelNormal>{label}</LabelNormal>
                <Input className={cn(Styles.field, cnToggle(hasError, Styles.error))}
                    multiline={this.props?.multiline}
                    rows={this.props?.rows}
                    theme={InputTheme}
                    value={this.state.value}
                    onBlur={() => attemptInvoke(onBlur, undefined)}
                    onChange={(value: string) => this.setState({ value }, () => attemptInvoke(onChange, this.state.value))}
                    maxLength={maxLenght || 300}
                    disabled={this.props?.disabled}
                    {...{ autoFocus }} />
            </div>
        )
    }
}