import * as Firebase from "firebase";

export interface Auth {
  isAuthenticated: boolean;
  login(email: string, password: string): Promise<any>;
  singup(email: string, password: string): Promise<any>;
  signInWithCustomToken(token: string): Promise<any>;
  loginWithGoogle(): Promise<any>;
  getToken(): Promise<string>;
  logout(): Promise<void>;
}

export class FirebaseAuth implements Auth {
  isAuthenticated: boolean;
  constructor() {
    this.isAuthenticated = !!Firebase?.auth()?.currentUser;
  }
  async getToken(): Promise<string> {
    return Firebase?.auth()?.currentUser?.getIdToken(false);
  }

  login(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return Firebase.auth().signInWithEmailAndPassword(email, password);
  }

  singup(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return Firebase.auth().createUserWithEmailAndPassword(email, password);
  }

  loginWithGoogle(): Promise<firebase.auth.UserCredential> {
    return Firebase.auth().signInWithPopup(new Firebase.auth.GoogleAuthProvider());
  }

  signInWithCustomToken(token: string): Promise<firebase.auth.UserCredential> {
    return Firebase.auth().signInWithCustomToken(token);
  }

  logout(): Promise<void> {
    return Firebase.auth().signOut();
  }
}

export default Auth;
