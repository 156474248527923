import { SET_REPORTING_FILTERS } from "../constants";
import { FilterParameters } from "../services/bespoken-reporting-api";

export type SetFilterParameters = {
  type: SET_REPORTING_FILTERS,
  filters: FilterParameters,
};

export function setFilterParameters(value: FilterParameters): SetFilterParameters {
  return {
    type: SET_REPORTING_FILTERS,
    filters: value,
  };
}
