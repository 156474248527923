import { isValid, parseISO } from "date-fns";
import { attempt, chain, filter, get, isEmpty, isFunction, isNumber, isString, lowerCase, trim, xorBy } from "lodash";
import * as React from "react";

export async function wrapCallbackAsAsync(callback: (handle: (err: Error, successData?: any) => any) => any) {
    return new Promise((resolve, reject) => {
        const handle = (err: Error, successData: any[]) => {
            // console.log('wrapCallbackAsAsync', err, successData)
            return isEmpty(err) ? resolve(successData) : reject(err);
        };
        callback(handle);
    });
}

export function joinWithElements<TParams, >(elements: TParams[], elementBuilder: (it: TParams, index: number) => JSX.Element, separatorBuilder: (it?: TParams, index?: number) => JSX.Element = (it?: TParams, index?: number) => <div key={`sep-${index}`}>|</div>) {
    return chain(elements)
        .map(elementBuilder)
        .reduce((prev, curr, index) => {
            if (index > 0) { prev.push(separatorBuilder(curr as any, index)); }
            return prev.concat(curr);
        }, [])
        .value();
}

export function joinElements<TParams, >(elements: TParams[], separatorBuilder: (it?: TParams, index?: number) => JSX.Element = (it?: TParams, index?: number) => <div key={`sep-${index}`}>|</div>) {
    return chain(elements)
        .reduce((prev, curr, index) => {
            if (index > 0) { prev.push(separatorBuilder(curr as any, index)); }
            return prev.concat(curr);
        }, [])
        .value();
}

export const cnToggle = (active: boolean, trueClassName: any, falseClassName: any = undefined) => active ? trueClassName : falseClassName;

export const attemptInvoke: <TParams, >(fn: Function, ...rest: TParams[] | any[]) => any = function (fn: Function, ...rest: any) {
    if (!isFunction(fn)) {
        return;
    }

    return attempt.apply(undefined, [fn].concat(rest));
};

export const isEqualByPropertiesLabelValue: (arr1: any[], arr2: any[]) => boolean = (arr1: any[], arr2: any[]) => isEmpty(xorBy(arr1, arr2, ({ value, label }) => `${label}@${value}`));

export const filterByProperty = (propertyName: string, filterValue: string, allValue: string = "") => {
    return (all: any[]) => {
        // show all platforms
        if (filterValue === allValue) { return all; }
        // filter the selected platform
        return filter(all, (it: any) => lowerCase(get(it, propertyName)) === lowerCase(filterValue));
    };
};


export function tryParseDate(dateISO: string | Date, errHadnler: () => Date = () => undefined): Date {
    if (isValid(dateISO)) { return dateISO as Date; }
    if (!isString(dateISO)) { return errHadnler(); }

    const date = parseISO(dateISO);

    if (!isValid(date)) { return errHadnler(); }
    return date;
}

export async function copyToClipboard(text: string, onSuccess: (val: string) => void = () => { }, onError: (err: Error) => void = () => { }) {

    if (isFunction(navigator.clipboard.writeText)) {
        return navigator.clipboard.writeText(text)
            .then(() => attemptInvoke(onSuccess, text), err => attemptInvoke(onError, err));
    }

    try {
        const textArea = document.createElement("textarea");
        textArea.style.display = "none";
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand("copy");
        onSuccess(text);
    } catch (err) {
        onError(err);
    }

}

export function ifEmpty(value: any, nullOrEmptyHandler: any | ((val: string) => any)) {
    const fn = isFunction(nullOrEmptyHandler) ? nullOrEmptyHandler : () => nullOrEmptyHandler;
    return chain([value]).map(v => trim(v)).filter(v => !isEmpty(v)).first().defaultTo(fn(value)).value();
}

export function tryParseNumber(stringOrNumber: string | number, errHadnler: () => Number = () => undefined): Number {
    if (isNumber(stringOrNumber)) { return stringOrNumber; }
    if (!/^[0-9]\d*(\.\d+)?$/.test(stringOrNumber)) { return errHadnler(); }

    const num = parseFloat(stringOrNumber);

    if (!isNumber(num) || isNaN(num)) { return errHadnler(); }
    return num;
}