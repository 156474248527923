import { isEmpty, isFunction } from "lodash";
import React = require("react");
import { Button } from "react-toolbox/lib/button";
import Input from "react-toolbox/lib/input";
import { copyToClipboard } from "../../../utils/ReactHelpers";

const AccountStyle = require("../account-style.scss");
const InputTheme = require("../../../themes/input.scss");
const buttonStyle = require("../../../themes/amazon_pane.scss");
const bespokenButton = require("../../../themes/bespoken_button.scss");

interface ApiSectionProps {
    id: string;
    changeSnackMessage: (snackbarMessage: string) => any;
}

interface ApiSectionState {
    maskedId: string;
}

export class ApiSection extends React.Component<ApiSectionProps, ApiSectionState> {
    constructor(props: any) {
        super(props);
        this.state = {
            maskedId: ''
        };
    }

    componentDidMount(): void {
        const len = this.props.id.length;
        const masked = this.props.id.split('').map((char, index) => index < len - 5 ? 'x' : char).join('')
        this.setState(() => ({
            maskedId: masked,
        }));
    }

    copyUserApiKeyToClipboard() {
        copyToClipboard(`api-${this.props?.id}`, this.props.changeSnackMessage('The API Key value has been copied to the clipboard'), err => console.error(err))
    }

    render() {
        return (
            <div className={`${AccountStyle.accountSection}`}>
                <div className={AccountStyle.description}>
                    <h4>
                        API Key
                    </h4>
                    <span>Your API Key is unique to you. Use it to interact with the Bespoken public APIs, run and manage your tests.</span>
                </div>
                <div className={`${AccountStyle.content} ${AccountStyle.work_around}`}>
                    <div className={AccountStyle.fields_container}>
                        <div className={AccountStyle.property}>
                            <Input
                                maxLength={100}
                                label="delete my account"
                                name="deleteConfirmation"
                                theme={InputTheme}
                                className={`${InputTheme.user_input} ${AccountStyle.custom_width}`}
                                value={this.state.maskedId}

                                {...{ onCopy: () => { this.copyUserApiKeyToClipboard() } }}
                                />
                            <Button
                                theme={bespokenButton}
                                className={`${bespokenButton.medium} ${AccountStyle.right}`}
                                accent={true}
                                label={"Copy"}
                                onClick={() => {
                                    if (isEmpty(this.props?.id)) {
                                        console.error(`this.props?.id is empty. [${this.props?.id}]`)
                                        return
                                    }

                                    this.copyUserApiKeyToClipboard()
                                }} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}