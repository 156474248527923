
export interface SourceProperties {
    name: string;
    config: any;
    yaml: string;
    yamlObject: any;
    meta: any;
    monitoringConfig: { cron: string, cronExplained?: string, emailsToNotify: string };
}

export class Source implements SourceProperties {
    name: string;
    config: any;
    yaml: string;
    yamlObject: any;
    meta: any;
    monitoringConfig: { cron: string, cronExplained?: string, emailsToNotify: string };

    constructor(props: SourceProperties) {
        this.name = props.name;
        this.config = props.config;
        this.yaml = props.yaml;
        this.yamlObject = props.yamlObject;
        this.meta = props.meta;
        this.monitoringConfig = props.monitoringConfig;
    }

    // get platform () {
    //     return this.config && this.config.platform;
    // }
}

export default Source;
