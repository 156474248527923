import * as React from "react";
import DropdownToolbox from "react-toolbox/lib/dropdown";
import * as uuid from "uuid";
import { sourceTypes } from "../../constants";

const dropdownStyles = require("./dropdownStyles.scss");
const theme = require("./dropdownTheme.scss");

interface DropdownProps {
    source?: any;
    value?: string;
    disabled?: boolean;
    className?: string;
    onChange?: (value: string) => void;
}

interface DropdownState {
    dropdownKey: number;
}

export default class Dropdown extends React.Component<DropdownProps, DropdownState> {
    constructor() {
        super();
        this.state = {
            dropdownKey: new Date().getTime(), // Hack to close dropdown after select an item
        };
    }

    handleOnChange = (value: string) => {
        const { onChange } = this.props;
        this.setState({
            dropdownKey: new Date().getTime(),
        });
        onChange && onChange(value);
    }

    render({ source, value, disabled, className, onChange, ...rest } = this.props) {
        return (
            <DropdownToolbox
                key={this.state.dropdownKey}
                theme={theme}
                disabled={disabled}
                className={className}
                onChange={this.handleOnChange}
                source={source}
                value={value}
                {...rest} />
        );
    }
}
