
import * as React from "react";
import * as cn from "classnames";
import Input from "react-toolbox/lib/input";
import { attemptInvoke, cnToggle } from "../../../utils/ReactHelpers";
import { LabelNormal } from "../texts/Text";
import { chain, isEqual, snakeCase, trim } from "lodash";

const COMPONENT_NAME = "TextAreaWithLabel"

const Styles = require("./TextareaWithLabel.scss");

type InputProps = {
    autoFocus?: boolean;
    onChange: (value: string) => void;
    label: string;
    hasError?: boolean;
    maxLenght?: number;
    value?: string;
    multiline?: boolean;
    rows?: number;
}
type InputState = {
    value: string;
}

export class TextAreaWithLabel extends React.Component<InputProps, InputState>  {

    static defaultProps: InputProps = {
        autoFocus: undefined,
        onChange: undefined,
        label: undefined,
        hasError: undefined,
        maxLenght: undefined,
        value: undefined,
        multiline: true,
        rows: 2
    }

    constructor(props: InputProps) {
        super({ ...props, value: undefined });

        this.state = {
            value: props.value || "",
        };
    }

    componentWillReceiveProps(nextProps: Readonly<InputProps>, nextContext: any): void {
        const newState: InputState = { ...this.state }

        if (!isEqual(this.state.value, nextProps.value)) {
            newState.value = nextProps.value as string
        }

        if (isEqual(newState, this.state)) { return }

        this.setState(newState)
    }

    render(): false | JSX.Element {
        return (
            <div className={cn(snakeCase(COMPONENT_NAME), Styles.control, cnToggle(this.props?.hasError, Styles.error))}>
                <LabelNormal>{this.props?.label}</LabelNormal>
                <textarea
                    maxLength={this.props?.maxLenght}
                    autoFocus={this.props?.autoFocus}
                    onInput={evt => {
                        const value = this.props?.multiline
                            ? evt?.currentTarget?.value
                            : chain(evt.currentTarget.value).split(/[\n\t ]/).map(w => trim(w)).join(' ').value()

                        this.setState({ value }, () => attemptInvoke(this.props?.onChange, this.state?.value))
                    }}
                    value={this.state.value}
                    rows={this.props?.rows}
                />
            </div>
        )
    }
}