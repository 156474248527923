import * as cn from "classnames";
import * as React from "react";
const style = require("./rightPanelStyles.scss");


export class RightPanel extends React.Component<any, any> {
  render({ fullHeight, className, children, image, ...rest } = this.props) {
    const _image = image && style.image;
    const _styles = image && { backgroundImage: `url(${image})`};
    const _fullHeight = fullHeight && style.fullHeight;
    return (
      <div className={cn(style.rightPanel, _fullHeight, _image, className)} style={_styles} {...rest}>
        <div data-id="container">
          {children}
        </div>
      </div>
    );
  }
}
