export namespace ACTIONS {
    export const PROMPT = "prompt";
    export const CARD_TITLE = "cardTitle";
    export const CARD_CONTENT = "cardContent";
    export const CARD_IMG_URL = "cardImageURL";
    export const REPROMPT = "reprompt";
    export const FINISHONPHRASE = "set finishOnPhrase";

    export type ACTIONS = "prompt" | "cardTitle" | "cardContent" | "cardImageURL" | "reprompt";

    export const SOURCE = {
        "prompt": "prompt",
        "cardTitle": "cardTitle",
        "cardContent": "cardContent",
        "cardImageURL": "cardImageURL",
        "reprompt": "reprompt",
    };

    export const SOURCE_TWILIO = {
        "prompt": "prompt",
        "set listeningTimeout": "set listeningTimeout",
        "set finishOnPhrase": "set finishOnPhrase",
        "set repeatOnPhrase": "set repeatOnPhrase",
        "set endSpeechTimeout": "set endSpeechTimeout",
    };

    export const SOURCE_SMS = {
        "prompt": "prompt",
    };
}

export const OPERATORS: any = [
    {value: ":", label: ":"},
    {value: "!=", label: "!="},
    {value: ">", label: ">"},
    {value: "<", label: "<"},
    {value: ">=", label: ">="},
    {value: "<=", label: "<="},
];

export interface YamlObject {
    configuration: Configuration;
    tests: TestRow[];
}

export interface Configuration {
    locale: string;
    platform: string;
    type: string;
    virtualDeviceToken: string;
}

export interface TestRow {
    interactions: Interaction[];
    name: string;
    skip?: boolean;
    only?: boolean;
}

export interface Interaction {
    input: string;
    expected: ExpectedRow[];
    actual: any;
    id: number;
    expressions?: any[];
}

export interface ExpectedRow {
    action: ACTIONS.ACTIONS | string;
    operator: string;
    value: string | string[];
    expected?: string;
}

export interface ResultRow {
    icon: string;
    text: string;
    status: string;
    assertions: any;
}

export const getDefaultYamlObject = (source: any) => {
    const sourceType = source?.sourceType;
    const name = source?.name;
    let returnValue = defaultYamlObject;
    let input = "";
    let expected = "";
    switch (sourceType) {
        case "alexa":
            input = `open ${name}`;
            expected = `welcome to ${name}`;
            break;
        case "google":
            returnValue = defaultGoogleYamlObject;
            input = `talk to ${name}`;
            expected = `welcome to ${name}`;
            break;
        case "twilio":
        case "phone":
            input = "$DIAL";
            returnValue = defaultTwilioYamlObject;
            expected = `welcome to ${name}`;
            break;
        case "sms":
            returnValue = defaultSmsYamlObject;
            input = "hello";
            expected = "hi! how can I help?";
            break;
        case "whatsapp":
            returnValue = defaultWhatsappYamlObject;
            input = "hello";
            expected = "hi! how can I help?";
            break;
        default:
            break;
    }
    returnValue.tests[0].interactions[0].input = input;
    returnValue.tests[0].interactions[0].expected[0].value = expected;

    return returnValue;
};

export const defaultYamlObject: YamlObject = {
    configuration: {
        locale: "en-US",
        platform: "alexa",
        type: "e2e",
        virtualDeviceToken: "defaultToken",
    },
    tests: [
        {
            name: "Test 1",
            interactions: [
                {
                    input: "",
                    expected: [
                        {
                            action: ACTIONS.PROMPT,
                            operator: ":",
                            value: "*",
                        },
                    ],
                    actual: "",
                    id: 0,
                }
            ],
        },
    ],
};

export const defaultGoogleYamlObject: YamlObject = {
    configuration: {
        locale: "en-US",
        platform: "google",
        type: "e2e",
        virtualDeviceToken: "defaultToken",
    },
    tests: [
        {
            name: "Test 1",
            interactions: [
                {
                    input: "",
                    expected: [
                        {
                            action: ACTIONS.PROMPT,
                            operator: ":",
                            value: "",
                        },
                    ],
                    actual: "",
                    id: 0,
                }
                // }, {
                //     input: "",
                //     expected: [
                //         {
                //             action: ACTIONS.PROMPT,
                //             operator: ":",
                //             value: "",
                //         }
                //     ],
                //     actual: "",
                //     id: 1,
                // }
            ],
        },
    ],
};

export const defaultTwilioYamlObject: YamlObject = {
    configuration: {
        locale: "en-US",
        platform: "twilio",
        type: "e2e",
        virtualDeviceToken: "defaultToken",
    },
    tests: [
        {
            name: "Test 1",
            interactions: [
                {
                    input: "",
                    expected: [
                        {
                            action: ACTIONS.PROMPT,
                            operator: ":",
                            value: "",
                        },
                        {
                            action: ACTIONS.FINISHONPHRASE,
                            operator: ":",
                            value: "what can I help you with?",
                        },
                    ],
                    actual: "",
                    id: 0,
                }
                // }, {
                //     input: "",
                //     expected: [
                //         {
                //             action: ACTIONS.PROMPT,
                //             operator: ":",
                //             value: "",
                //         }
                //     ],
                //     actual: "",
                //     id: 1,
                // }
            ],
        },
    ],
};

export const defaultSmsYamlObject: YamlObject = {
    configuration: {
        locale: "en-US",
        platform: "sms",
        type: "e2e",
        virtualDeviceToken: "defaultToken",
    },
    tests: [
        {
            name: "Test 1",
            interactions: [
                {
                    input: "",
                    expected: [
                        {
                            action: ACTIONS.PROMPT,
                            operator: ":",
                            value: "hi! how can I help?",
                        },
                    ],
                    actual: "",
                    id: 0,
                }
                // }, {
                //     input: "",
                //     expected: [
                //         {
                //             action: ACTIONS.PROMPT,
                //             operator: ":",
                //             value: "",
                //         }
                //     ],
                //     actual: "",
                //     id: 1,
                // }
            ],
        },
    ],
};

export const defaultWhatsappYamlObject: YamlObject = {
    configuration: {
        locale: "en-US",
        platform: "whatsapp",
        type: "e2e",
        virtualDeviceToken: "defaultToken",
    },
    tests: [
        {
            name: "Test 1",
            interactions: [
                {
                    input: "",
                    expected: [
                        {
                            action: ACTIONS.PROMPT,
                            operator: ":",
                            value: "hi! how can I help?",
                        },
                    ],
                    actual: "",
                    id: 0,
                }
            ],
        },
    ],
};

export const defaultTestObject = {
    name: "defaultName",
    interactions: [
        {
            input: "",
            expected: [
                {
                    action: ACTIONS.PROMPT,
                    operator: ":",
                    value: "*",
                },
            ],
            actual: "",
            id: 0,
        }
    ],
};

export const defaultInteraction: Interaction = {
    input: "",
    expected: [
        {
            action: ACTIONS.PROMPT,
            operator: ":",
            value: "",
        },
    ],
    actual: "",
    id: 0,
};

export const defaultExpected: ExpectedRow[] = [
    {
        action: ACTIONS.PROMPT,
        operator: ":",
        value: "",
    },
];

export const defaultExpectedOnlyPrompt: ExpectedRow[] = [
    {
        action: ACTIONS.PROMPT,
        operator: ":",
        value: "",
    }
];

export const DEFAULT_PARSE_ERROR: string = "Test Syntax Error";

export const waitForConversationInterval = 8000;
