import { defaultTo } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { fetchOrganization } from "../../../actions/organization";
import { BespokenSessionArgs } from "../../../index-utils";
import { BespokenUserProperties } from "../../../models/bespoken-user";
import { State } from "../../../reducers";
import { SelectedOrganization } from "../../../reducers/organization";
import { wrapCallbackAsAsync } from "../../../utils/ReactHelpers";
import { OrganizationSwitcher } from "../../OrganizationSwitcher/OrganizationSwitcher";
import { User } from "../../../reducers/user";

const Styles = require("./styles.scss");


interface DispatchToProps {
    fetchOrganization?: (orgId: string) => any;

}
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        fetchOrganization: (orgId: string) => wrapCallbackAsAsync(handle => dispatch(fetchOrganization(orgId, handle))),
    }
}

interface StateToProps {
    user?: User;
    selectedOrganization?: SelectedOrganization;
    selectedSource?: any;
    testResults?: any;
}
function mapStateToProps(state: State.All) {
    return {
        user: defaultTo(state?.user.currentUser, {}),
        selectedOrganization: defaultTo(state?.organization.selectedOrganization, {}),
        selectedSource: defaultTo(state?.sourceGit?.currentSource, {}),
        testResults: defaultTo(state?.sourceGit?.testResults, {})
    };
}

interface ExposedProps {
}
function mergeProps(ownProps: any, stateProps: any, dispatchProps: ExposedProps) {
    return { ...ownProps, ...stateProps, ...dispatchProps }
}

interface ComponentProps extends DispatchToProps, StateToProps, ExposedProps {
    selectedSource?: any;
}

interface ComponentState {
}

class DebugPanelComponent extends React.Component<ComponentProps, ComponentState> {

    static defaultProps: ComponentProps = {
    }

    constructor(props: ComponentProps) {
        super(props);
        this.state = {}
    }

    render(): false | JSX.Element {
        const content = `
${JSON.stringify(this.props.testResults, undefined, 2)}
====
${JSON.stringify(this.props?.selectedSource?.meta, undefined, 2)}
        `
        return (<div>
            <OrganizationSwitcher />
            <button onClick={() => this.props.fetchOrganization("current")}>Fetch Organization</button>
            <button onClick={() => this.emitEvent()}>Fire Event</button>

            <textarea className={Styles.component} value={content} />
        </div>)
    }
    emitEvent(): void {
        const customEvent = new CustomEvent("dev-event", { detail: { param: 1 } })
        window.document.dispatchEvent(customEvent)
    }
}

export const DebugPanel = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(DebugPanelComponent);


export const INFO = console.log
export const WARN = console.warn
export const DEBUG = console.debug
export const ERROR = console.error
export const TIME = console.time
export const TIME_END = console.timeEnd
// export const TIME_LOG = (console as Console).timeLog.apply(null, args)
export const TIME_STAMP = console.timeStamp

export const LISTEN_DEV_EVENT = (fn: (params: any) => any) => {
    window.document.addEventListener("dev-event", (evt) => fn(evt))
}