import { chain, defaultTo, isArray, isString } from "lodash";
import * as cn from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { State } from "../../../reducers";
import { BespokenColors } from "../constants";

const Styles = require("./styles.scss");

export type IconPosition = 'icon_left' | 'icon_right'

interface DispatchToProps { }
function mapDispatchToProps(dispatch: any) { return {} }

interface StateToProps { }
function mapStateToProps(state: State.All) { return {} }

interface ExposedProps {
    children: JSX.Element[] | JSX.Element | string;
    icon: JSX.Element;
    iconPosition?: IconPosition;
    size?: 'small' | 'normal' | 'large';
    color?: BespokenColors;
}
function mergeProps(ownProps: any, stateProps: any, dispatchProps: ExposedProps) {
    return { ...ownProps, ...stateProps, ...dispatchProps }
}

interface ComponentProps extends DispatchToProps, StateToProps, ExposedProps { }

interface ComponentState { }

class BadgeComponent extends React.Component<ComponentProps, ComponentState> {

    static defaultProps: ComponentProps = {
        children: [],
        icon: undefined,
        size: "normal",
        color: 'color_medium_aquamarine',
        iconPosition: 'icon_left'
    }

    constructor(props: ComponentProps) {
        super(props);
        this.state = {}
    }

    render(): false | JSX.Element {
        const iconPositionStyle = defaultTo(Styles[this.props?.iconPosition], Styles.icon_left)
        const sizeStyle = defaultTo(Styles[this.props?.size], Styles.medium)
        const badgeColor = defaultTo(Styles[this.props?.color], Styles.color_medium_aquamarine)
        return (
            <div className={cn(Styles.component, iconPositionStyle, sizeStyle, badgeColor)}>
                <div className={Styles.icon}>{this.props.icon}</div>
                <div className={Styles.content}>
                    {
                        chain(this.props?.children)
                            .thru(all => isString(all) ? [<span>{all}</span>] : all)
                            .thru(all => !isArray(all) ? [all] : all)
                            .value()
                    }
                </div>
            </div>
        )
    }
}

export const Badge = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(BadgeComponent);
