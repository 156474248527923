import * as cn from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { State } from "../../../reducers";
import { chain, clone, isArray, isEmpty, map, size, trim, uniq, uniqueId, values } from "lodash";
import { Input } from "../inputs/Input";
import { TextButtonSmall } from "../buttons/Button";
import { XIconButton } from "../icon-buttons/IconButton";
import { attemptInvoke } from "../../../utils/ReactHelpers";

// TODO: Requires to refactor to be aligned with other components. (date: 2019-10-10)

// Must be the same as the name of the component in the file
const COMPONENT_NAME = "MultipleTextInput"
const COMPONENT = "component"

const Styles = require("./MultipleTextInput.module.scss");

type ValueList = { [key: string]: string }
const createEntryId = () => `multiple-text-input-${uniqueId()}`

interface DispatchToProps {
}
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {};
}

interface StateToProps {
}
function mapStateToProps(state: State.All): StateToProps {
    return {};
}

interface ExposedProps {
    onChange?: (newValues: string[]) => void;
    values?: string[];
    disabled?: boolean;
    placeholder?: string;
    addButtonText?: string;
}
function mergeProps(ownProps: any, stateProps: any, dispatchProps: ExposedProps) {
    return { ...ownProps, ...stateProps, ...dispatchProps }
}

interface ComponentProps extends DispatchToProps, StateToProps, ExposedProps { }

interface ComponentState {
    values: ValueList;
    focusOn?: string;
}

class Component extends React.Component<ComponentProps, ComponentState> {

    static defaultProps: ComponentProps = {
        onChange: undefined,
        values: undefined,
        disabled: undefined,
        placeholder: undefined,
        addButtonText: 'Add Entry'
    }

    constructor(props: any) {
        super(props);

        // Adds default empty input
        const values: ValueList = chain(props?.values)
            .thru(all => isEmpty(all) ? [''] : all)
            .map((v: string) => ([createEntryId(), v]))
            .fromPairs()
            .value()

        this.state = {
            values,
            focusOn: undefined
        };
    }
    render(): false | JSX.Element {
        return (
            <div className={cn(COMPONENT, COMPONENT_NAME, Styles.component)}>
                {
                    chain(this.state?.values)
                        .entries()
                        .map(({ 0: key, 1: currentValue }, i, all) => {
                            const lastestEntry = i === size(all) - 1
                            const inputColumn = <Input
                                disabled={this.props?.disabled}
                                key={key}
                                placeholder={this.props?.placeholder}
                                value={currentValue}
                                autoFocus={this.state?.focusOn === key}
                                onTabKeyDown={lastestEntry ? () => this.addNewEntry() : undefined}
                                onChange={(val: string) => this.setState(prev => ({ values: { ...prev.values, [key]: val } }), () => this.emitOnChange())}
                            />

                            const removeButtonColumn = size(this.state?.values) > 1
                                ? (
                                    <XIconButton
                                        key={`multi-input-remove-button-${key}`}
                                        disabled={this.props?.disabled}
                                        color={"color_dark_cerulean"}
                                        hoverColor={"color_dark_cerulean"}
                                        onClick={() => {
                                            this.setState(prev => {
                                                const prevValues = clone(prev.values)
                                                delete prevValues[key]

                                                return { values: isEmpty(prevValues) ? { [createEntryId()]: '' } : prevValues }
                                            }, () => this.emitOnChange())
                                        }}
                                    />
                                )
                                : <div />

                            return [inputColumn, removeButtonColumn]
                        })
                        .value()
                }
                <div className={Styles.add_link_button}>
                    <TextButtonSmall
                        onClick={() => this.addNewEntry()}
                        disabled={this.props?.disabled}
                    >
                        {this.props?.addButtonText}
                    </TextButtonSmall>
                </div>
            </div>
        );
    }

    addNewEntry() {
        const newId = createEntryId()
        this.setState(
            { values: { ...this.state.values, [newId]: '' }, focusOn: newId },
            () => {
                this.setState({ focusOn: undefined })
                this.emitOnChange()
            }
        )
    }

    emitOnChange() {
        attemptInvoke(this.props?.onChange, values(this?.state?.values))
    }

}


export const MultipleTextInput = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(Component);
