import * as React from "react";

export default class ReactDiv extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const {theme, ...rest} = this.props;
        return (
            <div {...rest} />
        );
    }
}

export class ReactLi extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const {theme, ...rest} = this.props;
        return (
            <li {...rest} />
        );
    }
}

export class ReactSpan extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const {theme, ...rest} = this.props;
        return (
            <span style={{position: "relative"}} {...rest} />
        );
    }
}
