import * as React from "react";
import {MAX_LENGTH_INPUT} from "../constants";

const InlineEditInput = require("riek").RIEInput;

export default class GenericInlineEdit extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            name: ""
        };
    }

    componentDidMount() {
        this.setState(() => ({
            name: this.props.name,
        }));
    }

    componentWillReceiveProps(nextProps: any) {
        this.setState(() => ({
            name: nextProps.name,
        }));
    }

    validateString = (value: string) => {
        return value.trim() && value.trim().length <= MAX_LENGTH_INPUT;
    }

    handleNameChange = async (value: any) => {
        const { name } = value;
        this.props.onChange && this.props.onChange(name.trim());
    }

    render() {
        return (
            <InlineEditInput
                editProps={{maxLength: MAX_LENGTH_INPUT}}
                validate={this.validateString}
                className={this.props.className}
                classEditing={this.props.editClassName}
                propName={"name"}
                value={this.state.name}
                change={this.handleNameChange} />
        );
    }
}
