import * as cs from "classnames";
import { isEqual, reduce } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import KpiExecuted from "../../../assets/reporting-kpi-executed.svg";
import KpiFailure from "../../../assets/reporting-kpi-failure.svg";
import KpiSuccess from "../../../assets/reporting-kpi-success.svg";
import KpiTime from "../../../assets/reporting-kpi-time.svg";
import { setLoading } from "../../actions/loading";
import { setFilterParameters } from "../../actions/reporting";
import { setCurrentPageProps } from "../../actions/context";
import {
    DoughnutChart, KPICard,
    LineChart, LoaderIndicator, ResultsRangeFilter, TestResultsTable
} from "../../components/ConsolidatedReports";
import Source from "../../models/source-legacy";
import { UserDetails } from "../../models/user";
import { State } from "../../reducers";
import { CurrentPageProps } from "../../reducers/context";
import BespokenReportingApi, { FilterParameters } from "../../services/bespoken-reporting-api";
import { wrapCallbackAsAsync } from "../../utils/ReactHelpers";
import { fetchOrganization } from "../../actions/organization";
import { ERROR } from "../../components/lunacy/debug-panel/DebugPanel";
import { User } from "../../reducers/user";

const globalWindow: any = typeof (window) !== "undefined" ? window : {};
const Styles = require("./MonitoringHistoryStyle.scss");

type PathParams = {
    projectId?: string;
}

interface MonitoringHistoryPageProps {
    setLoading: (value: boolean) => any;
    setCurrentPageProps: (value: CurrentPageProps) => any;
    user: User;
    userDetails: UserDetails;
    sources: Source[];
    currentSource: Source;
    getSources: () => Promise<Source[]>;
    sourceSearchText: string;
    test?: string;
    goTo: (path: string) => void;
    setFilterParameters: (value: FilterParameters) => void;
    currentFilters: FilterParameters;
    fetchOrganization: () => Promise<void>;
    params: PathParams;
}

interface ReportData {
    testRunsEvolutionOverTime?: any;

    kpiAverageExecutionTime?: number;
    kpiExecutedTest?: number;
    kpiFaliureRate?: number;
    kpiSuccessRate?: number;

    testRunsByPlatform?: any;
    testRunsByClient?: any;

    testRunsResultsAndConfig?: any;
}

interface MonitoringHistoryPageState extends ReportData {
    filters: FilterParameters;
    loading: boolean;
    loadingTestRunResults: boolean;
}

export class MonitoringHistoryPage extends React.Component<MonitoringHistoryPageProps, MonitoringHistoryPageState> {

    reportingApi: any;

    constructor(props: MonitoringHistoryPageProps) {
        super(props);

        this.state = {
            filters: {
                projectId: undefined,
                platformId: undefined,
                localeId: undefined,
                startTimestamp: undefined,
                endTimestamp: undefined,
                searchKey: undefined,
                page: undefined,
                timezoneOffset: undefined
            },
            loading: true,
            loadingTestRunResults: true,
        };
    }

    async componentDidMount(): Promise<void> {
        this.props.setCurrentPageProps({
            title: "Testing",
            subTitle: "History"
        })

        try {
            this.props.setLoading(true)
            await this.props.fetchOrganization()
        } catch (err) {
            ERROR("Error fetching sources", err)
        }
        this.props.setLoading(false)
    }

    componentDidUpdate(prevProps: Readonly<MonitoringHistoryPageProps>, prevState: Readonly<MonitoringHistoryPageState>, prevContext: any): void {
        if (!isEqual(this.state.filters, prevState.filters)) {
            const onlyPageHasChanged = this.modifiedKeys(this.state.filters, prevState.filters).every(k => k === 'page');

            if (!onlyPageHasChanged) {
                this.setState({ loading: true })
            }

            this.setState({ loadingTestRunResults: true })

            this.retrieveData(this.state.filters, onlyPageHasChanged)
                .then((reportsData: ReportData) => this.setState({ ...reportsData, loading: false, loadingTestRunResults: false }))
        }
    }

    modifiedKeys(curr: FilterParameters, prev: FilterParameters): string[] {
        return reduce(curr, function (result, value, key) {
            return isEqual(value, (prev as any)[key]) ?
                result : result.concat(key);
        }, []);
    }

    async retrieveData(filters: FilterParameters, onlyPageHasChanged: boolean): Promise<ReportData> {
        if (onlyPageHasChanged) {
            return Promise.all([
                this.reportingApi.getTestRunsResultsTableValues(filters)
                    .catch(() => ({}))
            ]).then(({
                0: testRunsResultsAndConfig,
            }) => ({
                testRunsResultsAndConfig,
            }))
                .catch(err => { console.log("Error:", err); return this.state })

        }

        return Promise.all([
            this.reportingApi.getTestRunsEvolutionOverTimeChartValues(filters)
                .catch(() => ({})),

            this.reportingApi.getExecutedTestsKPIValue(filters)
                .catch(() => {/* returns undefined */ }),
            this.reportingApi.getSuccessRateKPIValue(filters)
                .catch(() => {/* returns undefined */ }),
            this.reportingApi.getFailureRateKPIValue(filters)
                .catch(() => {/* returns undefined */ }),
            this.reportingApi.getAvgExecutionTimeKPIValue(filters)
                .catch(() => {/* returns undefined */ }),

            this.reportingApi.getTestRunsByPlatformChartValues(filters)
                .catch(() => ({})),
            this.reportingApi.getTestRunsByClientChartValues(filters)
                .catch(() => ({})),

            this.reportingApi.getTestRunsResultsTableValues(filters)
                .catch(() => ({}))
        ]).then(({
            0: testRunsEvolutionOverTime,

            1: kpiExecutedTest,
            2: kpiSuccessRate,
            3: kpiFaliureRate,
            4: kpiAverageExecutionTime,

            5: testRunsByPlatform,
            6: testRunsByClient,

            7: testRunsResultsAndConfig,
        }) => ({
            testRunsEvolutionOverTime,

            kpiExecutedTest,
            kpiSuccessRate,
            kpiFaliureRate,
            kpiAverageExecutionTime,

            testRunsByPlatform,
            testRunsByClient,

            testRunsResultsAndConfig,
        }))
            .catch(err => { console.log("Error:", err); return this.state })
    }

    render() {
        /**
         * This layput uses CSS gridlayout and is divided in levels:
         * First level: top, left, right
         * Second level:
         *      Top: one control
         *      Left: A grid layout with one column
         *      Right: A grid layout with two columns, here the ordering of elements is important
         */
        const loadingStateClass = this.state.loading ? Styles.loading_feature : "";
        const loadingStateTestRunResultsClass = this.state.loadingTestRunResults ? Styles.loading_feature : "";
        const defaultSvgProps = {
            viewBox: "0 0 34 34",
            width: "24",
            height: "24"
        }
        return (
            <div className={Styles.root_component}>
                <BespokenReportingApi ref={(instance: any) => { if (!instance) { return } this.reportingApi = instance.getWrappedInstance() }} />

                <div className={Styles.top_region}>
                    <ResultsRangeFilter
                        filters={this.props.currentFilters}
                        onFiltered={filters => {
                            console.info('initiali filtering of range component')
                            this.setState({ filters })
                            this.props.setFilterParameters(filters)
                        }}
                        onDownload={(filters) => { console.info('downloading of range component') }}
                        projectId={this.props.params.projectId}
                    />
                </div>
                <div className={Styles.left_region}>
                    <div className={cs(Styles.test_overtime, loadingStateClass)}>
                        <LoaderIndicator className={Styles.hide} />
                        <div className={Styles.title}>Test runs evolution over time</div>
                        <LineChart
                            data={this.state.testRunsEvolutionOverTime} />
                    </div>
                    <div className={cs(Styles.test_results, loadingStateTestRunResultsClass)}>
                        <LoaderIndicator className={Styles.hide} />
                        <div className={Styles.title}>Test runs results</div>
                        <TestResultsTable
                            data={this.state.testRunsResultsAndConfig}
                            currentPage={this.state.filters?.page || 1}
                            onSelect={(runId: string) => this.props.goTo(`history/testruns/${runId}`)}
                            onPageChanged={(page) => {
                                const filters = { ...this.state.filters, page };
                                this.setState({ filters })
                                this.props.setFilterParameters(filters)
                            }}
                        />
                    </div>
                </div>
                <div className={Styles.right_region}>
                    <div className={cs(Styles.kpis_executed_test, loadingStateClass)}>
                        <LoaderIndicator className={Styles.hide} />
                        <KPICard
                            className={Styles.kpi_texts}
                            data={this.state.kpiExecutedTest}
                            label="Executed tests"
                            icon={<KpiExecuted {...defaultSvgProps} />}
                        />
                    </div>
                    <div className={cs(Styles.kpis_success_rate, loadingStateClass)}>
                        <LoaderIndicator className={Styles.hide} />
                        <KPICard
                            data={this.state.kpiSuccessRate}
                            label="Success rate"
                            icon={<KpiSuccess {...defaultSvgProps} />}
                            suffix="%"
                        />
                    </div>
                    <div className={cs(Styles.kpis_average_time, loadingStateClass)}>
                        <LoaderIndicator className={Styles.hide} />
                        <KPICard
                            data={this.state.kpiAverageExecutionTime}
                            label="Avg execution time"
                            suffix="s"
                            icon={<KpiTime {...defaultSvgProps} />} />
                    </div>
                    <div className={cs(Styles.kpis_faliure_rate, loadingStateClass)}>
                        <LoaderIndicator className={Styles.hide} />
                        <KPICard
                            data={this.state.kpiFaliureRate}
                            label="Failure rate"
                            icon={<KpiFailure {...defaultSvgProps} />}
                            suffix="%"
                        />
                    </div>

                    <div className={cs(Styles.runs_by_platform, loadingStateClass)}>
                        <LoaderIndicator className={Styles.hide} />
                        <div className={Styles.title}>Test runs by platform</div>
                        <DoughnutChart
                            key={"by-pltaform"}
                            data={this.state.testRunsByPlatform}
                        />
                    </div>
                    <div className={cs(Styles.runs_by_client, loadingStateClass)}>
                        <LoaderIndicator className={Styles.hide} />
                        <div className={Styles.title}>Test runs by client</div>
                        <DoughnutChart
                            key={"by-client"}
                            data={this.state.testRunsByClient}
                        />
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state: State.All) {
    return {
        user: state.user.currentUser,
        currentFilters: state.reporting.filters
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        setLoading: function (value: boolean) {
            return dispatch(setLoading(value));
        },
        goTo: (path: string) => {
            dispatch(push(path));
        },
        setFilterParameters(value: FilterParameters) {
            return dispatch(setFilterParameters(value));
        },
        setCurrentPageProps: (value: CurrentPageProps) => dispatch(setCurrentPageProps(value)),
        fetchOrganization: async () => wrapCallbackAsAsync(handle => dispatch(fetchOrganization("current", handle)))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MonitoringHistoryPage);


