import { isEmpty, every, chain, find, uniqueId } from "lodash"
import * as React from "react";
import * as cn from "classnames";
import { attemptInvoke, joinWithElements } from "../../../utils/ReactHelpers";

import DropdownToolbox from "react-toolbox/lib/dropdown";

const Styles = require("./PropertyDropdown.module.scss");
const Themes = require("./PropertyDropdown.themes.scss");

const COMPONENT_NAME = "PropertyDropdown"
const COMPONENT = "component"

type OptionType = { label: string; value: string; }
type ModelType = { phone: OptionType[]; webchat: OptionType[]; alexa: OptionType[]; whatsapp: OptionType[];  sms: OptionType[]; otherwise: OptionType[] };
const model: ModelType = {
    "alexa": [
        { label: "caption", value: "caption" },
        { label: "prompt", value: "prompt" },
    ],

    "otherwise": [
        { label: "prompt", value: "prompt" },
    ],

    "phone": [
        { label: "connection.endedBy", value: "connection.endedBy" },
        { label: "connection.endTime", value: "connection.endTime" },
        { label: "connection.state", value: "connection.state" },
        { label: "prompt", value: "prompt" },
        { label: "set endSpeechTimeout", value: "set endSpeechTimeout" },
        { label: "set finishOnPhrase", value: "set finishOnPhrase" },
        { label: "set listeningTimeout", value: "set listeningTimeout" },
        { label: "set pauseBeforeUtterance", value: "set pauseBeforeUtterance" },
        { label: "set repeatOnPhrase", value: "set repeatOnPhrase" },
    ],

    "sms": [
        { label: "prompt", value: "prompt" },
        { label: "set replyWaitTime", value: "set replyWaitTime" },
    ],

    "webchat": [
        { label: "display", value: "display" },
        { label: "prompt", value: "prompt" },
    ],

    "whatsapp": [
        { label: "prompt", value: "prompt" },
        { label: "set replyWaitTime", value: "set replyWaitTime" },
    ]
}

type PropertyDropdownExposedProps = {
    platform: keyof (ModelType);
    onChange: ((item: OptionType) => void), disabled?: boolean,
    value?: string;
    /**
     * This add a custom value to the dropdown
     */
    customValue?: string
}
export const PropertyDropdown = (props: PropertyDropdownExposedProps) => {
    const data = chain(model)
        .get(props?.platform)
        .defaultTo(model.otherwise)
        .tap((all) => {
            if (!isEmpty(props?.customValue) && every(all, ({ value }) => value !== props?.customValue)) {
                all.unshift({ label: props?.customValue, value: props?.customValue })
            }
        })
        .value()

    // Using random id to force close popup when value changed
    const uniqId = uniqueId()
    return (
        <DropdownToolbox
            className={cn(COMPONENT, COMPONENT_NAME)}
            key={`property-dropdown-${uniqId}`}
            theme={Themes}
            auto={false}
            disabled={props?.disabled}
            onChange={(currentValue: any) => {
                const selectedValue = find(data, ({ value }) => value === currentValue)
                attemptInvoke(props.onChange, selectedValue)
            }}
            source={data}
            value={props?.value} />
    )
}