import { defaultTo, get, toLower, toUpper } from "lodash";
import * as React from "react";
import { Chip } from "react-toolbox/lib/chip";
import BespokenIconFailed from "../../../assets/bespoken_icon_failed.svg";
import BespokenIconPassed from "../../../assets/bespoken_icon_passed.svg";
import BespokenIconSkipped from "../../../assets/bespoken_icon_skipped.svg";
import { TestRunSummaryRow } from "../../services/bespoken-reporting-api";

const TestRunSummaryStyle = require("./TestRunSummaryStyle.scss");

const dataKeyName = "data";

const SUITE_STATUS_COLOR: { [key: string]: any } = {
  PASSED: TestRunSummaryStyle.suite_status_passed,
  FAILED: TestRunSummaryStyle.suite_status_failed,
  SKIPPED: TestRunSummaryStyle.suite_status_skipped
}
const TEST_STATUS_ICON: { [key: string]: any } = {
  PASSED: (<BespokenIconPassed />),
  FAILED: (<BespokenIconFailed />),
  SKIPPED: (<BespokenIconSkipped />),
}

export interface TestRunSummaryProps {
  [dataKeyName]: TestRunSummaryRow[];
}
interface HeaderRunSummary extends TestRunSummaryRow { }
type Rows = { header: HeaderRunSummary, items: TestRunSummaryRow[] }[];
export interface TestRunSummaryState {
  data: Rows;
}

export class TestRunSummary extends React.Component<TestRunSummaryProps, TestRunSummaryState> {

  state: TestRunSummaryState;
  props: TestRunSummaryProps;

  constructor(props: TestRunSummaryProps) {
    super(props);

    this.state = {
      data: []
    };
  }

  componentWillReceiveProps(nextProps: Readonly<TestRunSummaryProps>, nextContext: any): void {
    /*
     The result must have following format:
     [
     [header:{level_1},items:[{level_2},{level_3},{level_3},{level_2},{level_3},{level_3}]],
     [header:{level_1},items:[{level_2},{level_3},{level_3},{level_2},{level_3},{level_3}]]
     ]
    */
    const response = (get(nextProps, dataKeyName, []) as TestRunSummaryRow[])
    const toLocaleFormat = (locale: string) => defaultTo(locale, '').split('-').reduce((p, c, i) => i === 0 ? p.concat(toLower(c)) : p.concat(toUpper(c)), []).join('-')

    const suites = response
      .reduce((p, c) => p.some(({ test_suite_id }) => test_suite_id === c.test_suite_id) ? p : p.concat(c), [])
      .map((suiteRow, suiteOrderIndex) => {
        const slot_type = 'level_1';
        const slot_index = { suite: suiteRow.test_suite_id };
        const slot_title = `${toUpper(suiteRow.test_suite_name)} ${suiteRow.locale ? `(${toLocaleFormat(suiteRow.locale)})` : ''}`;
        const slot_status = suiteRow.test_suite_result;

        return { slot_type, slot_index, slot_title, slot_status, slot_content: undefined, suiteOrderIndex }
      })

    const tests = response
      .reduce((p, c) => p.some(({ test_result_id }) => test_result_id === c.test_result_id) ? p : p.concat(c), [])
      .map(testRow => {
        const slot_type = 'level_2';
        const slot_index = { suite: testRow.test_suite_id, test: testRow.test_result_id }
        const slot_title = testRow.test_name;
        const slot_status = testRow.test_result;
        const slot_content = {
          step_passed: testRow.step_passed,
          step_failed: testRow.step_failed,
          step_skipped: testRow.step_skipped,
          step_executed: testRow.step_executed,
          step_total: testRow.step_total
        }
        const testOrderIndex = testRow.test_order;

        return { slot_type, slot_index, slot_title, slot_status, slot_content, testOrderIndex }
      });

    const data = suites.reduce((p, c: any) => {
      const items = tests
        .filter(it => it.slot_index.suite === c.slot_index.suite)
        .reduce((p1, test) => {
          p1.push(test)
          return p1;
        }, [])

      p.push({ header: c, items });
      return p;
    }, []);

    this.setState({ data })
  }

  render() {
    return (
      <div>
        <div className={TestRunSummaryStyle.layout}>
          {
            (this.state.data as Rows)
              .reduce((p, { header, items }) => {
                p.push(
                  <Chip
                    key={`suite-status-${header.slot_index.suite}:${header.slot_index.test}`}
                    className={SUITE_STATUS_COLOR[header.slot_status]}
                    style={{ marginBottom: "10px" }}
                  >
                    <span>{header.slot_status}</span>
                  </Chip>
                )
                p.push(<div key={`suite-title-${header.slot_index.suite}:${header.slot_index.test}`} style={{ marginBottom: "10px" }} className={TestRunSummaryStyle.suite_name_title}>{header.slot_title}</div>)
                p.push(<div key={`suite-value-${header.slot_index.suite}:${header.slot_index.test}`} style={{ marginBottom: "10px" }} />)

                items.forEach(it => {
                  p.push(<div key={`status-test-${it.slot_index.suite}:${it.slot_index.test}`} className={TestRunSummaryStyle.test_status}>{TEST_STATUS_ICON[it.slot_status]}</div>)
                  p.push(<div key={`test-title-${it.slot_index.suite}:${it.slot_index.test}`} className={TestRunSummaryStyle.test_name_title}>{it.slot_title}</div>)
                  p.push(<div key={`test-value-${it.slot_index.suite}:${it.slot_index.test}`} className={TestRunSummaryStyle.test_counters}>{`[${it.slot_content.step_passed}/${it.slot_content.step_total}]`}</div>)
                });

                return p;
              }, [])
          }
        </div>
      </div>
    );
  }

}
