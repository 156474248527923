import * as React from "react";
import { RadioButton as RadioButtonToolbox, RadioGroup as RadioGroupToolbox } from "react-toolbox/lib/radio";

const radioboxStyles = require("./radioboxStyles.scss");
const theme = require("./radioboxTheme.scss");

interface RadioButtonProps {
    label: any;
    value: string;
}

interface RadioboxProps {
    items?: Array<RadioButtonProps>;
    onChange?: (value: string) => void;
    name?: string;
    value?: string;
    className?: string;
}


export default class Radiobox extends React.Component<RadioboxProps, any> {
    getRadioButtons = () => {
        const { items } = this.props;
        return items.map((item: any) => (
            <RadioButtonToolbox key={item.value}
                theme={theme}
                name={item.name}
                label={item.label}
                value={item.value} />
        ));
    }

    handleOnChange = (value: string) => {
        const { onChange } = this.props;
        onChange && onChange(value);
    }

    render({ name, value, className } = this.props) {
        return (
            <RadioGroupToolbox
                name={name}
                className={className}
                value={value}
                onChange={this.handleOnChange}>
                {this.getRadioButtons()}
            </RadioGroupToolbox>
        );
    }
}
