import * as React from "react";
import DeleteEmail from "../../../../assets/lunacy/bespoken_icon_delete_email.svg";
import DebugIcon from "../../../../assets/debug_icon.svg";
import TrashCan from "../../../../assets/lunacy/bespoken_icon_trash_can.svg";
import UserProfile from "../../../../assets/lunacy/bespoken_icon_user_profile.svg";
import PlusSign from "../../../../assets/bespoken_add_icon.svg";
import RemoveSign from "../../../../assets/bespoken_remove_icon.svg";
import XIcon from "../../../../assets/bespoken_x_icon.svg";
import CheckmarkIcon from "../../../../assets/bespoken_check_icon.svg";
import MenuDostsIcon from "../../../../assets/bespoken_menu_dots.svg";
import PlusSignOnly from "../../../../assets/lunacy/bespoken_icon_plus_sign.svg";
import CopyIcon from "../../../../assets/bespoken_copy_icon.svg";
import { BespokenColors, defaultIconColor } from "../constants";

const Styles = require("./styles.scss");

type IconProps = {
    color?: BespokenColors;
}

export const IconDeleteEmail = ({ color }: IconProps = { color: defaultIconColor }) => <span className={Styles[color]}><DeleteEmail /></span>;
export const IconDebug = ({ color }: IconProps = { color: defaultIconColor }) => <span className={Styles[color]}><DebugIcon /></span>;
export const IconUserProfile = ({ color }: IconProps = { color: defaultIconColor }) => <span className={Styles[color]}><UserProfile /></span>;
export const IconTrashCan = ({ color }: IconProps = { color: defaultIconColor }) => <span className={Styles[color]}><TrashCan /></span>;
export const IconPlusSign = ({ color }: IconProps = { color: defaultIconColor }) => <span className={Styles[color]}><PlusSign /></span>;
export const IconRemove = ({ color }: IconProps = { color: defaultIconColor }) => <span className={Styles[color]}><RemoveSign /></span>;
export const IconCheckmark = ({ color }: IconProps = { color: defaultIconColor }) => <span className={Styles[color]}><CheckmarkIcon /></span>;
export const MenuDosts = ({ color }: IconProps = { color: defaultIconColor }) => <span className={Styles[color]}><MenuDostsIcon /></span>;
export const IconPlusSignOnly = ({ color }: IconProps = { color: defaultIconColor }) => <span className={Styles[color]}><PlusSignOnly /></span>;
export const IconCopy = ({ color }: IconProps = { color: defaultIconColor }) => <span className={Styles[color]}><CopyIcon /></span>;
export const IconX = ({ color }: IconProps = { color: defaultIconColor }) => <span className={Styles[color]}><XIcon /></span>;
