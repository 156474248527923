import * as React from "react";
import BespokenButtonIcon from "../../../assets/bespoken_button_icon.svg";
import BespokenLockIcon from "../../../assets/bespoken_lock_icon.svg";
import { Button, Input } from "../../components";
import errorHelper from "../../helpers/error";
import LoginGoogle from "./LoginGoogle";

const globalWindow: any = typeof (window) !== "undefined" ? window : {};

const authFormStyle = require("./AuthFormStyle.scss");

export interface AuthFormProps {
    error?: string;
    onSubmit: (email: string, pass: string) => void;
    onLoginWithGithub?: () => void;
    onLoginWithGoogle?: () => void;
    onLoginWithAmazon?: () => void;
    onSignUpWithEmail?: (email: string, pass: string, confirmPass: string) => void;
    onResetPassword?: (email: string) => void;
    location?: any;
    onEmailBlur?: (email: string) => void;
}

export interface AuthFormState {
    email: string;
    isConfirmPasswordVisible?: boolean;
}

export class AuthForm extends React.Component<AuthFormProps, AuthFormState> {

    constructor(props: AuthFormProps) {
        super(props);
        this.state = {
            email: "",
            isConfirmPasswordVisible: false
        };

        this.onEmailChange = this.onEmailChange.bind(this);
        this.onResetPassword = this.onResetPassword.bind(this);
    }

    onRegister() {
        this.setState(prevState => ({
            ...prevState,
            isConfirmPasswordVisible: true,
        }));
    }

    onEmailChange(email: string) {

    }

    onResetPassword(email: string) {
        if (this.props.onResetPassword) {
            this.props.onResetPassword(email);
        }
    }

    handleLogoClick = () => {
        globalWindow && globalWindow.location.assign("http://bespoken.io/");
    }

    render() {
        return (
            <div className={authFormStyle.main_form}>
                <NormalLoginForm
                    error={this.props.error}
                    onLogin={this.props.onSubmit}
                    onSignUpWithEmail={this.props.onSignUpWithEmail}
                    onEmailChange={this.onEmailChange}
                    onResetPassword={this.onResetPassword}
                    onLoginWithAmazon={this.props.onLoginWithAmazon}
                    onLoginWithGithub={this.props.onLoginWithGithub}
                    onLoginWithGoogle={this.props.onLoginWithGoogle}
                    location={this.props.location}
                    onEmailBlur={this.props.onEmailBlur}
                />
            </div>
        );
    }
}

export default AuthForm;

export interface NormalLoginFormProps {
    onEmailChange: (email: string) => void;
    onLogin: (email: string, pass: string) => void;
    onSignUpWithEmail: (email: string, pass: string, confirmPass: string) => void;
    onResetPassword: (email: string) => void;
    error?: string;
    onLoginWithGithub?: () => void;
    onLoginWithGoogle?: () => void;
    onLoginWithAmazon?: (isFromWebsite: boolean) => void;
    location?: any;
    onEmailBlur?: (email: string) => void;
}

interface NormalLoginFormState {
    error?: string;
    email?: string;
    password?: string;
    confirmPassword?: string;
    isConfirmPassword?: boolean;
}

export class NormalLoginForm extends React.Component<NormalLoginFormProps, NormalLoginFormState> {

    constructor(props: NormalLoginFormProps) {
        super(props);
        this.state = {
            error: props.error,
            email: '',
        };
    }

    componentWillReceiveProps(nextProps: NormalLoginFormProps, ctx: any) {
        this.setState(prevState => ({
            ...prevState,
            error: nextProps.error,
        }));
    }

    onEmailChange = (value: string) => {
        this.setState(prevState => ({
            ...prevState,
            email: value,
        }));
        if (this.props.onEmailChange) {
            this.props.onEmailChange(value);
        }
    }
    onEmailBlur = () => {
        if (this.props.onEmailBlur) {
            this.props.onEmailBlur(this.state.email);
        }
    }

    resetFields = (includePassword?: boolean) => {
        let newState: any = {
            confirmPassword: "",
            error: "",
        };
        if (includePassword) {
            newState.password = "";
        }
        this.setState(prevState => ({
            ...prevState,
            ...newState,
        }));
    }

    onPasswordChange = (value: string) => {
        this.setState(prevState => ({
            ...prevState,
            password: value,
        }));
    }

    onConfirmPassChange = (value: string) => {
        this.setState(prevState => ({
            ...prevState,
            confirmPassword: value,
        }));
    }

    onLogin = () => {
        let email = this.state.email;
        let pass = this.state.password;
        this.resetFields(true);
        this.setState(this.state);

        this.props.onLogin(email, pass);
    }

    onSignUpClick = () => {
        this.resetFields();
        this.setState(prevState => ({
            ...prevState,
            isConfirmPassword: true,
        }));
    }

    onCancelClick = () => {
        this.resetFields();
        this.setState(prevState => ({
            ...prevState,
            isConfirmPassword: false,
        }));
    }

    onSubmitClicked = () => {
        let email = this.state.email;
        let pass = this.state.password;
        let confirmPass = this.state.confirmPassword;

        this.setState(prevState => ({
            ...prevState,
            password: "",
            confirmPassword: "",
        }));
        this.props.onSignUpWithEmail(email, pass, confirmPass);
    }

    onPasswordReset = () => {
        this.props.onResetPassword(this.state.email);
    }

    onFormSubmit = () => {
        if (this.state.isConfirmPassword) {
            this.onSubmitClicked();
        } else {
            this.onLogin();
        }
    }

    renderResetPassword = () => {
      if (this.state.isConfirmPassword) {
        return <noscript />;
      }
      return (
        <div className={authFormStyle.reset_password_container}>
          <Button
              label="Reset Password"
              secondary
              onClick={this.onPasswordReset} />
        </div>
      );
    }

    renderActions = () => {
      if (this.state.isConfirmPassword) {
        return (
          <div data-id="actions">
            <Button className={`${authFormStyle.regular_button} login_email_id`}
                label="Cancel"
                accent
                onClick={this.onCancelClick} />
            <Button
                className={`${authFormStyle.regular_button} submit_button_id`}
                label="Submit"
                icon={<BespokenButtonIcon />}
                accent
                onClick={this.onSubmitClicked}
            />
          </div>
        );
      }
      return (
        <div data-id="actions">
          <Button className={`${authFormStyle.regular_button} login_email_id`}
              label="Log in"
              accent
              icon={<BespokenLockIcon />}
              onClick={this.onLogin} />
          <Button className={authFormStyle.regular_button}
              label="Register"
              icon={<BespokenButtonIcon />}
              accent
              onClick={this.onSignUpClick} />
        </div>);
    }

    render() {
        return (
            <div className={authFormStyle.main_normal_login_form}>

                <LoginForms
                    email={this.state.email}
                    password={this.state.password}
                    confirmPassword={this.state.confirmPassword}
                    showConfirmPassword={this.state.isConfirmPassword}
                    error={this.state.error}
                    onEmailChange={this.onEmailChange}
                    onPasswordChange={this.onPasswordChange}
                    onConfirmPasswordChange={this.onConfirmPassChange}
                    onPasswordSubmit={this.onFormSubmit}
                    onConfirmPasswordSubmit={this.onFormSubmit}
                    onEmailBlur={this.onEmailBlur}
                    />
                {this.renderResetPassword()}
                {this.renderActions()}
                <div className={authFormStyle.or_separation}><span>or</span></div>
                <div>
                    <LoginGoogle onLoginWithGoogle={this.props.onLoginWithGoogle} />
                </div>
            </div>
        );
    }
}

export interface LoginFormsProps {
    email: string;
    password: string;
    confirmPassword: string;
    error: string;
    showConfirmPassword: boolean;
    onEmailChange: (newText: string) => void;
    onPasswordChange: (newText: string) => void;
    onConfirmPasswordChange: (newText: string) => void;
    onPasswordSubmit: () => void;
    onConfirmPasswordSubmit: () => void;
    onEmailBlur: () => void;
}

interface LoginFormsState {
}

export class LoginForms extends React.Component<LoginFormsProps, LoginFormsState> {

    emailInputField: any;

    constructor(props: LoginFormsProps) {
        super(props);

        this.onPasswordKeyPress = this.onPasswordKeyPress.bind(this);
        this.onConfirmPasswordKeyPress = this.onPasswordKeyPress.bind(this);
    }

    onConfirmPasswordKeyPress(event: any) {
        this.onKeyPress("confirmPassword", event);
    }

    onPasswordKeyPress(event: any) {
        this.onKeyPress("password", event);
    }

    onKeyPress(name: string, event: any) {
        if (event.charCode === 13) {
            if (name === "password") {
                this.props.onPasswordSubmit();
            } else if (name === "confirmPassword") {
                this.props.onConfirmPasswordSubmit();
            }
        }
    }

    handleEmailInputRef = (email: any) => {
        this.emailInputField = email;
    }

    componentWillReceiveProps = (nextProps: any) => {
        if (this.props.error === nextProps.error) return;
        const notValidEmail = errorHelper.isEqualToErrorCode(nextProps.error, "custom/not-valid-email");
        const invalidEmail = errorHelper.isEqualToErrorCode(nextProps.error, "auth/invalid-email");
        if (notValidEmail || invalidEmail) {
            this.emailInputField.focus();
        }
    }

    showError = () => {
        const errorMessage = this.props.error && errorHelper.getMessage(this.props.error);
        return <section data-id="error" dangerouslySetInnerHTML={{ __html: errorMessage}} />;
    }

    render() {
        const emailProps = { autoFocus: true };
        return (
            <div className={authFormStyle.login_inputs_container}>
                <Input
                    className={"email_text_id"}
                    ref={this.handleEmailInputRef}
                    label="Email"
                    type="text"
                    value={this.props.email}
                    onBlur={this.props.onEmailBlur}
                    onChange={this.props.onEmailChange}
                    {...emailProps}
                />
                <Input
                    label="Password"
                    type="password"
                    value={this.props.password}
                    onChange={this.props.onPasswordChange}
                    onKeyPress={this.onPasswordKeyPress}
                />
                <Input
                    hidden={!this.props.showConfirmPassword}
                    label="Confirm Password"
                    type="password"
                    value={this.props.confirmPassword}
                    onChange={this.props.onConfirmPasswordChange}
                    onKeyPress={this.onPasswordKeyPress}
                />
                {this.showError()}
            </div>
        );
    }
}
