import React = require("react");
import { Button } from "react-toolbox/lib/button";

import { BespokenModal } from "../../../components/Modal/BespokenModal";
import UserUtils from "../../../utils/User";
import {
    Checkbox,
} from "../../index";
import { User } from "../../../reducers/user";

const AccountStyle = require("../account-style.scss");
const bespokenButton = require("../../../themes/bespoken_button.scss");

interface CookieSectionProps {
    user: User;
    setLoading: (value: boolean) => any;
    changeSnackMessage: (snackbarMessage: string) => any;
}

interface CookieSectionState {
    acceptCookie: boolean;
    openDialog: boolean;
}

export class CookieSection extends React.Component<CookieSectionProps, CookieSectionState> {
    constructor(props: any) {
        super(props);
        this.state = {
            acceptCookie: true,
            openDialog: false,
        };
    }

    componentDidMount() {
        this.initCheckbox();
    }

    initCheckbox = async () => {
        try {
            const optOut = !UserUtils.getTagManagerUserId();
            this.setState({ acceptCookie: !optOut });
        } catch (error) {
            this.props.changeSnackMessage(error);
        }
    }

    addOrRemoveOptOutCookie = (addCookie: boolean) => {
        const { user } = this.props;
        if (addCookie) {
            UserUtils.setTagManagerUserId(user?.name);
        } else {
            UserUtils.removeTagManagerUserId();
        }
        UserUtils.setOptOut(!addCookie);
    }

    handleOnChange = async (value: boolean) => {
        const { setLoading } = this.props;
        setLoading && setLoading(true);
        try {
            this.addOrRemoveOptOutCookie(value);
            this.setState({ acceptCookie: value });
            this.props.changeSnackMessage("Your cookie usage preferences have been updated.");
        } catch (error) {
            this.props.changeSnackMessage(error);

        }
        setLoading && setLoading(false);
    }

    toogleDialog = () => {
        this.setState(prevState => ({ openDialog: !prevState.openDialog }));
    }

    render() {
        return (
            <div className={`${AccountStyle.accountSection}`}>
                <div className={AccountStyle.description}>
                    <h4>Cookie Usage</h4>
                    <span>Mmmm... cookies</span>
                </div>
                <div className={AccountStyle.content}>
                    <div className={`${AccountStyle.fields_container} ${AccountStyle.test}`}>
                        <div className={`${AccountStyle.property}`}>
                            <Checkbox
                                label="I consent to the use of cookies"
                                checked={this.state.acceptCookie}
                                onChange={(value: any) => this.handleOnChange(value)}
                            />
                            <a target={""} onClick={this.toogleDialog} className={`${AccountStyle.right} ${AccountStyle.label}`}>
                                {"Cookie policy"}</a>
                        </div>
                    </div>
                </div>
                <BespokenModal
                    title={"Cookie policy"}
                    showModal={this.state.openDialog}
                    dialogToggle={this.toogleDialog}>
                    <p>Bespoken provides tools to test, tune, and monitor voice experiences. Our tools, services, and
                        app use cookies to understand how people use our products.</p><br />
                    <p><strong>What are cookies and how we use them</strong></p><br />
                    <p>A cookie is a small text file that is stored in your device so our website can recognize it.
                        Almost every site uses cookies. We use cookies to understand how people interact with the
                        content and features on our website and use this information to improve our product. You’ll
                        never see a third-party personalize ad, and we don’t share the collected data with anyone.</p>
                    <br />
                    <div className={bespokenButton.buttons_container}>
                        <Button
                            style={{ marginBottom: 5 }}
                            theme={bespokenButton}
                            accent={true}
                            onClick={this.toogleDialog}>
                            Close
                        </Button>
                    </div>
                </BespokenModal>
            </div>
        )
    }
}