import "ace-builds/src-noconflict/mode-yaml";

// https://github.com/securingsincity/react-ace/blob/master/docs/FAQ.md#how-do-i-add-a-custom-mode
// @ts-ignore
export class CustomHighlightRules extends window.ace.acequire(
  "ace/mode/text_highlight_rules"
).TextHighlightRules {
  constructor() {
    super();
    // @ts-ignore
    this.$rules = {
      "start": [
        {
          token: "comment",
          regex: "#.*$"
        }, {
          token: "list.markup",
          regex: /^(?:-{3}|\.{3})\s*(?=#|$)/
        }, {
          token: "list.markup",
          regex: /^\s*[\-?](?:$|\s)/
        }, {
          token: "constant",
          regex: "!![\\w//]+"
        }, {
          token: "constant.language",
          regex: "[&\\*][a-zA-Z0-9-_]+"
        }, {
          token: ["meta.tag", "keyword"],
          regex: /^(\s*\w.*?)(:(?=\s|$))/
        }, {
          token: ["meta.tag", "keyword"],
          regex: /(\w+?)(\s*:(?=\s|$))/
        }, {
          token: "keyword.operator",
          regex: "<<\\w*:\\w*"
        }, {
          token: "keyword.operator",
          regex: "-\\s*(?=[{])"
        }, {
          token: "string", // single line
          regex: '["](?:(?:\\\\.)|(?:[^"\\\\]))*?["]'
        }, {
          token: "string", // multi line string start
          regex: /[|>][-+\d]*(?:$|\s+(?:$|#))/,
          // @ts-ignore
          onMatch: function (val, state, stack, line) {
            line = line.replace(/ #.*/, "");
            var indent = /^ *((:\s*)?-(\s*[^|>])?)?/.exec(line)[0]
              .replace(/\S\s*$/, "").length;
            // @ts-ignore
            var indentationIndicator = parseInt(/\d+[\s+-]*$/.exec(line));

            if (indentationIndicator) {
              indent += indentationIndicator - 1;
              this.next = "mlString";
            } else {
              this.next = "mlStringPre";
            }
            if (!stack.length) {
              stack.push(this.next);
              stack.push(indent);
            } else {
              stack[0] = this.next;
              stack[1] = indent;
            }
            return this.token;
          },
          next: "mlString"
        }, {
          token: "string", // single quoted string
          regex: "['](?:(?:\\\\.)|(?:[^'\\\\]))*?[']"
        }, {
          token: "constant.language.boolean",
          regex: "\\b(?:true|false|TRUE|FALSE|True|False|yes|no)\\b"
        }, {
          token: "paren.lparen",
          regex: "[[({]"
        }, {
          token: "paren.rparen",
          regex: "[\\])}]"
        }, {
          token: "text",
          regex: /[^\s,:\[\]\{\}]+/
        }
      ],
    };
  }
}

// @ts-ignore
export default class CustomMode extends window.ace.acequire("ace/mode/yaml")
  .Mode {
  constructor() {
    super();
    // @ts-ignore
    this.HighlightRules = CustomHighlightRules;
  }
}