import { UserReduxActions } from "../actions/user";
import { FETCH_USER, SET_USER } from "../constants/user";
import { OrganizationProfile } from "./organization";

export type OnboardingData = {
  ibm?: any;
  organizationName: string;
};

export type User = {
  id: string;
  name?: string;
  email?: string;
  jobTitle?: string;
  memberOf?: string[];
  defaultOrganizationId?: string;
  intercomContactId?: string;
  mailchimpId?: string;
  skipEmailVerification?: boolean;
  onboardingAppointmentSet?: boolean;
  userRequiresActivation?: boolean;
  surveyCompleted?: boolean;
  surveyData?: any;
  profile?: OrganizationProfile;
  weeklyStats?: boolean; // TODO impl
  isSubscribedToMailchimp?: boolean // TODO impl
  onboardingData?: OnboardingData;
};

export type UserState = {
  currentUser?: User;
};

const INITIAL_STATE: UserState = {};

export function user(state: UserState = INITIAL_STATE, action: UserReduxActions): UserState {
  switch (action.type) {

    case FETCH_USER:
      return { ...state };
    case SET_USER:
      return { ...state, currentUser: action.userState.currentUser };
    default:
      return state;
  }
}
