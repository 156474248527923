import * as React from "react";
import SliderToolbox from "react-toolbox/lib/slider";

const theme = require("./sliderTheme.scss");
const style = require("./sliderStyles.scss");

interface SliderProps {
    onChange?: any;
    value?: number;
    range: [number, number];

}

interface SliderStates {
}

export default class Slider extends React.Component<SliderProps, SliderStates> {
    constructor(props: SliderProps) {
        super(props);
    }

    handleOnChange = (value: number) => {
        const { onChange } = this.props;
        onChange && onChange(value);
    }

    render({ value, range, ...rest } = this.props) {
        const getMin = range[0];
        const getMax = range[1];
        return (
            <SliderToolbox snaps={true} min={getMin} max={getMax} step={1}
                theme={theme}
                value={value}
                onChange={this.handleOnChange}
                {...rest} />
        );
    }
}
