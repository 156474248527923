import React = require("react");
import { Button } from "react-toolbox/lib/button";
import Input from "react-toolbox/lib/input";
import auth from "../../../services/auth";

const AccountStyle = require("../account-style.scss");
const InputTheme = require("../../../themes/input.scss");
const bespokenButton = require("../../../themes/bespoken_button.scss");

interface SecuritySectionProps {
    setLoading: (value: boolean) => any;
    changeSnackMessage: any;
}

interface SecuritySectionState {
    error: string;
    success: string;
    currentPassword: string;
    newPassword: string;
    repeatPassword: string;
    doTheUserHaveAPassword: boolean;
}

export class SecuritySection extends React.Component<SecuritySectionProps, SecuritySectionState> {
    constructor(props: any) {
        super(props);
        this.state = {
            error: "",
            success: "",
            currentPassword: "",
            newPassword: "",
            repeatPassword: "",
            doTheUserHaveAPassword: true,
        };
    }

    componentDidMount() {
        const doTheUserHaveAPassword = auth.doTheUserHaveAPassword();
        this.setState(prevState => ({
            ...prevState,
            doTheUserHaveAPassword,
        }));

    }

    verifyAndChangeCurrentPassword = async () => {
        try {
            await auth.changeUserPassword(this.state.currentPassword, this.state.newPassword, this.state.repeatPassword);
        } catch (error) {
            if (error.code && error.code === "auth/wrong-password") {
                this.setState((prevState) => ({
                    ...prevState,
                    currentPassword: "",
                    error: "Old password doesn't match your current password",
                    success: "",
                    saveButtonDisabled: true
                }));

            } else {
                this.setState((prevState) => ({ ...prevState, error: error.message, success: "" }));
            }
            return false;
        }
        return true;
    }

    handleUpdatePassword = async () => {
        if (this.state.newPassword !== this.state.repeatPassword) {
            this.setState((prevState) => ({
                ...prevState,
                error: "New password fields don't match",
                success: "",
                repeatPassword: "",
            }));
            return;
        }
        if (this.state.newPassword.length < 8) {
            this.setState((prevState) => ({
                ...prevState,
                error: "New password must be greater than 8 characters",
                success: "",
                repeatPassword: "",
            }));
            return;
        }

        this.props.setLoading(true);
        const verification = await this.verifyAndChangeCurrentPassword();

        if (verification) {
            this.setState((prevState) => ({ ...prevState, error: "", success: "" }));
            this.props.changeSnackMessage("Your password has been updated.");
        }
        this.props.setLoading(false);

    }

    handleChangeInput = (value: string, event: any) => {
        if (event && event.target) {
            const nameInput = event.target.name;
            const value = event.target.value;
            switch (nameInput) {
                case "current":
                    this.setState((prevState) => ({ ...prevState, currentPassword: value }));
                    break;
                case "new":
                    this.setState((prevState) => ({ ...prevState, newPassword: value }));
                    break;
                case "repeat":
                    this.setState((prevState) => ({ ...prevState, repeatPassword: value }));
                    break;
                default:
                    break;
            }
        }
    }

    render() {
        return (
            <div className={`${AccountStyle.accountSection}`}>
                <div className={AccountStyle.description}>
                    <h4>
                        Security
                    </h4>
                    <span>Update your password. Make sure it's at least 8 characters and it includes numbers, lower and upper case letters</span>
                </div>
                <div className={`${AccountStyle.content}`}>
                    <div className={AccountStyle.fields_container}>
                        <div className={AccountStyle.property}>
                            <span>{'Current password'}</span>
                            <Input
                                maxLength={100}
                                type="Password"
                                name="current"
                                theme={InputTheme}
                                className={InputTheme.user_input}
                                value={this.state.currentPassword}
                                onChange={this.handleChangeInput} />
                        </div>

                        <div className={AccountStyle.property}>
                            <span>{'New password'}</span>
                            <Input
                                maxLength={100}
                                type="Password"
                                name="new"
                                theme={InputTheme}
                                className={InputTheme.user_input}
                                value={this.state.newPassword}
                                onChange={this.handleChangeInput} />
                        </div>

                        <div className={AccountStyle.property}>
                            <span>{'Re-enter new password'}</span>
                            <Input
                                maxLength={100}
                                type="Password"
                                name="repeat"
                                theme={InputTheme}
                                className={InputTheme.user_input}
                                value={this.state.repeatPassword}
                                onChange={this.handleChangeInput} />
                        </div>
                        <div className={`${AccountStyle.property} ${AccountStyle.fix_property}`} />
                    </div>
                    <div className={AccountStyle.button_container}>
                        <span className={AccountStyle.error}>{this.state.error}</span>
                        <Button
                            theme={bespokenButton}
                            className={bespokenButton.medium}
                            accent={true}
                            label={"Update"}
                            disabled={!this.state.doTheUserHaveAPassword}
                            onClick={this.handleUpdatePassword} />
                    </div>
                    {!this.state.doTheUserHaveAPassword &&
                        <div className={`${AccountStyle.overlay}`} />
                    }

                </div>
            </div>
        )
    }
}
