import * as React from "react";
import { Button } from "react-toolbox/lib/button";
import BespokenCheckIcon from "../../assets/bespoken_check_icon.svg";
import BinocularsIcon from "../../assets/binoculars.svg";
// import source from "../../src/services/source";
const ButtonTheme = require("../themes/bespoken_button.scss");
const MarketplaceStyles = require("../themes/aws_marketplace.scss");

const getUrlParameter = (name: string) => {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(location.search);
  return results ? decodeURIComponent(results[1]) : "";
};

export default class MarketplaceFormPage extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { email: "", confirmEmail: "", subscribed: false, isSubmitting: false };
        this.subscribeToMarketplace = this.subscribeToMarketplace.bind(this);
        this.isValidEmail = this.isValidEmail.bind(this);
    }

    subscribeToMarketplace() {
        this.setState({ isSubmitting: true, hasError: false, errorMessage: "" });
        const awsToken = getUrlParameter("x-amzn-marketplace-token");
        const params = {
            email: this.state.email,
            awsToken: awsToken
        };
        // fetch(source.CREATE_AWS_MARKETPLACE_USER, {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //         "x-access-token": process.env.SOURCE_API_ACCESS_TOKEN,
        //     },
        //     body: JSON.stringify(params)
        // })
        // .then((res) => res.json())
        // .then((res) => {
        //     if (res.id) {
        //         this.setState({ subscribed: true });
        //     }
        //     if (res.status === "error") {
        //         this.setState({ subscribed: false, isSubmitting: false, hasError: true, errorMessage: res.message });
        //     }
        // });
    }

    isValidEmail() {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.state.email === "" ||
            this.state.confirmEmail === "" ||
            !emailRegex.test(String(this.state.email).toLowerCase()) ||
            !emailRegex.test(String(this.state.confirmEmail).toLowerCase())) {
            return false;
        }
        return this.state.email === this.state.confirmEmail;
    }

    render() {
        return (
            <div className={MarketplaceStyles.marketplace_container}>
                <div className={MarketplaceStyles.description} style={{backgroundImage: `url(${require("../../assets/images/bespoken_bumping.png")})`}}>
                    <BinocularsIcon data-id="icon" />
                    <h1>Bespoken Automated Testing for IVR</h1>
                    <p>Thank you for subscribing to Bespoken!</p>
                    <p>To complete the registration process, please fill in the required information.</p>
                    <div>Bespoken</div>
                </div>
                <div className={MarketplaceStyles.form} style={{backgroundImage: `url(${require("../../assets/images/bg_mountains.png")})`}}>
                    {
                        this.state.subscribed ?
                            <div className={MarketplaceStyles.success}>
                                <BespokenCheckIcon/>
                                <h2>Success!</h2>
                                <p>Your account has been created. Please check your email for credentials, then click below to start.</p>
                                <p><a href="/dashboard/login">Go to Login</a></p>
                            </div> :
                            <div>
                                <h2>Create a new profile</h2>
                                <p>We'll email your credentials right away</p>
                                <div className={MarketplaceStyles.fieldset}>
                                    <label htmlFor="email">Email</label>
                                    <input id="email" onChange={e => this.setState({ email: e.target.value })} value={this.state.email} />
                                </div>
                                <div className={MarketplaceStyles.fieldset}>
                                    <label htmlFor="confirmEmail">Confirm your email</label>
                                    <input id="confirmEmail" onChange={e => this.setState({ confirmEmail: e.target.value })} value={this.state.confirmEmail}/>
                                </div>
                                <div className={MarketplaceStyles.fieldset}>
                                    <Button
                                        theme={ButtonTheme}
                                        primary={true}
                                        onClick={this.subscribeToMarketplace}
                                        disabled={!this.isValidEmail() || this.state.isSubmitting}
                                        label="Submit" />
                                </div>
                                {this.state.hasError ? <div className={MarketplaceStyles.has_error}>{this.state.errorMessage}</div> : undefined}
                            </div>
                    }
                </div>
            </div>
        );
    }
};

