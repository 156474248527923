import * as React from "react";
import { default as CheckboxToolbox } from "react-toolbox/lib/checkbox";

const checkboxStyles = require("./checkboxStyles.scss");
const theme = require("./checkboxTheme.scss");

export interface CheckboxProps {
  label?: any;
  onChange?: (value: boolean) => void;
  className?: string;
  checked?: boolean;
  index?: string;
}

export default class Checkbox extends React.Component<CheckboxProps, any> {

  handleOnChange = (value: boolean) => {
    const { onChange } = this.props;
    onChange && onChange(value);
  }

  render({ label, className, index, ...rest } = this.props) {
    return (
      <CheckboxToolbox
        key={index}
        theme={theme}
        label={label}
        className={className}
        onChange={this.handleOnChange}
        {...rest}
      />
    );
  }

}
