import { isNumber, isString } from "util";

export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const formatToHumanReadable = (words: Array<string> = [], toCapitalize?: boolean) => {
    words = words.filter(word => word);
    if (toCapitalize) {
        words = words.map(word => capitalize(word));
    }
    if (words.length > 2) {
        const lastWord = words.pop();
        return words.join(", ").concat(`, and ${lastWord}`);
    }
    // for one element the "and" will be ignored
    return words.join(" and ");
};

export const formatNumberByLocale = (data: number | string, locale: string = "en-EN") => {
    if (isNumber(data)) {
        return new Intl.NumberFormat(locale).format(data);
    } else if (isString(data) && Number(data)) {
        return new Intl.NumberFormat(locale).format(Number(data));
    }
    return "";
};
