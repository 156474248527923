
import { cloneDeep, get, isEmpty, last, toArray, trim } from "lodash";
import * as React from "react";
import { IconButton } from "react-toolbox/lib/button";
import Input from "react-toolbox/lib/input";
import BespokenRemoveIcon from "../../../assets/bespoken_remove_icon.svg";


const Styles = require("./RepeatOnPhraseListStyle.scss");
const bespokenInput = require("../../themes/bespoken_input.scss");


interface RepeatOnPhraseProps {
    onChange: (newValues: string[]) => void;
    values: string[];
    disabled?: boolean;
}

interface RepeatOnPhraseState {
    phrases: { phrase: string, key: number }[];
}

export default class RepeatOnPhraseComponent extends React.Component<RepeatOnPhraseProps, RepeatOnPhraseState> {

    rootComponent: HTMLDivElement;

    constructor(props: any) {
        super(props);

        const values = isEmpty(props.values) ? [""] : props.values.filter((phrase: string) => trim(phrase))

        this.state = {
            phrases: values.map((phrase: string, key: number) => ({ phrase, key }))
        };
    }

    render() {
        const { phrases } = this.state
        const { disabled } = this.props
        return <div
            className={Styles.container}
            ref={(input) => { if (input) { this.rootComponent = input } }}
        >
            {
                phrases
                    .map(({ phrase, key }, index, all) =>
                        <div
                            key={`phrase-container-key-${key}`}
                        >
                            <Input

                                label={"E.g. Sorry, I couldn't hear that"}
                                key={`phrase-key-${key}`}
                                theme={bespokenInput}
                                floating={false}
                                className={Styles.input_phrase}
                                onChange={(val: any) => this.handleOnChangeEntryValue(val, key)}
                                value={phrase}
                                disabled={disabled}
                                onKeyDown={(evt: any) => this.handleOnAddNewRow(key, evt)}
                            />
                            {phrases.length > 1 ? <IconButton className={Styles.btn_remove}
                                key={`phrase-remove-key-${key}`}
                                icon={<BespokenRemoveIcon />}
                                onClick={() => this.handleOnRemoveEntry(key)} /> : <div key={`phrase-remove-key-${key}`} />}
                        </div>
                    )
            }
            <div className={Styles.add_phrase_container}>
                <a className={Styles.btn_add_phrase} onClick={() => { !disabled && this.handleOnAddNewRow(phrases.length - 1) }}>Add phrase</a>
            </div>
        </div>
    }

    emitOnChange(): void {
        if (!this.props.onChange) {
            return
        }
        const phrasesArray = cloneDeep(this.state.phrases.map(({ phrase }) => phrase))
            .filter(phrase => trim(phrase))

        this.props.onChange(phrasesArray)
    }

    handleOnRemoveEntry(keyToRemove: number): void {
        const { phrases } = this.state

        const index = phrases.findIndex(({ key }) => key === keyToRemove)
        if (index < 0) {
            return
        }

        phrases.splice(index, 1)

        phrases
            .filter((_, i) => i >= index)
            .forEach((e, i) => {
                e.key = index + i
            })

        this.setState({ phrases }, () => this.emitOnChange())
    }

    handleOnChangeEntryValue(phrase: any, keyToEdit: number) {
        const { phrases } = this.state

        phrases
            .filter(({ key }) => key === keyToEdit)
            .forEach(e => {
                e.phrase = phrase
            })

        this.setState({ phrases }, () => this.emitOnChange())
    }

    handleOnAddNewRow(neighborEntryKey: number, evt: any = undefined) {
        const { phrases } = this.state
        if (!isEmpty(evt) && (get(evt, 'key') !== "Tab") || neighborEntryKey + 1 !== phrases.length) {
            return
        }

        if (!isEmpty(evt)) {
            evt.preventDefault();
            evt.stopPropagation();
        }

        const index = phrases.findIndex(({ key }) => key === neighborEntryKey)
        if (index < 0) {
            return
        }

        const key = index + 1
        phrases.splice(key, 0, { key, phrase: "" })

        this.setState({ phrases }, () => {
            this.emitOnChange()
            const input: HTMLInputElement = last(toArray(this.rootComponent.querySelectorAll(`input[type="text"]:last-of-type`)))
            input.focus()
        })
    }
}
