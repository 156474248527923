import * as cn from 'classnames';
import { chain, defaultTo, isEmpty, isNull, isUndefined, map, trim, uniqueId } from 'lodash';
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import Autocomplete from "react-toolbox/lib/autocomplete";
import { IconButton } from "react-toolbox/lib/button";
import Dropdown from "react-toolbox/lib/dropdown";
import Input from "react-toolbox/lib/input";
import Tooltip from "react-toolbox/lib/tooltip";
import BespokenAddIcon from "../../../../assets/bespoken_add_icon.svg";
import BespokenRearrengeIcon from "../../../../assets/bespoken_rearrange_icon.svg";
import SpinnerImg from "../../../../assets/spinner.svg";
import { TestResultStatus } from "../../../constants";
import { ACTIONS, OPERATORS } from "../../../constants/validation";
import { Source } from "../../../models/source";
import { IconLabelButton } from "../../IconLabelButton/IconLabelButton";
import ReactDiv from "../../ReactDiv";
import { ErrorBadgeSmall, MatchViewer, PlusSignIconButton, RemoveIconButton, SuccessBadgeSmall } from '../../lunacy';
import React = require("react");
import { PropertyDropdown } from '../../lunacy/dropdowns/PropertyDropdown';

const Styles = require("./test-editor.scss");

const validationVisualStyle = require("../../validation/ValidationVisualComponentStyle.scss");
const bespokenTooltipTheme = require("../../../themes/tooltip.scss");

export const TooltipDiv = Tooltip(ReactDiv);

const DISABLED_DUE_IS_NOT_IMPLEMENTED = false
const DragHandle = SortableHandle(() => <span><BespokenRearrengeIcon /></span>);

const SortableItem = SortableElement(({ grabbing, itemToRender, loadingResults, itemCount }: any) => <div className={`${validationVisualStyle.drag_sortable} ${grabbing ? validationVisualStyle.grabbing : ""}`}>
    {
        DISABLED_DUE_IS_NOT_IMPLEMENTED && !loadingResults && itemCount > 1 &&
        <DragHandle />
    }
    {itemToRender}
</div>);

const SortableParentContainer = SortableContainer(({ children }: any) => {
    return <div className={validationVisualStyle.sortable_list}>{children}</div>;
});

interface TestEditorProps {
    selectedTestIndex: number;
    source: Source;
    interimResults: string[];
    testResults: any;
    loadingValidationResults: boolean;
    addInteraction: (testIndex: number, interactionIndex?: number) => void;
    removeInteraction: (testIndex: number, interactionIndex: number) => void;
    updateInteractionInput: (testIndex: number, interactionIndex: number, input: string) => void;
    addAssertion: (testIndex: number, interactionIndex: number) => void;
    deleteAssertion: (testIndex: number, interactionIndex: number, itemIndex: number) => void;
    updateAssertionAction: (testIndex: number, interactionIndex: number, itemIndex: number, value: string) => void;
    updateAssertionOperator: (testIndex: number, interactionIndex: number, itemIndex: number, value: string) => void;
    updateAssertionValue: (testIndex: number, interactionIndex: number, itemIndex: number, value: string) => void;
}

interface TestEditorState {
    grabbing: boolean;
    utterClass: any;
}

export default class TestEditor extends React.Component<TestEditorProps, TestEditorState> {

    constructor(props: TestEditorProps) {
        super(props);

        this.state = {
            grabbing: false,
            utterClass: {},
        };
    }

    async componentDidMount() {
    }

    handleAddInteraction = () => {
        // console.log('handleAddInteraction');
        this.props.addInteraction(this.props.selectedTestIndex);
    }

    handleRemoveInteraction = (interactionIndex: number) => {
        // console.log('handleAddInteraction');
        this.props.removeInteraction(this.props.selectedTestIndex, interactionIndex);
    }
    handleInsertInteraction = (interactionIndex: number) => {
        // console.log('handleAddInteraction');
        this.props.addInteraction(this.props.selectedTestIndex, interactionIndex);
    }

    handleInputChange = (interactionIndex: any, value: string) => {
        const validValue = chain(value).split(/[\n\t ]/).map(w => trim(w)).join(' ').value()
        // console.log('handleInputChange', value);
        this.props.updateInteractionInput(this.props.selectedTestIndex, interactionIndex, validValue);
    }

    handleAddAssertion = (interactionIndex: any) => {
        // console.log('handleAddAssertion', interactionIndex);
        this.props.addAssertion(this.props.selectedTestIndex, interactionIndex);
    }

    handleRemoveAssertion = (interactionIndex: number, itemIndex: number) => {
        // console.log('handleRemoveAssertion', interactionIndex, itemIndex);
        this.props.deleteAssertion(this.props.selectedTestIndex, interactionIndex, itemIndex);
    }

    handleOperatorChange = (interactionIndex: any, itemIndex: any, value: string) => {
        // console.log('handleOperatorChange', itemIndex, value);
        this.props.updateAssertionOperator(this.props.selectedTestIndex, interactionIndex, itemIndex, value);
    }

    handleValueChange = (interactionIndex: any, itemIndex: any, value: string) => {
        const validValue = chain(value).split(/[\n\t ]/).map(w => trim(w)).join(' ').value()

        this.props.updateAssertionValue(this.props.selectedTestIndex, interactionIndex, itemIndex, validValue);
    }

    onSortStart = () => {
        // this.setState(prevState => ({
        //     ...prevState,
        //     grabbing: true,
        // }));
        // this.props.clearValidationResults();
    }

    onSortEnd = ({ oldIndex, newIndex }: any) => {
        // const {loadingValidationResults, yamlObject, selectedTestIndex} = this.props;
        // if (loadingValidationResults) return;
        // const moveYamlObject = {...yamlObject};
        // moveYamlObject.tests[selectedTestIndex].interactions = arrayMove(moveYamlObject.tests[selectedTestIndex].interactions, oldIndex, newIndex);
        // this.props.handleSetYamlObject(moveYamlObject);
        // this.setState(prevState => ({
        //     ...prevState,
        //     grabbing: false,
        // }));
    }

    handleInputBlur = (testIndex: any, utterIndex: any, event: any) => {
        let utterClass = { ...this.state.utterClass };
        if (!event.target.value) {
            utterClass[`${testIndex}${utterIndex}`] = validationVisualStyle.empty_expected || "empty_expected";
        } else {
            utterClass[`${testIndex}${utterIndex}`] = '';
        }
        this.setState(prevState => ({
            ...prevState,
            utterClass,
        }));
    }

    interactions = () => {
        const selectedIndex = this.props.selectedTestIndex;
        return this.props.source?.yamlObject?.tests.length &&
            this.props.source?.yamlObject?.tests[selectedIndex] &&
            this.props.source?.yamlObject?.tests[selectedIndex]?.interactions;
    }

    testResults = (testIndex: any, interactionIndex: any): any => {
        return this.props.testResults?.length &&
            this.props.testResults[testIndex] &&
            this.props.testResults[testIndex].interactions?.length &&
            this.props.testResults[testIndex].interactions[interactionIndex];
    }

    renderTestResultItem = (item: any, index: number, interactionIndex: number, expectedInput: string) => {
        // console.log('renderTestResultItem', item, index, interactionIndex);
        // console.log('Response', interactionIndex, item.status, item)
        const value = item.actual;
        const passed = item.passed;
        const status = item.status;
        const error = passed ? '' : item.actual;
        const pendingResult = status === TestResultStatus.PENDING;
        const valueIsNull = isUndefined(item?.actual) || isNull(item?.actual)

        if (status === TestResultStatus.COMPLETED) {
            return !valueIsNull ? (
                <TooltipDiv
                    key={`actual-tooltip-${interactionIndex}-${index}`}
                    tooltipPosition={'vertical'}
                    tooltip={value}
                    theme={bespokenTooltipTheme}
                >
                    <MatchViewer
                        type={passed ? "successful" : "failed"}
                        before={expectedInput}
                        after={value}
                    />
                </TooltipDiv>
            ) : (error &&
                <TooltipDiv
                    key={`actual_div${interactionIndex}${index}`}
                    data-react-toolbox="input"
                    tooltip={error}
                    theme={bespokenTooltipTheme}>
                    <MatchViewer
                        type={"failed"}
                        before={error}
                        after={error}
                    />
                </TooltipDiv>
            );
        }

        if (pendingResult && item.interimResult) {
            return (
                <TooltipDiv
                    key={`actual-tooltip-${interactionIndex}-${index}`}
                    tooltipPosition={'vertical'}
                    tooltip={item.interimResult}
                    theme={bespokenTooltipTheme}
                >
                <MatchViewer
                    type="interim"
                    before={item.interimResult}
                    after={item.interimResult}
                />
            </TooltipDiv>
            )
        } else if (pendingResult) {
            return (
                <div key={`actual_div${interactionIndex}${index}`} className={Styles.loading}>
                    <IconButton primary={true} icon={<SpinnerImg />} />
                </div>
            )
        } else {
            return <div key={`actual_div${interactionIndex}${index}`} />;
        }

    }

    renderInteractionItemRow = (item: any, index: any, interactionIndex: any, rowsLength: any) => {
        const renderClearButton = (index !== 0 || (index === 0 && rowsLength > 1));
        const emptyClass = this.state.utterClass[`${interactionIndex}${index}`];

        return [
            renderClearButton
                ? (
                    <span
                        key={`remove-assertion-button-${interactionIndex}`}
                        title={"Remove assertion"}
                        className={Styles.remove_expected}
                    >
                        <RemoveIconButton
                            onClick={this.handleRemoveAssertion.bind(this, interactionIndex, index)}
                        />
                    </span>
                )
                : undefined
            ,
            <PropertyDropdown
                key={`prompt-selector-${interactionIndex}-${index}`}
                onChange={val => {
                    this.props?.updateAssertionAction(
                        this.props?.selectedTestIndex,
                        interactionIndex,
                        index,
                        val.value
                    );
                }}
                platform={this.props?.source?.config?.platform}
                value={item?.action}
                customValue={item?.action}
            />
            ,
            <Dropdown
                key={`prompt-operator-${interactionIndex}-${index}-${uniqueId()}`}
                className={Styles.operator}
                onChange={this.handleOperatorChange.bind(this, interactionIndex, index)}
                source={OPERATORS}
                value={item.operator}
                auto={false}
            />,

            <Input
                key={`prompt-expected-${interactionIndex}-${index}}`}
                multiline={true}
                className={`${Styles.expected} ${emptyClass} text_input`}
                error={isEmpty(item?.value || item?.expected || "") ? 'Expected is required' : false}
                value={item.value || item.expected || ""}
                hint={"Expected value"}
                onChange={this.handleValueChange.bind(this, interactionIndex, index)}
                onBlur={this.handleInputBlur.bind(this, interactionIndex, index)}
            />
        ]
    }

    renderInteractionRow = (row: any, interactionIndex: any, rowsLength: any) => {
        let interimResult: (string | undefined) = undefined
        if (this.props.interimResults && interactionIndex < this.props.interimResults.length) {
            interimResult = this.props.interimResults[interactionIndex]
        }
        const interactionResult = this.testResults(this.props.selectedTestIndex, interactionIndex);
        const areResultsCompleted = interactionResult?.items?.length > 0 && interactionResult?.items?.every((item: any) => item.status === TestResultStatus.COMPLETED);
        const isResultOk = areResultsCompleted && interactionResult?.items.every((assert: any) => assert.passed);
        const failures = areResultsCompleted && interactionResult?.items.filter((assert: any) => !assert.passed).length;
        const borderClass = areResultsCompleted && (isResultOk ? validationVisualStyle.success : validationVisualStyle.error);
        const interactionBorderColor = areResultsCompleted && (isResultOk ? Styles.border_color_success : Styles.border_color_error);

        const sizeClass = areResultsCompleted && (interactionResult?.items?.length > 1 ? validationVisualStyle.small : validationVisualStyle.large);

        const renderClearButton = (interactionIndex !== 0 || (interactionIndex === 0 && rowsLength > 1));
        const emptyClass = this.state.utterClass[`${interactionIndex}`];
        const expectedList = row.items;
        const sourceType = this.props.source?.config?.platform;
        const inputDisabled = sourceType === "phone" && interactionIndex === 0 && row?.input === "$DIAL";

        return (
            <div className={Styles.step_row} key={`parent_div${interactionIndex}`}>
                <div className={Styles.side_buttons}>
                    {
                        renderClearButton &&
                        (
                            <span title={"Remove interaction"}>
                                <IconButton icon={"clear"}
                                    className={Styles.remove_row}
                                    onClick={this.handleRemoveInteraction.bind(this, interactionIndex)}
                                />
                            </span>
                        )
                    }
                    {
                        renderClearButton &&
                        (
                            <span title={"Insert interaction"}>
                                <IconButton icon={"add"}
                                    className={Styles.add_row_here}
                                    onClick={this.handleInsertInteraction.bind(this, interactionIndex)}
                                />
                            </span>
                        )
                    }
                </div>
                <div className={cn(Styles.interaction_row, interactionBorderColor)} key={`interaction-row-${interactionIndex}`}>
                    <div className={Styles.interaction_input}>
                        <Input
                            multiline={true}
                            name={`input${interactionIndex}`}
                            error={isEmpty(row?.input) ? 'Input is required' : false}
                            key={`input${interactionIndex}`}
                            value={row.input}
                            hint={"You say to the device ..."}
                            data-id="utter-input-interaction"
                            className={cn(emptyClass, 'text_input', Styles.multiline_text_input)}
                            onChange={this.handleInputChange.bind(this, interactionIndex)}
                            onBlur={this.handleInputBlur.bind(this, interactionIndex, "")}
                            disabled={inputDisabled}
                        />
                    </div>
                    <div className={Styles.expected_and_actual}>

                        {
                            chain(expectedList)
                                .map((item: any, expectedRowIndex: number) => {
                                    return map(this.renderInteractionItemRow(item, expectedRowIndex, interactionIndex, expectedList.length), (e, col) => {
                                        if (col === 0) { return <div key={`expected-r${expectedRowIndex}-c${col}`} className={Styles.prompt_remove_button}>{e}</div> }
                                        if (col === 1) { return <div key={`expected-r${expectedRowIndex}-c${col}`} className={Styles.prompt_input}>{e}</div> }
                                        if (col === 2) { return <div key={`expected-r${expectedRowIndex}-c${col}`} className={Styles.prompt_colon}>{e}</div> }
                                        if (col === 3) { return <div key={`expected-r${expectedRowIndex}-c${col}`} className={Styles.prompt_expected}>{e}</div> }
                                    })
                                })
                                .map((elementRow, index) => index === 0 ? elementRow.concat(<div className={Styles.prompt_add_button}> <PlusSignIconButton onClick={this.handleAddAssertion.bind(this, interactionIndex)} title={"Add assertion"} /> </div>) : elementRow)
                                .map((elementRow, respnoseIndex) => {
                                    const isRunning = interactionResult?.items?.length > 0 && interactionResult?.items?.some((item: any) => item.status === TestResultStatus.PENDING);


                                    // console.log('isRunning', isRunning)

                                    if (isEmpty(interactionResult?.items)) { return elementRow }
                                    if (isEmpty(interactionResult?.items[respnoseIndex])) { return elementRow }

                                    const expectedItem = expectedList[respnoseIndex]
                                    const expectedInput = expectedItem?.value || expectedItem?.expected || ""

                                    // Show the interim result if pending and it is the first one
                                    if (interactionResult?.items.length > 0) {
                                        for (const resultItem of interactionResult.items) {
                                            if (resultItem.status === 'PENDING' && resultItem.path === 'prompt') {
                                                if (interimResult) {
                                                    resultItem.interimResult = interimResult
                                                    break
                                                }
                                            }
                                        }
                                    }
                                    const actualResponses = this.renderTestResultItem(interactionResult?.items[respnoseIndex], respnoseIndex, respnoseIndex, expectedInput)
                                    return elementRow.concat(<div key={`actual-${respnoseIndex}`} className={Styles.actual_column}>{actualResponses}</div>)
                                })
                                .value()

                        }
                    </div>
                    <div className={`${Styles.resume}`}>
                        {/* <MatchViewer
                        type={"successful"}
                        before={"chainchito"}
                        after={"chainchito te hola"}
                    /> */}
                        {areResultsCompleted && (
                            isResultOk ? (
                                <SuccessBadgeSmall>
                                    {interactionResult?.items?.length || ""} Assertion{interactionResult?.items?.length === 1 ? "" : "s"} | &nbsp;
                                    {!failures ? "No Failures" : `${failures} Failure${failures === 1 ? "" : "s"}`}
                                </SuccessBadgeSmall>
                            ) : (
                                <ErrorBadgeSmall>
                                    {interactionResult?.items?.length || ""} Assertion{interactionResult?.items?.length === 1 ? "" : "s"} | &nbsp;
                                    {!failures ? "No Failures" : `${failures} Failure${failures === 1 ? "" : "s"}`}
                                </ErrorBadgeSmall>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const interactions = this.interactions();

        return (
            <div className={Styles.main_container}>
                <div className={cn(validationVisualStyle.container, Styles.container)} data-intercom-target="TestEditor">
                    <div className={validationVisualStyle.title_container}>
                        <div>
                            <h4>
                                Input
                            </h4>
                            <small>
                                (Your question to the device)
                            </small>
                        </div>
                        <div className={`${validationVisualStyle.title} ${validationVisualStyle.second_item}`}>
                            <h4>
                                Expected
                            </h4>
                            <small>
                                (Your expected answer from the device)
                            </small>
                        </div>
                        <div className={`${validationVisualStyle.title} ${validationVisualStyle.third_item}`}>
                            <h4>
                                Actual
                            </h4>
                            <small>
                                (The actual device response)
                            </small>
                        </div>
                    </div>
                    <SortableParentContainer onSortStart={this.onSortStart} onSortEnd={this.onSortEnd} useDragHandle={true}>
                        {
                            interactions && interactions.length > 0
                            && interactions?.map((row: any, index: number) => (
                                <SortableItem
                                    key={`interaction-${index}`}
                                    // loadingResults={this.props.loadingValidationResults}
                                    index={index}
                                    grabbing={this.state.grabbing}
                                    itemCount={interactions.length}
                                    itemToRender={this.renderInteractionRow(row, index, interactions.length)} />
                            ))
                        }
                    </SortableParentContainer>
                    <div className={Styles.add_button_container} data-intercom-target="AddInteraction">
                        <IconLabelButton
                            icon={<BespokenAddIcon />}
                            label={"Add Interaction"}
                            disable={this.props.loadingValidationResults}
                            onClick={this.handleAddInteraction}
                            size="small"
                        />
                    </div>
                </div>
                {this.props.children}
            </div>
        )
    }
}