import { isUndefined, partial } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { setCurrentPageProps } from "../../actions/context";
import { ShadowDom } from "../../components/ConsolidatedReports";
import { PluginDef } from "../../models/user";
import { State } from "../../reducers";
import { CurrentPageProps } from "../../reducers/context";

const Styles = require("./PluginPlayerPage.module.scss");

interface PluginPlayerPagePath {
    params: { pluginId: string; };
}

interface PluginPlayerPageDispatchedProps {
    setCurrentPageProps: (value: CurrentPageProps) => any;
}

interface PluginPlayerPageProps extends PluginPlayerPagePath, PluginPlayerPageDispatchedProps {
    plugins: PluginDef[]
}

interface PluginPlayerPageState {
    pluginURL: string;
    pluginTitle: string;
    pluginId: string;
}

export class PluginPlayerPage extends React.Component<PluginPlayerPageProps, PluginPlayerPageState> {
    domRef: { current: any, previous: any } = { current: undefined, previous: undefined }

    state: PluginPlayerPageState = {
        pluginURL: undefined,
        pluginTitle: undefined,
        pluginId: undefined
    };

    componentDidMount(): void {
        this.updateUrlValue();
    }

    updateUrlValue() {
        const currentPlugin = this.props.plugins.find((_, i) => `${i}` === this.props.params.pluginId)

        if (isUndefined(currentPlugin)) { return; }

        const { pluginTitle, pluginURL } = currentPlugin

        this.props.setCurrentPageProps({
            title: pluginTitle,
            subTitle: ""
        })

        this.setState({ pluginTitle, pluginURL })
    }

    componentDidUpdate(prevProps: Readonly<PluginPlayerPageProps>, prevState: Readonly<PluginPlayerPageState>, prevContext: any): void {
        if (prevProps.params.pluginId !== this.props.params.pluginId) {
            this.updateUrlValue()
            return
        }

        if (prevState.pluginURL !== this.state.pluginURL) {
            this.updateIframeUrl()
            return
        }
    }

    setup(shadowRoot: ShadowRoot): void {
        const iframe: HTMLIFrameElement = document.createElement('iframe');

        this.updateIframeUrl = partial(this.updateIframeUrl, iframe)

        const updateLayout = () => {
            if (isUndefined(this.domRef.current)) { return }
            if (isUndefined(this.domRef.current?.parentNode)) { return }
            if (isUndefined(this.domRef.current?.parentNode?.parentNode)) { return }

            const { clientWidth: width } = this.domRef.current.firstChild
            const { clientHeight: height, offsetWidth: containerWidth } = this.domRef.current.parentNode.parentNode

            iframe.style.width = `${Math.min(width, containerWidth - (80 + 64 * 2 + 40))}px`
            iframe.style.height = `${height - 144 - (40 + 50)}px`
        }

        iframe.src = this.state.pluginURL || 'about:blank'
        iframe.addEventListener('load', () => updateLayout());
        iframe.setAttribute('frameBorder', '0');

        window.addEventListener('resize', () => updateLayout());

        shadowRoot.appendChild(iframe);
    }

    updateIframeUrl(refIframe?: HTMLIFrameElement) {
        if (isUndefined(refIframe)) { return; }
        refIframe.src = this.state.pluginURL || 'about:blank'
    }

    render(): false | JSX.Element {
        if (this.domRef.current) {
            this.domRef.previous = this.domRef.current;
        }

        return (
            <div className={Styles.container}>
                <div className={Styles.plugin_player} ref={(ref) => { this.domRef.current = ref }}>
                    <ShadowDom builder={shadowRoot => this.setup(shadowRoot)} />
                </div>
            </div >
        )
    }
}


function mapStateToProps(state: State.All) {
    return {
      plugins: state.organization.selectedOrganization.plugins
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        setCurrentPageProps: (value: CurrentPageProps) => dispatch(setCurrentPageProps(value))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PluginPlayerPage);
