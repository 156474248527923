import React = require("react");
import {
    Checkbox,
} from "../../index";
import { User } from "../../../reducers/user";

const AccountStyle = require("../account-style.scss");
const InputTheme = require("../../../themes/input.scss");
const buttonStyle = require("../../../themes/amazon_pane.scss");
const bespokenButton = require("../../../themes/bespoken_button.scss");

interface CommunicationSectionProps {
    user: User;
    updateUser: (user: User) => any;
    setLoading: (value: boolean) => any;
    changeSnackMessage: (snackbarMessage: string) => any;
}

interface CommunicationSectionState {
}

export class CommunicationSection extends React.Component<CommunicationSectionProps, CommunicationSectionState> {
    constructor(props: any) {
        super(props);
        this.state = {
        };
    }

    handleWeeklyStatsChange = async (value: any) => {
        const { setLoading } = this.props;
        const userId = this.props.user.id;
        setLoading && setLoading(true);

        this.props.updateUser({
            id: userId,
            weeklyStats: value,
        }).then((result: any) => {
            setLoading && setLoading(false);
            if (result.error) {
                this.props.changeSnackMessage(result.error);
            } else {
                this.props.changeSnackMessage("Your communications preferences have been updated.");
            }
        });
    }

    handleSubscriptionChange = async (value: any) => {
        const { setLoading } = this.props;

        const userId = this.props.user.id;
        setLoading && setLoading(true);

        this.props.updateUser({
            id: userId,
            isSubscribedToMailchimp: value,
        }).then((result: any) => {
            setLoading && setLoading(false);
            if (result.error) {
                this.props.changeSnackMessage(result.error);
            } else {
                this.props.changeSnackMessage("Your communications preferences have been updated.");
            }
        });
    }

    render() {
        return (
            <div className={`${AccountStyle.accountSection}`}>
                <div className={AccountStyle.description}>
                    <h4>Communications</h4>
                    <span>Manage your Bespoken Newsletter subscription</span>
                </div>
                <div className={AccountStyle.content}>
                    <div className={AccountStyle.fields_container}>
                        <div className={AccountStyle.property}>
                            <Checkbox
                                label="Subscribe to our newsletter"
                                // checked={false} TODO fix mailchimp
                                onChange={(value: any) => this.handleSubscriptionChange(value)}
                            />
                        </div>
                        <div className={AccountStyle.property}>
                            <Checkbox
                                label="Monitoring - Weekly Summary"
                                checked={this.props.user?.weeklyStats}
                                onChange={(value: any) => this.handleWeeklyStatsChange(value)}
                            />
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}