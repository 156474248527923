import * as React from "react";
import { IconMenu, IconMenuProps } from "react-toolbox/lib/menu";

const MenuTheme = require("../../themes/bespoken_menu.scss");

export interface StyledMenuProps extends IconMenuProps {
    welcomeItem?: any;
}

interface BespokenMenuState { }

/**
 * This exists to re-use the consistent theme around the list.
 */
export class BespokenMenu extends React.Component<StyledMenuProps, BespokenMenuState> {
    render() {
        const { theme, welcomeItem, ...others } = this.props;

        const useTheme = { ...MenuTheme, ...theme };

        return (
            <IconMenu
                theme={useTheme}
                {...others}>
                {
                    this.props.welcomeItem
                }
                {this.props.children}
            </IconMenu>
        );
    }
}
