export enum PlanIdTypes {
    ENTERPRISE = "enterprise",
    CROSS_PLATFORM = "crossPlatform-v2",
    SINGLE_PLATFORM = "singlePlatform-v2",
    STARTUP = "startup",
    AWS_YEARLY = "aws-yearly",
    GENESYS_MONTHLY = "genesys-monthly",
    GENESYS_YEARLY = "genesys-yearly",
    VIRTUAL_ASSISTANTS = "virtualAssistants-v1",
    CONTACT_CENTERS = "contactCenters-v1",
    SELF_SERVE = "self-serve-v1",

}

export enum PlanNames {
    ENTERPRISE = "Enterprise",
    CROSS_PLATFORM = "Cross Platform",
    SINGLE_PLATFORM = "Single Platform",
    STARTUP = "Startup",
}

export enum IntervalPeriods {
    DAY = "day",
    MONTH = "month",
    YEAR = "year",
}

export enum PaymentMethods {
    CARD = "card",
    ACH_CREDIT_TRANSFER= "ach_credit_transfer"
}
