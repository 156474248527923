import { isFunction } from "lodash";
import {
  FETCH_USER,
  SET_USER,
  UPDATE_USER,
} from "../constants/user";
import { State } from "../reducers/index";
import { User, UserState } from "../reducers/user";
import { fetchInternalApi } from "../services/internal-api";
import { BrowserStorage, LocalStorage } from "../store/local-storage";

type Callback0 = (err?: Error) => void;
type Callback2 = (err: Error, data?: any) => void;
type DispatchFn<T> = (dispatch: () => any) => T;
type DispatchAndStoreFn<T> = (dispatch: () => any, getState: () => State.All) => T;

export type FetchUser = {
  type: FETCH_USER,
  userId: string,
  callback?: () => void
};
export function fetchUser(callback: Callback2 = err => { }): DispatchAndStoreFn<FetchUser> {
  return function (dispatch: any, getState: () => State.All) {
    const { id } = getState().context?.authUser || {};

    const promise = fetchInternalApi(`/users/${id}`);
    promise.then((user: any) => {
      dispatch(setUser({ currentUser: user }));
      return user;
    });

    if (isFunction(callback)) {
      promise
        .then((user) => callback(undefined, user));
    }

    promise.catch(err => callback(err));

    return {
      type: FETCH_USER,
      userId: id,
    };
  };
}

export type SetUser = {
  type: SET_USER,
  userState: UserState

};
export function setUser(userState: UserState): SetUser {
  return {
    type: SET_USER,
    userState,
  };
}

export type UpdateUser = {
  type: UPDATE_USER,
  userId: string,
  callback?: () => void
};
export function updateUser(user: User, callback: Callback2 = err => { }): DispatchAndStoreFn<UpdateUser> {
  return function (dispatch: any, getState: () => State.All) {
    const { id } = getState().context?.authUser || {};

    const promise = fetchInternalApi(`/users/${id}`, "PUT", user);
    promise.then((user: any) => {
      dispatch(setUser({ currentUser: user }));
      return user;
    });

    if (isFunction(callback)) {
      promise
        .then((user) => callback(undefined, user));
    }

    promise.catch(err => callback(err));

    return {
      type: UPDATE_USER,
      userId: id,
    };
  };
}

export type UserReduxActions = FetchUser | SetUser | UpdateUser;
