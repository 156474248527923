exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".BarsChartStyle__container--3dEmB {\n  width: 100%;\n  padding: 0;\n  margin: 0;\n  text-align: center; }\n  .BarsChartStyle__container--3dEmB .BarsChartStyle__item--1BkMc {\n    list-style: none; }\n  .BarsChartStyle__container--3dEmB .BarsChartStyle__item--1BkMc span {\n      display: block;\n      color: #969696;\n      font-size: 20.8px;\n      font-size: 1.3rem; }\n  .BarsChartStyle__container--3dEmB .BarsChartStyle__item--1BkMc span.BarsChartStyle__number--1Xi8y {\n        color: #425863;\n        margin-top: 20px;\n        font-size: 64px;\n        font-size: 4rem;\n        line-height: 64px;\n        line-height: 4rem; }\n  .BarsChartStyle__container--3dEmB .BarsChartStyle__item--1BkMc span.BarsChartStyle__number--1Xi8y.BarsChartStyle__subtitle_margin--TLZWV {\n          margin-top: 0; }\n", "",{"version":3,"sources":["/Users/diegomartin/Documents/GitHub/dashboard/src/components/Graphs/Bar/BarsChartStyle.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,UAAU;EACV,SAAS;EACT,kBAAkB,EAAA;EAJtB;IAMQ,gBAAgB,EAAA;EANxB;MAQY,cAAc;MACd,cAAc;MACd,iBAAiB;MAAjB,iBAAiB,EAAA;EAV7B;QAYgB,cAAc;QACd,gBAAgB;QAChB,eAAe;QAAf,eAAe;QACf,iBAAiB;QAAjB,iBAAiB,EAAA;EAfjC;UAiBoB,aAAa,EAAA","file":"BarsChartStyle.scss","sourcesContent":[".container {\n    width: 100%;\n    padding: 0;\n    margin: 0;\n    text-align: center;\n    .item {\n        list-style: none;\n        span {\n            display: block;\n            color: #969696;\n            font-size: 1.3rem;\n            &.number {\n                color: #425863;\n                margin-top: 20px;\n                font-size: 4rem;\n                line-height: 4rem;\n                &.subtitle_margin {\n                    margin-top: 0;\n                }\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);

// Exports
exports.locals = {
	"container": "BarsChartStyle__container--3dEmB",
	"item": "BarsChartStyle__item--1BkMc",
	"number": "BarsChartStyle__number--1Xi8y",
	"subtitle_margin": "BarsChartStyle__subtitle_margin--TLZWV"
};