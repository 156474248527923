// This import loads the firebase namespace along with all its type information.
import * as firebase from "firebase/app";

// These imports load individual services into the firebase namespace.
import "firebase/analytics";
import "firebase/auth";
import "firebase/database";

/**
 * RemoteService is the link between the world and the rest of the universe. The defaultService
 * provided is the concrete implementation of this link. The rest of the class is extensible for unit
 * testing so they can provide their own unique implementations.  This is useful for dependency injection
 * in which case the remote connections can be flaky.
 */
export namespace remoteservice {
    export interface Service {
        auth(): remoteservice.auth.Auth;
        database(): remoteservice.database.Database;
        emailAuthProviderGetCredencial(email: string, password: string): remoteservice.auth.AuthCredential;
    }

    export function defaultService(): Service {
        return new FirebaseService();
    }
}

export namespace remoteservice.user {
    export interface User extends UserInfo {
        emailVerified: boolean;
        sendEmailVerification(): Promise<any>;
        reload(): Promise<any>;
        providerData?: any[];
        reauthenticateWithCredential?: (credential: any) => {};
        updatePassword?: (password: string) => {};
        link?: (credential: any) => Promise<any>;
    }

    export interface UserInfo {
        displayName: string | undefined;
        email: string | undefined;
        photoURL: string | undefined;
        providerId: string;
        uid: string;
    }
}

export namespace remoteservice.auth {

    import UserCredential = firebase.auth.UserCredential;

    export class GoogleAuthProvider extends firebase.auth.GoogleAuthProvider {
    }

    export class EmailAuthProvider extends firebase.auth.EmailAuthProvider {
    }

    export interface AuthCredential extends firebase.auth.AuthCredential {
    }

    export interface AuthProvider {
        readonly providerId: string;
    }

    export interface Auth {
        currentUser: remoteservice.user.User | undefined;
        createUserWithEmailAndPassword(email: string, password: string): Promise<UserCredential>;
        fetchSignInMethodsForEmail(email: string): Promise<Array<string>>;
        signInWithRedirect(provider: AuthProvider): Promise<any>;
        getRedirectResult(): Promise<any>;
        signInWithPopup(provider: AuthProvider): Promise<any>;
        signInWithEmailAndPassword(email: string, password: string): Promise<any>;
        signInWithCustomToken(token: string): Promise<any>;
        signOut(): Promise<any>;
        sendPasswordResetEmail(email: string): Promise<any>;
    }
}

export namespace remoteservice.database {
    export interface Database {
        ref(path?: string): Reference;
    }

    export interface Reference extends Query {
        child(path: string): Reference;
        set(value: any): Promise<any>;
        update(values: Object, onComplete?: (a: Error | null) => any):
            Promise<any>;
        remove(): Promise<any>;
        on(listenerType: string, callback: (snapshot: any) => any): any;
    }

    export interface Query {
        once(eventType: string): Promise<any>;
    }
}

export default remoteservice;

/** Firebase implementation beyond this point **/

/**
 * Firebase implementation of the remote-service class.
 */
class FirebaseService implements remoteservice.Service {
    auth(): remoteservice.auth.Auth {
        return firebase.auth();
    };

    emailAuthProviderGetCredencial(email: string, password: string): firebase.auth.AuthCredential {
        return firebase.auth.EmailAuthProvider.credential(email, password);
    }

    database(): remoteservice.database.Database {
        return firebase.database();
    }
}
