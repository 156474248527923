import * as React from "react";
import { IconCheckmark, IconRemove } from "../icons";
import { Badge, IconPosition } from './Badge';

type BadageProps = {
    children?: JSX.Element[] | JSX.Element | string;
    iconPosition?: IconPosition;
}

export const SuccessBadge = (prop: BadageProps) => <Badge
    icon={<IconCheckmark />}
    iconPosition={prop?.iconPosition}
    color={"color_medium_aquamarine"}
>
    {prop?.children}
</Badge>

export const SuccessBadgeSmall = (prop: BadageProps) => <Badge
    icon={<IconCheckmark />}
    size={"small"}
    iconPosition={prop?.iconPosition}
    color={"color_medium_aquamarine"}
>
    {prop?.children}
</Badge>

export const ErrorBadgeSmall = (prop: BadageProps) => <Badge
    icon={<IconRemove />}
    size={"small"}
    iconPosition={prop?.iconPosition}
    color={"color_congo_pink"}
>
    {prop?.children}
</Badge>