import { chain } from "lodash";
import { OrganizationReduxActions } from "../actions/organization";
import { CREATE_VIRTUAL_DEVICE, FETCH_ORGANIZATION, FETCH_SOURCES, REFRESH_VIRTUAL_DEVICE_WITH_OAUTH_LINK, REFRESH_VIRTUAL_DEVICES_STATE, RESET_ORGANIZATION_STATE, SWITCH_ORGANIZATION, UPDATE_ORGANIZATION, UPDATE_SOURCE } from "../constants/organization";
import { PlanIdTypes } from "../constants/stripe";
import { Source } from "../models/source";

const immutabilityHelper = require("immutability-helper");

// @ts-ignore
const NULL = JSON.parse("null");

export type VirutalDevicePlatform = "alexa" | "google" | "twilio" | "sms" | "whatsapp" | "webchat" | "phone" | "hybrid";
type UID = string;
export type MemberRole = "admin" | "owner" | "member";
type OrganizationType = "organization" | "user" | "defaultOrganization";

type UserStatus = "unsubscribed" | "disabled" | "enabled" | "trial" | "trialEnded";

export type Plugin = {
    pluginIconURL?: string;
    pluginTitle?: string;
    pluginURL?: string;
    enabled?: boolean;
};

export type Subscription = {
    status: string,
    planId: string,
    platforms: string[],
    utterances?: number;
    dailyUtterances?: number;
    startDate?: string;
    endDate?: string;
    numberOfDays: number,
    numberOfDaysLeft: number,
    isSubscriptionExpired: boolean,
    isTrialTimeExpired: boolean,
    planDetails: string[];
    planName: string;
};

export type Organization = {
    id: UID;
    name: string;
    type: OrganizationType;
    profile: OrganizationProfile;
    ibmUser: false;
};
export type OrganizationProfile = {
    color?: string;
    avatarImageUrl?: string;
};
export type SelectedOrganization = Organization & {
    virtualDevices: VirtualDevices[];
    subscription: Subscription;
    locales: Locale[];
    owners?: Owner[];
    members?: Member[];
    invitations?: Invitation[];
    sources: OrganizationSource[];
    planId: string;
    status: UserStatus;
    isAdmin: boolean;
    isOwner: boolean;
    creationDate: string;
    githubRepo: string;
    plugins?: Plugin[];
    requiresActivation?: boolean;
};

export type OrganizationSource = Source & { organizationId: UID } & { id: string };

type Member = {
    id: UID;
    role: MemberRole;
    lastActive: Date;
    addedBy: string;
    addedByName?: string;
    addedDate: Date;

    email: UID;
    name: UID;
};
type Invitation = {
    id: UID;
    role: MemberRole;
    email: string;
    creationDate: Date;
    sentBy: string;
    sentByName: string;
    sentDate: Date;
};
type Owner = {
    id: UID;
};
export type VirtualDevices = {
    token: string;
    name: string;
    status: string;
    created: Date;
    platform: string;
};
type Locale = {
    platforms?: Platform[];
    localeId: string;
    label: string;
};
type Platform = {
    voices?: Voice[];
    platformId: string;
    defaultVoice: string;
};
type Voice = {
    voiceId: string;
    label: string;
    gender: string;
    isDefault: boolean;
};

export type SelectedSource = OrganizationSource;

export type OrganizationState = {
    organizations?: Organization[];
    selectedOrganization?: SelectedOrganization;
    selectedSource?: SelectedSource;
};


export enum VirtualDevicePlatform {
    alexa,
    google,
    twilio,
    sms,
    whatsapp,
    webchat,
    phone,
    hybrid,
}

export type VirtualDeviceRequest = {
    token?: string;
    paramOverride?: object;
    hardLimits?: boolean;
    platform?: string;
    /**
     * Will be ovveriden when sending
     */
    origin?: string;
    parameters?: { alexaMusic: boolean; }
} & { returnUrl?: string; };

const INITIAL_STATE: OrganizationState = {};

export function organization(state: OrganizationState = INITIAL_STATE, action: OrganizationReduxActions): OrganizationState {
    switch (action.type) {

        case FETCH_ORGANIZATION:
            return { ...state };

        case SWITCH_ORGANIZATION:
            return { ...state };

        case UPDATE_ORGANIZATION:
            return immutabilityHelper(action.organizationState, {
                selectedOrganization: {
                    virtualDevices: { $set: state?.selectedOrganization?.virtualDevices || [] }
                }
            });

        case FETCH_SOURCES:
            return { ...state };

        case UPDATE_SOURCE: {
            const newState = chain(state)
                .merge({ selectedOrganization: { sources: NULL } })
                .merge({ selectedOrganization: { sources: action.organizationSources } })
                .value();

            return { ...newState };
        }

        case CREATE_VIRTUAL_DEVICE:
            return { ...state };

        case REFRESH_VIRTUAL_DEVICE_WITH_OAUTH_LINK:
            return { ...state };

        case REFRESH_VIRTUAL_DEVICES_STATE: {
            const newState = immutabilityHelper(state, {
                selectedOrganization: {
                    virtualDevices: {
                        $set: action.virtualDevices
                    }
                }
            });

            return newState;
        }
        case RESET_ORGANIZATION_STATE: {
            return {};
        }
        default:
            return state;
    }
}