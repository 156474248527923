import * as cn from "classnames";
import * as React from "react";
const style = require("./leftPanelStyles.scss");


export class LeftPanel extends React.Component<any, any> {
  render({ fullHeight, className, children, image, ...rest } = this.props) {
    const _image = image && style.image;
    const _styles = image && { backgroundImage: `url(${image})`};
    const _fullHeight = fullHeight && style.fullHeight;
    return (
      <div className={cn(style.leftPanel, _fullHeight, _image, className)} style={_styles} {...rest}>
        {children}
      </div>
    );
  }
}
