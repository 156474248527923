import * as cn from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import Slider from 'react-toolbox/lib/slider';
import BespokenDownloadIcon from "../../../assets/bespoken_download_icon_2.svg";
import BespokenPauseAudio from "../../../assets/bespoken_pause_icon.svg";
import BespokenPlayAudio from "../../../assets/bespoken_run_all_icon.svg";
import { LoaderIndicator } from "../ConsolidatedReports";
import { EMPTY_AUDIO } from "../ConsolidatedReports/EmptyAudio";
import { AudioFile } from "./AudioFile";
import { DownloadFile } from "./DownloadFile";
import StreamPlayer from "../AudioPlayer/StreamPlayer"

const TrackSliderTheme = require("./baseTrackSliderTheme.scss");
const Styles = require("./AudioPlayer.module.scss");


const Pizzicato = require("pizzicato");


interface AudioPlayerProps {
    url: string;
    disable?: boolean;
    loading?: boolean;
}

interface AudioPlayerState {
    url?: string;

    sliderValue: number;
    newStartAudioValue: number;

    isDraggingSlider: boolean;

    paused: boolean;
    loadingFile: boolean;
    secondsElapsed: number;
    streaming: boolean;
    disable: boolean;
}



interface RefAudioFile {
    current: AudioFile;
}

interface Ref<R> {
    current: R;
}


export class AudioPlayer extends React.Component<AudioPlayerProps, AudioPlayerState> {

    static defaultProps: AudioPlayerProps = {
        url: EMPTY_AUDIO.src,
        disable: false,
        loading: false
    }

    audioFile: Ref<AudioFile> = { current: undefined }

    constructor(props: AudioPlayerProps) {
        super(props);

        const { url } = props
        this.state = {
            url,

            sliderValue: 0,
            newStartAudioValue: 0,

            isDraggingSlider: false,


            streaming: true,
            paused: true,
            loadingFile: false,
            secondsElapsed: 0,
            disable: props.disable
        };

        StreamPlayer.instance().onPlaying(() => {
            this.setState(prevState => ({
                ...prevState,
                disable: false,
                paused: false,
                streaming: true
            }));
        })
    }

    componentWillReceiveProps(nextProps: Readonly<AudioPlayerProps>, nextContext: any): void {
        const state: AudioPlayerState = this.state

        if (!isEqual(this.props.url, nextProps.url)) {
            state.url = nextProps.url
            state.sliderValue = 0
            state.secondsElapsed = 0
            state.streaming = false
            state.paused = true
        }

        if (!nextProps.url) {
            state.sliderValue = 0
            state.secondsElapsed = 0
        }

        if (!isEqual(this.props.disable, nextProps.disable)) {
            state.disable = nextProps.disable
        }

        state.loadingFile = nextProps.loading
        this.setState(state)
    }

    componentWillMount(): void {
        if (this.audioFile && this.audioFile.current) {
            this.audioFile.current.pause()
        }
    }

    formatHMS(value: number | any) {
        const sec = parseInt(value, 10); // convert value to number if it's string
        let hours: any = Math.floor(sec / 3600); // get hours
        let minutes: any = Math.floor((sec - (hours * 3600)) / 60); // get minutes
        let seconds: any = sec - (hours * 3600) - (minutes * 60); //  get seconds
        // add 0 if value < 10; Example: 2 => 02
        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        if (hours === '00') {
            return minutes + ':' + seconds; // Return is HH : MM : SS
        } else {
            return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
        }
    }

    render() {
        const {
            url,
            sliderValue,
            secondsElapsed,
            newStartAudioValue,
            paused,
            loadingFile,
            isDraggingSlider,
            disable
        } = this.state

        const downloadFile: Ref<DownloadFile> = { current: undefined }
        const disableActions = disable || loadingFile;

        return (
            <div className={Styles.root}>
                <LoaderIndicator className={cn(Styles.loadingIndicator, loadingFile ? undefined : Styles.hide)} />
                <div className={cn(Styles.container, loadingFile ? Styles.hide : undefined)}>
                    <DownloadFile
                        {...{ url }}
                        ref={ref => downloadFile.current = ref}
                        onError={err => console.error(err)}
                        onSucccess={filename => console.log("File downloaded", filename)}
                    />
                    <AudioFile
                        {...{ url, newStartAudioValue }}
                        ref={ref => this.audioFile.current = ref}

                        onFileLoading={() => this.setState({ loadingFile: true })}
                        onFileLoaded={() => this.setState({ loadingFile: false })}
                        onPlaying={() => this.setState({ paused: false })}
                        onPaused={() => this.setState({ paused: true })}
                        onEnd={() => this.setState({ paused: true })}
                        onUpdateTime={(secondsElapsed, sliderValue, duration) => {
                            if (isDraggingSlider) {
                                this.setState({ secondsElapsed })
                            } else {
                                this.setState({ sliderValue, secondsElapsed })
                            }
                        }
                        }
                    />
                    <div className={cn(Styles.header, disableActions ? Styles.hide : undefined)}>
                        { this.state.streaming ?
                            <span>Call stream</span>
                        :
                            <span>Call recording</span>
                        }

                        <BespokenDownloadIcon
                            onClick={async () => { if (!disableActions && !this.state.streaming) { await downloadFile?.current?.download() } }}
                            className={cn(disableActions || this.state.streaming ? Styles.disable : undefined)}
                        />
                    </div>
                    <div className={cn(Styles.controls, disableActions ? Styles.hide : undefined)}>
                        <div className={Styles.play}>
                            {
                                paused ?
                                    <BespokenPlayAudio
                                        onClick={() => {
                                            if (!disableActions) {
                                                if (this.state.streaming) {
                                                    StreamPlayer.instance().play()
                                                    this.setState({ paused: false })
                                                } else {
                                                    this.audioFile?.current?.play()
                                                }
                                            }
                                        }}
                                        className={cn(disableActions ? Styles.disable : undefined)}
                                    />
                                    :
                                    <BespokenPauseAudio
                                        onClick={() => {
                                            if (!disableActions) {
                                                if (this.state.streaming) {
                                                    StreamPlayer.instance().pause()
                                                    this.setState({ paused: true })
                                                } else {
                                                    this.audioFile?.current?.pause()
                                                }
                                            }
                                        }}
                                        className={cn(disableActions ? Styles.disable : undefined)}
                                    />
                            }
                        </div>
                        <Slider
                            className={Styles.slider}
                            disabled={disableActions}
                            theme={TrackSliderTheme}
                            value={this.state.sliderValue}
                            onChange={(sliderValue: number) => {
                                this.setState({ sliderValue: Math.max(sliderValue, 1) })
                            }}
                            onDragStart={() => this.setState({ isDraggingSlider: true })}
                            onDragStop={() => this.setState({ sliderValue, newStartAudioValue: sliderValue, isDraggingSlider: false })}
                        />
                        <span style={{ display: this.state.streaming ? 'none' : 'inherit'}}>{this.formatHMS(secondsElapsed)}</span>

                    </div>
                </div>
            </div>
        )
    }
}