import { chain, forEach, groupBy, includes, isArray, isString, reduce, replace, size, startsWith, trim, values } from "lodash";

const yaml = require("js-yaml");


/**
 * Utility methods
 */
class YamlHelper {

    /**
     * converts a yaml object into a yaml file
     * @param {object} yamlObject - yaml representation of a yaml file
     */
    static toYaml(yamlObject: any) {
        const configuration = yamlObject.configuration;
        const tests = yamlObject.tests;
        let contents = "";
        if (configuration) {
            contents += "---\nconfiguration:\n";
            contents += Object.keys(configuration)
                .map(key => `  ${key}: ${configuration[key]}`)
                .join("\n");
        }

        if (tests && tests.length) {
            for (let i = 0; i < tests.length; i++) {
                let testContent = "";
                if (i === 0 && contents === "") {
                    testContent = "---\n";
                } else {
                    testContent = "\n---\n";
                }
                const testSuffix = tests[i].only ? ".only" :
                    tests[i].skip ? ".skip" : "";
                testContent += `- test${testSuffix} : ${tests[i].name}\n`;
                if (tests[i].tags && tests[i].tags.length) {
                    testContent += `- tags : ${tests[i].tags.join(", ")}\n`;
                }
                testContent += tests[i].interactions
                    .map((interaction: any) => {
                        const assertionsRawYaml = chain(interaction?.items)
                            // grouping by action and operator
                            .groupBy(s => `${s.action}(${s.operator})`)
                            // stringifying the values to have yaml format compatible with bespoken yaml
                            .mapValues((v, k) => reduce(v, (prev, curr, i, all) => {
                                const isFirstValue = i === 0;
                                const isSingleValue = all?.length === 1;
                                const isNumericValue = includes([">", "<", ">=", "<="], curr?.operator);

                                // for numeric values use one line definition
                                if (isNumericValue) { return `${prev}\n  - ${v[0]?.action} ${v[0]?.operator} ${curr?.value}`; }

                                // for single values use one line definition
                                if (isSingleValue) { return `${prev}\n  - ${v[0]?.action} ${v[0]?.operator} ${this.addQuotesToNonRegexString(curr?.value)}`; }

                                // for first value with multiple line definition
                                if (isFirstValue) {
                                    const header = `\n  - ${v[0]?.action/* The action */} ${v[0]?.operator/* The operator */}`;
                                    return `${header}\n    - ${this.addQuotesToNonRegexString(curr?.value)}`;
                                }

                                return `${prev}\n    - ${this.addQuotesToNonRegexString(curr?.value)}`;
                            }, ""))
                            .values()
                            .join("")
                            .value();

                        const rawYaml = `- ${interaction?.input} :${assertionsRawYaml}\n`;
                        return rawYaml;
                    }).join("");
                contents += testContent;
            }
        }

        if (contents.includes(" : \"\"\n-")) {
            contents = contents.replace(/ : ""\n-/gi, "\n-");
        }
        if (contents.includes(" : \"\"\n")) {
            contents = contents.replace(/ : ""\n/gi, "\n");
        }
        return contents;
    }

    /**
     * Add quotes to string if not a regex
     * @param {string} value - original string
     * @return {string} string with quotes
     */
    static addQuotesToNonRegexString(value: string) {
        if (value.startsWith && value.startsWith("/")) {
            return value;
        }

        const MATCH_ESCAPE_CHARACTERS = /[\\"]/g;

        // is string
        return `"${replace(value, MATCH_ESCAPE_CHARACTERS, "\\$&")}"`;
    }

    /**
     * Returns true if is a string object
     * @param {any} s - object to verify
     * @return {boolean} string with quotes
     */
    static isString(s: any) {
        return (s && (typeof s === "string" || s instanceof String));
    }

    /**
     * converts a yaml object into a ui yaml object used for dashboard
     * @param {object} yamlObject - yaml representation of a yaml file
     */
    static toYamlDashboardObject(yamlObject: any) {
        const data = {
            tests: yamlObject?.tests.map((test: any) => ({
                ...test,
                interactions: test.interactions.map((interaction: any) => ({
                    input: interaction.input,
                    items:
                        (interaction.expected || []).reduce((acc: any, item: any) => {
                            // Only for yaml arrays when operator is not ":"
                            if (item?.operator !== ":" && isString(item.value) && startsWith(trim(item.value), "-")) {
                                const values = yaml.load(item?.value);
                                forEach(values, (element: any) => {
                                    acc.push({
                                        action: item.action,
                                        value: element,
                                        operator: item.operator,
                                    });
                                });
                            } else if (isArray(item.value)) {
                                item.value.forEach((element: any) => {
                                    acc.push({
                                        action: item.action,
                                        value: element,
                                        operator: item.operator,
                                    });
                                });
                            } else {
                                acc.push({
                                    action: item.action,
                                    value: item.value,
                                    operator: item.operator,
                                });
                            }
                            return acc;
                        }, []).concat(
                            (interaction.expressions || []).reduce((acc: any, expression: any) => {
                                if (isArray(expression.value)) {
                                    expression.value.forEach((element: any) => {
                                        acc.push({
                                            action: expression.path,
                                            value: element,
                                            operator: ":"
                                        });
                                    });
                                } else {
                                    acc.push({
                                        action: expression.path,
                                        value: expression.value,
                                        operator: ":"
                                    });
                                }
                                return acc;
                            }, []))
                }))
            })) || [],
        };
        return data;
    }
}

export default YamlHelper;
