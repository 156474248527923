import * as cn from "classnames";
import { chain, endsWith, isEqual, isNumber, property, startsWith, toString, trim } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { State } from "../../../reducers";
import { copyToClipboard, joinElements, tryParseDate, tryParseNumber } from "../../../utils/ReactHelpers";
import { IconCheckmark, IconRemove } from "../icons";
import { IconButton } from "react-toolbox/lib/button";
import { CopyIconButton } from "../icon-buttons/IconButton";
import SpinnerImg from "../../../../assets/spinner.svg";

const Styles = require("./styles.scss");
const Diff = require('diff');

type DiffEntry = {
    count?: number;
    added?: boolean;
    removed?: boolean;
    value: string;
}

type ResultType = 'successful' | 'failed' | 'interim'

interface DispatchToProps { }
function mapDispatchToProps(dispatch: any) { return {} }

interface StateToProps { }
function mapStateToProps(state: State.All) { return {} }

interface ExposedProps {
    before: string;
    after: string;
    type: ResultType;
    showIcon?: boolean;
}
function mergeProps(ownProps: any, stateProps: any, dispatchProps: ExposedProps) {
    return { ...ownProps, ...stateProps, ...dispatchProps }
}

interface ComponentProps extends DispatchToProps, StateToProps, ExposedProps { }

interface ComponentState {
    diff: DiffEntry[]
}

class Component extends React.Component<ComponentProps, ComponentState> {

    static defaultProps: ComponentProps = {
        before: undefined,
        after: undefined,
        type: "successful",
        showIcon: true
    }

    constructor(props: ComponentProps) {
        super(props);
        this.state = {
            diff: this.diff(props.before, props.after, props.type)
        }
    }

    componentWillReceiveProps(nextProps: Readonly<ComponentProps>, nextContext: any): void {
        console.log(this.props, nextProps)
        if (isEqual(this.props, nextProps)) { return }

        this.setState({ diff: this.diff(nextProps.before, nextProps.after, nextProps.type) })
    }

    diff(before: string, after: string, type: ResultType): DiffEntry[] {
        const trimedBefore = trim(before)

        if (trimedBefore === '*') {
            if (type === "successful") { return [{ value: after, added: true }] }
            if (type === "failed") { return [{ value: after }] }

            throw new Error('Diff parameters not implemented')
        }

        if (startsWith(trimedBefore, '/') && endsWith(trimedBefore, '/')) {
            if (type === "successful") { return [{ value: after }] }
            if (type === "failed") { return [{ value: after, removed: true }, { value: after, added: true }] }

            throw new Error('Diff parameters not implemented')
        }

        if (isNumber(tryParseNumber(before, () => undefined))) {
            if (type === "successful") { return [{ value: after }] }
            if (type === "failed") { return [{ value: after, removed: true }, { value: after, added: true }] }

            throw new Error('Diff parameters not implemented')
        }

        try {
            return Diff.diffWords(toString(before), toString(after), { ignoreCase: true })
        } catch (err) {
            // Handling unexpected error when diff
            if (type === "successful") { return [{ value: after, added: true }] }
            return [{ value: after }]
        }
    }

    render(): false | JSX.Element {
        const resultAsText = { text: "" }
        return (
            <div className={cn(Styles.component)}>

                <div className={Styles.results_component}>
                    {this.props?.type === "successful" &&
                        chain(this.state.diff)
                            .map((d, index) => {
                                if (d.removed) { return undefined }
                                resultAsText.text += d.value
                                const className = d.added ? cn(Styles.successful_text) : cn(Styles.successful_text, Styles.matches)
                                return this.interceptResults(d.value, `span-${index}`, className)
                            })
                            .filter(d => d)
                            .value()
                    }

                    {this.props?.type === "failed" &&
                        chain(this.state.diff)
                            .map((d, index, all) => {

                                if (d.removed) { return undefined }
                                resultAsText.text += d.value

                                const className = (d.added && all[index - 1]?.removed) ? cn(Styles.failed_text, Styles.matches) : cn(Styles.failed_text)
                                return this.interceptResults(d.value, `span-${index}`, className)
                            })
                            .filter(d => d)
                            .value()
                    }

                    {this.props?.type === "interim" &&
                        <span key="span-0" className={cn(Styles.interim_text)}>{this.props.before}</span>
                    }
                </div>

                {this.props?.showIcon
                    && <div
                        key={`copy-button`}
                        className={Styles.copy_button}>
                        <CopyIconButton
                            color={"color_spanish_gray"}
                            title={"Copy to clipboard"}
                            onClick={() => copyToClipboard(this.props?.type === "interim" ? this.props.before : resultAsText.text)}
                        />
                    </div>
                }

                {this.props?.showIcon && this.props?.type !== "interim" &&
                    <div
                        key={`icon`}
                        className={Styles.icon}
                    >
                        {this.props?.type === "successful"
                            ? <IconCheckmark color={"color_medium_aquamarine"} />
                            : <IconRemove color={"color_congo_pink"} />
                        }
                    </div>
                }

                {this.props?.showIcon && this.props?.type === "interim" &&
                    <div
                        key={`icon`}
                        className={Styles.icon}
                    >
                        <IconButton primary={true} icon={<SpinnerImg />} />
                    </div>
                }
            </div>
        )
    }

    interceptResults(text: string, key: string, className: string) {
        const parts = text.split(/\{\{IMAGE=(.*?)\}\}/);
        return (
            <span key={key} className={className}>
                {parts.map((part, index) => {
                    if (index % 2 === 0) {
                        return part;
                    } else {
                        const url = part;
                        return (
                            <p>
                                <a key={index} href={url} target="_blank" rel="noopener noreferrer">
                                    [IMAGE]
                                </a>
                            </p>
                        );
                    }
                })}
            </span>
        );
    }
}

export const MatchViewer = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(Component);
