import { find, flatMap, get } from "lodash";

export const getLocalesByType = (appSettings: any, sourceType: string) => {
    const locales = appSettings?.locales || {};
    const localesList = Object.getOwnPropertyNames(locales).map(localeId => ({id: localeId, ...locales[localeId]}));
    const filteredLocales = localesList.filter(locale => locale.hasOwnProperty(sourceType));
    const filteredDisabled = filteredLocales.filter(locale => locale[`${sourceType}`] && !locale[`${sourceType}`].disable);
    return filteredDisabled;
};

export const getLocaleDefaultVoice = (appSettings: any, locale: string) => {
    const locales = appSettings?.locales || {};
    const match = find(locales, (value, key) => key === locale);
    return match?.defaultVoice || 0;
};

export const getVoicesByLocaleAndType = (appSettings: any, sourceType: string, locale: string) => {
    const locales = appSettings?.locales || {};
    const voices = appSettings?.voices || {};
    // const voicesByLocaleRef = await ref.child("locales").child(locale).child(sourceType).child("voices").once("value");
    // const voicesByLocale: any[] = voicesByLocaleRef && voicesByLocaleRef.val();
    // const voicesRef = await ref.child("voices").once("value");
    // const voices = voicesRef && voicesRef.val();
    // const voicesList = Object.getOwnPropertyNames(voices).map(voiceId => ({id: voiceId, ...voices[voiceId]}));
    // return voicesList.filter(voice => voicesByLocale && voicesByLocale.indexOf(voice.id) >= 0);
    const voicesByLocale = get(locales, `${locale}.${sourceType}.voices`) || [];
    const voicesArray = flatMap(voices, (value, key) => ({...value, id: key }));
    return voicesArray.filter(voice => voicesByLocale && voicesByLocale.indexOf(voice.id) >= 0);
};

export const localeKeys = {
    DE_DE: "de-DE",
    EN_AU: "en-AU",
    EN_CA: "en-CA",
    EN_GB: "en-GB",
    EN_IN: "en-IN",
    EN_US: "en-US",
    FR_FR: "fr-FR",
    JA_JP: "ja-JP",
    ES_ES: "es-ES",
    IT_IT: "it-IT",
    ES_US: "es-US",
    PT_BR: "pt-BR",
};
