import * as React from "react";
import { sourceType } from "../../constants";
import BespokenSolidCircle from "../../../assets/solid_circle.svg";


export const typeImage: any = {
    alexa: <img src={"https://firebasestorage.googleapis.com/v0/b/bespoken-tools.appspot.com/o/images%2Famazon-alexa-tini.png?alt=media&token=2785b968-43af-4860-8db3-7e669e77a6d2"} alt={"alexa icon"} />,
    google: <img src={"https://firebasestorage.googleapis.com/v0/b/bespoken-tools.appspot.com/o/images%2Fgoogle-tini.png?alt=media&token=31f8bbd5-f4c1-473a-8c8b-1557b161dce3"} alt={"google icon"} />,
    twilio: <img src={"https://firebasestorage.googleapis.com/v0/b/bespoken-tools.appspot.com/o/images%2Fphone-tini.png?alt=media&token=cc26fc46-50a1-4982-a994-a1935339bbb9"} alt={"phone icon"} />,
    sms: <img src={"https://firebasestorage.googleapis.com/v0/b/bespoken-tools.appspot.com/o/images%2Fsms-tini.png?alt=media&token=2dc0cf55-103a-4acf-8975-e84a8ca9b1fa"} alt={"sms icon"} />,
    whatsapp: <img src={"https://firebasestorage.googleapis.com/v0/b/bespoken-tools.appspot.com/o/images%2Fwhatsapp-tini.png?alt=media&token=2b3dd4aa-726a-4f65-88e1-2c2be4ea9b31"} alt={"whatsapp icon"} />,
    phone: <img src={"https://firebasestorage.googleapis.com/v0/b/bespoken-tools.appspot.com/o/images%2Fphone-tini.png?alt=media&token=cc26fc46-50a1-4982-a994-a1935339bbb9"} alt={"phone icon"} />,
    webchat: <img src={"https://firebasestorage.googleapis.com/v0/b/bespoken-tools.appspot.com/o/images%2Fwebchat.png?alt=media&token=25ee025c-524a-424e-bfad-4a99ae49f6bf"} alt={"webchat icon"} />,
    voiceflow: <img src={"https://firebasestorage.googleapis.com/v0/b/bespoken-tools.appspot.com/o/images%2Fvoiceflow.png?alt=media&token=fb4faaca-c71a-4348-a7a7-1b6838e3fbee"} alt={"voiceflow icon"} />,
    lex: <img src={"https://firebasestorage.googleapis.com/v0/b/bespoken-tools.appspot.com/o/images%2Famazon-lex.png?alt=media&token=ea4e668d-b55e-4185-ab76-8d33364622ee"} alt={"lex icon"} />,
    watson: <img src={"https://firebasestorage.googleapis.com/v0/b/bespoken-tools.appspot.com/o/images%2Fwatson.png?alt=media&token=fb4faaca-c71a-4348-a7a7-1b6838e3fbee"} alt={"watson icon"} />,
    email: <img src={"https://firebasestorage.googleapis.com/v0/b/bespoken-tools.appspot.com/o/images%2Femail1.png?alt=media&token=56636a28-4b93-421c-844f-718c55d4e2ee"} alt={"email icon"} />,
};

interface TypeImageComponentProps {
    platform?: "alexa" | "google" | "twilio" | "sms" | "whatsapp" | "phone" | "voiceflow" | "lex" | "watson" | "email" | string;
}

interface TypeImageComponentState {
}

export default class Card extends React.Component<TypeImageComponentProps, TypeImageComponentState> {
    static defaultProps = {
        platform: "",
    };

    constructor(props: any) {
        super(props);
    }

    render() {
        const platformExists = (Object as any).values(sourceType).includes(this.props.platform);

        if (!platformExists) { return <BespokenSolidCircle /> }

        return typeImage[this.props.platform];
    }
}
