import {get, remove, set} from "js-cookie";

export namespace UserUtils {

    // Bespoken Tag Manager UserId
    export const setTagManagerUserId = async (name: string) => {
        try {
            const names = name.match(/^(\S+)\s(.*)/);
            const firstName = (names && names.length && names[1]) || name;
            const geoDataPromise = await fetch("https://ipinfo.io?token=885a71114a7535", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            });
            const geoData = geoDataPromise.json && await geoDataPromise.json();
            const tagManagerUserId = `${firstName}_from_${geoData.city}_${geoData.country}`.toLowerCase();
            set("bespokenAnalyticsSetting", tagManagerUserId, { path: "/", domain: ".bespoken.io", secure: true });
            return tagManagerUserId;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    };

    export const getTagManagerUserId = () => {
        return get("bespokenAnalyticsSetting");
    };

    export const removeTagManagerUserId = () => {
        remove("bespokenAnalyticsSetting", { path: "/", domain: ".bespoken.io" });
    };

    // Bespoken Opt Out
    export const setOptOut = (value: any) => {
        set("optOut", value, { path: "/", domain: ".bespoken.io", secure: true });
    };

    export const getOptOut = () => {
        return get("optOut");
    };

    export const removeOptOut = () => {
        remove("optOut", { path: "/", domain: ".bespoken.io" });
    };


    // Bespoken User Status
    export const setBespokenUserStatusCookie = (userStatus: string) => {
        const bespokenUserStatus = get("bespokenUserStatus");
        if (bespokenUserStatus !== userStatus) {
            set("bespokenUserStatus", userStatus, { path: "/", domain: ".bespoken.io", secure: true });
        }
    };
}

export default UserUtils;
