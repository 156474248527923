export type LOGIN = "LOGIN";
export const LOGIN: LOGIN = "LOGIN";

export type SIGNUP = "SIGNUP";
export const SIGNUP: SIGNUP = "SIGNUP";

export type LOGIN_WITH_GOOGLE = "LOGIN_WITH_GOOGLE";
export const LOGIN_WITH_GOOGLE: LOGIN_WITH_GOOGLE = "LOGIN_WITH_GOOGLE";

export type LOGOUT = "LOGOUT";
export const LOGOUT: LOGOUT = "LOGOUT";

export type UPDATE_AUTH_USER = "UPDATE_AUTH_USER";
export const UPDATE_AUTH_USER: UPDATE_AUTH_USER = "UPDATE_AUTH_USER";

export type REMOVE_AUTH_USER = "REMOVE_AUTH_USER";
export const REMOVE_AUTH_USER: REMOVE_AUTH_USER = "REMOVE_AUTH_USER";

export type SET_LATEST_ORGANIZATION_ID = "SET_LATEST_ORGANIZATION_ID";
export const SET_LATEST_ORGANIZATION_ID: SET_LATEST_ORGANIZATION_ID = "SET_LATEST_ORGANIZATION_ID";

export type FETCH_APP_SETTINGS = "FETCH_APP_SETTINGS";
export const FETCH_APP_SETTINGS: FETCH_APP_SETTINGS = "FETCH_APP_SETTINGS";

export type SET_APP_SETTINGS = "SET_APP_SETTINGS";
export const SET_APP_SETTINGS: SET_APP_SETTINGS = "SET_APP_SETTINGS";

export type SET_CURRENT_PAGE_PROPS = "SET_CURRENT_PAGE_PROPS";
export const SET_CURRENT_PAGE_PROPS: SET_CURRENT_PAGE_PROPS = "SET_CURRENT_PAGE_PROPS";

export type ONBOARDING_VIA_INVITE = "ONBOARDING_VIA_INVITE";
export const ONBOARDING_VIA_INVITE: ONBOARDING_VIA_INVITE = "ONBOARDING_VIA_INVITE";