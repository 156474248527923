import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import * as Redux from "redux";

import { LOGOUT_USER } from "../constants";
import { authForm, AuthFormState } from "./auth-form";
import { context, ContextState } from "./context";
import { debouncing, DebouncingState } from "./debounce";
import { loading, LoadingState } from "./loading";
import { log, LogState } from "./log";
import { notification, NotificationState } from "./notification";
import { organization, OrganizationState } from "./organization";
import { reporting, ReportingState } from "./reporting";
import { session, SessionState } from "./session";
import { source as sourceGit, SourceState as SourceGitState } from "./source";
import { source, SourceState } from "./source-legacy";
import { toasts, ToastState } from "./toast";
import { user, UserState } from "./user";

export namespace State {
  export type All = {
    authForm: AuthFormState,
    debouncing: DebouncingState,
    log: LogState,
    notification: NotificationState,
    routing: any,
    // session: SessionState,
    source: SourceState;
    toasts: ToastState;
    loading?: LoadingState;
    sourceGit: SourceGitState;
    reporting: ReportingState;

    organization: OrganizationState;
    user: UserState;
    context: ContextState
  };
}

const appReducer = combineReducers<State.All>({
  authForm,
  debouncing,
  log,
  notification,
  routing: routerReducer,
  // session,
  source,
  toasts,
  loading,
  sourceGit,
  reporting,
  organization,
  user,
  context,
});

// Intercept global actions, such as logout to reset the state.
// From http://stackoverflow.com/a/35641992/1349766
const rootReducer = function (state: State.All, action: Redux.Action) {

  if (action.type === LOGOUT_USER) {
    state = undefined;
  }

  // and pass it on to the high level reducers
  return appReducer(state, action);
};

export default rootReducer;
