import { OrganizationProfile } from "../reducers/organization";

export type ChameleonSettings = {
    annWelcomeTour: string;
    annVirtualDeviceTour: string;
    annEndToEndTestingTour: string;
    wtWelcomeTour: string;
    wtVirtualDeviceTour: string;
    wtEndToEndTestingTour: string;
};

export type IntercomSettings = {
    autoWelcomeTour: string;
    autoVirtualDevicesTour: string;
    autoTestPageTour: string;
    manualTestPageTour: string;
  };

export type AppSettings = {
    chameleon: ChameleonSettings;
    intercom: IntercomSettings;
};

type UserProfile = OrganizationProfile;

export interface BespokenUserProperties {
    id: string;
    email: string;
    name: string;
    company: string;
    jobTitle: string;
    planId: string;
    benefits: any;
    githubUser: boolean;
    githubRepo: string;
    weeklyStats: boolean;
    isSubscribedToMailchimp: boolean;
    virtualDevices: any;
    appSettings: AppSettings;
    profile?: UserProfile;
}

export class BespokenUser implements BespokenUserProperties {
    id: string;
    email: string;
    name: string;
    company: string;
    jobTitle: string;
    planId: string;
    benefits: any;
    githubUser: boolean;
    githubRepo: string;
    weeklyStats: boolean;
    isSubscribedToMailchimp: boolean;
    virtualDevices: any;
    appSettings: AppSettings;
    profile: UserProfile;

    constructor(props: BespokenUserProperties) {
        this.id = props.id;
        this.email = props.email;
        this.name = props.name;
        this.planId = props.planId;
        this.company = props.company;
        this.jobTitle = props.jobTitle;
        this.benefits = props.benefits;
        this.githubUser = props.githubUser;
        this.weeklyStats = props.weeklyStats;
        this.isSubscribedToMailchimp = props.isSubscribedToMailchimp;
        this.githubRepo = props.githubRepo;
        this.virtualDevices = props.virtualDevices;
        this.appSettings = props.appSettings;
        this.profile = props.profile;
    }

    get platforms() {
        return this.benefits && this.benefits?.platforms || [];
    }
}

export default BespokenUser;
