import arrayMove = require("array-move");
import { isArray, isFunction } from "lodash";
import {
    SET_LOADING,
    SET_SOURCE_SEARCH_TEXT,
    SET_SOURCES,
    SOURCE_DELETE_SOURCE,
    SOURCE_INTERIM_RESULTS_UPDATE,
    SOURCE_RESULTS_INIT,
    SOURCE_RESULTS_PENDING,
    SOURCE_RESULTS_UPDATE,
    SOURCE_RESULTS_UPDATE_ALL_PENDING_STATUS,
    SOURCE_RESULTS_UPDATE_INTERACTION_ALL_STATUS,
    SOURCE_SET_CURRENT,
    SOURCE_SET_IS_VALID_SYNTAX,
    SOURCE_SET_LOADING,
    SOURCE_UPDATE_META,
    SOURCE_UPDATE_MONITORING_SCHEDULE_TYPE,
    SOURCE_UPDATE_NAME,
    SOURCE_UPDATE_TEST_DESCRIPTION,
    SOURCE_UPDATE_TEST_ID,
    SOURCE_UPDATE_TEST_SUITE_DESCRIPTION,
    SOURCE_UPDATE_YAML,
    SOURCE_UPDATE_YAML_OBJECT,
    SOURCE_YAMLOBJECT_ADD_ASSERTION,
    SOURCE_YAMLOBJECT_ADD_INTERACTION,
    SOURCE_YAMLOBJECT_ADD_TEST,
    SOURCE_YAMLOBJECT_CLONE_TEST,
    SOURCE_YAMLOBJECT_DELETE_ASSERTION,
    SOURCE_YAMLOBJECT_DELETE_TEST,
    SOURCE_YAMLOBJECT_REMOVE_INTERACTION,
    SOURCE_YAMLOBJECT_RESET_CONFIG,
    SOURCE_YAMLOBJECT_UPDATE_ASSERTION_ACTION,
    SOURCE_YAMLOBJECT_UPDATE_ASSERTION_OPERATOR,
    SOURCE_YAMLOBJECT_UPDATE_ASSERTION_VALUE,
    SOURCE_YAMLOBJECT_UPDATE_CONFIG,
    SOURCE_YAMLOBJECT_UPDATE_CONFIG_NESTED,
    SOURCE_YAMLOBJECT_UPDATE_INTERACTION_INPUT,
    SOURCE_YAMLOBJECT_UPDATE_ONLY_FLAG,
    SOURCE_YAMLOBJECT_UPDATE_SKIP_FLAG,
    SOURCE_YAMLOBJECT_UPDATE_TEST_NAME,
    TestResultStatus
} from "../constants";
import { Source } from "../models/source";
import { State } from "../reducers/index";
import BespokenApi from "../services/bespoken-api";
import { fetchInternalApi, testSuiteMapper } from "../services/internal-api";

export type SetSources = {
    type: SET_SOURCES,
    sources: Source[],
    finishLoading: boolean,
};

export type SourceDeleteSource = {
    type: SOURCE_DELETE_SOURCE,
    id: string
};

export type SetCurrentSource = {
    type: SOURCE_SET_CURRENT,
    currentSource: Source,
    finishLoading: boolean,
};

export type SetSourceSearchText = {
    type: SET_SOURCE_SEARCH_TEXT,
    searchText: string
};

export type SourceYamlObjectUpdateConfig = {
    type: SOURCE_YAMLOBJECT_UPDATE_CONFIG,
    property: string,
    value: string,
};

export type SourceYamlObjectResetConfig = {
    type: SOURCE_YAMLOBJECT_RESET_CONFIG,
    property: string,
    value: string,
};

export type SourceYamlObjectUpdateConfigNested = {
    type: SOURCE_YAMLOBJECT_UPDATE_CONFIG_NESTED,
    property: string,
    value: string,
};

export type SourceYamlObjectAddTest = {
    type: SOURCE_YAMLOBJECT_ADD_TEST,
    name: string;
};

export type SourceYamlObjectDeleteTest = {
    type: SOURCE_YAMLOBJECT_DELETE_TEST,
    index: number;
};

export type SourceYamlObjectCloneTest = {
    type: SOURCE_YAMLOBJECT_CLONE_TEST,
    index: number;
};

export type SourceYamlObjectUpdateTestName = {
    type: SOURCE_YAMLOBJECT_UPDATE_TEST_NAME,
    testIndex: number;
    name: string;
};

export type SourceYamlObjectUpdateSkipFlag = {
    type: SOURCE_YAMLOBJECT_UPDATE_SKIP_FLAG,
    testIndex: number;
    value: boolean;
};

export type SourceYamlObjectUpdateOnlyFlag = {
    type: SOURCE_YAMLOBJECT_UPDATE_ONLY_FLAG,
    testIndex: number;
    value: boolean;
};

export type SourceYamlObjectAddInteraction = {
    type: SOURCE_YAMLOBJECT_ADD_INTERACTION,
    testIndex: number;
    interactionIndex?: number;
};

export type SourceYamlObjectRemoveInteraction = {
    type: SOURCE_YAMLOBJECT_REMOVE_INTERACTION,
    testIndex: number;
    interactionIndex: number;
};

export type SourceYamlObjectUpdateInteractionInput = {
    type: SOURCE_YAMLOBJECT_UPDATE_INTERACTION_INPUT,
    testIndex: number;
    interactionIndex: number;
    input: string;
};

export type SourceYamlObjectAddAssertion = {
    type: SOURCE_YAMLOBJECT_ADD_ASSERTION,
    testIndex: number;
    interactionIndex: number;
};

export type SourceYamlObjectDeleteAssertion = {
    type: SOURCE_YAMLOBJECT_DELETE_ASSERTION,
    testIndex: number;
    interactionIndex: number;
    itemIndex: number;
};

export type SourceYamlObjectUpdateAssertionAction = {
    type: SOURCE_YAMLOBJECT_UPDATE_ASSERTION_ACTION,
    testIndex: number;
    interactionIndex: number;
    itemIndex: number;
    value: string;
};

export type SourceYamlObjectUpdateAssertionOperator = {
    type: SOURCE_YAMLOBJECT_UPDATE_ASSERTION_OPERATOR,
    testIndex: number;
    interactionIndex: number;
    itemIndex: number;
    value: string;
};

export type SourceYamlObjectUpdateAssertionValue = {
    type: SOURCE_YAMLOBJECT_UPDATE_ASSERTION_VALUE,
    testIndex: number;
    interactionIndex: number;
    itemIndex: number;
    value: string;
};

export type SourceResultsInit = {
    type: SOURCE_RESULTS_INIT,
    yamlObject: any;
};

export type SourceResultsPending = {
    type: SOURCE_RESULTS_PENDING,
    yamlObject: any;
};

export type SourceResultsUpdateInteractionAllStatus = {
    type: SOURCE_RESULTS_UPDATE_INTERACTION_ALL_STATUS,
    testIndex: number;
    interactionIndex: number;
    status: string;
};

export type SourceResultsUpdate = {
    type: SOURCE_RESULTS_UPDATE,
    testIndex: number;
    interactionIndex: number;
    conversationId: string;
    path: string;
    value: any;
};

export type SourceInterimResultsUpdate = {
    type: SOURCE_INTERIM_RESULTS_UPDATE,
    interimResults: string[]
};

export type SourceResultsUpdateAllPendingStatus = {
    type: SOURCE_RESULTS_UPDATE_ALL_PENDING_STATUS,
    testIndex: number;
    status: string;
};

export type SourceSetLoading = {
    type: SOURCE_SET_LOADING,
    finishLoading: boolean,
};

export type SourceUpdateMeta = {
    type: SOURCE_UPDATE_META,
    property: string,
    value: string,
};

export type SourceUpdateName = {
    type: SOURCE_UPDATE_NAME,
    value: any,
};

export type SourceUpdateYaml = {
    type: SOURCE_UPDATE_YAML,
    value: any,
};

export type SourceUpdateYamlObject = {
    type: SOURCE_UPDATE_YAML_OBJECT,
    value: any,
};

export type SourceSetIsYamlSyntax = {
    type: SOURCE_SET_IS_VALID_SYNTAX,
    value: string,
};

export type SourceYamlUpdateMonitoringSchedule = {
    type: SOURCE_UPDATE_MONITORING_SCHEDULE_TYPE,
    selectedScheduleType: string;
    emailsToNotify: string;
};


export type SourceYamlUpdateTestId = {
    type: SOURCE_UPDATE_TEST_ID,
    testIndex: number;
    testId: string;
};

export type SourceYamlUpdateTestDescription = {
    type: SOURCE_UPDATE_TEST_DESCRIPTION,
    testIndex: number;
    testDescription: string;
};

export type SourceYamlUpdateTestSuiteDescription = {
    type: SOURCE_UPDATE_TEST_SUITE_DESCRIPTION,
    testSuiteDescription: string;
};

export function getSources(userId: string) {
    return function (dispatch: any) {
        BespokenApi.sources(userId).then((sources: any) => {
            dispatch({
                type: SET_SOURCES,
                sources: sources,
                finishLoading: true,
            });
        });
    };
}

export function getSource(userId: string, sourceId: string, callback: any) {
    return function (dispatch: any, getState: () => State.All) {
        const promise = fetchInternalApi(({ userId, organizationId }) => `/users/${userId}/organizations/${organizationId}/testsuites/${sourceId}`);

        promise
            .then(testSuiteMapper)
            .then((source: any) => {
                dispatch({
                    type: SOURCE_SET_CURRENT,
                    currentSource: source,
                    finishLoading: true,
                });
                dispatch({
                    type: SOURCE_RESULTS_INIT,
                    yamlObject: source.yamlObject,
                });
                return source;
        });


        if (isFunction(callback)) {
            promise
              .then((source) => {
                callback(undefined, source);
              });
          }

          promise.catch(err => callback(err));
    };
}

export function createSource(userId: string, config: any, meta: any, goto: any) {
    return function (dispatch: any) {
        BespokenApi.createSource(userId, config, meta).then((source: any) => {
            dispatch({
                type: SOURCE_SET_CURRENT,
                currentSource: source,
                finishLoading: true,
            });
            dispatch(goto(`/skills/${source?.name}`));
        });
    };
}

export function reorderTest(oldIndex: number, newIndex: number) {
    return function (dispatch: any, getState: any) {
        const { yamlObject: value } = getState().sourceGit?.currentSource;

        value.tests = arrayMove(value.tests, oldIndex, newIndex);

        dispatch({
            type: SOURCE_UPDATE_YAML_OBJECT,
            value,
        });
    };
}

export function saveSource(name: string, sourceId: string, yaml: any, config: any, meta: any, monitoringConfig: any) {
    return function (dispatch: any) {
        return new Promise((resolve, reject) => {
            BespokenApi.validateYaml(yaml).then((result: any) => {
                dispatch({
                    type: SOURCE_SET_IS_VALID_SYNTAX,
                    value: result?.error,
                });
                if (!result?.error) {
                    return fetchInternalApi(({ userId, organizationId }) => `/users/${userId}/organizations/${organizationId}/testsuites/${sourceId}`, "PUT",
                    {
                        yaml, testingJson: config, metadata: meta, monitoringConfig, name,
                    })
                    .then(testSuiteMapper)
                    .then((source: any) => {
                        dispatch({
                            type: SOURCE_SET_CURRENT,
                            currentSource: source,
                            finishLoading: true,
                        });
                        resolve(source);
                    });
                } else {
                    reject(result?.error);
                }
            });
        });
    };
}

export function deleteSource(userId: string, sourceId: string) {
    return function (dispatch: any) {
        dispatch({
            type: SET_LOADING,
            loading: true,
        });
        BespokenApi.deleteSource(userId, sourceId).then((source: any) => {
            dispatch({
                type: SOURCE_DELETE_SOURCE,
                id: sourceId,
            });
            dispatch({
                type: SET_LOADING,
                loading: false,
            });
        });
    };
}

export function updateConfig(property: string, value: string) {
    return function (dispatch: any) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: SOURCE_YAMLOBJECT_UPDATE_CONFIG,
                property,
                value,
            });
            resolve("");
        });

    };
}

export function resetConfig() {
    return function (dispatch: any) {
        return dispatch({
            type: SOURCE_YAMLOBJECT_RESET_CONFIG
        });
    };
}

export function updateInterimResults(results: string[]) {
    return function (dispatch: any) {
        dispatch({
            type: SOURCE_INTERIM_RESULTS_UPDATE,
            interimResults: results
        });
    };
}
export function updateConfigNested(property: string, value: string) {
    return function (dispatch: any) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: SOURCE_YAMLOBJECT_UPDATE_CONFIG_NESTED,
                property,
                value,
            });
            resolve("");
        });

    };
}

export function updateMeta(property: string, value: string) {
    return function (dispatch: any) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: SOURCE_UPDATE_META,
                property,
                value,
            });
            resolve("");
        });
    };
}

export function updateName(value: string) {
    return function (dispatch: any) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: SOURCE_UPDATE_NAME,
                value,
            });
            resolve("");
        });
    };
}
export function updateYaml(value: any) {
    return function (dispatch: any) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: SOURCE_UPDATE_YAML,
                value,
            });
            resolve("");
        });
    };
}

export function updateYamlObject(value: any) {
    return function (dispatch: any) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: SOURCE_UPDATE_YAML_OBJECT,
                value,
            });
            resolve("");
        });
    };
}

export function addTest(name: string) {
    return function (dispatch: any) {
        dispatch({
            type: SOURCE_YAMLOBJECT_ADD_TEST,
            name,
        });
    };
}

export function deleteTest(index: number) {
    return function (dispatch: any) {
        dispatch({
            type: SOURCE_YAMLOBJECT_DELETE_TEST,
            index,
        });
    };
}

export function cloneTest(index: number) {
    return function (dispatch: any) {
        dispatch({
            type: SOURCE_YAMLOBJECT_CLONE_TEST,
            index,
        });
    };
}


export function updateTestName(testIndex: number, name: string) {
    return function (dispatch: any) {
        dispatch({
            type: SOURCE_YAMLOBJECT_UPDATE_TEST_NAME,
            testIndex,
            name,
        });
    };
}

export function updateSkipFlag(testIndex: number, value: boolean) {
    return function (dispatch: any) {
        dispatch({
            type: SOURCE_YAMLOBJECT_UPDATE_SKIP_FLAG,
            testIndex,
            value,
        });
    };
}

export function updateOnlyFlag(testIndex: number, value: boolean) {
    return function (dispatch: any) {
        dispatch({
            type: SOURCE_YAMLOBJECT_UPDATE_ONLY_FLAG,
            testIndex,
            value,
        });
    };
}

export function addInteraction(testIndex: number, interactionIndex?: number) {
    return function (dispatch: any) {
        dispatch({
            type: SOURCE_YAMLOBJECT_ADD_INTERACTION,
            testIndex,
            interactionIndex,
        });
    };
}

export function removeInteraction(testIndex: number, interactionIndex: number) {
    return function (dispatch: any) {
        dispatch({
            type: SOURCE_YAMLOBJECT_REMOVE_INTERACTION,
            testIndex,
            interactionIndex,
        });
    };
}

export function updateInteractionInput(testIndex: number, interactionIndex: number, input: string) {
    return function (dispatch: any) {
        dispatch({
            type: SOURCE_YAMLOBJECT_UPDATE_INTERACTION_INPUT,
            testIndex,
            interactionIndex,
            input,
        });
    };
}

export function addAssertion(testIndex: number, interactionIndex: number) {
    return function (dispatch: any) {
        dispatch({
            type: SOURCE_YAMLOBJECT_ADD_ASSERTION,
            testIndex,
            interactionIndex,
        });
    };
}

export function deleteAssertion(testIndex: number, interactionIndex: number, itemIndex: number) {
    return function (dispatch: any) {
        dispatch({
            type: SOURCE_YAMLOBJECT_DELETE_ASSERTION,
            testIndex,
            interactionIndex,
            itemIndex,
        });
    };
}

export function updateAssertionAction(testIndex: number, interactionIndex: number, itemIndex: number, value: string) {
    return function (dispatch: any) {
        dispatch({
            type: SOURCE_YAMLOBJECT_UPDATE_ASSERTION_ACTION,
            testIndex,
            interactionIndex,
            itemIndex,
            value,
        });
    };
}

export function updateAssertionOperator(testIndex: number, interactionIndex: number, itemIndex: number, value: string) {
    return function (dispatch: any) {
        dispatch({
            type: SOURCE_YAMLOBJECT_UPDATE_ASSERTION_OPERATOR,
            testIndex,
            interactionIndex,
            itemIndex,
            value,
        });
    };
}

export function updateAssertionValue(testIndex: number, interactionIndex: number, itemIndex: number, value: string) {
    return function (dispatch: any) {
        dispatch({
            type: SOURCE_YAMLOBJECT_UPDATE_ASSERTION_VALUE,
            testIndex,
            interactionIndex,
            itemIndex,
            value,
        });
    };
}

export function resultsInit(yamlObject: any, testIndex: number | number[], status: string = TestResultStatus.PENDING) {
    return function (dispatch: any) {
        dispatch({
            type: SOURCE_RESULTS_INIT,
            yamlObject,
        });
        if (!isArray(testIndex)) {
            yamlObject.tests[testIndex]?.interactions?.forEach((interaction: any, interactionIndex: number) => {
                dispatch({
                    type: SOURCE_RESULTS_UPDATE_INTERACTION_ALL_STATUS,
                    testIndex,
                    interactionIndex,
                    status,
                });
            });
        } else {
            testIndex.forEach((index: number) =>
                yamlObject.tests[index]?.interactions?.forEach((interaction: any, interactionIndex: number) => {
                    dispatch({
                        type: SOURCE_RESULTS_UPDATE_INTERACTION_ALL_STATUS,
                        testIndex: index,
                        interactionIndex,
                        status,
                    });
                })
            );
        }
    };
}

export function resultsPending(yamlObject: any) {
    return function (dispatch: any) {
        dispatch({
            type: SOURCE_RESULTS_PENDING,
            yamlObject,
        });
    };
}

export function resultsUpdateInteractionAllStatus(testIndex: number, interactionIndex: number, status: string) {
    return function (dispatch: any) {
        dispatch({
            type: SOURCE_RESULTS_UPDATE_INTERACTION_ALL_STATUS,
            testIndex,
            interactionIndex,
            status,
        });
    };
}

export function resultsUpdate(testIndex: number, interactionIndex: number, conversationId: string, path: string, value: any) {
    return function (dispatch: any) {
        dispatch({
            type: SOURCE_RESULTS_UPDATE,
            testIndex,
            interactionIndex,
            conversationId,
            path,
            value,
        });
    };
}

export function resultsUpdateAllPendingStatus(testIndex: number, status: string) {
    return function (dispatch: any) {
        dispatch({
            type: SOURCE_RESULTS_UPDATE_ALL_PENDING_STATUS,
            testIndex,
            status,
        });
    };
}

export function validateYamlSyntax(yaml: string) {
    return function (dispatch: any) {
        BespokenApi.validateYaml(yaml).then((result: any) => {
            dispatch({
                type: SOURCE_SET_IS_VALID_SYNTAX,
                value: result?.error,
            });
        });
    };
}

export function updateMonitoringSchedule(selectedScheduleType: string, emailsToNotify: string) {
    return function (dispatch: any) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: SOURCE_UPDATE_MONITORING_SCHEDULE_TYPE,
                selectedScheduleType,
                emailsToNotify,
            });
            resolve("");
        });
    };
}



export function updateTestId(testIndex: number, testId: string): SourceYamlUpdateTestId {
    return {
        type: SOURCE_UPDATE_TEST_ID,
        testIndex,
        testId
    };
}

export function updateTestDescription(testIndex: number, testDescription: string): SourceYamlUpdateTestDescription {
    return {
        type: SOURCE_UPDATE_TEST_DESCRIPTION,
        testIndex,
        testDescription
    };
}

export function updateTestSuiteDescription(testSuiteDescription: string): SourceYamlUpdateTestSuiteDescription {
    return {
        type: SOURCE_UPDATE_TEST_SUITE_DESCRIPTION,
        testSuiteDescription
    };
}

export function setSourceSearchText(searchText: string): SetSourceSearchText {
    return {
        type: SET_SOURCE_SEARCH_TEXT,
        searchText,
    };
}