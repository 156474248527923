import * as React from "react";
import * as cn from "classnames";
import { attemptInvoke, cnToggle } from "../../../utils/ReactHelpers";
import { TextBlueLabelMedium, TextBlueLabelSmall } from "../texts/Text";

const Styles = require("./styles.scss");

const COMPONENT = "component"

type ExposedProps = {
    onClick?: () => void;
    disabled?: boolean;
    children: React.ReactNode;
}
type ButtonProps = {}

export const PrimaryButtonSmall = (props: ExposedProps & ButtonProps) => {
    const { children, onClick } = props
    return (
        <button
            className={cn(COMPONENT, 'PrimaryButtonSmall', Styles.component, Styles.button_primary_small)}
            onClick={() => attemptInvoke(onClick)}
        >{children}</button>
    )
}

export const PrimaryButtonMedium = (props: ExposedProps & ButtonProps) => {
    const { children, onClick, disabled } = props
    return (
        <button
            disabled={disabled}
            className={cn(COMPONENT, 'PrimaryButtonMedium', Styles.component, Styles.button_primary_medium)}
            onClick={() => attemptInvoke(onClick)}
        >{children}</button>
    )
}

export const SecondaryButtonSmall = (props: ExposedProps & ButtonProps) => {
    const { children, onClick } = props
    return (
        <button
            className={cn(COMPONENT, 'SecondaryButtonSmall', Styles.component, Styles.button_secondary_small)}
            onClick={() => attemptInvoke(onClick)}
        >{children}</button>
    )
}

export const SecondaryButtonMedium = (props: ExposedProps & ButtonProps) => {
    const { children, onClick } = props
    return (
        <button
            className={cn(COMPONENT, 'SecondaryButtonMedium', Styles.component, Styles.button_secondary_medium)}
            onClick={() => attemptInvoke(onClick)}
        >{children}</button>
    )
}

export const WarningButtonSmall = (props: ExposedProps & ButtonProps) => {
    const { children, onClick } = props
    return (
        <button
            className={cn(COMPONENT, 'WarningButtonSmall', Styles.component, Styles.button_warning_small)}
            onClick={() => attemptInvoke(onClick)}
        >{children}</button>
    )
}

export const WarningButtonMedium = (props: ExposedProps & ButtonProps) => {
    const { children, onClick } = props
    return (
        <button
            className={cn(COMPONENT, 'WarningButtonMedium', Styles.component, Styles.button_warning_medium)}
            onClick={() => attemptInvoke(onClick)}
        >{children}</button>
    )
}

const TextButton = (props: ExposedProps & { children: JSX.Element, controlName: string }) => (
    <a
        className={
            cn(
                COMPONENT,
                props?.controlName,
                Styles.component,
                cnToggle(props?.disabled, Styles.text_disabled, Styles.text_button)
            )
        }
        onClick={() => props?.disabled !== true && attemptInvoke(props?.onClick)}
    >{props?.children}</a>
)

export const TextButtonSmall = (props: ExposedProps & ButtonProps) => {
    return (<TextButton {...props} controlName="TextButtonSmall" ><TextBlueLabelSmall>{props?.children}</TextBlueLabelSmall></TextButton>)
}

export const TextButtonMedium = (props: ExposedProps & ButtonProps) => {
    const { children, onClick } = props
    return (
        <a className={cn(COMPONENT, 'TextButtonMedium', Styles.text_button)}
            onClick={() => attemptInvoke(onClick)}
        >
            <TextBlueLabelMedium>{children}</TextBlueLabelMedium>
        </a>
    )
}