import * as cn from 'classnames';
import { chain, isFunction, uniqueId } from "lodash";
import * as React from "react";
import { cnToggle, joinWithElements, isEqualByPropertiesLabelValue } from "../../utils/ReactHelpers";

const Styles = require("./styles.scss");

type Item = { label: string; value: string; }

interface HorizontalCategoryFilterProps {
    categories?: Item[];
    selectedValue?: string;
    onChangeCategory?: (item: Item) => void
}

interface HorizontalCategoryFilterState {
    items: Item[];
    selectedValue: string;
}

export class HorizontalCategoryFilter extends React.Component<HorizontalCategoryFilterProps, HorizontalCategoryFilterState> {

    static defaultProps: HorizontalCategoryFilterProps = {
        categories: [],
        selectedValue: undefined,
        onChangeCategory: () => { }
    }

    constructor(props: HorizontalCategoryFilterProps) {
        super(props);
        this.state = {
            items: [],
            selectedValue: undefined
        }
    }

    componentDidMount(): void {
        const { categories: items, selectedValue } = this.props
        this.setState({ items, selectedValue })
    }

    componentWillReceiveProps(nextProps: Readonly<HorizontalCategoryFilterProps>, nextContext: any): void {
        if (!isEqualByPropertiesLabelValue(nextProps.categories, this.state.items)) {
            this.setState({ items: nextProps.categories })
        }
    }

    render(): false | JSX.Element {
        const { items, selectedValue } = this.state

        // Using random id to force close popup when value changed
        const uniqId = uniqueId()
        return (
            <div className={Styles.container}>
                {
                    joinWithElements(
                        items,
                        (it: Item, index: number) => <div key={`${it.value}-${index}`}><a className={cn(cnToggle(it.value === selectedValue, Styles.active))} onClick={() => this.handleOnChange(it.value)}>{it.label}</a></div>,
                        (_, index) => <div key={`sep-${index}`} className={cn(Styles.separator)}>|</div>
                    )
                }
            </div>
        )
    }

    handleOnChange(selectedValue: any) {
        this.setState({ selectedValue }, () => this.emitOnChangeCategory())
    }

    emitOnChangeCategory(): void {
        if (!isFunction(this.props?.onChangeCategory)) {
            console.warn('onChangeCategory is not a function')
            return
        }

        const selectedItem: Item = chain(this.state.items).find(({ value }) => value === this.state.selectedValue).clone().value()
        this.props.onChangeCategory(selectedItem)
    }
}
