import * as Bluebird from "bluebird";
import * as moment from "moment";
import { PlanIdTypes, PlanNames } from "../constants/stripe";
import { PaymentMethods } from "../constants/stripe";
import { formatToHumanReadable } from "../helpers/string";
import { UserDetails } from "../models/user";
import auth from "../services/auth";
import logService from "../services/log";
import * as notifier from "../services/notifier";
import * as stripe from "../services/stripe";
import { remoteservice } from "./remote-service";

export enum planStatus {
    Disabled = "disabled",
    Enabled = "enabled",
}

export enum virtualDeviceStatus {
    Suspended = "suspended",
    Disabled = "disabled",
    Enabled = "enabled",
    Trial = "trial",
}

export interface VirtualDevicePlatform {
    imageUrl: string;
    displayName: string;
    alt: string;
    name: string;
}

export const virtualDevicePlatforms: VirtualDevicePlatform[] = [
    {
        imageUrl: "https://bespoken.io/wp-content/uploads/2018/03/amazon-alexa.png",
        displayName: "Amazon Alexa",
        alt: "alexa icon",
        name: "alexa",
    },
    {
        imageUrl: "https://bespoken.io/wp-content/uploads/2018/03/google-actions.png",
        displayName: "Google Assistant",
        alt: "google action icon",
        name: "google",
    }
];

export const PAYMENT_ENABLED = process.env.ENABLE_PAYMENT && process.env.ENABLE_PAYMENT === "true" ? true : false;

export class Payment {
    private logService: any;
    private notifier: any;
    private stripe: any;
    private remoteservice: any;
    public plansData: any;

    constructor(logServiceRef?: any, notifierRef?: any, stripeRef?: any, remoteserviceRef?: any) {
        this.logService = logServiceRef ? logServiceRef : logService;
        this.notifier = notifierRef ? notifierRef : notifier;
        this.stripe = stripeRef ? stripeRef : stripe;
        this.remoteservice = remoteserviceRef ? remoteserviceRef : remoteservice;
        this.stripePlanId = this.stripePlanId.bind(this);
    }

    public getPlansData = async () => {
        return {};
    }

    public getPlatforms = async (plandId: string, humanReadable?: boolean) => {
        if (!this.plansData) {
            await this.getUserPlansData();
        }
        if (humanReadable) {
            return formatToHumanReadable(this.plansData[plandId]?.benefits?.platforms, true);
        }
        return this.plansData[plandId]?.benefits?.platforms;
    }

    public getUserPlansData = async () => {
        {};
    }

    public async getNumberVirtualDevicesUsed(userDetail: UserDetails) {
        let details = userDetail;
        if (!details) details = await auth.currentUserDetails();
        if (details) {
            const { virtualDevices } = details;
            const devicesObject: any = virtualDevices;
            return !virtualDevices
                ? (details.silentEchoToken ? 1 : 0)
                : ((virtualDevices && Object.keys(virtualDevices).map(key => devicesObject[key])) || []).filter(vd => vd.status !== "disabled").length;
        }
        return 0;
    }

    public getSourcesLimitNumber = async (planId: string) => {
        if (!this.plansData) {
            await this.getUserPlansData();
        }
        const benefits = this.plansData[planId] && this.plansData[planId].benefits;
        if (benefits) {
            return benefits.monitoredSkills || Number.POSITIVE_INFINITY;
        }
        // Plan benefits does not exist
        return Number.POSITIVE_INFINITY;
    }

    public getUtterancesLimitNumber = async (planId: string) => {
        if (!this.plansData) {
            await this.getUserPlansData();
        }

        const benefits = this.plansData[planId] && this.plansData[planId].benefits;
        if (benefits) {
            return benefits.utterances || Number.POSITIVE_INFINITY;
        }
        // plan benefits does not exist
        return Number.POSITIVE_INFINITY;
    }

    public getDowngradePlanMetadata = async (oldPlanId: string, newPlanId: string, platforms: string, isYearly: boolean) => {
        if (!this.plansData) {
            await this.getUserPlansData();
        }
        platforms = platforms === "alexa" ? "Alexa" : "Google";
        let oldPlanName = this.plansData && this.plansData[oldPlanId].displayName;
        let newPlanName = this.plansData && this.plansData[newPlanId].displayName + " " + platforms;

        if (oldPlanId === newPlanId) {
            oldPlanName = this.plansData && this.plansData[oldPlanId].displayName + " " + (platforms === "Alexa" ? "Google" : "Alexa");
        }

        const metaData = {
            old_plan_name: oldPlanName,
            new_plan_name: newPlanName,
            platforms,
            date_of_change: moment().format(),
            effective_since: moment().format(),
            isYearly,
        };

        return metaData;
    }

    public getUpgradePlanMetadata = async (oldPlanId: string, newPlanId: string, oldPlatform: string, isYearly: boolean) => {
        if (!this.plansData) {
            await this.getUserPlansData();
        }
        let oldPlanName = this.plansData && this.plansData[oldPlanId].displayName;
        if (oldPlanId === PlanIdTypes.SINGLE_PLATFORM && oldPlatform) {
            oldPlatform === "alexa" ? "Alexa" : "Google";
            oldPlanName += " " + oldPlatform;
        }
        const metaData = {
            old_plan_name: oldPlanName,
            new_plan_name: this.plansData && this.plansData[newPlanId].displayName,
            platforms: "Alexa AND Google",
            date_of_change: moment().format(),
            effective_since: moment().format(),
            isYearly,
        };

        return metaData;
    }

    public getsubscribeMetadata = async (planId: string, platforms: string, isYearly: boolean) => {
        if (!this.plansData) {
            await this.getUserPlansData();
        }

        const planDetail = await stripe.getPlanDetail(this.stripePlanId(planId));
        const amount = planDetail && planDetail.amount.toString();
        const planPrice = amount.substr(0, amount.length - 2);

        let planName = this.plansData && this.plansData[planId].displayName;
        if (planId === PlanIdTypes.SINGLE_PLATFORM) {
            platforms = platforms === "alexa" ? "Alexa" : "Google";
            planName = this.plansData && this.plansData[planId].displayName + " " + platforms;
        }

        const metaData = {
            plan_name: planName,
            plan_limit: this.plansData && this.plansData[planId].benefits && this.plansData[planId].benefits.utterances,
            plan_price: planPrice,
            platforms,
            date: moment().format(),
            isYearly,
        };
        return metaData;
    }

    public getUnsubscribeMetadata = async (planId: string, platforms: string, stripeSubscribedId: string) => {
        if (!this.plansData) {
            await this.getUserPlansData();
        }

        let oldPlanName = this.plansData && this.plansData[planId].displayName;
        if (planId === PlanIdTypes.SINGLE_PLATFORM) {
            platforms = platforms === "alexa" ? "Alexa" : "Google";
            oldPlanName = this.plansData && this.plansData[planId].displayName + " " + platforms;
        }

        const subscribeInformation = await stripe.getSubscriptionDetail(stripeSubscribedId);
        const endPeriod = new Date(0);
        endPeriod.setUTCSeconds(subscribeInformation.current_period_end);
        const account_downgrades_at = moment(endPeriod).format();

        const metaData = {
            old_plan_name: oldPlanName,
            old_plan_limit: this.plansData && this.plansData[planId].benefits && this.plansData[planId].benefits.utterances,
            platforms,
            date: moment().format(),
            account_downgrades_at
        };

        return metaData;
    }

    public getVirtualDevicesLimitNumber = async (planId: string) => {
        if (!this.plansData) {
            await this.getUserPlansData();
        }
        const benefits = this.plansData[planId] && this.plansData[planId].benefits;
        if (benefits) {
            return benefits.virtualDevices || Number.POSITIVE_INFINITY;
        }
        // plan benefits does not exist
        return Number.POSITIVE_INFINITY;
    }

    public getPlatformRestriction = async (planId: string) => {
        if (!this.plansData) { await this.getUserPlansData(); }
        if (planId === PlanIdTypes.CROSS_PLATFORM) {
            return [];
        }

        const benefits = this.plansData[planId] && this.plansData[planId].benefits;
        if (benefits && benefits.platforms) {
            return benefits.platforms.length ? benefits.platforms : [];
        }
        // plan benefits does not exist
        return [];
    }

    public getPrice = async (plan: string) => {
        if (plan === "Enterprise") return "0";
        const planDetail: stripe.Plan = (await this.stripe.getPlanDetail(plan));
        if (planDetail && planDetail.amount) {
            // taking off the decimal part
            const amount = planDetail.amount.toString();
            const price = amount.substr(0, amount.length - 2);
            return price;
        }
        return "0";
    }

    getDisplayPlanName = async (planId: string, withPlatform?: boolean) => {
        await this.getUserPlansData();
        const plansData = this.plansData;
        let planName = (plansData[planId] && plansData[planId].name) || planId;
        if (withPlatform) {
            if (planId === PlanIdTypes.SINGLE_PLATFORM) {
                let platform = plansData[planId].benefits && plansData[planId].benefits.platforms && plansData[planId].benefits.platforms[0];
                if (platform) {
                    platform = platform.charAt(0).toUpperCase() + platform.slice(1);
                }
                planName = `${planName} ${platform}`;
            }
        }
        return planName;
    }

    getPlanId = (planName: string) => {
        switch (planName) {
            case PlanNames.ENTERPRISE: return PlanIdTypes.ENTERPRISE;
            case PlanNames.SINGLE_PLATFORM: return PlanIdTypes.SINGLE_PLATFORM;
            case PlanNames.CROSS_PLATFORM: return PlanIdTypes.CROSS_PLATFORM;
            default: return planName;
        }
    }

    public stripePlanId = (planId: string, yearly?: boolean) => {
        switch (planId) {
            case PlanIdTypes.SINGLE_PLATFORM: return yearly ? this.plansData[PlanIdTypes.SINGLE_PLATFORM].stripeYearlyPlanId : this.plansData[PlanIdTypes.SINGLE_PLATFORM].stripePlanId;
            case PlanIdTypes.CROSS_PLATFORM: return yearly ? this.plansData[PlanIdTypes.CROSS_PLATFORM].stripeYearlyPlanId : this.plansData[PlanIdTypes.CROSS_PLATFORM].stripePlanId;
            case PlanNames.SINGLE_PLATFORM: return  yearly ? this.plansData[PlanIdTypes.SINGLE_PLATFORM].stripeYearlyPlanId : this.plansData[PlanIdTypes.SINGLE_PLATFORM].stripePlanId;
            case PlanNames.CROSS_PLATFORM: return  yearly ? this.plansData[PlanIdTypes.CROSS_PLATFORM].stripeYearlyPlanId : this.plansData[PlanIdTypes.CROSS_PLATFORM].stripePlanId;
            case PlanIdTypes.VIRTUAL_ASSISTANTS: return  yearly ? this.plansData[PlanIdTypes.VIRTUAL_ASSISTANTS].stripeYearlyPlanId : this.plansData[PlanIdTypes.VIRTUAL_ASSISTANTS].stripePlanId;
            case PlanIdTypes.CONTACT_CENTERS: return  yearly ? this.plansData[PlanIdTypes.CONTACT_CENTERS].stripeYearlyPlanId : this.plansData[PlanIdTypes.CONTACT_CENTERS].stripePlanId;

            default: return planId;
        }
    }

    public getMonitoredSkillNumber = (sources: any[] = []) =>
        sources.reduce((acc: number, source: any) => {
            const firstCondition = source.source && source.source.validation_enabled;
            const secondCondition = source && source.validation_enabled;

            if (firstCondition || secondCondition) acc++;
            return acc;
        }, 0)

    public checkMonitoredSkillLimits = async (plan: string,
        sources: any[], userEmail: string) => {
        try {

            const allowedSkill = await this.getSourcesLimitNumber(plan);
            const totalSkillCount = this.getMonitoredSkillNumber(sources);
            if (totalSkillCount > allowedSkill) {
                await this.notifier.reportSkillAlertToSales(userEmail, plan, totalSkillCount, allowedSkill);
            }

        } catch (error) {
            console.error("There was a problem trying to verify the monitored skill limits of the subscribed plan", error);
        }
    }

    public isPaymentEnabled = (): boolean => {
        return PAYMENT_ENABLED;
    }

    public getBillingDates = (subscription: any, userDetails: any) => {
        let lastBillingDate = undefined;
        let nextBillingDate = undefined;
        if (subscription) {
            lastBillingDate = moment.unix(subscription.current_period_start);
            nextBillingDate = moment.unix(subscription.current_period_end);

            // tricky section for bad response(yearly plan get monthly periods) in dev env
            // this should be deleted in the future
            if (userDetails?.yearlyPlan) {
                const diff = nextBillingDate.diff(lastBillingDate, "months");
                if (diff === 0) {
                    nextBillingDate = lastBillingDate.clone().add(1, "year");
                }
            }
            // end of the tricky section
        }
        return {
            lastBillingDate,
            nextBillingDate,
        };
    }

    private getCardDetail = (card: any) => {
        const cardExpiration = (card.exp_year.toString()).substring(2, 4);
        const sourceCardExpirationDate = `${card.exp_month}/${cardExpiration}`;
        const sourceCardType = card.brand;
        const sourceLast4 = card.last4;
        const sourcePaymentMethod = "Credit Card";
        return { sourceCardExpirationDate, sourceCardType, sourceLast4, sourceType: PaymentMethods.CARD, sourcePaymentMethod };
    }

    public getStripeSourceFields = (stripeSource: any): any => {
        const { type, object } = stripeSource;

        if (object === PaymentMethods.CARD && !type) {
            return this.getCardDetail(stripeSource);
        }

        if (type === PaymentMethods.CARD) {
            return this.getCardDetail(stripeSource[type]);
        } else if (type === PaymentMethods.ACH_CREDIT_TRANSFER) {
            const achObj = stripeSource[type];
            const sourceLast4 = achObj.account_number?.slice(-4);
            const sourceCardType = achObj.bank_name;
            const sourcePaymentMethod = "ACH Credit transfer";
            return { sourceLast4, sourceCardType, sourceType: type, sourcePaymentMethod };
        }
        return {};
    }

    public getQuoteUsageDates = async (stripeSubscriptionId?: string, userId?: string): Promise<any> => {
        // const { startQuotaUsageDate, endQuotaUsageDate } =
        //     await sourceService.getQuotaUsageMonthlyDates(stripeSubscriptionId, userId);
        // return {
        //     startQuotaUsageDate: moment(startQuotaUsageDate),
        //     endQuotaUsageDate: moment(endQuotaUsageDate),
        // };
    }
}
