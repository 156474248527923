import React = require("react");
import Input from "react-toolbox/lib/input";
import { Button } from "react-toolbox/lib/button";
import { User } from "../../../reducers/user";
import { SelectedOrganization } from "../../../reducers/organization";


const AccountStyle = require("../account-style.scss");

const InputTheme = require("../../../themes/input.scss");
const bespokenButton = require("../../../themes/bespoken_button.scss");

interface UserDetailsSectionProps {
    user: User;
    organization: SelectedOrganization;
    updateUser: (user: User) => Promise<any>;
    setLoading: (value: boolean) => any;
    changeSnackMessage: (snackbarMessage: string) => any;
}

interface UserDetailsSectionState {
    name: string;
    company: string;
    jobTitle: string;
    email: string;
    error: string;
}

const MAX_FIELD_LENGTH = 100;
const MIN_FIELD_LENGTH = 2;

export class UserDetailsSection extends React.Component<UserDetailsSectionProps, UserDetailsSectionState> {
    constructor(props: UserDetailsSectionProps) {
        super(props);
        this.state = {
            name: props.user.name,
            company: props.organization.name,
            jobTitle: props.user.jobTitle,
            email: props.user.email,
            error: '',
        };
    }

    componentDidUpdate(prevProps: Readonly<UserDetailsSectionProps>): void {
        if (prevProps.user?.name !== this.props.user?.name) {
            this.setState(prevState => ({ name: this.props.user.name }));
        }
    }

    handleInputChange = (value: string, e: any) => {
        const inputName: string = e.target.name;
        // @ts-ignore
        this.setState(prevState => ({ [inputName]: value }));
    }

    handleInputBlur = (value: any, e: any) => {
        const inputName = value.target.name;
        let originalValue = value.target.value;
        let formattedValue = value.target.value;
        if (formattedValue && formattedValue.trim) {
            formattedValue = formattedValue.trim();
        }
        if (formattedValue !== originalValue) {
            // @ts-ignore
            this.setState(prevState => ({ [inputName]: formattedValue }));
        }
    }

    validateForm = () => {
        const { name, company, jobTitle } = this.state;

        if (!name) {
            this.setState((prevState) => ({
                ...prevState,
                error: "Name can not be empty",
            }));
            return false;
        } else if (name.length < MIN_FIELD_LENGTH || name.length > MAX_FIELD_LENGTH) {
            this.setState((prevState) => ({
                ...prevState,
                error: `Name must have between ${MIN_FIELD_LENGTH} and ${MAX_FIELD_LENGTH} letters`,
            }));
            return false;
        } else if (/[^a-zA-Z ]/.test(name)) {
            this.setState((prevState) => ({
                ...prevState,
                error: `Numbers and symbols are not allowed for name`,
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                ...prevState,
                error: '',
            }));
        }

        if (!company) {
            this.setState((prevState) => ({
                ...prevState,
                error: "Company can not be empty",
            }));
            return false;
        } else if (company.length < MIN_FIELD_LENGTH || company.length > MAX_FIELD_LENGTH) {
            this.setState((prevState) => ({
                ...prevState,
                error: `Company must have between ${MIN_FIELD_LENGTH} and ${MAX_FIELD_LENGTH} letters`,
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                ...prevState,
                error: '',
            }));
        }

        if (!jobTitle) {
            this.setState((prevState) => ({
                ...prevState,
                error: "Job title can not be empty",
            }));
            return false;
        } else if (jobTitle.length < MIN_FIELD_LENGTH || jobTitle.length > MAX_FIELD_LENGTH) {
            this.setState((prevState) => ({
                ...prevState,
                error: `Job title must have between ${MIN_FIELD_LENGTH} and ${MAX_FIELD_LENGTH} letters`,
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                ...prevState,
                error: '',
            }));
        }

        return true;
    }

    handleSave = () => {
        const { setLoading } = this.props;

        const isValid = this.validateForm();
        if (!isValid) {
            return;
        }

        const userId = this.props.user.id;
        setLoading && setLoading(true);
        this.props.updateUser({
            id: userId,
            name: this.state.name,
            jobTitle: this.state.jobTitle,
        })?.then((result: any) => {
            setLoading && setLoading(false);
            if (result.error) {
                this.setState({ error: result.errror });
            } else {
                this.props.changeSnackMessage("Your user details have been updated.");
            }
        })
    }

    render() {
        return (
            <div className={`${AccountStyle.accountSection}`}>
                <div className={AccountStyle.description}>
                    <h4>User Details</h4>
                    <span>Let us know how we should address you</span>
                </div>
                <div className={AccountStyle.content}>
                    <div className={AccountStyle.fields_container}>
                        <div className={AccountStyle.property}>
                            <span>Full name</span>
                            <Input
                                name='name'
                                maxLength={MAX_FIELD_LENGTH}
                                theme={InputTheme}
                                className={InputTheme.user_input}
                                value={this.state.name}
                                onBlur={this.handleInputBlur}
                                onChange={this.handleInputChange} />
                        </div>

                        <div className={AccountStyle.property}>
                            <span>Company</span>
                            <Input
                                name='company'
                                maxLength={MAX_FIELD_LENGTH}
                                theme={InputTheme}
                                className={InputTheme.user_input}
                                value={this.state.company}
                                onBlur={this.handleInputBlur}
                                onChange={this.handleInputChange} />
                        </div>

                        <div className={AccountStyle.property}>
                            <span>Job title</span>
                            <Input
                                name='jobTitle'
                                maxLength={MAX_FIELD_LENGTH}
                                theme={InputTheme}
                                className={InputTheme.user_input}
                                value={this.state.jobTitle}
                                onBlur={this.handleInputBlur}
                                onChange={this.handleInputChange} />
                        </div>

                        <div className={`${AccountStyle.property}`}>
                            <span>Email</span>
                            <div className={`${AccountStyle.fix_margin}`}>{this.state.email}</div>
                        </div>
                    </div>
                    <div className={AccountStyle.button_container}>
                        <span className={AccountStyle.error}>{this.state.error}</span>
                        <Button
                            theme={bespokenButton}
                            className={bespokenButton.medium}
                            accent={true}
                            label={"Save"}
                            onClick={this.handleSave} />
                    </div>
                </div>
            </div>
        )
    }
}