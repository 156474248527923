import * as React from "react";
import { attemptInvoke } from "../../../utils/ReactHelpers";
import { ToggleLink, ToggleLinkItem } from "../../ToggleLink/ToggleLink";

type AZSortingLinkProps = { onChange: (val: any) => void }
export const AZSortingLink = (props: AZSortingLinkProps) => {
    const { onChange } = props
    return (
        <div>
        <ToggleLink onValueChanged={val => attemptInvoke<AZSortingLinkProps>(onChange, val)}>
            <ToggleLinkItem value="AZ">AZ {String.fromCharCode(8595)}</ToggleLinkItem>
            <ToggleLinkItem value="ZA">AZ {String.fromCharCode(8593)}</ToggleLinkItem>
        </ToggleLink>
        </div>
    )
}