import * as cn from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { setLoading } from "../../actions/loading";
import { fetchOrganization, fetchSources } from "../../actions/organization";
import { setCurrentPageProps } from "../../actions/context";
import { ERROR, INFO } from "../../components/lunacy/debug-panel/DebugPanel";
import { State } from "../../reducers";
import { Subscription, OrganizationSource } from "../../reducers/organization";
import { CurrentPageProps } from "../../reducers/session";
const globalWindow: any = typeof (window) !== "undefined" ? window : {};
import BespokenCheckNoCircleIcon from "../../../assets/bespoken_check_no_circle_icon.svg";
import UtterancesIcon from "../../../assets/utterances.svg";
import VirtualDevicesIcon from "../../../assets/virtual_devices.svg";
import MonitoringIcon from "../../../assets/monitoring.svg";
import Slider from "react-toolbox/lib/slider";
import { fetchInternalApi } from "../../services/internal-api";
import { wrapCallbackAsAsync } from "../../utils/ReactHelpers";
import { defaultTo } from "lodash";
const bespokenButton = require("../../themes/bespoken_button.scss");

const Styles = require("./styles.scss");

interface DispatchToProps {
    fetchOrganization: () => Promise<void>;
    setLoading: (value: boolean) => void;
    setCurrentPageProps?: (value: CurrentPageProps) => any;
    goTo?: (path: string) => void;
    organizationId: string;
}
function mapDispatchToProps(dispatch: any) {
    return {
        fetchOrganization: async () => wrapCallbackAsAsync(handle => dispatch(fetchOrganization("current", handle))),
        setLoading: (value: boolean) => dispatch(setLoading(value)),
        setCurrentPageProps: (value: CurrentPageProps) => dispatch(setCurrentPageProps(value)),
        goTo: (path: string) => dispatch(push(path)),
    };
}

interface StateToProps { }
function mapStateToProps(state: State.All) {
    return {
        organizationId: state.organization?.selectedOrganization?.id,
    };
}

interface ExposedProps { }
function mergeProps(ownProps: any, stateProps: any, dispatchProps: ExposedProps) {
    return { ...ownProps, ...stateProps, ...dispatchProps }
}

interface PageProps extends DispatchToProps, StateToProps {
}

interface PageState {
    utterances: number;
    virtualDevicesCount: number;
    startDate: string;
    endDate: string;
    usageRate: number;
    planUtterances: number;
    monitoredTestSuites: number;
}

class BillingPageComponent extends React.Component<PageProps, PageState> {

    static defaultProps: PageProps = {
        fetchOrganization: async () => { },
        setLoading: () => { },
        organizationId: '',
    }

    constructor(props: PageProps) {
        super(props);
        this.state = {
            utterances: 0,
            usageRate: 0,
            planUtterances: 0,
            virtualDevicesCount: 0,
            startDate: '',
            endDate: '',
            monitoredTestSuites: 0,
        }
    }

    componentWillReceiveProps(nextProps: Readonly<PageProps>, nextContext: any): void { }

    async componentDidUpdate(prevProps: PageProps, prevState: PageState) { }

    async componentDidMount(): Promise<void> {
        this.props.setCurrentPageProps({ title: "Subscription", subTitle: "& Billing" })
        try {
            this.props.setLoading(true)
            const usage = await fetchInternalApi(({ userId, organizationId }) =>
                `/users/${userId}/organizations/${organizationId}/usage`)
            this.setState(usage)

        } catch (err) {
            ERROR("Error fetching sources", err)
        }


        this.props.setLoading(false)

    }

    render(): false | JSX.Element {
        return (<div className={cn(Styles.container)}>
            <Benefits filteredPlatform="test" />
            <Usage
                utterances={this.state.utterances}
                planUtterances={this.state.planUtterances}
                usageRate={this.state.usageRate}
                virtualDevicesCount={this.state.virtualDevicesCount}
                monitoredTestSuites={this.state.monitoredTestSuites}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
            />
        </div>)
    }
}

export const BillingPage = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(BillingPageComponent);


const BenefitsStyles = require("./benefits.scss");
type BenefitsProps = {
    benefits: Subscription;
}
type BenefitsExposedProps = {
    filteredPlatform: string;
}
const Benefits = connect(
    function mapStateToProps(state: State.All) {
        const benefits = state.organization?.selectedOrganization?.subscription
        return {
            benefits,
        };
    },
    undefined,
    function mergeProps(ownProps: any, stateProps: any, dispatchProps: BenefitsExposedProps) {
        return { ...ownProps, ...stateProps, ...dispatchProps }
    }
)((props: BenefitsProps & BenefitsExposedProps) => {
    const { benefits } = props
    const { planDetails = [], planName } = benefits
    const planTier = 'Professional'
    return (
        <div className={BenefitsStyles.container}>
            <div><b>You are subscribed to a</b></div>
            <div className={BenefitsStyles.plan}>
                {planTier}<br />
                <strong>{planName} PLAN</strong>
            </div>
            <div className={BenefitsStyles.plan_details}>
                <span>This plan includes:</span>
                <ul>
                    {planDetails.map((detail, index) =>
                        <li key={index}><BespokenCheckNoCircleIcon data-test="no-circle-check" />{detail}</li>
                    )}
                </ul>
            </div>
            <div className={BenefitsStyles.button_container}>
                <a className={BenefitsStyles.label} href={"mailto:sales@bespoken.io?subject=Bespoken%20unsubscribe&body=Please%20contact%20me%20-%20I%20want%20to%20unsubscribe%20from%20my%20current%20paid%20plan."}>Unsubscribe</a>
            </div>
        </div>
    )
})

const UsageStyles = require("./usage.scss");
type UsageProps = {
}
type UsageExposedProps = {
    utterances: number;
    planUtterances: number;
    usageRate: number;
    virtualDevicesCount: number;
    startDate: string;
    endDate: string;
    monitoredTestSuites: number;
}
const Usage = connect(
    function mapStateToProps(state: State.All) { },
    undefined,
    function mergeProps(ownProps: any, stateProps: any, dispatchProps: UsageExposedProps) {
        return { ...ownProps, ...stateProps, ...dispatchProps }
    }
)((props: UsageProps & UsageExposedProps) => {
    const { utterances, planUtterances, usageRate, virtualDevicesCount, startDate, endDate, monitoredTestSuites } = props
    const start = startDate ? new Date(startDate) : new Date()
    const end = endDate ? new Date(endDate) : new Date()

    const startDateFormatted = `${start.getUTCMonth() + 1}/${start.getUTCDate()}`
    const endDateFormatted = `${end.getUTCMonth() + 1}/${end.getUTCDate()}`
    const usageRagePercent = usageRate <= 1 ? usageRate * 100 : 100;

    const quotaMessage = `${utterances} utterances used out of ${planUtterances} available (${Math.round(usageRagePercent)}%)`
    const sliderClas = usageRate < .6 ?
        UsageStyles.green :
        usageRate < .9 ?
            UsageStyles.yellow :
            usageRate < 1 ?
                UsageStyles.orange :
                UsageStyles.red;
    return (
        <div className={UsageStyles.container}>
            <div className={UsageStyles.panel}>
                <div><div>{virtualDevicesCount}</div><span><VirtualDevicesIcon />Virtual Devices</span></div>
                <div><div>{monitoredTestSuites}</div><span><MonitoringIcon />Monitored Test Suites</span></div>
                <hr className={UsageStyles.break} />
                <div><div>{utterances}</div><span><UtterancesIcon className={UsageStyles.utterances} />Utterances this month</span></div>
            </div>
            <div className={UsageStyles.bottom_panel}>
                <div>Monthly Quota Usage</div>
                <div>
                    <div>{quotaMessage}</div>
                    <div className={UsageStyles.slider}>
                        <Slider onChange={() => { }} value={usageRagePercent} className={sliderClas} />
                        <div className={UsageStyles.periods}>
                            <span>{startDateFormatted}</span>
                            <span>{endDateFormatted}</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
})
