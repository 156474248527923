import * as React from "react";
import LoginGoogleBg from "../../../assets/login_google.svg";

const authFormStyle = require("./AuthFormStyle.scss");

interface LoginGoogleProps {
    onLoginWithGoogle?: () => void;
    location?: any;
}

export default class LoginGoogle extends React.Component<LoginGoogleProps, any> {

    componentDidMount() {
        if (this.props.location && this.props.location.query && this.props.location.query.google_login === "1") {
            this.props.onLoginWithGoogle && this.props.onLoginWithGoogle();
        }
    }

    render() {
      if (!this.props.onLoginWithGoogle) {
        return <noscript />;
      }
      return (
          <div id={"login_google_id"} className={authFormStyle.google_login_button} onClick={this.props.onLoginWithGoogle}>
            <LoginGoogleBg />
              <label>Log in with Google</label>
        </div>
      );
    }
}
