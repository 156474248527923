import * as React from "react";
import { Button, IconButton } from "react-toolbox/lib/button";
import { Checkbox } from "../../components";
import { updateIntercom, sendAccountDeletedAtIntercomEvent } from "../../services/intercom";
import moment = require("moment");
import BespokenApi from "../../services/bespoken-api";

const AccountStyle = require("./AccountStyle.scss");
const bespokenButton = require("../../themes/bespoken_button.scss");
const style = require("./DeleteAccountStyle.scss");

export interface DeleteAccountProps {
    handleCancelClick: () => void;
    userId: string;
    logout: () => void;
    globalWindow?: any;
    setLoading: any;
}

interface DeleteAccountState {
    [key: string]: any;
    error: string;
    success: string;
    currentText: string;
    // saveButtonDisabled: boolean;
    reasonsToQuit?: any[];
    txtOtherQuitReason?: string;
    txtOtherQuitReasonDisabled: boolean;
    currentTextDisabled: boolean;
}

export class DeleteAccount extends React.Component<DeleteAccountProps, DeleteAccountState> {
    confirmInput: any;
    otherReasonInput: any;

    constructor(props: any) {
        super(props);
        this.state = {
            error: "",
            currentText: "",
            success: "",
            // saveButtonDisabled: true,
            reasonsToQuit: this.getQuitReasons(),
            txtOtherQuitReason: "",
            currentTextDisabled: true,
            txtOtherQuitReasonDisabled: true,
        };
    }

    handleSaveClick = async () => {
        // if (this.state.currentText !== "yes delete my account") {
        //     this.setState((prevState) => ({
        //         ...prevState,
        //         error: "Text must be 'yes delete my account'",
        //         success: "",
        //         saveButtonDisabled: true,
        //         repeatPassword: "",
        //     }));

        //     this.confirmInput.focus();
        //     return;
        // }
        this.props.setLoading(true);
        const verification = await this.verifyAndDeleteUserAccount();

        if (verification) {
            this.setState((prevState) => ({ ...prevState, error: "", success: "" }));
            await this.props.handleCancelClick();
            this.props.setLoading(false);
            this.props.logout();
        }
        this.props.setLoading(false);
    }

    verifyAndDeleteUserAccount = async () => {
        try {
            // TODO implement delete account
            // await sourceService.deleteDataFromUserAccount(this.props.userId);
            const accountDeletedSurvey = this.getSurveyDetails();
            if (this.props && this.props.globalWindow) {
                sendAccountDeletedAtIntercomEvent(this.props.globalWindow);
                await updateIntercom(this.props.globalWindow, { accountDeletedAt: moment().unix(), accountDeletedSurvey });
            }
            await BespokenApi.deleteUser(this.props.userId);
        } catch (error) {
            this.setState((prevState) => ({
                ...prevState,
                error: "There was an issue with your request, please try again later.",
                success: ""
            }));
            return false;
        }
        return true;
    }

    handleOnChangeField = (event: any) => {
        if (event && event.target) {
            const value = event.target.value;
            let currentTextDisabled: boolean;
            if (value.trim().length === 0) {
                const other = true;
                currentTextDisabled = this.isCurrentTextDisabled(other);
            } else {
                currentTextDisabled = false;
            }
            this.setState((prevState) => ({ ...prevState, txtOtherQuitReason: value, currentTextDisabled }), () => {
            });
        }
    }
    getSurveyDetails = () => {
        let surveyDetails = "";
        for (const reason of this.state.reasonsToQuit) {
            if (reason.value) {
                surveyDetails += reason.id !== "other" ? reason.id + "," : "";
            }
        }
        if (this.state.txtOtherQuitReasonDisabled || this.state.txtOtherQuitReason.trim().length === 0) {
            surveyDetails = surveyDetails.substr(0, surveyDetails.length - 1);
        } else {
            surveyDetails += "other:" + this.state.txtOtherQuitReason.trim();
        }
        return surveyDetails;
    }

    getQuitReasons = () =>
        [
            { label: "I was just playing around", value: false, id: "playingAround" },
            { label: "Bespoken tools do not fulfill my needs", value: false, id: "cantFullfillNeeds" },
            { label: "Can’t afford the price", value: false, id: "cantAffordPrice" },
            { label: "The trial period was enough for my needs", value: false, id: "trialWasEnough" },
            { label: "I found another product that I like better", value: false, id: "anotherProduct" },
            { label: "Too complex, I didn’t know how to use it", value: false, id: "tooComplex" },
            { label: "other", value: false, id: "other" },
        ]

    isCurrentTextDisabled = (other?: boolean) => {
        const currentTextEnabled = this.state.reasonsToQuit.some((obj) => {
            if (obj.label === "other" && obj.value) {
                if (other) {
                    return false;
                }
                return !(this.state.txtOtherQuitReason.trim().length === 0);
            }
            return obj.value === true;
        });
        return !currentTextEnabled;
    }


    handleChangeCheckBox = (value: boolean, index?: number) => {
        const array = this.state.reasonsToQuit;
        let currentTextDisabled = this.state.currentTextDisabled;
        let txtOtherQuitReasonDisabled = this.state.txtOtherQuitReasonDisabled;
        if (index !== undefined && array[index]) {
            array[index].value = value;
            currentTextDisabled = this.isCurrentTextDisabled();
            if (index === 6) {
                txtOtherQuitReasonDisabled = !(value === true);
            }

            this.setState((prevState) => ({
                ...prevState, reasonsToQuit: array, currentTextDisabled,
                txtOtherQuitReasonDisabled
            }), () => {
                if (index === 6 && value === true) {
                    this.otherReasonInput.focus();
                }
            });
        }
    }
    getOtherReasonToQuit = () => (
        <div data-id="others">
            <input
                placeholder={"Others (specify):"}
                name={"otherReason"}
                key={"otherReason"}
                ref={(input) => this.otherReasonInput = input}
                maxLength={200}
                type="text"
                value={this.state.txtOtherQuitReason}
                disabled={this.state.txtOtherQuitReasonDisabled} onChange={(value: any) => this.handleOnChangeField(value)} />
        </div>
    )
    renderSurvey = () => {
        return (
            <div data-id="reasons" className={style.checkBoxContainer}>
                {
                    this.state.reasonsToQuit && this.state.reasonsToQuit.map((reason: any, index: any) => {
                        const key = "reason" + index;
                        return (
                            reason.label !== "other" ?
                                (
                                    <Checkbox
                                        key={key}
                                        index={key}
                                        label={reason.label}
                                        checked={this.state.reasonsToQuit[index].value}
                                        onChange={(value: any) => this.handleChangeCheckBox(value, index)}/>) :
                                (
                                    <Checkbox
                                        className={style.checkbox_div}
                                        key={key}
                                        index={key}
                                        label={this.getOtherReasonToQuit()}
                                        checked={this.state.reasonsToQuit[index].value}
                                        onChange={(value: any) => this.handleChangeCheckBox(value, index)}/>
                                )
                        );
                    })
                }
            </div>
        );
    }

    render() {
        const {reasonsToQuit, txtOtherQuitReason} = this.state;
        const otherReasonCheck = reasonsToQuit && reasonsToQuit[6];
        const otherReasonTextFilled = !!txtOtherQuitReason;
        const disableSaveButton = otherReasonCheck && otherReasonCheck.value && !otherReasonTextFilled;
        return (
            <div className={`${AccountStyle.dialogContainer} ${style.deleteAccount}`}>
                <div>
                    <p>This will permanently delete your Bespoken Dashboard account and erase all of your registered
                        test suites and Virtual Devices. This process can't be undone.</p>
                </div>
                <div>
                    <p>Before continuing, please tell us why you’re leaving:</p>
                    {this.renderSurvey()}
                </div>
                <div>
                    <p>We are sad to see you leave. Click the delete button to delete your account.</p>
                </div>
                <div className={AccountStyle.validation}>
                    <label className={AccountStyle.error}>{this.state.error}</label>
                    <label className={AccountStyle.success}>{this.state.success}</label>
                </div>
                <div className={`${AccountStyle.deleteAccountButton} ${AccountStyle.v2}`}>
                    <Button
                        type="button"
                        theme={bespokenButton}
                        onClick={this.props.handleCancelClick}>
                        Cancel
                    </Button>
                    <Button
                        className={bespokenButton.warning}
                        type="button"
                        theme={bespokenButton}
                        onClick={this.handleSaveClick}
                        accent={true}
                        disabled={disableSaveButton}>
                        Delete
                    </Button>
                </div>
            </div>
        );
    }
}

