import * as React from "react";
import BespokenButtonIcon from "../../../assets/bespoken_button_icon.svg";
import BespokenLogo from "../../../assets/bespoken_logoweb.svg";
import BespokenMountains from "../../../assets/bespoken_mountains.svg";
import * as backgroundLeft from "../../../assets/images/bespoken_survey_second_no_text.jpg";
import SignalIcon from "../../../assets/signal.svg";
import {
    Button, Checkbox,
    Input, LeftPanel, Loader,
    Radiobox, RightPanel, Slider
} from "../../components";
import { User } from "../../reducers/user";
import { isEmpty } from "lodash";

const style = require("./secondStepStyles.scss");

interface SecondStepProps {
    onStepChange: (data: any) => void;
    setLoading: any;
    changeSnackMessage: (snackbarMessage: string) => void;
    email: string;
    user: User;
}

interface SecondStepStates {
    [key: string]: any;
    usabilityTestingImportance?: any;
    unitTestingImportance?: any;
    e2eTestingImportance?: any;
    monitoringImportance?: any;
    proxyImportance?: any;
    logsStatsImportance?: any;
    voiceAppsPlatforms?: any;
    txtOtherPlatform?: string;
    liveApps?: any;
    painPoint?: string;
}

export default class SecondStep extends React.Component<SecondStepProps, SecondStepStates> {
    constructor(props: SecondStepProps) {
        super(props);
        this.state = {
            usabilityTestingImportance: 1,
            unitTestingImportance: 1,
            e2eTestingImportance: 1,
            monitoringImportance: 1,
            proxyImportance: 1,
            logsStatsImportance: 1,
            voiceAppsPlatforms: [],
            txtOtherPlatform: "",
            liveApps: "",
            painPoint: "",
        };
    }

    getReadableSliderValues = (value: number) => {
        const messages = ["Not important",
            "Slightly important",
            "Important",
            "Fairly important",
            "Extremely important"];
        return messages[value - 1];
    }

    getBooleanFromReadableString = (text: string) => text === "yes" ? true : false;

    getReadableArray = (array: string[]) => array.join(" & ");

    handleOnChangeField = (value: number | string, field: string) => {
        this.setState({ [field]: value });
    }

    handleChangePlatforms = (isChecked: boolean, value?: string) => {
        let { voiceAppsPlatforms } = this.state;
        if (isChecked) {
            voiceAppsPlatforms = voiceAppsPlatforms.concat(value);
        } else {
            voiceAppsPlatforms = voiceAppsPlatforms.filter((platform: any) => platform !== value);
        }
        this.setState({ voiceAppsPlatforms });
    }

    renderSlider = (label: string, field: string) => (
        <div className={style.inputBlock}>
            <label className={style.primaryText} htmlFor="">{`${label}:`}</label>
            <Slider range={[1, 5]}
                value={this.state[field]}
                onChange={(value: number) => this.handleOnChangeField(value, field)} />
        </div>
    )

    renderFields = () => (
        <div className={style.fieldsBlock}>
            {this.renderSlider("Usability Testing", "usabilityTestingImportance")}
            {this.renderSlider("Unit testing", "unitTestingImportance")}
            {this.renderSlider("End to end testing", "e2eTestingImportance")}
            {this.renderSlider("Monitoring", "monitoringImportance")}
            {/* {this.renderSlider("CLI and Proxy", "proxyImportance")} */}
            {this.renderSlider("Logs and stats", "logsStatsImportance")}
        </div>
    )

    sendSurvey = () => {
        const data = { ...this.state };
        const otherPlatform = data.txtOtherPlatform.trim();
        if (otherPlatform) {
            data.voiceAppsPlatforms = data.voiceAppsPlatforms.concat(`Other: ${otherPlatform}`);
        }
        data.voiceAppsPlatforms = this.getReadableArray(data.voiceAppsPlatforms);
        delete data.txtOtherPlatform;

        data.usabilityTestingImportance = this.getReadableSliderValues(data.usabilityTestingImportance);
        data.unitTestingImportance = this.getReadableSliderValues(data.unitTestingImportance);
        data.e2eTestingImportance = this.getReadableSliderValues(data.e2eTestingImportance);
        data.monitoringImportance = this.getReadableSliderValues(data.monitoringImportance);
        data.proxyImportance = this.getReadableSliderValues(data.proxyImportance);
        data.logsStatsImportance = this.getReadableSliderValues(data.logsStatsImportance);
        data.liveApps = this.getBooleanFromReadableString(data.liveApps);
        this.props.onStepChange(data);
    }
    changeSnackMessage = (snackbarMessage: string) => {
        this.setState((prevState) => ({
            ...prevState,
            showSnackbar: true,
            snackbarMessage,
        }));
    }
    handleErrorMessage = async (errorMessage: string) => {
        if (errorMessage) {
            this.props.changeSnackMessage(errorMessage);
        } else {
            this.props.changeSnackMessage("There was an error handling your request. Please, try again in a few moments.");
        }
    }
    render() {
        const { onboardingData } = this.props?.user || {}
        const ibmUser = !isEmpty(onboardingData)
        const { voiceAppsPlatforms, liveApps } = this.state;
        const isAlexaChecked = voiceAppsPlatforms.indexOf("Alexa") >= 0;
        const isGoogleChecked = voiceAppsPlatforms.indexOf("Google") >= 0;
        const isPhoneChecked = voiceAppsPlatforms.indexOf("Phone") >= 0;
        const isWebchatChecked = voiceAppsPlatforms.indexOf("Webchat") >= 0;
        const appLiveRadiosBtn = [{ label: "Yes", value: "yes" }, { label: "No", value: "no" }];
        const enableMailChimp = process.env.ENABLE_MAILCHIMP;

        return (
            <div>
                <LeftPanel className={style.leftPanel} fullHeight={true} image={backgroundLeft}>
                    <div data-id="details">
                        <SignalIcon data-id="icon" />
                        <div data-id="message1">Welcome to your Dashboard</div>
                        <div data-id="message2">optimize voice</div>
                        <div data-id="message3">ux outcomes</div>
                    </div>
                    <div data-id="companyName">Bespoken</div>
                </LeftPanel>
                <RightPanel className={style.rightPanel} fullHeight={true}>
                    <section>
                        <BespokenLogo data-id="icon" />
                        <div data-id="message1">Helps us to better help you!</div>
                        <div className={style.secondaryText} data-id="message2">Tell us about what testing and automation tools are most important to you...</div>
                        <div data-id="ranking">
                            <div data-id="fake" />
                            <div data-id="titles">
                                <span className={style.secondaryText} data-id="noImportant">Not<br />Important</span>
                                <span className={style.secondaryText} data-id="important">Extremely<br />Important</span>
                            </div>
                        </div>
                        {this.renderFields()}
                        <div className={style.secondaryText} data-id="message2">... and a bit more about your voice project</div>
                        <div className={style.primaryText} data-id="separation">Which platforms are you developing for?</div>
                        <div className={style.secondaryText}>You can select more than one</div>
                        <div data-id="platforms">
                            {ibmUser &&  [
                                <Checkbox key="phoneCheck" label="Phone" checked={isPhoneChecked} onChange={(value: any) => this.handleChangePlatforms(value, "Phone")} />,
                                <Checkbox key="webchatCheck" label="Webchat" checked={isWebchatChecked} onChange={(value: any) => this.handleChangePlatforms(value, "Webchat")} />
                            ]}
                            {!ibmUser &&  [
                                <Checkbox key="alexaCheck" label="Alexa" checked={isAlexaChecked} onChange={(value: any) => this.handleChangePlatforms(value, "Alexa")} /> ,
                                <Checkbox key="googleCheck" label="Google" checked={isGoogleChecked} onChange={(value: any) => this.handleChangePlatforms(value, "Google")} />
                            ]}
                            <div data-id="others">
                                <label htmlFor="" className={style.primaryText}>Others (please specify)</label>
                                <Input onChange={(value: any) => this.handleOnChangeField(value, "txtOtherPlatform")} />
                            </div>
                        </div>
                        <div data-id="painPoint">
                            <div className={style.primaryText} data-id="separation">What is your biggest pain point with testing for voice?</div>
                            <Input onChange={(value: any) => this.handleOnChangeField(value, "painPoint")} />
                        </div>
                        <div data-id="appLive">
                            <div className={style.primaryText}>Are your test suites live?</div>
                            <Radiobox items={appLiveRadiosBtn}
                                value={liveApps}
                                onChange={(value: string) => this.handleOnChangeField(value, "liveApps")} />
                        </div>
                        <div data-id="continue">
                            <div data-id={enableMailChimp ? "" : "newsLetter"}>
                                <div className={style.secondaryText}>Stay connected</div>
                                <NewsLetterCheckBox
                                    handleErrorMessage={this.handleErrorMessage}
                                    setLoading={this.props.setLoading}
                                />
                            </div>
                            <Button icon={<BespokenButtonIcon />} iconToRight accent label="Continue" onClick={this.sendSurvey} />
                        </div>
                    </section>
                    <BespokenMountains data-id="mountains" />
                </RightPanel>
                {this.state.showLoader && <Loader />}
            </div>
        );
    }
}

interface NewsLetterCheckBoxProps {
    handleErrorMessage: (errorMessage?: string) => void;
    setLoading?: (value: boolean) => void;
    onSuccess?: any;
}

interface NewsLetterCheckBoxState {
    isNewsLetterChecked: boolean;
}

export class NewsLetterCheckBox extends React.Component<NewsLetterCheckBoxProps, NewsLetterCheckBoxState> {
    constructor(props: NewsLetterCheckBoxProps) {
        super(props);
        this.state = {
            isNewsLetterChecked: true
        };
    }
    componentDidMount() {
    }

    handleOnChange = async (value: any) => {
        this.props.setLoading && this.props.setLoading(true);
        try {
            this.props.onSuccess && this.props.onSuccess();
            this.setState(prevState => ({ ...prevState, isNewsLetterChecked: value, }));
        } catch (error) {
            const errorMessage = error;
            this.props.handleErrorMessage &&
                this.props.handleErrorMessage(errorMessage);
        }
        this.props.setLoading && this.props.setLoading(false);
    }


    render() {
        return (
            <Checkbox label="Subscribe to our newsletter" checked={this.state.isNewsLetterChecked}
                onChange={(value: any) => this.handleOnChange(value)} />
        );
    }
}
