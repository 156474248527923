export default class WebsocketClient {
  init (url: string, handlers: any) {
    let client = new WebSocket(url);

    client.addEventListener("open", () => {
      console.log(`[websockets] Connected to ${url}`);
      handlers?.onOpen();
    });

    client.addEventListener("close", () => {
      console.log(`[websockets] Disconnected from ${url}`);
      client = undefined;
    });

    client.addEventListener("message", (event) => {
      if (event?.data && handlers.onMessage) {
        handlers.onMessage(event.data);
      }
    });

    const connection = {
      client,
      send: (message = {}) => {
        if (!client) {
          throw new Error("WS connection is closed");
        }
        return client.send(JSON.stringify(message));
      },
      close: () => {
        console.log(`[websockets] closing connection ${url}`);
        client.close();
      }
    };

    return connection;
  }
}